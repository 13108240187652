// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file restError.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.RestError
 */
export class RestError extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string simpleBody = 2;
     */
    this.simpleBody = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RestError().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RestError().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RestError().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RestError, a, b);
  }
}
RestError.runtime = proto3;
RestError.typeName = "GoThrive.RestError";
RestError.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "jsonBody",
  kind: "message",
  T: RestError_ErrorJson
}, {
  no: 2,
  name: "simpleBody",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.RestError.ErrorJson
 */
export class RestError_ErrorJson extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string message = 1;
     */
    this.message = "";
    /**
     * @generated from field: int32 statusCode = 2;
     */
    this.statusCode = 0;
    /**
     * @generated from field: string name = 3;
     */
    this.name = "";
    /**
     * @generated from field: string stack = 4;
     */
    this.stack = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RestError_ErrorJson().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RestError_ErrorJson().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RestError_ErrorJson().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RestError_ErrorJson, a, b);
  }
}
RestError_ErrorJson.runtime = proto3;
RestError_ErrorJson.typeName = "GoThrive.RestError.ErrorJson";
RestError_ErrorJson.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "statusCode",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "stack",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);