// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file exercise.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Any, Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Background, ExerciseOptionRow, NavigationBar, Popup } from "./common_pb.js";
import { Moment, RelaxationLevel, RelaxationTime } from "./moment_pb.js";
import { ResponseError } from "./error_pb.js";
import { EntryTable, GoCoreUnityEntry } from "./goCoreUnity_pb.js";
import { CommandEvalAssessment } from "./assessment_pb.js";
/**
 * @generated from message GoThrive.ExerciseConfig
 */
export class ExerciseConfig extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 4;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.ExerciseOption data = 5;
     */
    this.data = [];
    /**
     * @generated from field: string exitConfirmationText = 6;
     */
    this.exitConfirmationText = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ExerciseConfig().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ExerciseConfig().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ExerciseConfig().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ExerciseConfig, a, b);
  }
}
ExerciseConfig.runtime = proto3;
ExerciseConfig.typeName = "GoThrive.ExerciseConfig";
ExerciseConfig.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 3,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 4,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 5,
  name: "data",
  kind: "message",
  T: ExerciseOption,
  repeated: true
}, {
  no: 6,
  name: "exitConfirmationText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "exitConfirmationPopup",
  kind: "message",
  T: Popup
}]);
/**
 * @generated from message GoThrive.ExerciseOption
 */
export class ExerciseOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ExerciseOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ExerciseOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ExerciseOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ExerciseOption, a, b);
  }
}
ExerciseOption.runtime = proto3;
ExerciseOption.typeName = "GoThrive.ExerciseOption";
ExerciseOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.ExerciseOptionList
 */
export class ExerciseOptionList extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 optionIndex = 1;
     */
    this.optionIndex = 0;
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string icon = 3;
     */
    this.icon = "";
    /**
     * @generated from field: repeated GoThrive.ExerciseOptionRow values = 4;
     */
    this.values = [];
    /**
     * @generated from field: string default = 5;
     */
    this.default = "";
    /**
     * @generated from field: string indicator = 6;
     */
    this.indicator = "";
    /**
     * @generated from field: string style = 7;
     */
    this.style = "";
    /**
     * @generated from field: string description = 8;
     */
    this.description = "";
    /**
     * @generated from field: int32 displayIndex = 9;
     */
    this.displayIndex = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ExerciseOptionList().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ExerciseOptionList().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ExerciseOptionList().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ExerciseOptionList, a, b);
  }
}
ExerciseOptionList.runtime = proto3;
ExerciseOptionList.typeName = "GoThrive.ExerciseOptionList";
ExerciseOptionList.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "optionIndex",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "icon",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "values",
  kind: "message",
  T: ExerciseOptionRow,
  repeated: true
}, {
  no: 5,
  name: "default",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "indicator",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "displayIndex",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.ExerciseOptionTable
 */
export class ExerciseOptionTable extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string spacer = 2;
     */
    this.spacer = "";
    /**
     * @generated from field: string divider = 3;
     */
    this.divider = "";
    /**
     * @generated from field: string footer = 4;
     */
    this.footer = "";
    /**
     * @generated from field: repeated string entries = 5;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ExerciseOptionTable().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ExerciseOptionTable().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ExerciseOptionTable().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ExerciseOptionTable, a, b);
  }
}
ExerciseOptionTable.runtime = proto3;
ExerciseOptionTable.typeName = "GoThrive.ExerciseOptionTable";
ExerciseOptionTable.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "spacer",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "divider",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "footer",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "entries",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.ExerciseCatalog
 */
export class ExerciseCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.Exercise> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ExerciseCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ExerciseCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ExerciseCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ExerciseCatalog, a, b);
  }
}
ExerciseCatalog.runtime = proto3;
ExerciseCatalog.typeName = "GoThrive.ExerciseCatalog";
ExerciseCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Exercise
  }
}]);
/**
 * @generated from message GoThrive.ExerciseConfigCatalog
 */
export class ExerciseConfigCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.ExerciseConfig> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ExerciseConfigCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ExerciseConfigCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ExerciseConfigCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ExerciseConfigCatalog, a, b);
  }
}
ExerciseConfigCatalog.runtime = proto3;
ExerciseConfigCatalog.typeName = "GoThrive.ExerciseConfigCatalog";
ExerciseConfigCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: ExerciseConfig
  }
}]);
/**
 * @generated from message GoThrive.Exercise
 */
export class Exercise extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: int64 variation = 2;
     */
    this.variation = protoInt64.zero;
    /**
     * @generated from field: repeated string pageIds = 4;
     */
    this.pageIds = [];
    /**
     * @generated from field: repeated GoThrive.ExercisePage pages = 5;
     */
    this.pages = [];
    /**
     * @generated from field: string resultId = 6;
     */
    this.resultId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Exercise().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Exercise().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Exercise().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Exercise, a, b);
  }
}
Exercise.runtime = proto3;
Exercise.typeName = "GoThrive.Exercise";
Exercise.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "variation",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 4,
  name: "pageIds",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 5,
  name: "pages",
  kind: "message",
  T: ExercisePage,
  repeated: true
}, {
  no: 6,
  name: "resultId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ExercisePage
 */
export class ExercisePage extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string pageType = 2;
     */
    this.pageType = "";
    /**
     * @generated from field: repeated string data = 3;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ExercisePage().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ExercisePage().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ExercisePage().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ExercisePage, a, b);
  }
}
ExercisePage.runtime = proto3;
ExercisePage.typeName = "GoThrive.ExercisePage";
ExercisePage.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "pageType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "data",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.CommandBeginExercise
 */
export class CommandBeginExercise extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: int32 variant = 2;
     */
    this.variant = 0;
    /**
     * @generated from field: string sessionID = 3;
     */
    this.sessionID = "";
    /**
     * @generated from field: string page = 5;
     */
    this.page = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandBeginExercise().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandBeginExercise().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandBeginExercise().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandBeginExercise, a, b);
  }
}
CommandBeginExercise.runtime = proto3;
CommandBeginExercise.typeName = "GoThrive.CommandBeginExercise";
CommandBeginExercise.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "variant",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "sessionID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "exercise",
  kind: "message",
  T: Exercise
}, {
  no: 5,
  name: "page",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandStepExercise
 */
export class CommandStepExercise extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sessionID = 2;
     */
    this.sessionID = "";
    /**
     * @generated from field: bool prev = 3;
     */
    this.prev = false;
    /**
     * @generated from field: string page = 4;
     */
    this.page = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandStepExercise().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandStepExercise().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandStepExercise().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandStepExercise, a, b);
  }
}
CommandStepExercise.runtime = proto3;
CommandStepExercise.typeName = "GoThrive.CommandStepExercise";
CommandStepExercise.fields = proto3.util.newFieldList(() => [{
  no: 2,
  name: "sessionID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "prev",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 4,
  name: "page",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandCheckExercise
 */
export class CommandCheckExercise extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sessionID = 2;
     */
    this.sessionID = "";
    /**
     * @generated from field: bool inProgress = 3;
     */
    this.inProgress = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandCheckExercise().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandCheckExercise().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandCheckExercise().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandCheckExercise, a, b);
  }
}
CommandCheckExercise.runtime = proto3;
CommandCheckExercise.typeName = "GoThrive.CommandCheckExercise";
CommandCheckExercise.fields = proto3.util.newFieldList(() => [{
  no: 2,
  name: "sessionID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "inProgress",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.CommandEndExercise
 */
export class CommandEndExercise extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sessionID = 2;
     */
    this.sessionID = "";
    /**
     * @generated from field: bool completed = 3;
     */
    this.completed = false;
    /**
     * @generated from field: repeated google.protobuf.Any nestedMoments = 4;
     */
    this.nestedMoments = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandEndExercise().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandEndExercise().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandEndExercise().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandEndExercise, a, b);
  }
}
CommandEndExercise.runtime = proto3;
CommandEndExercise.typeName = "GoThrive.CommandEndExercise";
CommandEndExercise.fields = proto3.util.newFieldList(() => [{
  no: 2,
  name: "sessionID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "completed",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 4,
  name: "nestedMoments",
  kind: "message",
  T: Any,
  repeated: true
}]);
/**
 * @generated from message GoThrive.CommandLinkVisited
 */
export class CommandLinkVisited extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string LinkName = 1;
     */
    this.LinkName = "";
    /**
     * @generated from field: string LinkUrl = 2;
     */
    this.LinkUrl = "";
    /**
     * @generated from field: bool LinkIsHelpLink = 3;
     */
    this.LinkIsHelpLink = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandLinkVisited().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandLinkVisited().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandLinkVisited().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandLinkVisited, a, b);
  }
}
CommandLinkVisited.runtime = proto3;
CommandLinkVisited.typeName = "GoThrive.CommandLinkVisited";
CommandLinkVisited.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "LinkName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "LinkUrl",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "LinkIsHelpLink",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.CommandGetLatestDaily
 */
export class CommandGetLatestDaily extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetLatestDaily().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetLatestDaily().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetLatestDaily().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetLatestDaily, a, b);
  }
}
CommandGetLatestDaily.runtime = proto3;
CommandGetLatestDaily.typeName = "GoThrive.CommandGetLatestDaily";
CommandGetLatestDaily.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "dailyCheckup",
  kind: "message",
  T: Moment
}]);
/**
 * @generated from message GoThrive.CommandGetLatestGADPHQ
 */
export class CommandGetLatestGADPHQ extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetLatestGADPHQ().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetLatestGADPHQ().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetLatestGADPHQ().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetLatestGADPHQ, a, b);
  }
}
CommandGetLatestGADPHQ.runtime = proto3;
CommandGetLatestGADPHQ.typeName = "GoThrive.CommandGetLatestGADPHQ";
CommandGetLatestGADPHQ.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "gadphq",
  kind: "message",
  T: Moment
}]);
/**
 * @generated from message GoThrive.CommandGetLatestRelaxationRating
 */
export class CommandGetLatestRelaxationRating extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetLatestRelaxationRating().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetLatestRelaxationRating().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetLatestRelaxationRating().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetLatestRelaxationRating, a, b);
  }
}
CommandGetLatestRelaxationRating.runtime = proto3;
CommandGetLatestRelaxationRating.typeName = "GoThrive.CommandGetLatestRelaxationRating";
CommandGetLatestRelaxationRating.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "RelaxationRating",
  kind: "message",
  T: Moment
}]);
/**
 * @generated from message GoThrive.CommandGetMoment
 */
export class CommandGetMoment extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated string momentTypes = 1;
     */
    this.momentTypes = [];
    /**
     * @generated from field: repeated GoThrive.Moment moments = 2;
     */
    this.moments = [];
    /**
     * @generated from field: repeated GoThrive.ResponseError errors = 3;
     */
    this.errors = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetMoment().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetMoment().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetMoment().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetMoment, a, b);
  }
}
CommandGetMoment.runtime = proto3;
CommandGetMoment.typeName = "GoThrive.CommandGetMoment";
CommandGetMoment.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "momentTypes",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 2,
  name: "moments",
  kind: "message",
  T: Moment,
  repeated: true
}, {
  no: 3,
  name: "errors",
  kind: "message",
  T: ResponseError,
  repeated: true
}, {
  no: 4,
  name: "count",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}]);
/**
 * @generated from message GoThrive.CommandSaveMoment
 */
export class CommandSaveMoment extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string momentType = 1;
     */
    this.momentType = "";
    /**
     * @generated from field: repeated string subGoal = 4;
     */
    this.subGoal = [];
    /**
     * @generated from field: map<string, GoThrive.EntryTable> durations = 6;
     */
    this.durations = {};
    /**
     * @generated from field: string sessionId = 7;
     */
    this.sessionId = "";
    /**
     * @generated from field: string ActivityKind = 11;
     */
    this.ActivityKind = "";
    /**
     * @generated from field: int32 progress = 15;
     */
    this.progress = 0;
    /**
     * @generated from field: int32 total = 16;
     */
    this.total = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSaveMoment().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSaveMoment().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSaveMoment().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSaveMoment, a, b);
  }
}
CommandSaveMoment.runtime = proto3;
CommandSaveMoment.typeName = "GoThrive.CommandSaveMoment";
CommandSaveMoment.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "momentType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "moment",
  kind: "message",
  T: Moment
}, {
  no: 3,
  name: "adaptorType",
  kind: "message",
  T: GoCoreUnityEntry
}, {
  no: 4,
  name: "subGoal",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 5,
  name: "assessmentResult",
  kind: "message",
  T: CommandEvalAssessment
}, {
  no: 6,
  name: "durations",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: EntryTable
  }
}, {
  no: 7,
  name: "sessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "momentExt",
  kind: "message",
  T: Moment
}, {
  no: 9,
  name: "subMoment",
  kind: "message",
  T: Moment
}, {
  no: 10,
  name: "anyMessage",
  kind: "message",
  T: Any
}, {
  no: 11,
  name: "ActivityKind",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 12,
  name: "previousResult",
  kind: "message",
  T: Any
}, {
  no: 13,
  name: "relaxationLevel",
  kind: "message",
  T: RelaxationLevel
}, {
  no: 14,
  name: "relaxationTime",
  kind: "message",
  T: RelaxationTime
}, {
  no: 15,
  name: "progress",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 16,
  name: "total",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.CommandSaveAR
 */
export class CommandSaveAR extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string momentType = 1;
     */
    this.momentType = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSaveAR().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSaveAR().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSaveAR().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSaveAR, a, b);
  }
}
CommandSaveAR.runtime = proto3;
CommandSaveAR.typeName = "GoThrive.CommandSaveAR";
CommandSaveAR.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "momentType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "moment",
  kind: "message",
  T: Moment
}, {
  no: 3,
  name: "adaptorType",
  kind: "message",
  T: GoCoreUnityEntry
}, {
  no: 4,
  name: "assessmentResult",
  kind: "message",
  T: CommandEvalAssessment
}]);
/**
 * @generated from message GoThrive.CommandSaveARSession
 */
export class CommandSaveARSession extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string momentType = 1;
     */
    this.momentType = "";
    /**
     * @generated from field: int32 progress = 6;
     */
    this.progress = 0;
    /**
     * @generated from field: int32 total = 7;
     */
    this.total = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSaveARSession().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSaveARSession().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSaveARSession().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSaveARSession, a, b);
  }
}
CommandSaveARSession.runtime = proto3;
CommandSaveARSession.typeName = "GoThrive.CommandSaveARSession";
CommandSaveARSession.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "momentType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "moment",
  kind: "message",
  T: Moment
}, {
  no: 3,
  name: "adaptorType",
  kind: "message",
  T: GoCoreUnityEntry
}, {
  no: 4,
  name: "relaxationLevel",
  kind: "message",
  T: RelaxationLevel
}, {
  no: 5,
  name: "RelaxationTime",
  kind: "message",
  T: RelaxationTime
}, {
  no: 6,
  name: "progress",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 7,
  name: "total",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.CommandSaveARRelaxation
 */
export class CommandSaveARRelaxation extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string momentType = 1;
     */
    this.momentType = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSaveARRelaxation().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSaveARRelaxation().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSaveARRelaxation().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSaveARRelaxation, a, b);
  }
}
CommandSaveARRelaxation.runtime = proto3;
CommandSaveARRelaxation.typeName = "GoThrive.CommandSaveARRelaxation";
CommandSaveARRelaxation.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "momentType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "moment",
  kind: "message",
  T: Moment
}, {
  no: 3,
  name: "adaptorType",
  kind: "message",
  T: GoCoreUnityEntry
}, {
  no: 4,
  name: "assessmentResult",
  kind: "message",
  T: CommandEvalAssessment
}, {
  no: 5,
  name: "beforeSession",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}]);
/**
 * @generated from message GoThrive.CommandSaveRapidRelaxation
 */
export class CommandSaveRapidRelaxation extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string momentType = 1;
     */
    this.momentType = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSaveRapidRelaxation().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSaveRapidRelaxation().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSaveRapidRelaxation().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSaveRapidRelaxation, a, b);
  }
}
CommandSaveRapidRelaxation.runtime = proto3;
CommandSaveRapidRelaxation.typeName = "GoThrive.CommandSaveRapidRelaxation";
CommandSaveRapidRelaxation.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "momentType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "moment",
  kind: "message",
  T: Moment
}, {
  no: 3,
  name: "adaptorType",
  kind: "message",
  T: GoCoreUnityEntry
}, {
  no: 4,
  name: "assessmentResult",
  kind: "message",
  T: CommandEvalAssessment
}, {
  no: 5,
  name: "TimeTakenToRelax",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}]);
/**
 * @generated from message GoThrive.CommandGetLatestMomentByType
 */
export class CommandGetLatestMomentByType extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string momentType = 1;
     */
    this.momentType = "";
    /**
     * @generated from field: repeated GoThrive.Moment moments = 2;
     */
    this.moments = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetLatestMomentByType().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetLatestMomentByType().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetLatestMomentByType().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetLatestMomentByType, a, b);
  }
}
CommandGetLatestMomentByType.runtime = proto3;
CommandGetLatestMomentByType.typeName = "GoThrive.CommandGetLatestMomentByType";
CommandGetLatestMomentByType.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "momentType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "moments",
  kind: "message",
  T: Moment,
  repeated: true
}, {
  no: 3,
  name: "adaptorType",
  kind: "message",
  T: GoCoreUnityEntry
}, {
  no: 4,
  name: "assessmentResult",
  kind: "message",
  T: CommandEvalAssessment
}, {
  no: 5,
  name: "TimeTakenToRelax",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 6,
  name: "Count",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}]);
/**
 * @generated from message GoThrive.CommandSaveActivityMoment
 */
export class CommandSaveActivityMoment extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string momentType = 1;
     */
    this.momentType = "";
    /**
     * @generated from field: string SessionId = 3;
     */
    this.SessionId = "";
    /**
     * @generated from field: string ActivityKind = 4;
     */
    this.ActivityKind = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSaveActivityMoment().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSaveActivityMoment().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSaveActivityMoment().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSaveActivityMoment, a, b);
  }
}
CommandSaveActivityMoment.runtime = proto3;
CommandSaveActivityMoment.typeName = "GoThrive.CommandSaveActivityMoment";
CommandSaveActivityMoment.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "momentType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "moment",
  kind: "message",
  T: Moment
}, {
  no: 3,
  name: "SessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "ActivityKind",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandSaveDaily
 */
export class CommandSaveDaily extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string momentType = 1;
     */
    this.momentType = "";
    /**
     * @generated from field: repeated string subGoal = 4;
     */
    this.subGoal = [];
    /**
     * @generated from field: map<string, GoThrive.EntryTable> durations = 6;
     */
    this.durations = {};
    /**
     * @generated from field: string sessionId = 7;
     */
    this.sessionId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSaveDaily().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSaveDaily().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSaveDaily().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSaveDaily, a, b);
  }
}
CommandSaveDaily.runtime = proto3;
CommandSaveDaily.typeName = "GoThrive.CommandSaveDaily";
CommandSaveDaily.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "momentType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "moment",
  kind: "message",
  T: Moment
}, {
  no: 3,
  name: "adaptorType",
  kind: "message",
  T: GoCoreUnityEntry
}, {
  no: 4,
  name: "subGoal",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 5,
  name: "assessmentResult",
  kind: "message",
  T: CommandEvalAssessment
}, {
  no: 6,
  name: "durations",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: EntryTable
  }
}, {
  no: 7,
  name: "sessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "momentExt",
  kind: "message",
  T: Moment
}, {
  no: 9,
  name: "subMoment",
  kind: "message",
  T: Moment
}, {
  no: 10,
  name: "anyMessage",
  kind: "message",
  T: Any
}]);