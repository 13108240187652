// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file uiActionResponse.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Any, Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.Action
 */
export class Action extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string type = 1;
     */
    this.type = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string body = 3;
     */
    this.body = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Action().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Action().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Action().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Action, a, b);
  }
}
Action.runtime = proto3;
Action.typeName = "GoThrive.Action";
Action.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.UIResponse
 */
export class UIResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, string> headers = 3;
     */
    this.headers = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new UIResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new UIResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new UIResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(UIResponse, a, b);
  }
}
UIResponse.runtime = proto3;
UIResponse.typeName = "GoThrive.UIResponse";
UIResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Response",
  kind: "message",
  T: Any
}, {
  no: 2,
  name: "action",
  kind: "message",
  T: Action
}, {
  no: 3,
  name: "headers",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "scalar",
    T: 9 /* ScalarType.STRING */
  }
}]);