// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file chats.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.ChatMessagesUrlParams
 */
export class ChatMessagesUrlParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string chatSessionId = 1;
     */
    this.chatSessionId = "";
    /**
     * @generated from field: string chatMessageKey = 2;
     */
    this.chatMessageKey = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatMessagesUrlParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatMessagesUrlParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatMessagesUrlParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatMessagesUrlParams, a, b);
  }
}
ChatMessagesUrlParams.runtime = proto3;
ChatMessagesUrlParams.typeName = "GoThrive.ChatMessagesUrlParams";
ChatMessagesUrlParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "chatSessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "chatMessageKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChatMessagesBody
 */
export class ChatMessagesBody extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string message = 1;
     */
    this.message = "";
    /**
     * @generated from field: int64 clientTime = 2;
     */
    this.clientTime = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatMessagesBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatMessagesBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatMessagesBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatMessagesBody, a, b);
  }
}
ChatMessagesBody.runtime = proto3;
ChatMessagesBody.typeName = "GoThrive.ChatMessagesBody";
ChatMessagesBody.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "clientTime",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);
/**
 * @generated from message GoThrive.ChatParticipant
 */
export class ChatParticipant extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string userId = 1;
     */
    this.userId = "";
    /**
     * @generated from field: string firstName = 2;
     */
    this.firstName = "";
    /**
     * @generated from field: string lastName = 3;
     */
    this.lastName = "";
    /**
     * @generated from field: string email = 4;
     */
    this.email = "";
    /**
     * @generated from field: string patientId = 5;
     */
    this.patientId = "";
    /**
     * @generated from field: string therapistId = 6;
     */
    this.therapistId = "";
    /**
     * @generated from field: string role = 7;
     */
    this.role = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatParticipant().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatParticipant().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatParticipant().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatParticipant, a, b);
  }
}
ChatParticipant.runtime = proto3;
ChatParticipant.typeName = "GoThrive.ChatParticipant";
ChatParticipant.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "userId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "firstName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "lastName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "patientId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "therapistId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "role",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChatParticipants
 */
export class ChatParticipants extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.ChatParticipant entries = 1;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatParticipants().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatParticipants().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatParticipants().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatParticipants, a, b);
  }
}
ChatParticipants.runtime = proto3;
ChatParticipants.typeName = "GoThrive.ChatParticipants";
ChatParticipants.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "message",
  T: ChatParticipant,
  repeated: true
}]);