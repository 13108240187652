// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file mfa.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.MFAOptions
 */
export class MFAOptions extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.MFAOption Options = 1;
     */
    this.Options = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MFAOptions().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MFAOptions().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MFAOptions().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MFAOptions, a, b);
  }
}
MFAOptions.runtime = proto3;
MFAOptions.typeName = "GoThrive.MFAOptions";
MFAOptions.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Options",
  kind: "message",
  T: MFAOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.MFAOption
 */
export class MFAOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Type = 1;
     */
    this.Type = "";
    /**
     * @generated from field: string Label = 2;
     */
    this.Label = "";
    /**
     * @generated from field: string Description = 3;
     */
    this.Description = "";
    /**
     * @generated from field: string Id = 4;
     */
    this.Id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MFAOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MFAOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MFAOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MFAOption, a, b);
  }
}
MFAOption.runtime = proto3;
MFAOption.typeName = "GoThrive.MFAOption";
MFAOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Label",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "Description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "Id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.MFAOptionsParams
 */
export class MFAOptionsParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated string scopes = 1;
     */
    this.scopes = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MFAOptionsParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MFAOptionsParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MFAOptionsParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MFAOptionsParams, a, b);
  }
}
MFAOptionsParams.runtime = proto3;
MFAOptionsParams.typeName = "GoThrive.MFAOptionsParams";
MFAOptionsParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "scopes",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.CommandChangeGetMfaConfiguration
 */
export class CommandChangeGetMfaConfiguration extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChangeGetMfaConfiguration().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChangeGetMfaConfiguration().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChangeGetMfaConfiguration().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChangeGetMfaConfiguration, a, b);
  }
}
CommandChangeGetMfaConfiguration.runtime = proto3;
CommandChangeGetMfaConfiguration.typeName = "GoThrive.CommandChangeGetMfaConfiguration";
CommandChangeGetMfaConfiguration.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "configurations",
  kind: "message",
  T: MFAConfigurations
}]);
/**
 * @generated from message GoThrive.MFAConfiguration
 */
export class MFAConfiguration extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Id = 1;
     */
    this.Id = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: string Label = 3;
     */
    this.Label = "";
    /**
     * @generated from field: string Description = 6;
     */
    this.Description = "";
    /**
     * @generated from field: int64 CreatedAt = 5;
     */
    this.CreatedAt = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MFAConfiguration().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MFAConfiguration().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MFAConfiguration().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MFAConfiguration, a, b);
  }
}
MFAConfiguration.runtime = proto3;
MFAConfiguration.typeName = "GoThrive.MFAConfiguration";
MFAConfiguration.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "Label",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "Description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "CreatedAt",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);
/**
 * @generated from message GoThrive.MFAConfigurations
 */
export class MFAConfigurations extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.MFAConfiguration Configurations = 1;
     */
    this.Configurations = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MFAConfigurations().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MFAConfigurations().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MFAConfigurations().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MFAConfigurations, a, b);
  }
}
MFAConfigurations.runtime = proto3;
MFAConfigurations.typeName = "GoThrive.MFAConfigurations";
MFAConfigurations.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Configurations",
  kind: "message",
  T: MFAConfiguration,
  repeated: true
}]);