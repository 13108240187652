// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file anyParams.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.AnyParams
 */
export class AnyParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, string> payload = 1;
     */
    this.payload = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AnyParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AnyParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AnyParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AnyParams, a, b);
  }
}
AnyParams.runtime = proto3;
AnyParams.typeName = "GoThrive.AnyParams";
AnyParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "payload",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "scalar",
    T: 9 /* ScalarType.STRING */
  }
}]);