// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file assessment.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Any, Message, proto3 } from "@bufbuild/protobuf";
import { UserAnalytics } from "./common_pb.js";
/**
 * @generated from message GoThrive.CommandBeginAssessment
 */
export class CommandBeginAssessment extends Message {
  constructor(data) {
    super();
    /**
     * Request Session Name
     *
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string sessionID = 2;
     */
    this.sessionID = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandBeginAssessment().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandBeginAssessment().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandBeginAssessment().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandBeginAssessment, a, b);
  }
}
CommandBeginAssessment.runtime = proto3;
CommandBeginAssessment.typeName = "GoThrive.CommandBeginAssessment";
CommandBeginAssessment.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "sessionID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "previousResult",
  kind: "message",
  T: Any,
  opt: true
}]);
/**
 * @generated from message GoThrive.AssessmentSessionLabel
 */
export class AssessmentSessionLabel extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: int32 score = 2;
     */
    this.score = 0;
    /**
     * @generated from field: int32 correctAnswers = 3;
     */
    this.correctAnswers = 0;
    /**
     * @generated from field: string textId = 4;
     */
    this.textId = "";
    /**
     * @generated from field: repeated string goals = 5;
     */
    this.goals = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentSessionLabel().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentSessionLabel().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentSessionLabel().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentSessionLabel, a, b);
  }
}
AssessmentSessionLabel.runtime = proto3;
AssessmentSessionLabel.typeName = "GoThrive.AssessmentSessionLabel";
AssessmentSessionLabel.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "score",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "correctAnswers",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "textId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "goals",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.AssessmentLayout
 */
export class AssessmentLayout extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string text = 2;
     */
    this.text = "";
    /**
     * @generated from field: string extra = 3;
     */
    this.extra = "";
    /**
     * @generated from field: repeated string goals = 4;
     */
    this.goals = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentLayout().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentLayout().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentLayout().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentLayout, a, b);
  }
}
AssessmentLayout.runtime = proto3;
AssessmentLayout.typeName = "GoThrive.AssessmentLayout";
AssessmentLayout.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "text",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "extra",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "goals",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.CommandEvalAssessment
 */
export class CommandEvalAssessment extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.AssessmentSessionLabel results = 1;
     */
    this.results = [];
    /**
     * @generated from field: string sessionID = 2;
     */
    this.sessionID = "";
    /**
     * @generated from field: repeated GoThrive.AssessmentLayout layout = 3;
     */
    this.layout = [];
    /**
     * @generated from field: repeated GoThrive.AssessmentStepResponse steps = 4;
     */
    this.steps = [];
    /**
     * @generated from field: repeated GoThrive.SelectedAnswerInfo answersInfo = 5;
     */
    this.answersInfo = [];
    /**
     * @generated from field: bool NoAnalytics = 7;
     */
    this.NoAnalytics = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandEvalAssessment().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandEvalAssessment().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandEvalAssessment().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandEvalAssessment, a, b);
  }
}
CommandEvalAssessment.runtime = proto3;
CommandEvalAssessment.typeName = "GoThrive.CommandEvalAssessment";
CommandEvalAssessment.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "results",
  kind: "message",
  T: AssessmentSessionLabel,
  repeated: true
}, {
  no: 2,
  name: "sessionID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "layout",
  kind: "message",
  T: AssessmentLayout,
  repeated: true
}, {
  no: 4,
  name: "steps",
  kind: "message",
  T: AssessmentStepResponse,
  repeated: true
}, {
  no: 5,
  name: "answersInfo",
  kind: "message",
  T: SelectedAnswerInfo,
  repeated: true
}, {
  no: 6,
  name: "userAnalytics",
  kind: "message",
  T: UserAnalytics
}, {
  no: 7,
  name: "NoAnalytics",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.CommandStepAssessment
 */
export class CommandStepAssessment extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool stepBack = 3;
     */
    this.stepBack = false;
    /**
     * @generated from field: int32 privateIndex = 4;
     */
    this.privateIndex = 0;
    /**
     * @generated from field: int32 privatePrev = 6;
     */
    this.privatePrev = 0;
    /**
     * @generated from field: int32 privateNext = 7;
     */
    this.privateNext = 0;
    /**
     * in a block of questions
     *
     * @generated from field: int32 currentQuestion = 8;
     */
    this.currentQuestion = 0;
    /**
     * in a block of questions
     *
     * @generated from field: int32 totalQuestions = 9;
     */
    this.totalQuestions = 0;
    /**
     * @generated from field: string sessionID = 10;
     */
    this.sessionID = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandStepAssessment().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandStepAssessment().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandStepAssessment().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandStepAssessment, a, b);
  }
}
CommandStepAssessment.runtime = proto3;
CommandStepAssessment.typeName = "GoThrive.CommandStepAssessment";
CommandStepAssessment.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "step",
  kind: "message",
  T: AssessmentSessionStep
}, {
  no: 2,
  name: "response",
  kind: "message",
  T: AssessmentStepResponse
}, {
  no: 3,
  name: "stepBack",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 4,
  name: "privateIndex",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 6,
  name: "privatePrev",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 7,
  name: "privateNext",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 8,
  name: "currentQuestion",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 9,
  name: "totalQuestions",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 10,
  name: "sessionID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.SelectedAnswerInfo
 */
export class SelectedAnswerInfo extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string title = 1;
     */
    this.title = "";
    /**
     * @generated from field: string question = 2;
     */
    this.question = "";
    /**
     * @generated from field: repeated string options = 3;
     */
    this.options = [];
    /**
     * @generated from field: string answer = 4;
     */
    this.answer = "";
    /**
     * @generated from field: int32 score = 5;
     */
    this.score = 0;
    /**
     * @generated from field: int32 index = 6;
     */
    this.index = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new SelectedAnswerInfo().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new SelectedAnswerInfo().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new SelectedAnswerInfo().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(SelectedAnswerInfo, a, b);
  }
}
SelectedAnswerInfo.runtime = proto3;
SelectedAnswerInfo.typeName = "GoThrive.SelectedAnswerInfo";
SelectedAnswerInfo.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "question",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "options",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 4,
  name: "answer",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "score",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 6,
  name: "index",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.CommandEndAssessment
 */
export class CommandEndAssessment extends Message {
  constructor(data) {
    super();
    /**
     * Request
     *
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string sessionID = 2;
     */
    this.sessionID = "";
    /**
     * @generated from field: bool completed = 3;
     */
    this.completed = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandEndAssessment().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandEndAssessment().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandEndAssessment().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandEndAssessment, a, b);
  }
}
CommandEndAssessment.runtime = proto3;
CommandEndAssessment.typeName = "GoThrive.CommandEndAssessment";
CommandEndAssessment.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "sessionID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "completed",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 4,
  name: "message",
  kind: "message",
  T: Any,
  opt: true
}]);
/**
 * @generated from message GoThrive.MultiChoice
 */
export class MultiChoice extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string type = 1;
     */
    this.type = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string question = 3;
     */
    this.question = "";
    /**
     * @generated from field: repeated string answer = 4;
     */
    this.answer = [];
    /**
     * @generated from field: repeated string choice = 5;
     */
    this.choice = [];
    /**
     * @generated from field: repeated int32 score = 6;
     */
    this.score = [];
    /**
     * @generated from field: int32 default = 7;
     */
    this.default = 0;
    /**
     * @generated from field: repeated string extraText = 8;
     */
    this.extraText = [];
    /**
     * @generated from field: string multiselectMode = 9;
     */
    this.multiselectMode = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MultiChoice().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MultiChoice().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MultiChoice().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MultiChoice, a, b);
  }
}
MultiChoice.runtime = proto3;
MultiChoice.typeName = "GoThrive.MultiChoice";
MultiChoice.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "question",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "answer",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 5,
  name: "choice",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 6,
  name: "score",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  repeated: true
}, {
  no: 7,
  name: "default",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 8,
  name: "extraText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 9,
  name: "multiselectMode",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.Range
 */
export class Range extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated double range = 1;
     */
    this.range = [];
    /**
     * @generated from field: repeated string text = 2;
     */
    this.text = [];
    /**
     * @generated from field: repeated double value = 3;
     */
    this.value = [];
    /**
     * @generated from field: repeated string goto = 4;
     */
    this.goto = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Range().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Range().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Range().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Range, a, b);
  }
}
Range.runtime = proto3;
Range.typeName = "GoThrive.Range";
Range.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "range",
  kind: "scalar",
  T: 1 /* ScalarType.DOUBLE */,
  repeated: true
}, {
  no: 2,
  name: "text",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 3,
  name: "value",
  kind: "scalar",
  T: 1 /* ScalarType.DOUBLE */,
  repeated: true
}, {
  no: 4,
  name: "goto",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.AssessmentSessionStep
 */
export class AssessmentSessionStep extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string value = 2;
     */
    this.value = [];
    /**
     * @generated from field: bytes data = 3;
     */
    this.data = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentSessionStep().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentSessionStep().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentSessionStep().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentSessionStep, a, b);
  }
}
AssessmentSessionStep.runtime = proto3;
AssessmentSessionStep.typeName = "GoThrive.AssessmentSessionStep";
AssessmentSessionStep.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "value",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 3,
  name: "data",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.AssessmentSession
 */
export class AssessmentSession extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: repeated GoThrive.AssessmentSessionStep steps = 3;
     */
    this.steps = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentSession().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentSession().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentSession().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentSession, a, b);
  }
}
AssessmentSession.runtime = proto3;
AssessmentSession.typeName = "GoThrive.AssessmentSession";
AssessmentSession.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "steps",
  kind: "message",
  T: AssessmentSessionStep,
  repeated: true
}]);
/**
 * @generated from message GoThrive.AssessmentStepResponse
 */
export class AssessmentStepResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string text = 2;
     */
    this.text = "";
    /**
     * @generated from field: int32 value = 3;
     */
    this.value = 0;
    /**
     * @generated from field: bytes data = 4;
     */
    this.data = new Uint8Array(0);
    /**
     * @generated from field: repeated int32 multiValue = 5;
     */
    this.multiValue = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentStepResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentStepResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentStepResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentStepResponse, a, b);
  }
}
AssessmentStepResponse.runtime = proto3;
AssessmentStepResponse.typeName = "GoThrive.AssessmentStepResponse";
AssessmentStepResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "text",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "value",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "data",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}, {
  no: 5,
  name: "multiValue",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.AssessmentData
 */
export class AssessmentData extends Message {
  constructor(data) {
    super();
    /**
     * Request
     *
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string url = 2;
     */
    this.url = "";
    /**
     * @generated from field: string screenId = 3;
     */
    this.screenId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentData, a, b);
  }
}
AssessmentData.runtime = proto3;
AssessmentData.typeName = "GoThrive.AssessmentData";
AssessmentData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "url",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "screenId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.AssessmentCatalog
 */
export class AssessmentCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.AssessmentData> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentCatalog, a, b);
  }
}
AssessmentCatalog.runtime = proto3;
AssessmentCatalog.typeName = "GoThrive.AssessmentCatalog";
AssessmentCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: AssessmentData
  }
}]);