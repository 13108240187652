// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file cbtScreening.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.CbtScreening
 */
export class CbtScreening extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string type = 1;
     */
    this.type = "";
    /**
     * @generated from field: int32 timestamp = 2;
     */
    this.timestamp = 0;
    /**
     * @generated from field: string id = 3;
     */
    this.id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CbtScreening().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CbtScreening().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CbtScreening().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CbtScreening, a, b);
  }
}
CbtScreening.runtime = proto3;
CbtScreening.typeName = "GoThrive.CbtScreening";
CbtScreening.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "timestamp",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "data",
  kind: "message",
  T: CbtScreening_Data
}]);
/**
 * @generated from message GoThrive.CbtScreening.Data
 */
export class CbtScreening_Data extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 selfEsteem = 1;
     */
    this.selfEsteem = 0;
    /**
     * @generated from field: int32 anger = 2;
     */
    this.anger = 0;
    /**
     * @generated from field: int32 ocd = 3;
     */
    this.ocd = 0;
    /**
     * @generated from field: int32 substance = 4;
     */
    this.substance = 0;
    /**
     * @generated from field: int32 bodyImage = 5;
     */
    this.bodyImage = 0;
    /**
     * @generated from field: int32 bodyImageMain = 6;
     */
    this.bodyImageMain = 0;
    /**
     * @generated from field: string type = 7;
     */
    this.type = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CbtScreening_Data().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CbtScreening_Data().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CbtScreening_Data().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CbtScreening_Data, a, b);
  }
}
CbtScreening_Data.runtime = proto3;
CbtScreening_Data.typeName = "GoThrive.CbtScreening.Data";
CbtScreening_Data.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "selfEsteem",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "anger",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "ocd",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "substance",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 5,
  name: "bodyImage",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 6,
  name: "bodyImageMain",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 7,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CbtScreenings
 */
export class CbtScreenings extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.CbtScreening entries = 1;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CbtScreenings().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CbtScreenings().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CbtScreenings().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CbtScreenings, a, b);
  }
}
CbtScreenings.runtime = proto3;
CbtScreenings.typeName = "GoThrive.CbtScreenings";
CbtScreenings.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "message",
  T: CbtScreening,
  repeated: true
}]);