// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file chatScreen.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, BottomBar2, Button, ImageIcon, NavigationBar2 } from "./common_pb.js";
/**
 * @generated from message GoThrive.ChatScreen
 */
export class ChatScreen extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 5;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.ChatScreenOption data = 6;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatScreen().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatScreen().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatScreen().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatScreen, a, b);
  }
}
ChatScreen.runtime = proto3;
ChatScreen.typeName = "GoThrive.ChatScreen";
ChatScreen.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar2
}, {
  no: 3,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 4,
  name: "bottomBar",
  kind: "message",
  T: BottomBar2
}, {
  no: 5,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 6,
  name: "data",
  kind: "message",
  T: ChatScreenOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.ChatScreenOption
 */
export class ChatScreenOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatScreenOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatScreenOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatScreenOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatScreenOption, a, b);
  }
}
ChatScreenOption.runtime = proto3;
ChatScreenOption.typeName = "GoThrive.ChatScreenOption";
ChatScreenOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.ChatInputView
 */
export class ChatInputView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string placeholder = 2;
     */
    this.placeholder = "";
    /**
     * @generated from field: string style = 4;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatInputView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatInputView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatInputView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatInputView, a, b);
  }
}
ChatInputView.runtime = proto3;
ChatInputView.typeName = "GoThrive.ChatInputView";
ChatInputView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "placeholder",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "button",
  kind: "message",
  T: Button
}, {
  no: 4,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChatMessageView
 */
export class ChatMessageView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string type = 5;
     */
    this.type = "";
    /**
     * @generated from field: string primaryStyle = 6;
     */
    this.primaryStyle = "";
    /**
     * @generated from field: string secondaryStyle = 7;
     */
    this.secondaryStyle = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatMessageView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatMessageView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatMessageView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatMessageView, a, b);
  }
}
ChatMessageView.runtime = proto3;
ChatMessageView.typeName = "GoThrive.ChatMessageView";
ChatMessageView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "primaryIcon",
  kind: "message",
  T: ImageIcon
}, {
  no: 4,
  name: "secondaryIcon",
  kind: "message",
  T: ImageIcon
}, {
  no: 5,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "primaryStyle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "secondaryStyle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChatScreenCatalog
 */
export class ChatScreenCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.ChatScreen> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatScreenCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatScreenCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatScreenCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatScreenCatalog, a, b);
  }
}
ChatScreenCatalog.runtime = proto3;
ChatScreenCatalog.typeName = "GoThrive.ChatScreenCatalog";
ChatScreenCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: ChatScreen
  }
}]);