// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file menu.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { ImageIcon } from "./common_pb.js";
/**
 * @generated from enum GoThrive.MenuItemActionType
 */
export var MenuItemActionType;
(function (MenuItemActionType) {
  /**
   * @generated from enum value: NONE = 0;
   */
  MenuItemActionType[MenuItemActionType["NONE"] = 0] = "NONE";
  /**
   * @generated from enum value: MENU = 1;
   */
  MenuItemActionType[MenuItemActionType["MENU"] = 1] = "MENU";
  /**
   * @generated from enum value: EXERCISE = 2;
   */
  MenuItemActionType[MenuItemActionType["EXERCISE"] = 2] = "EXERCISE";
  /**
   * @generated from enum value: GAME = 3;
   */
  MenuItemActionType[MenuItemActionType["GAME"] = 3] = "GAME";
  /**
   * @generated from enum value: ASSESSMENT = 4;
   */
  MenuItemActionType[MenuItemActionType["ASSESSMENT"] = 4] = "ASSESSMENT";
  /**
   * @generated from enum value: CHAT = 5;
   */
  MenuItemActionType[MenuItemActionType["CHAT"] = 5] = "CHAT";
  /**
   * @generated from enum value: WEBPAGE = 6;
   */
  MenuItemActionType[MenuItemActionType["WEBPAGE"] = 6] = "WEBPAGE";
  /**
   * @generated from enum value: CHAPTER = 7;
   */
  MenuItemActionType[MenuItemActionType["CHAPTER"] = 7] = "CHAPTER";
  /**
   * @generated from enum value: GOAL = 8;
   */
  MenuItemActionType[MenuItemActionType["GOAL"] = 8] = "GOAL";
  /**
   * @generated from enum value: NOT_IMPLEMENTED = 9;
   */
  MenuItemActionType[MenuItemActionType["NOT_IMPLEMENTED"] = 9] = "NOT_IMPLEMENTED";
})(MenuItemActionType || (MenuItemActionType = {}));
// Retrieve enum metadata with: proto3.getEnumType(MenuItemActionType)
proto3.util.setEnumType(MenuItemActionType, "GoThrive.MenuItemActionType", [{
  no: 0,
  name: "NONE"
}, {
  no: 1,
  name: "MENU"
}, {
  no: 2,
  name: "EXERCISE"
}, {
  no: 3,
  name: "GAME"
}, {
  no: 4,
  name: "ASSESSMENT"
}, {
  no: 5,
  name: "CHAT"
}, {
  no: 6,
  name: "WEBPAGE"
}, {
  no: 7,
  name: "CHAPTER"
}, {
  no: 8,
  name: "GOAL"
}, {
  no: 9,
  name: "NOT_IMPLEMENTED"
}]);
/**
 * @generated from message GoThrive.MenuItemAction
 */
export class MenuItemAction extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: GoThrive.MenuItemActionType action = 1;
     */
    this.action = MenuItemActionType.NONE;
    /**
     * @generated from field: string actionName = 2;
     */
    this.actionName = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MenuItemAction().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MenuItemAction().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MenuItemAction().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MenuItemAction, a, b);
  }
}
MenuItemAction.runtime = proto3;
MenuItemAction.typeName = "GoThrive.MenuItemAction";
MenuItemAction.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "action",
  kind: "enum",
  T: proto3.getEnumType(MenuItemActionType)
}, {
  no: 2,
  name: "actionName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.MenuItem
 */
export class MenuItem extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string labelName = 2;
     */
    this.labelName = "";
    /**
     * @generated from field: string screenCatalog = 5;
     */
    this.screenCatalog = "";
    /**
     * @generated from field: string chapterDetails = 6;
     */
    this.chapterDetails = "";
    /**
     * @generated from field: bool requireskey = 7;
     */
    this.requireskey = false;
    /**
     * @generated from field: bool hidden = 8;
     */
    this.hidden = false;
    /**
     * @generated from field: bool preview = 9;
     */
    this.preview = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MenuItem().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MenuItem().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MenuItem().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MenuItem, a, b);
  }
}
MenuItem.runtime = proto3;
MenuItem.typeName = "GoThrive.MenuItem";
MenuItem.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "labelName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "primaryImage",
  kind: "message",
  T: ImageIcon
}, {
  no: 4,
  name: "action",
  kind: "message",
  T: MenuItemAction
}, {
  no: 5,
  name: "screenCatalog",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "chapterDetails",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "requireskey",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 8,
  name: "hidden",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 9,
  name: "preview",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.Menu
 */
export class Menu extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string label = 2;
     */
    this.label = "";
    /**
     * @generated from field: repeated GoThrive.MenuItem items = 3;
     */
    this.items = [];
    /**
     * @generated from field: string iconName = 4;
     */
    this.iconName = "";
    /**
     * @generated from field: string imageName = 5;
     */
    this.imageName = "";
    /**
     * @generated from field: string textInfo = 6;
     */
    this.textInfo = "";
    /**
     * @generated from field: string iconBackground = 7;
     */
    this.iconBackground = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Menu().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Menu().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Menu().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Menu, a, b);
  }
}
Menu.runtime = proto3;
Menu.typeName = "GoThrive.Menu";
Menu.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "label",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "items",
  kind: "message",
  T: MenuItem,
  repeated: true
}, {
  no: 4,
  name: "iconName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "imageName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "textInfo",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "iconBackground",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.MenuCatalog
 */
export class MenuCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.Menu> contentMap = 2;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MenuCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MenuCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MenuCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MenuCatalog, a, b);
  }
}
MenuCatalog.runtime = proto3;
MenuCatalog.typeName = "GoThrive.MenuCatalog";
MenuCatalog.fields = proto3.util.newFieldList(() => [{
  no: 2,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Menu
  }
}]);