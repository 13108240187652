// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file mediaTextScreen.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, BottomBar2, InfoPopup, NavigationBar } from "./common_pb.js";
/**
 * @generated from message GoThrive.MediaTextScreen
 */
export class MediaTextScreen extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 6;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.MediaTextOption data = 7;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MediaTextScreen().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MediaTextScreen().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MediaTextScreen().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MediaTextScreen, a, b);
  }
}
MediaTextScreen.runtime = proto3;
MediaTextScreen.typeName = "GoThrive.MediaTextScreen";
MediaTextScreen.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "configuration",
  kind: "message",
  T: MediaTextConfiguration
}, {
  no: 3,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 4,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 5,
  name: "bottomBar",
  kind: "message",
  T: BottomBar2
}, {
  no: 6,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 7,
  name: "data",
  kind: "message",
  T: MediaTextOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.MediaTextOption
 */
export class MediaTextOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MediaTextOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MediaTextOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MediaTextOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MediaTextOption, a, b);
  }
}
MediaTextOption.runtime = proto3;
MediaTextOption.typeName = "GoThrive.MediaTextOption";
MediaTextOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.MediaTextConfiguration
 */
export class MediaTextConfiguration extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: int32 pageIndex = 2;
     */
    this.pageIndex = 0;
    /**
     * @generated from field: int32 totalPages = 3;
     */
    this.totalPages = 0;
    /**
     * @generated from field: repeated string optionKeys = 4;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.MediaTextView pages = 5;
     */
    this.pages = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MediaTextConfiguration().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MediaTextConfiguration().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MediaTextConfiguration().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MediaTextConfiguration, a, b);
  }
}
MediaTextConfiguration.runtime = proto3;
MediaTextConfiguration.typeName = "GoThrive.MediaTextConfiguration";
MediaTextConfiguration.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "pageIndex",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "totalPages",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 5,
  name: "pages",
  kind: "message",
  T: MediaTextView,
  repeated: true
}]);
/**
 * @generated from message GoThrive.MediaTextView
 */
export class MediaTextView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string image = 2;
     */
    this.image = "";
    /**
     * @generated from field: string animation = 3;
     */
    this.animation = "";
    /**
     * @generated from field: string body = 4;
     */
    this.body = "";
    /**
     * @generated from field: string subtext = 5;
     */
    this.subtext = "";
    /**
     * @generated from field: string style = 6;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MediaTextView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MediaTextView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MediaTextView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MediaTextView, a, b);
  }
}
MediaTextView.runtime = proto3;
MediaTextView.typeName = "GoThrive.MediaTextView";
MediaTextView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "animation",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "body",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "subtext",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "infoPopup",
  kind: "message",
  T: InfoPopup
}]);
/**
 * @generated from message GoThrive.MediaTextScreenCatalog
 */
export class MediaTextScreenCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.MediaTextScreen> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MediaTextScreenCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MediaTextScreenCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MediaTextScreenCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MediaTextScreenCatalog, a, b);
  }
}
MediaTextScreenCatalog.runtime = proto3;
MediaTextScreenCatalog.typeName = "GoThrive.MediaTextScreenCatalog";
MediaTextScreenCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: MediaTextScreen
  }
}]);