// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file moodMeterDetailScreen.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, BottomBar2, ImageIcon, NavigationBar } from "./common_pb.js";
/**
 * @generated from message GoThrive.MoodMeterDetailScreen
 */
export class MoodMeterDetailScreen extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 5;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.MoodMeterDetailScreenOption data = 6;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoodMeterDetailScreen().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoodMeterDetailScreen().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoodMeterDetailScreen().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoodMeterDetailScreen, a, b);
  }
}
MoodMeterDetailScreen.runtime = proto3;
MoodMeterDetailScreen.typeName = "GoThrive.MoodMeterDetailScreen";
MoodMeterDetailScreen.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 3,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 4,
  name: "bottomBar",
  kind: "message",
  T: BottomBar2
}, {
  no: 5,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 6,
  name: "data",
  kind: "message",
  T: MoodMeterDetailScreenOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.MoodMeterDetailScreenOption
 */
export class MoodMeterDetailScreenOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoodMeterDetailScreenOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoodMeterDetailScreenOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoodMeterDetailScreenOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoodMeterDetailScreenOption, a, b);
  }
}
MoodMeterDetailScreenOption.runtime = proto3;
MoodMeterDetailScreenOption.typeName = "GoThrive.MoodMeterDetailScreenOption";
MoodMeterDetailScreenOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.MoodMeterDetailCardView
 */
export class MoodMeterDetailCardView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string style = 2;
     */
    this.style = "";
    /**
     * @generated from field: repeated string optionKeys = 3;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.MoodMeterDetailCardViewOption data = 4;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoodMeterDetailCardView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoodMeterDetailCardView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoodMeterDetailCardView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoodMeterDetailCardView, a, b);
  }
}
MoodMeterDetailCardView.runtime = proto3;
MoodMeterDetailCardView.typeName = "GoThrive.MoodMeterDetailCardView";
MoodMeterDetailCardView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 4,
  name: "data",
  kind: "message",
  T: MoodMeterDetailCardViewOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.MoodMeterDetailCardViewOption
 */
export class MoodMeterDetailCardViewOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoodMeterDetailCardViewOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoodMeterDetailCardViewOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoodMeterDetailCardViewOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoodMeterDetailCardViewOption, a, b);
  }
}
MoodMeterDetailCardViewOption.runtime = proto3;
MoodMeterDetailCardViewOption.typeName = "GoThrive.MoodMeterDetailCardViewOption";
MoodMeterDetailCardViewOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.MoodMeterProfile
 */
export class MoodMeterProfile extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string name = 2;
     */
    this.name = "";
    /**
     * @generated from field: string style = 4;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoodMeterProfile().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoodMeterProfile().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoodMeterProfile().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoodMeterProfile, a, b);
  }
}
MoodMeterProfile.runtime = proto3;
MoodMeterProfile.typeName = "GoThrive.MoodMeterProfile";
MoodMeterProfile.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "image",
  kind: "message",
  T: ImageIcon
}, {
  no: 4,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.MoodMeterList
 */
export class MoodMeterList extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string style = 4;
     */
    this.style = "";
    /**
     * @generated from field: repeated string optionKeys = 5;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.MoodMeterListOption data = 6;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoodMeterList().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoodMeterList().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoodMeterList().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoodMeterList, a, b);
  }
}
MoodMeterList.runtime = proto3;
MoodMeterList.typeName = "GoThrive.MoodMeterList";
MoodMeterList.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "titleImage",
  kind: "message",
  T: ImageIcon
}, {
  no: 4,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 6,
  name: "data",
  kind: "message",
  T: MoodMeterListOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.MoodMeterListOption
 */
export class MoodMeterListOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoodMeterListOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoodMeterListOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoodMeterListOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoodMeterListOption, a, b);
  }
}
MoodMeterListOption.runtime = proto3;
MoodMeterListOption.typeName = "GoThrive.MoodMeterListOption";
MoodMeterListOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.MoodMeterDetailScreenCatalog
 */
export class MoodMeterDetailScreenCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.MoodMeterDetailScreen> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoodMeterDetailScreenCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoodMeterDetailScreenCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoodMeterDetailScreenCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoodMeterDetailScreenCatalog, a, b);
  }
}
MoodMeterDetailScreenCatalog.runtime = proto3;
MoodMeterDetailScreenCatalog.typeName = "GoThrive.MoodMeterDetailScreenCatalog";
MoodMeterDetailScreenCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: MoodMeterDetailScreen
  }
}]);