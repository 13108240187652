// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file theme.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from enum GoThrive.PlatformType
 */
export var PlatformType;
(function (PlatformType) {
  /**
   * @generated from enum value: all = 0;
   */
  PlatformType[PlatformType["all"] = 0] = "all";
  /**
   * @generated from enum value: ios = 1;
   */
  PlatformType[PlatformType["ios"] = 1] = "ios";
  /**
   * @generated from enum value: android = 2;
   */
  PlatformType[PlatformType["android"] = 2] = "android";
  /**
   * @generated from enum value: gocore = 3;
   */
  PlatformType[PlatformType["gocore"] = 3] = "gocore";
})(PlatformType || (PlatformType = {}));
// Retrieve enum metadata with: proto3.getEnumType(PlatformType)
proto3.util.setEnumType(PlatformType, "GoThrive.PlatformType", [{
  no: 0,
  name: "all"
}, {
  no: 1,
  name: "ios"
}, {
  no: 2,
  name: "android"
}, {
  no: 3,
  name: "gocore"
}]);
/**
 * @generated from enum GoThrive.FontWeight
 */
export var FontWeight;
(function (FontWeight) {
  /**
   * @generated from enum value: weight_light = 0;
   */
  FontWeight[FontWeight["weight_light"] = 0] = "weight_light";
  /**
   * @generated from enum value: weight_regular = 1;
   */
  FontWeight[FontWeight["weight_regular"] = 1] = "weight_regular";
  /**
   * @generated from enum value: weight_medium = 2;
   */
  FontWeight[FontWeight["weight_medium"] = 2] = "weight_medium";
  /**
   * @generated from enum value: weight_semibold = 3;
   */
  FontWeight[FontWeight["weight_semibold"] = 3] = "weight_semibold";
  /**
   * @generated from enum value: weight_bold = 4;
   */
  FontWeight[FontWeight["weight_bold"] = 4] = "weight_bold";
})(FontWeight || (FontWeight = {}));
// Retrieve enum metadata with: proto3.getEnumType(FontWeight)
proto3.util.setEnumType(FontWeight, "GoThrive.FontWeight", [{
  no: 0,
  name: "weight_light"
}, {
  no: 1,
  name: "weight_regular"
}, {
  no: 2,
  name: "weight_medium"
}, {
  no: 3,
  name: "weight_semibold"
}, {
  no: 4,
  name: "weight_bold"
}]);
/**
 * @generated from enum GoThrive.StyleMode
 */
export var StyleMode;
(function (StyleMode) {
  /**
   * @generated from enum value: mode_all = 0;
   */
  StyleMode[StyleMode["mode_all"] = 0] = "mode_all";
  /**
   * @generated from enum value: mode_light = 1;
   */
  StyleMode[StyleMode["mode_light"] = 1] = "mode_light";
  /**
   * @generated from enum value: mode_dark = 2;
   */
  StyleMode[StyleMode["mode_dark"] = 2] = "mode_dark";
  /**
   * @generated from enum value: mode_timeOfDay = 3;
   */
  StyleMode[StyleMode["mode_timeOfDay"] = 3] = "mode_timeOfDay";
})(StyleMode || (StyleMode = {}));
// Retrieve enum metadata with: proto3.getEnumType(StyleMode)
proto3.util.setEnumType(StyleMode, "GoThrive.StyleMode", [{
  no: 0,
  name: "mode_all"
}, {
  no: 1,
  name: "mode_light"
}, {
  no: 2,
  name: "mode_dark"
}, {
  no: 3,
  name: "mode_timeOfDay"
}]);
/**
 * @generated from message GoThrive.StyleParser
 */
export class StyleParser extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: GoThrive.PlatformType platform = 1;
     */
    this.platform = PlatformType.all;
    /**
     * @generated from field: string key = 2;
     */
    this.key = "";
    /**
     * @generated from field: GoThrive.StyleMode mode = 3;
     */
    this.mode = StyleMode.mode_all;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new StyleParser().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new StyleParser().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new StyleParser().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(StyleParser, a, b);
  }
}
StyleParser.runtime = proto3;
StyleParser.typeName = "GoThrive.StyleParser";
StyleParser.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "platform",
  kind: "enum",
  T: proto3.getEnumType(PlatformType)
}, {
  no: 2,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "mode",
  kind: "enum",
  T: proto3.getEnumType(StyleMode)
}, {
  no: 4,
  name: "primary",
  kind: "message",
  T: Colour
}, {
  no: 5,
  name: "secondary",
  kind: "message",
  T: Colour
}, {
  no: 6,
  name: "tertiary",
  kind: "message",
  T: Colour
}, {
  no: 7,
  name: "textPrimary",
  kind: "message",
  T: Colour
}, {
  no: 8,
  name: "textPrimaryFont",
  kind: "message",
  T: Font
}, {
  no: 9,
  name: "textSecondary",
  kind: "message",
  T: Colour
}, {
  no: 10,
  name: "textSecondaryFont",
  kind: "message",
  T: Font
}, {
  no: 11,
  name: "textTertiary",
  kind: "message",
  T: Colour
}, {
  no: 12,
  name: "textTertiaryFont",
  kind: "message",
  T: Font
}]);
/**
 * @generated from message GoThrive.ThemeCatalogParser
 */
export class ThemeCatalogParser extends Message {
  constructor(data) {
    super();
    /**
     * response
     *
     * @generated from field: repeated GoThrive.ThemeParser obsolete = 1;
     */
    this.obsolete = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ThemeCatalogParser().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ThemeCatalogParser().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ThemeCatalogParser().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ThemeCatalogParser, a, b);
  }
}
ThemeCatalogParser.runtime = proto3;
ThemeCatalogParser.typeName = "GoThrive.ThemeCatalogParser";
ThemeCatalogParser.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "obsolete",
  kind: "message",
  T: ThemeParser,
  repeated: true
}]);
/**
 * @generated from message GoThrive.ThemeParser
 */
export class ThemeParser extends Message {
  constructor(data) {
    super();
    /**
     * android or ios
     *
     * @generated from field: GoThrive.PlatformType platform = 1;
     */
    this.platform = PlatformType.all;
    /**
     * @generated from field: repeated GoThrive.Colour colours = 2;
     */
    this.colours = [];
    /**
     * @generated from field: repeated GoThrive.Font fonts = 3;
     */
    this.fonts = [];
    /**
     * @generated from field: repeated GoThrive.StyleParser styles = 4;
     */
    this.styles = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ThemeParser().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ThemeParser().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ThemeParser().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ThemeParser, a, b);
  }
}
ThemeParser.runtime = proto3;
ThemeParser.typeName = "GoThrive.ThemeParser";
ThemeParser.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "platform",
  kind: "enum",
  T: proto3.getEnumType(PlatformType)
}, {
  no: 2,
  name: "colours",
  kind: "message",
  T: Colour,
  repeated: true
}, {
  no: 3,
  name: "fonts",
  kind: "message",
  T: Font,
  repeated: true
}, {
  no: 4,
  name: "styles",
  kind: "message",
  T: StyleParser,
  repeated: true
}]);
/**
 * @generated from message GoThrive.Font
 */
export class Font extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: int32 size = 2;
     */
    this.size = 0;
    /**
     * @generated from field: GoThrive.FontWeight weight = 3;
     */
    this.weight = FontWeight.weight_light;
    /**
     * @generated from field: string family = 4;
     */
    this.family = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Font().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Font().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Font().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Font, a, b);
  }
}
Font.runtime = proto3;
Font.typeName = "GoThrive.Font";
Font.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "size",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "weight",
  kind: "enum",
  T: proto3.getEnumType(FontWeight)
}, {
  no: 4,
  name: "family",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.Colour
 */
export class Colour extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: int32 opacity = 2;
     */
    this.opacity = 0;
    /**
     * @generated from field: string hexColour = 3;
     */
    this.hexColour = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Colour().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Colour().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Colour().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Colour, a, b);
  }
}
Colour.runtime = proto3;
Colour.typeName = "GoThrive.Colour";
Colour.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "opacity",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "hexColour",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 *  string key = 1;         // Name,Mode,Styles ie. iosdefault,light,statusbar
 *
 * @generated from message GoThrive.Theme
 */
export class Theme extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: GoThrive.PlatformType platform = 1;
     */
    this.platform = PlatformType.all;
    /**
     * @generated from field: string mode = 2;
     */
    this.mode = "";
    /**
     * @generated from field: string style = 3;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Theme().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Theme().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Theme().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Theme, a, b);
  }
}
Theme.runtime = proto3;
Theme.typeName = "GoThrive.Theme";
Theme.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "platform",
  kind: "enum",
  T: proto3.getEnumType(PlatformType)
}, {
  no: 2,
  name: "mode",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "primary",
  kind: "message",
  T: Colour
}, {
  no: 5,
  name: "secondary",
  kind: "message",
  T: Colour
}, {
  no: 6,
  name: "tertiary",
  kind: "message",
  T: Colour
}, {
  no: 7,
  name: "textPrimary",
  kind: "message",
  T: Colour
}, {
  no: 8,
  name: "textSecondary",
  kind: "message",
  T: Colour
}, {
  no: 9,
  name: "textTertiary",
  kind: "message",
  T: Colour
}, {
  no: 10,
  name: "textPrimaryFont",
  kind: "message",
  T: Font
}, {
  no: 11,
  name: "textSecondaryFont",
  kind: "message",
  T: Font
}, {
  no: 12,
  name: "textTertiaryFont",
  kind: "message",
  T: Font
}]);
/**
 * @generated from message GoThrive.ThemeCatalog
 */
export class ThemeCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.Theme> contentMap = 2;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ThemeCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ThemeCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ThemeCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ThemeCatalog, a, b);
  }
}
ThemeCatalog.runtime = proto3;
ThemeCatalog.typeName = "GoThrive.ThemeCatalog";
ThemeCatalog.fields = proto3.util.newFieldList(() => [{
  no: 2,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Theme
  }
}]);