// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file toggleSupportedInfoPanel.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, BottomBar2, NavigationBar } from "./common_pb.js";
/**
 * @generated from message GoThrive.ToggleSupportedInfoPanel
 */
export class ToggleSupportedInfoPanel extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 5;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.ToggleSupportedInfoPanelOption data = 6;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ToggleSupportedInfoPanel().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ToggleSupportedInfoPanel().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ToggleSupportedInfoPanel().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ToggleSupportedInfoPanel, a, b);
  }
}
ToggleSupportedInfoPanel.runtime = proto3;
ToggleSupportedInfoPanel.typeName = "GoThrive.ToggleSupportedInfoPanel";
ToggleSupportedInfoPanel.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 3,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 4,
  name: "bottomBar",
  kind: "message",
  T: BottomBar2
}, {
  no: 5,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 6,
  name: "data",
  kind: "message",
  T: ToggleSupportedInfoPanelOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.ToggleSupportedInfoPanelOption
 */
export class ToggleSupportedInfoPanelOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ToggleSupportedInfoPanelOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ToggleSupportedInfoPanelOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ToggleSupportedInfoPanelOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ToggleSupportedInfoPanelOption, a, b);
  }
}
ToggleSupportedInfoPanelOption.runtime = proto3;
ToggleSupportedInfoPanelOption.typeName = "GoThrive.ToggleSupportedInfoPanelOption";
ToggleSupportedInfoPanelOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.ToggleSupportedInfoPanelCatalog
 */
export class ToggleSupportedInfoPanelCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.ToggleSupportedInfoPanel> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ToggleSupportedInfoPanelCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ToggleSupportedInfoPanelCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ToggleSupportedInfoPanelCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ToggleSupportedInfoPanelCatalog, a, b);
  }
}
ToggleSupportedInfoPanelCatalog.runtime = proto3;
ToggleSupportedInfoPanelCatalog.typeName = "GoThrive.ToggleSupportedInfoPanelCatalog";
ToggleSupportedInfoPanelCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: ToggleSupportedInfoPanel
  }
}]);