// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file scopes.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.Scopes
 */
export class Scopes extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.Scopes.Scope scopes = 1;
     */
    this.scopes = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Scopes().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Scopes().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Scopes().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Scopes, a, b);
  }
}
Scopes.runtime = proto3;
Scopes.typeName = "GoThrive.Scopes";
Scopes.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "scopes",
  kind: "message",
  T: Scopes_Scope,
  repeated: true
}]);
/**
 * @generated from message GoThrive.Scopes.Scope
 */
export class Scopes_Scope extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string name = 1;
     */
    this.name = "";
    /**
     * @generated from field: string description = 2;
     */
    this.description = "";
    /**
     * @generated from field: int32 mfalevel = 3;
     */
    this.mfalevel = 0;
    /**
     * @generated from field: repeated string scopeGroup = 4;
     */
    this.scopeGroup = [];
    /**
     * @generated from field: repeated string usergroups = 5;
     */
    this.usergroups = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Scopes_Scope().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Scopes_Scope().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Scopes_Scope().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Scopes_Scope, a, b);
  }
}
Scopes_Scope.runtime = proto3;
Scopes_Scope.typeName = "GoThrive.Scopes.Scope";
Scopes_Scope.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "mfalevel",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "scopeGroup",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 5,
  name: "usergroups",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.CurrentScopes
 */
export class CurrentScopes extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated string scopes = 1;
     */
    this.scopes = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CurrentScopes().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CurrentScopes().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CurrentScopes().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CurrentScopes, a, b);
  }
}
CurrentScopes.runtime = proto3;
CurrentScopes.typeName = "GoThrive.CurrentScopes";
CurrentScopes.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "scopes",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);