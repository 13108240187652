// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file dailyCheckup.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.MoodEntries
 */
export class MoodEntries extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: float Intensity = 1;
     */
    this.Intensity = 0;
    /**
     * @generated from field: string Mood = 4;
     */
    this.Mood = "";
    /**
     * @generated from field: float RevisedIntensity = 5;
     */
    this.RevisedIntensity = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoodEntries().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoodEntries().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoodEntries().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoodEntries, a, b);
  }
}
MoodEntries.runtime = proto3;
MoodEntries.typeName = "GoThrive.MoodEntries";
MoodEntries.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Intensity",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}, {
  no: 2,
  name: "IsHealthy",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */,
  opt: true
}, {
  no: 3,
  name: "LocalisationKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 4,
  name: "Mood",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "RevisedIntensity",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}, {
  no: 6,
  name: "FuzzType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}]);
/**
 * @generated from message GoThrive.DailyCheckup
 */
export class DailyCheckup extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string type = 1;
     */
    this.type = "";
    /**
     * @generated from field: int32 timestamp = 2;
     */
    this.timestamp = 0;
    /**
     * @generated from field: string id = 3;
     */
    this.id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new DailyCheckup().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new DailyCheckup().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new DailyCheckup().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(DailyCheckup, a, b);
  }
}
DailyCheckup.runtime = proto3;
DailyCheckup.typeName = "GoThrive.DailyCheckup";
DailyCheckup.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "timestamp",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "data",
  kind: "message",
  T: DailyCheckup_Data
}]);
/**
 * @generated from message GoThrive.DailyCheckup.Data
 */
export class DailyCheckup_Data extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: float MoodRating = 1;
     */
    this.MoodRating = 0;
    /**
     * @generated from field: repeated GoThrive.MoodEntries MoodEntries = 2;
     */
    this.MoodEntries = [];
    /**
     * @generated from field: string type = 4;
     */
    this.type = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new DailyCheckup_Data().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new DailyCheckup_Data().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new DailyCheckup_Data().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(DailyCheckup_Data, a, b);
  }
}
DailyCheckup_Data.runtime = proto3;
DailyCheckup_Data.typeName = "GoThrive.DailyCheckup.Data";
DailyCheckup_Data.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "MoodRating",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}, {
  no: 2,
  name: "MoodEntries",
  kind: "message",
  T: MoodEntries,
  repeated: true
}, {
  no: 3,
  name: "timestamp",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 4,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);