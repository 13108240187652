// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file command.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Any, Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.Command
 */
export class Command extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string name = 1;
     */
    this.name = "";
    /**
     * @generated from field: bytes body = 2;
     */
    this.body = new Uint8Array(0);
    /**
     * @generated from field: int32 id = 3;
     */
    this.id = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Command().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Command().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Command().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Command, a, b);
  }
}
Command.runtime = proto3;
Command.typeName = "GoThrive.Command";
Command.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}, {
  no: 3,
  name: "id",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.CommandGetFileFromURL
 */
export class CommandGetFileFromURL extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string destinationFilePath = 1;
     */
    this.destinationFilePath = "";
    /**
     * @generated from field: string sourceURL = 2;
     */
    this.sourceURL = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetFileFromURL().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetFileFromURL().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetFileFromURL().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetFileFromURL, a, b);
  }
}
CommandGetFileFromURL.runtime = proto3;
CommandGetFileFromURL.typeName = "GoThrive.CommandGetFileFromURL";
CommandGetFileFromURL.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "destinationFilePath",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "sourceURL",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandGetCatalog
 */
export class CommandGetCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool userdata = 2;
     */
    this.userdata = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetCatalog, a, b);
  }
}
CommandGetCatalog.runtime = proto3;
CommandGetCatalog.typeName = "GoThrive.CommandGetCatalog";
CommandGetCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "catalog",
  kind: "message",
  T: Any
}, {
  no: 2,
  name: "userdata",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.CommandGetAsset
 */
export class CommandGetAsset extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool partialKeys = 2;
     */
    this.partialKeys = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetAsset().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetAsset().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetAsset().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetAsset, a, b);
  }
}
CommandGetAsset.runtime = proto3;
CommandGetAsset.typeName = "GoThrive.CommandGetAsset";
CommandGetAsset.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "catalog",
  kind: "message",
  T: Any
}, {
  no: 2,
  name: "partialKeys",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.CommandModifyCatalog
 */
export class CommandModifyCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool delete = 2;
     */
    this.delete = false;
    /**
     * @generated from field: bool temporary = 3;
     */
    this.temporary = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandModifyCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandModifyCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandModifyCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandModifyCatalog, a, b);
  }
}
CommandModifyCatalog.runtime = proto3;
CommandModifyCatalog.typeName = "GoThrive.CommandModifyCatalog";
CommandModifyCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "catalog",
  kind: "message",
  T: Any
}, {
  no: 2,
  name: "delete",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 3,
  name: "temporary",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);