// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file lastPositiveParams.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.LastPositiveParams
 */
export class LastPositiveParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool mergeReadings = 1;
     */
    this.mergeReadings = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new LastPositiveParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new LastPositiveParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new LastPositiveParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(LastPositiveParams, a, b);
  }
}
LastPositiveParams.runtime = proto3;
LastPositiveParams.typeName = "GoThrive.LastPositiveParams";
LastPositiveParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "mergeReadings",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);