// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file common.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { InfoPanelRecommendationList } from "./subscribe_pb.js";
/**
 * @generated from message GoThrive.Background
 */
export class Background extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string style = 2;
     */
    this.style = "";
    /**
     * @generated from field: string animation = 3;
     */
    this.animation = "";
    /**
     * @generated from field: string image = 4;
     */
    this.image = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Background().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Background().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Background().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Background, a, b);
  }
}
Background.runtime = proto3;
Background.typeName = "GoThrive.Background";
Background.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "animation",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.NavigationBar
 */
export class NavigationBar extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string text = 2;
     */
    this.text = "";
    /**
     * @generated from field: string style = 3;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new NavigationBar().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new NavigationBar().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new NavigationBar().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(NavigationBar, a, b);
  }
}
NavigationBar.runtime = proto3;
NavigationBar.typeName = "GoThrive.NavigationBar";
NavigationBar.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "text",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.BottomBar
 */
export class BottomBar extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string text = 2;
     */
    this.text = "";
    /**
     * @generated from field: string style = 3;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new BottomBar().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new BottomBar().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new BottomBar().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(BottomBar, a, b);
  }
}
BottomBar.runtime = proto3;
BottomBar.typeName = "GoThrive.BottomBar";
BottomBar.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "text",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ProfileBar
 */
export class ProfileBar extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string leftImage = 2;
     */
    this.leftImage = "";
    /**
     * @generated from field: string leftText = 3;
     */
    this.leftText = "";
    /**
     * @generated from field: string leftStyle = 4;
     */
    this.leftStyle = "";
    /**
     * @generated from field: string rightText = 5;
     */
    this.rightText = "";
    /**
     * @generated from field: string rightStyle = 6;
     */
    this.rightStyle = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ProfileBar().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ProfileBar().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ProfileBar().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ProfileBar, a, b);
  }
}
ProfileBar.runtime = proto3;
ProfileBar.typeName = "GoThrive.ProfileBar";
ProfileBar.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "leftImage",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "leftText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "leftStyle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "rightText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "rightStyle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ProgressBar
 */
export class ProgressBar extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string description = 3;
     */
    this.description = "";
    /**
     * @generated from field: int32 iterations = 4;
     */
    this.iterations = 0;
    /**
     * @generated from field: string image = 5;
     */
    this.image = "";
    /**
     * @generated from field: string style = 6;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ProgressBar().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ProgressBar().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ProgressBar().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ProgressBar, a, b);
  }
}
ProgressBar.runtime = proto3;
ProgressBar.typeName = "GoThrive.ProgressBar";
ProgressBar.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "iterations",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 5,
  name: "image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.TabView
 */
export class TabView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string style = 4;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TabView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TabView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TabView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TabView, a, b);
  }
}
TabView.runtime = proto3;
TabView.typeName = "GoThrive.TabView";
TabView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "primaryIcon",
  kind: "message",
  T: ImageIcon
}, {
  no: 3,
  name: "secondaryIcon",
  kind: "message",
  T: ImageIcon
}, {
  no: 4,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ToggleView
 */
export class ToggleView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string text = 2;
     */
    this.text = "";
    /**
     * @generated from field: bool isActive = 3;
     */
    this.isActive = false;
    /**
     * @generated from field: string style = 4;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ToggleView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ToggleView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ToggleView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ToggleView, a, b);
  }
}
ToggleView.runtime = proto3;
ToggleView.typeName = "GoThrive.ToggleView";
ToggleView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "text",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "isActive",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 4,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.NotificationView
 */
export class NotificationView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string notificationTitle = 2;
     */
    this.notificationTitle = "";
    /**
     * @generated from field: bool notificationEnabled = 3;
     */
    this.notificationEnabled = false;
    /**
     * @generated from field: string startDateTitle = 4;
     */
    this.startDateTitle = "";
    /**
     * @generated from field: string startTimeTitle = 5;
     */
    this.startTimeTitle = "";
    /**
     * @generated from field: string repeatTitle = 6;
     */
    this.repeatTitle = "";
    /**
     * @generated from field: bool repeat = 7;
     */
    this.repeat = false;
    /**
     * @generated from field: string style = 8;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new NotificationView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new NotificationView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new NotificationView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(NotificationView, a, b);
  }
}
NotificationView.runtime = proto3;
NotificationView.typeName = "GoThrive.NotificationView";
NotificationView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "notificationTitle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "notificationEnabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 4,
  name: "startDateTitle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "startTimeTitle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "repeatTitle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "repeat",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 8,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CardView
 */
export class CardView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string header = 2;
     */
    this.header = "";
    /**
     * @generated from field: string title = 5;
     */
    this.title = "";
    /**
     * @generated from field: repeated string randomTitles = 6;
     */
    this.randomTitles = [];
    /**
     * @generated from field: string body = 7;
     */
    this.body = "";
    /**
     * @generated from field: string style = 8;
     */
    this.style = "";
    /**
     * @generated from field: string extraBody = 10;
     */
    this.extraBody = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CardView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CardView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CardView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CardView, a, b);
  }
}
CardView.runtime = proto3;
CardView.typeName = "GoThrive.CardView";
CardView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "header",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "primaryImage",
  kind: "message",
  T: ImageIcon
}, {
  no: 4,
  name: "secondaryImage",
  kind: "message",
  T: ImageIcon
}, {
  no: 5,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "randomTitles",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 7,
  name: "body",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "button",
  kind: "message",
  T: Button
}, {
  no: 10,
  name: "extraBody",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 11,
  name: "extraBodyImage",
  kind: "message",
  T: ImageIcon
}, {
  no: 12,
  name: "progressBar",
  kind: "message",
  T: ProgressBar
}, {
  no: 13,
  name: "tabView",
  kind: "message",
  T: TabView
}, {
  no: 14,
  name: "toggle",
  kind: "message",
  T: ToggleView
}, {
  no: 15,
  name: "notification",
  kind: "message",
  T: NotificationView
}, {
  no: 16,
  name: "recommendationList",
  kind: "message",
  T: InfoPanelRecommendationList
}]);
/**
 * @generated from message GoThrive.Button
 */
export class Button extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string image = 3;
     */
    this.image = "";
    /**
     * @generated from field: string style = 4;
     */
    this.style = "";
    /**
     * @generated from field: string action = 5;
     */
    this.action = "";
    /**
     * @generated from field: string actionSessionAutoComplete = 6;
     */
    this.actionSessionAutoComplete = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Button().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Button().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Button().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Button, a, b);
  }
}
Button.runtime = proto3;
Button.typeName = "GoThrive.Button";
Button.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "action",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "actionSessionAutoComplete",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "icon",
  kind: "message",
  T: ImageIcon
}]);
/**
 * @generated from message GoThrive.PageControl
 */
export class PageControl extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string style = 2;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PageControl().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PageControl().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PageControl().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PageControl, a, b);
  }
}
PageControl.runtime = proto3;
PageControl.typeName = "GoThrive.PageControl";
PageControl.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.BottomBar2
 */
export class BottomBar2 extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string leadingText = 2;
     */
    this.leadingText = "";
    /**
     * @generated from field: string trailingText = 3;
     */
    this.trailingText = "";
    /**
     * @generated from field: string style = 4;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new BottomBar2().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new BottomBar2().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new BottomBar2().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(BottomBar2, a, b);
  }
}
BottomBar2.runtime = proto3;
BottomBar2.typeName = "GoThrive.BottomBar2";
BottomBar2.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "leadingText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "trailingText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.QuestionnaireView
 */
export class QuestionnaireView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string style = 2;
     */
    this.style = "";
    /**
     * @generated from field: string correctAnswerHint = 3;
     */
    this.correctAnswerHint = "";
    /**
     * @generated from field: string incorrectAnswerHint = 4;
     */
    this.incorrectAnswerHint = "";
    /**
     * @generated from field: string correctAnswerIs = 5;
     */
    this.correctAnswerIs = "";
    /**
     * @generated from field: string doubleTapHint = 6;
     */
    this.doubleTapHint = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new QuestionnaireView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new QuestionnaireView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new QuestionnaireView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(QuestionnaireView, a, b);
  }
}
QuestionnaireView.runtime = proto3;
QuestionnaireView.typeName = "GoThrive.QuestionnaireView";
QuestionnaireView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "correctAnswerHint",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "incorrectAnswerHint",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "correctAnswerIs",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "doubleTapHint",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CustomEntry
 */
export class CustomEntry extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string textAddItem = 2;
     */
    this.textAddItem = "";
    /**
     * @generated from field: string textEditItem = 3;
     */
    this.textEditItem = "";
    /**
     * @generated from field: string textDeleteItem = 4;
     */
    this.textDeleteItem = "";
    /**
     * @generated from field: string style = 5;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CustomEntry().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CustomEntry().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CustomEntry().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CustomEntry, a, b);
  }
}
CustomEntry.runtime = proto3;
CustomEntry.typeName = "GoThrive.CustomEntry";
CustomEntry.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "textAddItem",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "textEditItem",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "textDeleteItem",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.InfoPopup
 */
export class InfoPopup extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string caption = 2;
     */
    this.caption = "";
    /**
     * @generated from field: string image = 3;
     */
    this.image = "";
    /**
     * @generated from field: string animation = 4;
     */
    this.animation = "";
    /**
     * @generated from field: string body = 5;
     */
    this.body = "";
    /**
     * @generated from field: string style = 6;
     */
    this.style = "";
    /**
     * @generated from field: bool infoMustRead = 7;
     */
    this.infoMustRead = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new InfoPopup().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new InfoPopup().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new InfoPopup().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(InfoPopup, a, b);
  }
}
InfoPopup.runtime = proto3;
InfoPopup.typeName = "GoThrive.InfoPopup";
InfoPopup.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "caption",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "animation",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "body",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "infoMustRead",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.HorizontalSlider
 */
export class HorizontalSlider extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string style = 2;
     */
    this.style = "";
    /**
     * @generated from field: string header = 3;
     */
    this.header = "";
    /**
     * @generated from field: string hint = 4;
     */
    this.hint = "";
    /**
     * @generated from field: int32 minValue = 5;
     */
    this.minValue = 0;
    /**
     * @generated from field: int32 maxValue = 6;
     */
    this.maxValue = 0;
    /**
     * @generated from field: int32 initialValue = 7;
     */
    this.initialValue = 0;
    /**
     * @generated from field: int32 minAcceptedValue = 8;
     */
    this.minAcceptedValue = 0;
    /**
     * @generated from field: string unit = 9;
     */
    this.unit = "";
    /**
     * @generated from field: int32 selectedValue = 10;
     */
    this.selectedValue = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new HorizontalSlider().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new HorizontalSlider().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new HorizontalSlider().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(HorizontalSlider, a, b);
  }
}
HorizontalSlider.runtime = proto3;
HorizontalSlider.typeName = "GoThrive.HorizontalSlider";
HorizontalSlider.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "header",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "hint",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "minValue",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 6,
  name: "maxValue",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 7,
  name: "initialValue",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 8,
  name: "minAcceptedValue",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 9,
  name: "unit",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 10,
  name: "selectedValue",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.SliderItem
 */
export class SliderItem extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string description = 3;
     */
    this.description = "";
    /**
     * @generated from field: string image = 4;
     */
    this.image = "";
    /**
     * @generated from field: int32 value = 5;
     */
    this.value = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new SliderItem().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new SliderItem().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new SliderItem().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(SliderItem, a, b);
  }
}
SliderItem.runtime = proto3;
SliderItem.typeName = "GoThrive.SliderItem";
SliderItem.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "value",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.VerticalSlider
 */
export class VerticalSlider extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: int32 steps = 2;
     */
    this.steps = 0;
    /**
     * @generated from field: string style = 3;
     */
    this.style = "";
    /**
     * @generated from field: repeated GoThrive.SliderItem items = 4;
     */
    this.items = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new VerticalSlider().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new VerticalSlider().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new VerticalSlider().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(VerticalSlider, a, b);
  }
}
VerticalSlider.runtime = proto3;
VerticalSlider.typeName = "GoThrive.VerticalSlider";
VerticalSlider.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "steps",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "items",
  kind: "message",
  T: SliderItem,
  repeated: true
}]);
/**
 * @generated from message GoThrive.UserAnalytics
 */
export class UserAnalytics extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.UserInfo userInfo = 1;
     */
    this.userInfo = [];
    /**
     * @generated from field: repeated GoThrive.HealthInfo healthInfo = 2;
     */
    this.healthInfo = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new UserAnalytics().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new UserAnalytics().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new UserAnalytics().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(UserAnalytics, a, b);
  }
}
UserAnalytics.runtime = proto3;
UserAnalytics.typeName = "GoThrive.UserAnalytics";
UserAnalytics.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "userInfo",
  kind: "message",
  T: UserInfo,
  repeated: true
}, {
  no: 2,
  name: "healthInfo",
  kind: "message",
  T: HealthInfo,
  repeated: true
}]);
/**
 * @generated from message GoThrive.UserInfo
 */
export class UserInfo extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string type = 1;
     */
    this.type = "";
    /**
     * @generated from field: string value = 2;
     */
    this.value = "";
    /**
     * @generated from field: string extra = 3;
     */
    this.extra = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new UserInfo().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new UserInfo().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new UserInfo().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(UserInfo, a, b);
  }
}
UserInfo.runtime = proto3;
UserInfo.typeName = "GoThrive.UserInfo";
UserInfo.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "value",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "extra",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.HealthInfo
 */
export class HealthInfo extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string type = 1;
     */
    this.type = "";
    /**
     * @generated from field: string unit = 2;
     */
    this.unit = "";
    /**
     * @generated from field: string value = 3;
     */
    this.value = "";
    /**
     * time stamp
     *
     * @generated from field: string startDate = 4;
     */
    this.startDate = "";
    /**
     * time stamp
     *
     * @generated from field: string endDate = 5;
     */
    this.endDate = "";
    /**
     * @generated from field: string extra = 6;
     */
    this.extra = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new HealthInfo().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new HealthInfo().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new HealthInfo().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(HealthInfo, a, b);
  }
}
HealthInfo.runtime = proto3;
HealthInfo.typeName = "GoThrive.HealthInfo";
HealthInfo.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "unit",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "value",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "startDate",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "endDate",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "extra",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.NavigationBar2
 */
export class NavigationBar2 extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string text = 2;
     */
    this.text = "";
    /**
     * @generated from field: string leadingButtonText = 3;
     */
    this.leadingButtonText = "";
    /**
     * @generated from field: string trailingButtonText = 4;
     */
    this.trailingButtonText = "";
    /**
     * @generated from field: string style = 5;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new NavigationBar2().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new NavigationBar2().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new NavigationBar2().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(NavigationBar2, a, b);
  }
}
NavigationBar2.runtime = proto3;
NavigationBar2.typeName = "GoThrive.NavigationBar2";
NavigationBar2.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "text",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "leadingButtonText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "trailingButtonText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * TODO rename ExerciseOptionRow
 *
 * @generated from message GoThrive.ExerciseOptionRow
 */
export class ExerciseOptionRow extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string text = 2;
     */
    this.text = "";
    /**
     * @generated from field: string hint = 3;
     */
    this.hint = "";
    /**
     * @generated from field: int32 order = 4;
     */
    this.order = 0;
    /**
     * @generated from field: int32 value = 5;
     */
    this.value = 0;
    /**
     * @generated from field: string preview = 6;
     */
    this.preview = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ExerciseOptionRow().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ExerciseOptionRow().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ExerciseOptionRow().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ExerciseOptionRow, a, b);
  }
}
ExerciseOptionRow.runtime = proto3;
ExerciseOptionRow.typeName = "GoThrive.ExerciseOptionRow";
ExerciseOptionRow.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "text",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "hint",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "order",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 5,
  name: "value",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 6,
  name: "preview",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.SeparatorElement
 */
export class SeparatorElement extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string asset = 2;
     */
    this.asset = "";
    /**
     * @generated from field: string style = 3;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new SeparatorElement().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new SeparatorElement().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new SeparatorElement().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(SeparatorElement, a, b);
  }
}
SeparatorElement.runtime = proto3;
SeparatorElement.typeName = "GoThrive.SeparatorElement";
SeparatorElement.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "asset",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.TableStyle
 */
export class TableStyle extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string headerStyle = 2;
     */
    this.headerStyle = "";
    /**
     * @generated from field: string dividerStyle = 3;
     */
    this.dividerStyle = "";
    /**
     * @generated from field: string footerStyle = 4;
     */
    this.footerStyle = "";
    /**
     * @generated from field: string rowStyle = 5;
     */
    this.rowStyle = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TableStyle().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TableStyle().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TableStyle().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TableStyle, a, b);
  }
}
TableStyle.runtime = proto3;
TableStyle.typeName = "GoThrive.TableStyle";
TableStyle.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "headerStyle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "dividerStyle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "footerStyle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "rowStyle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CustomInput
 */
export class CustomInput extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string value = 2;
     */
    this.value = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CustomInput().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CustomInput().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CustomInput().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CustomInput, a, b);
  }
}
CustomInput.runtime = proto3;
CustomInput.typeName = "GoThrive.CustomInput";
CustomInput.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "value",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.Popup
 */
export class Popup extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string body = 3;
     */
    this.body = "";
    /**
     * @generated from field: string positiveButton = 4;
     */
    this.positiveButton = "";
    /**
     * @generated from field: string negativeButton = 5;
     */
    this.negativeButton = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Popup().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Popup().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Popup().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Popup, a, b);
  }
}
Popup.runtime = proto3;
Popup.typeName = "GoThrive.Popup";
Popup.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "positiveButton",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "negativeButton",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.MediaPopup
 */
export class MediaPopup extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string style = 7;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MediaPopup().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MediaPopup().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MediaPopup().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MediaPopup, a, b);
  }
}
MediaPopup.runtime = proto3;
MediaPopup.typeName = "GoThrive.MediaPopup";
MediaPopup.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "button",
  kind: "message",
  T: Button
}, {
  no: 6,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 7,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.InputView
 */
export class InputView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string lockUnlockAsset = 2;
     */
    this.lockUnlockAsset = "";
    /**
     * @generated from field: bool isList = 3;
     */
    this.isList = false;
    /**
     * @generated from field: string title = 4;
     */
    this.title = "";
    /**
     * @generated from field: string placeholder = 5;
     */
    this.placeholder = "";
    /**
     * @generated from field: string style = 7;
     */
    this.style = "";
    /**
     * @generated from field: repeated string prefill = 9;
     */
    this.prefill = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new InputView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new InputView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new InputView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(InputView, a, b);
  }
}
InputView.runtime = proto3;
InputView.typeName = "GoThrive.InputView";
InputView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "lockUnlockAsset",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "isList",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 4,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "placeholder",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "detailView",
  kind: "message",
  T: InputDetailView
}, {
  no: 7,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "imageAsset",
  kind: "message",
  T: ImageIcon
}, {
  no: 9,
  name: "prefill",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.InputDetailView
 */
export class InputDetailView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string placeholder = 4;
     */
    this.placeholder = "";
    /**
     * @generated from field: string hint = 5;
     */
    this.hint = "";
    /**
     * @generated from field: string style = 6;
     */
    this.style = "";
    /**
     * @generated from field: repeated GoThrive.InputDetailViewList elements = 7;
     */
    this.elements = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new InputDetailView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new InputDetailView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new InputDetailView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(InputDetailView, a, b);
  }
}
InputDetailView.runtime = proto3;
InputDetailView.typeName = "GoThrive.InputDetailView";
InputDetailView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar2
}, {
  no: 3,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 4,
  name: "placeholder",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "hint",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "elements",
  kind: "message",
  T: InputDetailViewList,
  repeated: true
}]);
/**
 * @generated from message GoThrive.InputDetailViewList
 */
export class InputDetailViewList extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string style = 2;
     */
    this.style = "";
    /**
     * @generated from field: string title = 3;
     */
    this.title = "";
    /**
     * @generated from field: repeated string elements = 4;
     */
    this.elements = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new InputDetailViewList().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new InputDetailViewList().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new InputDetailViewList().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(InputDetailViewList, a, b);
  }
}
InputDetailViewList.runtime = proto3;
InputDetailViewList.typeName = "GoThrive.InputDetailViewList";
InputDetailViewList.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "elements",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.ImageIcon
 */
export class ImageIcon extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string icon = 2;
     */
    this.icon = "";
    /**
     * @generated from field: string animation = 3;
     */
    this.animation = "";
    /**
     * @generated from field: string background = 4;
     */
    this.background = "";
    /**
     * @generated from field: string style = 5;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ImageIcon().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ImageIcon().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ImageIcon().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ImageIcon, a, b);
  }
}
ImageIcon.runtime = proto3;
ImageIcon.typeName = "GoThrive.ImageIcon";
ImageIcon.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "icon",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "animation",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "background",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CustomScreen
 */
export class CustomScreen extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string referenceId = 2;
     */
    this.referenceId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CustomScreen().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CustomScreen().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CustomScreen().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CustomScreen, a, b);
  }
}
CustomScreen.runtime = proto3;
CustomScreen.typeName = "GoThrive.CustomScreen";
CustomScreen.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "referenceId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.Dialog
 */
export class Dialog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string primaryText = 3;
     */
    this.primaryText = "";
    /**
     * @generated from field: string secondaryText = 4;
     */
    this.secondaryText = "";
    /**
     * @generated from field: string style = 8;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Dialog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Dialog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Dialog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Dialog, a, b);
  }
}
Dialog.runtime = proto3;
Dialog.typeName = "GoThrive.Dialog";
Dialog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "primaryText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "secondaryText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "icon",
  kind: "message",
  T: ImageIcon
}, {
  no: 6,
  name: "primaryButton",
  kind: "message",
  T: Button
}, {
  no: 7,
  name: "secondaryButton",
  kind: "message",
  T: Button
}, {
  no: 8,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);