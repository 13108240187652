// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file analytics.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { UserAnalytics } from "./common_pb.js";
/**
 * @generated from message GoThrive.AnalyticEvent
 */
export class AnalyticEvent extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string eventName = 1;
     */
    this.eventName = "";
    /**
     * @generated from field: bytes params = 2;
     */
    this.params = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AnalyticEvent().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AnalyticEvent().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AnalyticEvent().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AnalyticEvent, a, b);
  }
}
AnalyticEvent.runtime = proto3;
AnalyticEvent.typeName = "GoThrive.AnalyticEvent";
AnalyticEvent.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "eventName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "params",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.CommandSendEvent
 */
export class CommandSendEvent extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool includeDefaultProperties = 2;
     */
    this.includeDefaultProperties = false;
    /**
     * @generated from field: bool includeSuperProperties = 3;
     */
    this.includeSuperProperties = false;
    /**
     * id,  ie. user id
     *
     * @generated from field: string superPropertiesKey = 4;
     */
    this.superPropertiesKey = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSendEvent().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSendEvent().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSendEvent().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSendEvent, a, b);
  }
}
CommandSendEvent.runtime = proto3;
CommandSendEvent.typeName = "GoThrive.CommandSendEvent";
CommandSendEvent.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "event",
  kind: "message",
  T: AnalyticEvent
}, {
  no: 2,
  name: "includeDefaultProperties",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 3,
  name: "includeSuperProperties",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 4,
  name: "superPropertiesKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandRegisterSuperProperties
 */
export class CommandRegisterSuperProperties extends Message {
  constructor(data) {
    super();
    /**
     * id,  ie. user id
     *
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: bytes params = 2;
     */
    this.params = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandRegisterSuperProperties().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandRegisterSuperProperties().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandRegisterSuperProperties().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandRegisterSuperProperties, a, b);
  }
}
CommandRegisterSuperProperties.runtime = proto3;
CommandRegisterSuperProperties.typeName = "GoThrive.CommandRegisterSuperProperties";
CommandRegisterSuperProperties.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "params",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.CommandUnregisterSuperProperties
 */
export class CommandUnregisterSuperProperties extends Message {
  constructor(data) {
    super();
    /**
     * id,  ie. user id
     *
     * @generated from field: string key = 1;
     */
    this.key = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandUnregisterSuperProperties().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandUnregisterSuperProperties().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandUnregisterSuperProperties().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandUnregisterSuperProperties, a, b);
  }
}
CommandUnregisterSuperProperties.runtime = proto3;
CommandUnregisterSuperProperties.typeName = "GoThrive.CommandUnregisterSuperProperties";
CommandUnregisterSuperProperties.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandGetSuperProperties
 */
export class CommandGetSuperProperties extends Message {
  constructor(data) {
    super();
    /**
     * id,  ie. user id
     *
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: bytes params = 2;
     */
    this.params = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetSuperProperties().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetSuperProperties().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetSuperProperties().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetSuperProperties, a, b);
  }
}
CommandGetSuperProperties.runtime = proto3;
CommandGetSuperProperties.typeName = "GoThrive.CommandGetSuperProperties";
CommandGetSuperProperties.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "params",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.CommandSendUserAnalytics
 */
export class CommandSendUserAnalytics extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSendUserAnalytics().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSendUserAnalytics().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSendUserAnalytics().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSendUserAnalytics, a, b);
  }
}
CommandSendUserAnalytics.runtime = proto3;
CommandSendUserAnalytics.typeName = "GoThrive.CommandSendUserAnalytics";
CommandSendUserAnalytics.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "userAnalytics",
  kind: "message",
  T: UserAnalytics
}]);