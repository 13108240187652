// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file observe.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Any, Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.CommandObserveProperty
 */
export class CommandObserveProperty extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: string key = 2;
     */
    this.key = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandObserveProperty().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandObserveProperty().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandObserveProperty().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandObserveProperty, a, b);
  }
}
CommandObserveProperty.runtime = proto3;
CommandObserveProperty.typeName = "GoThrive.CommandObserveProperty";
CommandObserveProperty.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandAddProperty
 */
export class CommandAddProperty extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: bool update = 3;
     */
    this.update = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandAddProperty().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandAddProperty().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandAddProperty().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandAddProperty, a, b);
  }
}
CommandAddProperty.runtime = proto3;
CommandAddProperty.typeName = "GoThrive.CommandAddProperty";
CommandAddProperty.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "message",
  kind: "message",
  T: Any
}, {
  no: 3,
  name: "update",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.CommandUpdateProperty
 */
export class CommandUpdateProperty extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandUpdateProperty().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandUpdateProperty().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandUpdateProperty().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandUpdateProperty, a, b);
  }
}
CommandUpdateProperty.runtime = proto3;
CommandUpdateProperty.typeName = "GoThrive.CommandUpdateProperty";
CommandUpdateProperty.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "message",
  kind: "message",
  T: Any
}]);