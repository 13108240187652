// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file organization.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.OrgImage
 */
export class OrgImage extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrgImage().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrgImage().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrgImage().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrgImage, a, b);
  }
}
OrgImage.runtime = proto3;
OrgImage.typeName = "GoThrive.OrgImage";
OrgImage.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "logos",
  kind: "message",
  T: OrgImage_Logo
}]);
/**
 * @generated from message GoThrive.OrgImage.Logo
 */
export class OrgImage_Logo extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string default = 1;
     */
    this.default = "";
    /**
     * @generated from field: string small = 2;
     */
    this.small = "";
    /**
     * @generated from field: string smallMono = 3;
     */
    this.smallMono = "";
    /**
     * @generated from field: string clientMenu = 4;
     */
    this.clientMenu = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrgImage_Logo().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrgImage_Logo().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrgImage_Logo().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrgImage_Logo, a, b);
  }
}
OrgImage_Logo.runtime = proto3;
OrgImage_Logo.typeName = "GoThrive.OrgImage.Logo";
OrgImage_Logo.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "default",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "small",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "smallMono",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "clientMenu",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.Details
 */
export class Details extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string title = 1;
     */
    this.title = "";
    /**
     * @generated from field: string name = 2;
     */
    this.name = "";
    /**
     * @generated from field: string description = 3;
     */
    this.description = "";
    /**
     * @generated from field: bool disableSmallMonoTint = 5;
     */
    this.disableSmallMonoTint = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Details().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Details().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Details().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Details, a, b);
  }
}
Details.runtime = proto3;
Details.typeName = "GoThrive.Details";
Details.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "images",
  kind: "message",
  T: OrgImage
}, {
  no: 5,
  name: "disableSmallMonoTint",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.AppSettings
 */
export class AppSettings extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string cbtCategory = 1;
     */
    this.cbtCategory = "";
    /**
     * @generated from field: string hadsCounsellorNumber = 2;
     */
    this.hadsCounsellorNumber = "";
    /**
     * @generated from field: string welfareEmailText = 3;
     */
    this.welfareEmailText = "";
    /**
     * @generated from field: string welfareClientMenuText = 4;
     */
    this.welfareClientMenuText = "";
    /**
     * @generated from field: string welfareEmailConfirmMessage = 5;
     */
    this.welfareEmailConfirmMessage = "";
    /**
     * @generated from field: string autoChatSentMsg = 6;
     */
    this.autoChatSentMsg = "";
    /**
     * @generated from field: repeated GoThrive.AppSettings.Link links = 7;
     */
    this.links = [];
    /**
     * @generated from field: repeated GoThrive.AppSettings.ClientRep clientReps = 8;
     */
    this.clientReps = [];
    /**
     * @generated from field: repeated GoThrive.AppSettings.RequestCallbackEmail requestCallbackEmails = 9;
     */
    this.requestCallbackEmails = [];
    /**
     * @generated from field: string requestCallbackTitle = 10;
     */
    this.requestCallbackTitle = "";
    /**
     * @generated from field: string requestCallbackDescription = 11;
     */
    this.requestCallbackDescription = "";
    /**
     * @generated from field: repeated GoThrive.AppSettings.RequestCallbackAdditionalField requestCallbackAdditionalFields = 12;
     */
    this.requestCallbackAdditionalFields = [];
    /**
     * @generated from field: bool flipSplash = 13;
     */
    this.flipSplash = false;
    /**
     * @generated from field: bool clientIsSingular = 14;
     */
    this.clientIsSingular = false;
    /**
     * @generated from field: bool notifyAllClientReps = 15;
     */
    this.notifyAllClientReps = false;
    /**
     * @generated from field: bool hasTherapistChat = 16;
     */
    this.hasTherapistChat = false;
    /**
     * @generated from field: bool chatOnboarding = 17;
     */
    this.chatOnboarding = false;
    /**
     * @generated from field: bool chatOnPositiveResults = 18;
     */
    this.chatOnPositiveResults = false;
    /**
     * @generated from field: bool ignoreDefaultSevereLinks = 19;
     */
    this.ignoreDefaultSevereLinks = false;
    /**
     * @generated from field: string defaultAvatar = 20;
     */
    this.defaultAvatar = "";
    /**
     * @generated from field: string crateLabel = 21;
     */
    this.crateLabel = "";
    /**
     * @generated from field: string introVideo = 22;
     */
    this.introVideo = "";
    /**
     * @generated from field: string customCBTResultsText = 23;
     */
    this.customCBTResultsText = "";
    /**
     * @generated from field: bool counsellorIs24Hours = 25;
     */
    this.counsellorIs24Hours = false;
    /**
     * @generated from field: repeated GoThrive.AppSettings.Link quizLinks = 26;
     */
    this.quizLinks = [];
    /**
     * @generated from field: repeated GoThrive.AppSettings.Link mildLinks = 27;
     */
    this.mildLinks = [];
    /**
     * @generated from field: bool dontAddDefaultLinks = 28;
     */
    this.dontAddDefaultLinks = false;
    /**
     * @generated from field: repeated GoThrive.AppSettings.Link resilientResilienceLinks = 29;
     */
    this.resilientResilienceLinks = [];
    /**
     * @generated from field: repeated GoThrive.AppSettings.Link averageResilienceLinks = 30;
     */
    this.averageResilienceLinks = [];
    /**
     * @generated from field: repeated GoThrive.AppSettings.Link atRiskResilienceLinks = 31;
     */
    this.atRiskResilienceLinks = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AppSettings().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AppSettings().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AppSettings().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AppSettings, a, b);
  }
}
AppSettings.runtime = proto3;
AppSettings.typeName = "GoThrive.AppSettings";
AppSettings.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "cbtCategory",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "hadsCounsellorNumber",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "welfareEmailText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "welfareClientMenuText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "welfareEmailConfirmMessage",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "autoChatSentMsg",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "links",
  kind: "message",
  T: AppSettings_Link,
  repeated: true
}, {
  no: 8,
  name: "clientReps",
  kind: "message",
  T: AppSettings_ClientRep,
  repeated: true
}, {
  no: 9,
  name: "requestCallbackEmails",
  kind: "message",
  T: AppSettings_RequestCallbackEmail,
  repeated: true
}, {
  no: 10,
  name: "requestCallbackTitle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 11,
  name: "requestCallbackDescription",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 12,
  name: "requestCallbackAdditionalFields",
  kind: "message",
  T: AppSettings_RequestCallbackAdditionalField,
  repeated: true
}, {
  no: 13,
  name: "flipSplash",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 14,
  name: "clientIsSingular",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 15,
  name: "notifyAllClientReps",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 16,
  name: "hasTherapistChat",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 17,
  name: "chatOnboarding",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 18,
  name: "chatOnPositiveResults",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 19,
  name: "ignoreDefaultSevereLinks",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 20,
  name: "defaultAvatar",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 21,
  name: "crateLabel",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 22,
  name: "introVideo",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 23,
  name: "customCBTResultsText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 25,
  name: "counsellorIs24Hours",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 26,
  name: "quizLinks",
  kind: "message",
  T: AppSettings_Link,
  repeated: true
}, {
  no: 27,
  name: "mildLinks",
  kind: "message",
  T: AppSettings_Link,
  repeated: true
}, {
  no: 28,
  name: "dontAddDefaultLinks",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 29,
  name: "resilientResilienceLinks",
  kind: "message",
  T: AppSettings_Link,
  repeated: true
}, {
  no: 30,
  name: "averageResilienceLinks",
  kind: "message",
  T: AppSettings_Link,
  repeated: true
}, {
  no: 31,
  name: "atRiskResilienceLinks",
  kind: "message",
  T: AppSettings_Link,
  repeated: true
}]);
/**
 * @generated from message GoThrive.AppSettings.Link
 */
export class AppSettings_Link extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string name = 1;
     */
    this.name = "";
    /**
     * @generated from field: string link = 2;
     */
    this.link = "";
    /**
     * @generated from field: string description = 3;
     */
    this.description = "";
    /**
     * @generated from field: bool helpLink = 4;
     */
    this.helpLink = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AppSettings_Link().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AppSettings_Link().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AppSettings_Link().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AppSettings_Link, a, b);
  }
}
AppSettings_Link.runtime = proto3;
AppSettings_Link.typeName = "GoThrive.AppSettings.Link";
AppSettings_Link.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "link",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "helpLink",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 5,
  name: "linkType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}]);
/**
 * @generated from message GoThrive.AppSettings.ClientRep
 */
export class AppSettings_ClientRep extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string name = 1;
     */
    this.name = "";
    /**
     * @generated from field: string email = 2;
     */
    this.email = "";
    /**
     * @generated from field: string role = 3;
     */
    this.role = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AppSettings_ClientRep().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AppSettings_ClientRep().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AppSettings_ClientRep().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AppSettings_ClientRep, a, b);
  }
}
AppSettings_ClientRep.runtime = proto3;
AppSettings_ClientRep.typeName = "GoThrive.AppSettings.ClientRep";
AppSettings_ClientRep.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "role",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.AppSettings.RequestCallbackEmail
 */
export class AppSettings_RequestCallbackEmail extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string groupName = 1;
     */
    this.groupName = "";
    /**
     * @generated from field: repeated string groupEmails = 2;
     */
    this.groupEmails = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AppSettings_RequestCallbackEmail().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AppSettings_RequestCallbackEmail().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AppSettings_RequestCallbackEmail().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AppSettings_RequestCallbackEmail, a, b);
  }
}
AppSettings_RequestCallbackEmail.runtime = proto3;
AppSettings_RequestCallbackEmail.typeName = "GoThrive.AppSettings.RequestCallbackEmail";
AppSettings_RequestCallbackEmail.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "groupName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "groupEmails",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.AppSettings.RequestCallbackAdditionalField
 */
export class AppSettings_RequestCallbackAdditionalField extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool required = 1;
     */
    this.required = false;
    /**
     * @generated from field: string fieldType = 2;
     */
    this.fieldType = "";
    /**
     * @generated from field: string fieldId = 3;
     */
    this.fieldId = "";
    /**
     * @generated from field: string label = 4;
     */
    this.label = "";
    /**
     * @generated from field: string placeholder = 5;
     */
    this.placeholder = "";
    /**
     * @generated from field: string defaultValue = 6;
     */
    this.defaultValue = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AppSettings_RequestCallbackAdditionalField().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AppSettings_RequestCallbackAdditionalField().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AppSettings_RequestCallbackAdditionalField().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AppSettings_RequestCallbackAdditionalField, a, b);
  }
}
AppSettings_RequestCallbackAdditionalField.runtime = proto3;
AppSettings_RequestCallbackAdditionalField.typeName = "GoThrive.AppSettings.RequestCallbackAdditionalField";
AppSettings_RequestCallbackAdditionalField.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "required",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 2,
  name: "fieldType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "fieldId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "label",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "placeholder",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "defaultValue",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.AppSettings.QuizLink
 */
export class AppSettings_QuizLink extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string name = 1;
     */
    this.name = "";
    /**
     * @generated from field: string link = 2;
     */
    this.link = "";
    /**
     * @generated from field: string description = 3;
     */
    this.description = "";
    /**
     * @generated from field: bool helpLink = 4;
     */
    this.helpLink = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AppSettings_QuizLink().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AppSettings_QuizLink().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AppSettings_QuizLink().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AppSettings_QuizLink, a, b);
  }
}
AppSettings_QuizLink.runtime = proto3;
AppSettings_QuizLink.typeName = "GoThrive.AppSettings.QuizLink";
AppSettings_QuizLink.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "link",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "helpLink",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.Subscription
 */
export class Subscription extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool enabled = 1;
     */
    this.enabled = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Subscription().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Subscription().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Subscription().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Subscription, a, b);
  }
}
Subscription.runtime = proto3;
Subscription.typeName = "GoThrive.Subscription";
Subscription.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 2,
  name: "redemptions",
  kind: "message",
  T: Subscription_Redemptions
}, {
  no: 3,
  name: "whitelist",
  kind: "message",
  T: Subscription_Whitelist
}]);
/**
 * @generated from message GoThrive.Subscription.Redemptions
 */
export class Subscription_Redemptions extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 current = 1;
     */
    this.current = 0;
    /**
     * @generated from field: int32 max = 2;
     */
    this.max = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Subscription_Redemptions().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Subscription_Redemptions().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Subscription_Redemptions().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Subscription_Redemptions, a, b);
  }
}
Subscription_Redemptions.runtime = proto3;
Subscription_Redemptions.typeName = "GoThrive.Subscription.Redemptions";
Subscription_Redemptions.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "current",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "max",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.Subscription.Whitelist
 */
export class Subscription_Whitelist extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated string emailDomains = 1;
     */
    this.emailDomains = [];
    /**
     * @generated from field: repeated string openIDIssuer = 2;
     */
    this.openIDIssuer = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Subscription_Whitelist().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Subscription_Whitelist().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Subscription_Whitelist().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Subscription_Whitelist, a, b);
  }
}
Subscription_Whitelist.runtime = proto3;
Subscription_Whitelist.typeName = "GoThrive.Subscription.Whitelist";
Subscription_Whitelist.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "emailDomains",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 2,
  name: "openIDIssuer",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.Logos
 */
export class Logos extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string default = 1;
     */
    this.default = "";
    /**
     * @generated from field: string darkDefault = 2;
     */
    this.darkDefault = "";
    /**
     * @generated from field: string smallMono = 3;
     */
    this.smallMono = "";
    /**
     * @generated from field: string darkSmallMono = 4;
     */
    this.darkSmallMono = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Logos().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Logos().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Logos().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Logos, a, b);
  }
}
Logos.runtime = proto3;
Logos.typeName = "GoThrive.Logos";
Logos.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "default",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "darkDefault",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "smallMono",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "darkSmallMono",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.LinkGroup
 */
export class LinkGroup extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string name = 1;
     */
    this.name = "";
    /**
     * @generated from field: string description = 2;
     */
    this.description = "";
    /**
     * @generated from field: repeated GoThrive.AppSettings.Link links = 4;
     */
    this.links = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new LinkGroup().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new LinkGroup().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new LinkGroup().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(LinkGroup, a, b);
  }
}
LinkGroup.runtime = proto3;
LinkGroup.typeName = "GoThrive.LinkGroup";
LinkGroup.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "logos",
  kind: "message",
  T: Logos
}, {
  no: 4,
  name: "links",
  kind: "message",
  T: AppSettings_Link,
  repeated: true
}]);
/**
 * @generated from message GoThrive.ClientLinksData
 */
export class ClientLinksData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.ClientLinks organisationHierarchy = 1;
     */
    this.organisationHierarchy = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ClientLinksData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ClientLinksData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ClientLinksData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ClientLinksData, a, b);
  }
}
ClientLinksData.runtime = proto3;
ClientLinksData.typeName = "GoThrive.ClientLinksData";
ClientLinksData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "organisationHierarchy",
  kind: "message",
  T: ClientLinks,
  repeated: true
}]);
/**
 * @generated from message GoThrive.ClientLinks
 */
export class ClientLinks extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.LinkGroup linkGroups = 1;
     */
    this.linkGroups = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ClientLinks().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ClientLinks().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ClientLinks().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ClientLinks, a, b);
  }
}
ClientLinks.runtime = proto3;
ClientLinks.typeName = "GoThrive.ClientLinks";
ClientLinks.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "linkGroups",
  kind: "message",
  T: LinkGroup,
  repeated: true
}]);
/**
 * @generated from message GoThrive.Organization
 */
export class Organization extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.ClientLinks> clientLinks = 4;
     */
    this.clientLinks = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Organization().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Organization().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Organization().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Organization, a, b);
  }
}
Organization.runtime = proto3;
Organization.typeName = "GoThrive.Organization";
Organization.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "details",
  kind: "message",
  T: Details
}, {
  no: 2,
  name: "subscription",
  kind: "message",
  T: Subscription
}, {
  no: 3,
  name: "appSettings",
  kind: "message",
  T: AppSettings
}, {
  no: 4,
  name: "clientLinks",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: ClientLinks
  }
}]);
/**
 * @generated from message GoThrive.Org
 */
export class Org extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string name = 3;
     */
    this.name = "";
    /**
     * @generated from field: string parentId = 4;
     */
    this.parentId = "";
    /**
     * @generated from field: string imagePath = 5;
     */
    this.imagePath = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Org().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Org().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Org().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Org, a, b);
  }
}
Org.runtime = proto3;
Org.typeName = "GoThrive.Org";
Org.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "parentId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "imagePath",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.Organizations
 */
export class Organizations extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string lastPage = 1;
     */
    this.lastPage = "";
    /**
     * @generated from field: repeated GoThrive.Org organisations = 2;
     */
    this.organisations = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Organizations().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Organizations().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Organizations().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Organizations, a, b);
  }
}
Organizations.runtime = proto3;
Organizations.typeName = "GoThrive.Organizations";
Organizations.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "lastPage",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "organisations",
  kind: "message",
  T: Org,
  repeated: true
}]);
/**
 * @generated from message GoThrive.OrganizationsListParams
 */
export class OrganizationsListParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 pageSize = 1;
     */
    this.pageSize = 0;
    /**
     * @generated from field: string parentOrganisation = 2;
     */
    this.parentOrganisation = "";
    /**
     * @generated from field: string lastPage = 3;
     */
    this.lastPage = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrganizationsListParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrganizationsListParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrganizationsListParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrganizationsListParams, a, b);
  }
}
OrganizationsListParams.runtime = proto3;
OrganizationsListParams.typeName = "GoThrive.OrganizationsListParams";
OrganizationsListParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "pageSize",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "parentOrganisation",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "lastPage",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.OrganizationAddBody
 */
export class OrganizationAddBody extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string parent = 1;
     */
    this.parent = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string name = 3;
     */
    this.name = "";
    /**
     * @generated from field: string description = 4;
     */
    this.description = "";
    /**
     * @generated from field: bool disableSmallMonoTint = 6;
     */
    this.disableSmallMonoTint = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrganizationAddBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrganizationAddBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrganizationAddBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrganizationAddBody, a, b);
  }
}
OrganizationAddBody.runtime = proto3;
OrganizationAddBody.typeName = "GoThrive.OrganizationAddBody";
OrganizationAddBody.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "parent",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "images",
  kind: "message",
  T: OrgImage
}, {
  no: 6,
  name: "disableSmallMonoTint",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.OrganizationAddParams
 */
export class OrganizationAddParams extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrganizationAddParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrganizationAddParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrganizationAddParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrganizationAddParams, a, b);
  }
}
OrganizationAddParams.runtime = proto3;
OrganizationAddParams.typeName = "GoThrive.OrganizationAddParams";
OrganizationAddParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "maxRedemptions",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}]);
/**
 * @generated from message GoThrive.OrganizationSubscriptionValid
 */
export class OrganizationSubscriptionValid extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool valid = 1;
     */
    this.valid = false;
    /**
     * @generated from field: bool limitReached = 2;
     */
    this.limitReached = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrganizationSubscriptionValid().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrganizationSubscriptionValid().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrganizationSubscriptionValid().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrganizationSubscriptionValid, a, b);
  }
}
OrganizationSubscriptionValid.runtime = proto3;
OrganizationSubscriptionValid.typeName = "GoThrive.OrganizationSubscriptionValid";
OrganizationSubscriptionValid.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "valid",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 2,
  name: "limitReached",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.OrganizationUnMergedUrlParams
 */
export class OrganizationUnMergedUrlParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string organizationId = 1;
     */
    this.organizationId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrganizationUnMergedUrlParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrganizationUnMergedUrlParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrganizationUnMergedUrlParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrganizationUnMergedUrlParams, a, b);
  }
}
OrganizationUnMergedUrlParams.runtime = proto3;
OrganizationUnMergedUrlParams.typeName = "GoThrive.OrganizationUnMergedUrlParams";
OrganizationUnMergedUrlParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "organizationId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.OrganizationUnMerged
 */
export class OrganizationUnMerged extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.Organization organisationHierarchy = 1;
     */
    this.organisationHierarchy = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrganizationUnMerged().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrganizationUnMerged().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrganizationUnMerged().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrganizationUnMerged, a, b);
  }
}
OrganizationUnMerged.runtime = proto3;
OrganizationUnMerged.typeName = "GoThrive.OrganizationUnMerged";
OrganizationUnMerged.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "organisationHierarchy",
  kind: "message",
  T: Organization,
  repeated: true
}]);
/**
 * @generated from message GoThrive.OrganizationDetailsUnMerged
 */
export class OrganizationDetailsUnMerged extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.Details organisationHierarchy = 1;
     */
    this.organisationHierarchy = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrganizationDetailsUnMerged().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrganizationDetailsUnMerged().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrganizationDetailsUnMerged().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrganizationDetailsUnMerged, a, b);
  }
}
OrganizationDetailsUnMerged.runtime = proto3;
OrganizationDetailsUnMerged.typeName = "GoThrive.OrganizationDetailsUnMerged";
OrganizationDetailsUnMerged.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "organisationHierarchy",
  kind: "message",
  T: Details,
  repeated: true
}]);
/**
 * @generated from message GoThrive.OrganizationAppSettingsUnMerged
 */
export class OrganizationAppSettingsUnMerged extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.AppSettings organisationHierarchy = 1;
     */
    this.organisationHierarchy = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrganizationAppSettingsUnMerged().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrganizationAppSettingsUnMerged().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrganizationAppSettingsUnMerged().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrganizationAppSettingsUnMerged, a, b);
  }
}
OrganizationAppSettingsUnMerged.runtime = proto3;
OrganizationAppSettingsUnMerged.typeName = "GoThrive.OrganizationAppSettingsUnMerged";
OrganizationAppSettingsUnMerged.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "organisationHierarchy",
  kind: "message",
  T: AppSettings,
  repeated: true
}]);
/**
 * @generated from message GoThrive.OrganizationImageUploadPolicyDocument
 */
export class OrganizationImageUploadPolicyDocument extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string url = 2;
     */
    this.url = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrganizationImageUploadPolicyDocument().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrganizationImageUploadPolicyDocument().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrganizationImageUploadPolicyDocument().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrganizationImageUploadPolicyDocument, a, b);
  }
}
OrganizationImageUploadPolicyDocument.runtime = proto3;
OrganizationImageUploadPolicyDocument.typeName = "GoThrive.OrganizationImageUploadPolicyDocument";
OrganizationImageUploadPolicyDocument.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "formData",
  kind: "message",
  T: OrganizationImageUploadPolicyDocument_FormData
}, {
  no: 2,
  name: "url",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.OrganizationImageUploadPolicyDocument.FormData
 */
export class OrganizationImageUploadPolicyDocument_FormData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string GoogleAccessId = 2;
     */
    this.GoogleAccessId = "";
    /**
     * @generated from field: string policy = 3;
     */
    this.policy = "";
    /**
     * @generated from field: string signature = 4;
     */
    this.signature = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrganizationImageUploadPolicyDocument_FormData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrganizationImageUploadPolicyDocument_FormData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrganizationImageUploadPolicyDocument_FormData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrganizationImageUploadPolicyDocument_FormData, a, b);
  }
}
OrganizationImageUploadPolicyDocument_FormData.runtime = proto3;
OrganizationImageUploadPolicyDocument_FormData.typeName = "GoThrive.OrganizationImageUploadPolicyDocument.FormData";
OrganizationImageUploadPolicyDocument_FormData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "GoogleAccessId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "policy",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "signature",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.OrganizationIsWhitelisted
 */
export class OrganizationIsWhitelisted extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool emailWhitelisted = 1;
     */
    this.emailWhitelisted = false;
    /**
     * @generated from field: bool accountExists = 2;
     */
    this.accountExists = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrganizationIsWhitelisted().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrganizationIsWhitelisted().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrganizationIsWhitelisted().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrganizationIsWhitelisted, a, b);
  }
}
OrganizationIsWhitelisted.runtime = proto3;
OrganizationIsWhitelisted.typeName = "GoThrive.OrganizationIsWhitelisted";
OrganizationIsWhitelisted.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "emailWhitelisted",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 2,
  name: "accountExists",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.OrganizationIsWhitelistedParams
 */
export class OrganizationIsWhitelistedParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string email = 1;
     */
    this.email = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrganizationIsWhitelistedParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrganizationIsWhitelistedParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrganizationIsWhitelistedParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrganizationIsWhitelistedParams, a, b);
  }
}
OrganizationIsWhitelistedParams.runtime = proto3;
OrganizationIsWhitelistedParams.typeName = "GoThrive.OrganizationIsWhitelistedParams";
OrganizationIsWhitelistedParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.OrganizationsSearch
 */
export class OrganizationsSearch extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.Org entries = 1;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrganizationsSearch().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrganizationsSearch().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrganizationsSearch().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrganizationsSearch, a, b);
  }
}
OrganizationsSearch.runtime = proto3;
OrganizationsSearch.typeName = "GoThrive.OrganizationsSearch";
OrganizationsSearch.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "message",
  T: Org,
  repeated: true
}]);
/**
 * @generated from message GoThrive.OrganizationsSearchParams
 */
export class OrganizationsSearchParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string term = 1;
     */
    this.term = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrganizationsSearchParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrganizationsSearchParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrganizationsSearchParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrganizationsSearchParams, a, b);
  }
}
OrganizationsSearchParams.runtime = proto3;
OrganizationsSearchParams.typeName = "GoThrive.OrganizationsSearchParams";
OrganizationsSearchParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "term",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.OrganizationsEnableParams
 */
export class OrganizationsEnableParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 maxRedemptions = 1;
     */
    this.maxRedemptions = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrganizationsEnableParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrganizationsEnableParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrganizationsEnableParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrganizationsEnableParams, a, b);
  }
}
OrganizationsEnableParams.runtime = proto3;
OrganizationsEnableParams.typeName = "GoThrive.OrganizationsEnableParams";
OrganizationsEnableParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "maxRedemptions",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);