// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file featuretoggle.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * / FEATURE TOGGLE
 *
 * @generated from message GoThrive.FeatureToggle
 */
export class FeatureToggle extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string description = 3;
     */
    this.description = "";
    /**
     * @generated from field: string group = 4;
     */
    this.group = "";
    /**
     * @generated from field: bool enabled = 5;
     */
    this.enabled = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new FeatureToggle().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new FeatureToggle().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new FeatureToggle().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(FeatureToggle, a, b);
  }
}
FeatureToggle.runtime = proto3;
FeatureToggle.typeName = "GoThrive.FeatureToggle";
FeatureToggle.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "group",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.FeatureToggleCatalog
 */
export class FeatureToggleCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.FeatureToggle> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new FeatureToggleCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new FeatureToggleCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new FeatureToggleCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(FeatureToggleCatalog, a, b);
  }
}
FeatureToggleCatalog.runtime = proto3;
FeatureToggleCatalog.typeName = "GoThrive.FeatureToggleCatalog";
FeatureToggleCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: FeatureToggle
  }
}]);