// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file activity.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.Activity
 */
export class Activity extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string type = 1;
     */
    this.type = "";
    /**
     * @generated from field: int64 ts = 2;
     */
    this.ts = protoInt64.zero;
    /**
     * @generated from field: string id = 3;
     */
    this.id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Activity().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Activity().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Activity().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Activity, a, b);
  }
}
Activity.runtime = proto3;
Activity.typeName = "GoThrive.Activity";
Activity.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "ts",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 3,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "data",
  kind: "message",
  T: Activity_Data
}]);
/**
 * @generated from message GoThrive.Activity.Data
 */
export class Activity_Data extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string type = 3;
     */
    this.type = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Activity_Data().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Activity_Data().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Activity_Data().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Activity_Data, a, b);
  }
}
Activity_Data.runtime = proto3;
Activity_Data.typeName = "GoThrive.Activity.Data";
Activity_Data.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "duration",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */,
  opt: true
}, {
  no: 2,
  name: "PatchTimestamp",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */,
  opt: true
}, {
  no: 3,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.Activities
 */
export class Activities extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.Activity entries = 1;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Activities().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Activities().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Activities().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Activities, a, b);
  }
}
Activities.runtime = proto3;
Activities.typeName = "GoThrive.Activities";
Activities.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "message",
  T: Activity,
  repeated: true
}]);