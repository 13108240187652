// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file chatSlashCommands.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Any, Message, proto3 } from "@bufbuild/protobuf";
import { ChatCommand, ChatCommands } from "./chat_pb.js";
/**
 * @generated from message GoThrive.CommandSlashChatCommand
 */
export class CommandSlashChatCommand extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Key = 1;
     */
    this.Key = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSlashChatCommand().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSlashChatCommand().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSlashChatCommand().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSlashChatCommand, a, b);
  }
}
CommandSlashChatCommand.runtime = proto3;
CommandSlashChatCommand.typeName = "GoThrive.CommandSlashChatCommand";
CommandSlashChatCommand.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Command",
  kind: "message",
  T: ChatCommand
}, {
  no: 3,
  name: "response",
  kind: "message",
  T: Any,
  opt: true
}]);
/**
 * @generated from message GoThrive.CommandSlashChatCommandValidate
 */
export class CommandSlashChatCommandValidate extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Key = 1;
     */
    this.Key = "";
    /**
     * @generated from field: bool valid = 3;
     */
    this.valid = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSlashChatCommandValidate().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSlashChatCommandValidate().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSlashChatCommandValidate().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSlashChatCommandValidate, a, b);
  }
}
CommandSlashChatCommandValidate.runtime = proto3;
CommandSlashChatCommandValidate.typeName = "GoThrive.CommandSlashChatCommandValidate";
CommandSlashChatCommandValidate.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Command",
  kind: "message",
  T: ChatCommand
}, {
  no: 3,
  name: "valid",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.CommandSlashChatCommands
 */
export class CommandSlashChatCommands extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSlashChatCommands().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSlashChatCommands().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSlashChatCommands().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSlashChatCommands, a, b);
  }
}
CommandSlashChatCommands.runtime = proto3;
CommandSlashChatCommands.typeName = "GoThrive.CommandSlashChatCommands";
CommandSlashChatCommands.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Commands",
  kind: "message",
  T: ChatCommands
}]);