// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file animation.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.Animation
 */
export class Animation extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string url = 2;
     */
    this.url = "";
    /**
     * @generated from field: int64 duration = 3;
     */
    this.duration = protoInt64.zero;
    /**
     * @generated from field: int64 size = 4;
     */
    this.size = protoInt64.zero;
    /**
     * @generated from field: bytes data = 5;
     */
    this.data = new Uint8Array(0);
    /**
     * @generated from field: bool omitdata = 6;
     */
    this.omitdata = false;
    /**
     * @generated from field: bool loop = 7;
     */
    this.loop = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Animation().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Animation().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Animation().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Animation, a, b);
  }
}
Animation.runtime = proto3;
Animation.typeName = "GoThrive.Animation";
Animation.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "url",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "duration",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 4,
  name: "size",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 5,
  name: "data",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}, {
  no: 6,
  name: "omitdata",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 7,
  name: "loop",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.AnimationCatalog
 */
export class AnimationCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.Animation> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AnimationCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AnimationCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AnimationCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AnimationCatalog, a, b);
  }
}
AnimationCatalog.runtime = proto3;
AnimationCatalog.typeName = "GoThrive.AnimationCatalog";
AnimationCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Animation
  }
}]);