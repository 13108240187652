// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file iamsUserManagement.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.AdditionalData
 */
export class AdditionalData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string dataType = 1;
     */
    this.dataType = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AdditionalData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AdditionalData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AdditionalData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AdditionalData, a, b);
  }
}
AdditionalData.runtime = proto3;
AdditionalData.typeName = "GoThrive.AdditionalData";
AdditionalData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "dataType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.UserCreationBody
 */
export class UserCreationBody extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string email = 1;
     */
    this.email = "";
    /**
     * @generated from field: string password = 2;
     */
    this.password = "";
    /**
     * @generated from field: string accessCode = 3;
     */
    this.accessCode = "";
    /**
     * @generated from field: string language = 4;
     */
    this.language = "";
    /**
     * @generated from field: repeated GoThrive.AdditionalData additionalSignupData = 5;
     */
    this.additionalSignupData = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new UserCreationBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new UserCreationBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new UserCreationBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(UserCreationBody, a, b);
  }
}
UserCreationBody.runtime = proto3;
UserCreationBody.typeName = "GoThrive.UserCreationBody";
UserCreationBody.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "password",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "accessCode",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "language",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "additionalSignupData",
  kind: "message",
  T: AdditionalData,
  repeated: true
}]);
/**
 * @generated from message GoThrive.UserCreationParams
 */
export class UserCreationParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool multiRegion = 1;
     */
    this.multiRegion = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new UserCreationParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new UserCreationParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new UserCreationParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(UserCreationParams, a, b);
  }
}
UserCreationParams.runtime = proto3;
UserCreationParams.typeName = "GoThrive.UserCreationParams";
UserCreationParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "multiRegion",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);