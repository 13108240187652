// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file accessCodes.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.AccessCodesValid
 */
export class AccessCodesValid extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool Valid = 1;
     */
    this.Valid = false;
    /**
     * @generated from field: bool LimitReached = 2;
     */
    this.LimitReached = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AccessCodesValid().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AccessCodesValid().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AccessCodesValid().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AccessCodesValid, a, b);
  }
}
AccessCodesValid.runtime = proto3;
AccessCodesValid.typeName = "GoThrive.AccessCodesValid";
AccessCodesValid.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Valid",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 2,
  name: "LimitReached",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.AccessCodesValidUrlParams
 */
export class AccessCodesValidUrlParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string AccessCode = 1;
     */
    this.AccessCode = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AccessCodesValidUrlParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AccessCodesValidUrlParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AccessCodesValidUrlParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AccessCodesValidUrlParams, a, b);
  }
}
AccessCodesValidUrlParams.runtime = proto3;
AccessCodesValidUrlParams.typeName = "GoThrive.AccessCodesValidUrlParams";
AccessCodesValidUrlParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "AccessCode",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CreateAccessCodeBody
 */
export class CreateAccessCodeBody extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string AccessCode = 1;
     */
    this.AccessCode = "";
    /**
     * @generated from field: string Organisation = 2;
     */
    this.Organisation = "";
    /**
     * @generated from field: int32 MaxRedemptions = 3;
     */
    this.MaxRedemptions = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CreateAccessCodeBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CreateAccessCodeBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CreateAccessCodeBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CreateAccessCodeBody, a, b);
  }
}
CreateAccessCodeBody.runtime = proto3;
CreateAccessCodeBody.typeName = "GoThrive.CreateAccessCodeBody";
CreateAccessCodeBody.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "AccessCode",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Organisation",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "MaxRedemptions",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.AccessCodesRedemptions
 */
export class AccessCodesRedemptions extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 Current = 1;
     */
    this.Current = 0;
    /**
     * @generated from field: int32 Max = 2;
     */
    this.Max = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AccessCodesRedemptions().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AccessCodesRedemptions().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AccessCodesRedemptions().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AccessCodesRedemptions, a, b);
  }
}
AccessCodesRedemptions.runtime = proto3;
AccessCodesRedemptions.typeName = "GoThrive.AccessCodesRedemptions";
AccessCodesRedemptions.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Current",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "Max",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.AccessCodesResponseData
 */
export class AccessCodesResponseData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Organisation = 1;
     */
    this.Organisation = "";
    /**
     * @generated from field: bool Enabled = 2;
     */
    this.Enabled = false;
    /**
     * @generated from field: string AccessCode = 4;
     */
    this.AccessCode = "";
    /**
     * @generated from field: int32 CreationTimestamp = 5;
     */
    this.CreationTimestamp = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AccessCodesResponseData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AccessCodesResponseData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AccessCodesResponseData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AccessCodesResponseData, a, b);
  }
}
AccessCodesResponseData.runtime = proto3;
AccessCodesResponseData.typeName = "GoThrive.AccessCodesResponseData";
AccessCodesResponseData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Organisation",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 3,
  name: "Redemptions",
  kind: "message",
  T: AccessCodesRedemptions
}, {
  no: 4,
  name: "AccessCode",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "CreationTimestamp",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);