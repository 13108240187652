// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file trackedGoalsScreen.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, Button, ImageIcon, NavigationBar } from "./common_pb.js";
/**
 * @generated from message GoThrive.TrackedGoalsScreen
 */
export class TrackedGoalsScreen extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 4;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.TrackedGoalsScreenOption data = 5;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TrackedGoalsScreen().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TrackedGoalsScreen().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TrackedGoalsScreen().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TrackedGoalsScreen, a, b);
  }
}
TrackedGoalsScreen.runtime = proto3;
TrackedGoalsScreen.typeName = "GoThrive.TrackedGoalsScreen";
TrackedGoalsScreen.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 3,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 4,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 5,
  name: "data",
  kind: "message",
  T: TrackedGoalsScreenOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.TrackedGoalsScreenOption
 */
export class TrackedGoalsScreenOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TrackedGoalsScreenOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TrackedGoalsScreenOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TrackedGoalsScreenOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TrackedGoalsScreenOption, a, b);
  }
}
TrackedGoalsScreenOption.runtime = proto3;
TrackedGoalsScreenOption.typeName = "GoThrive.TrackedGoalsScreenOption";
TrackedGoalsScreenOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.TrackedGoalsCardView
 */
export class TrackedGoalsCardView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string calendarText = 2;
     */
    this.calendarText = "";
    /**
     * @generated from field: string tapToCompleteText = 3;
     */
    this.tapToCompleteText = "";
    /**
     * @generated from field: string goalAbandonAnimation = 5;
     */
    this.goalAbandonAnimation = "";
    /**
     * @generated from field: string goalCompleteAnimation = 7;
     */
    this.goalCompleteAnimation = "";
    /**
     * @generated from field: string style = 8;
     */
    this.style = "";
    /**
     * @generated from field: repeated GoThrive.ImageIcon goalImages = 9;
     */
    this.goalImages = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TrackedGoalsCardView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TrackedGoalsCardView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TrackedGoalsCardView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TrackedGoalsCardView, a, b);
  }
}
TrackedGoalsCardView.runtime = proto3;
TrackedGoalsCardView.typeName = "GoThrive.TrackedGoalsCardView";
TrackedGoalsCardView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "calendarText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "tapToCompleteText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "goalAbandonButton",
  kind: "message",
  T: Button
}, {
  no: 5,
  name: "goalAbandonAnimation",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "goalCompleteButton",
  kind: "message",
  T: Button
}, {
  no: 7,
  name: "goalCompleteAnimation",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "goalImages",
  kind: "message",
  T: ImageIcon,
  repeated: true
}]);
/**
 * @generated from message GoThrive.TrackedGoalsScreenCatalog
 */
export class TrackedGoalsScreenCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.TrackedGoalsScreen> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TrackedGoalsScreenCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TrackedGoalsScreenCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TrackedGoalsScreenCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TrackedGoalsScreenCatalog, a, b);
  }
}
TrackedGoalsScreenCatalog.runtime = proto3;
TrackedGoalsScreenCatalog.typeName = "GoThrive.TrackedGoalsScreenCatalog";
TrackedGoalsScreenCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: TrackedGoalsScreen
  }
}]);