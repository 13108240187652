// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file apiCommand.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Any, Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.CommandApi
 */
export class CommandApi extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string name = 1;
     */
    this.name = "";
    /**
     * @generated from field: bool noRetry = 2;
     */
    this.noRetry = false;
    /**
     * @generated from field: string status = 5;
     */
    this.status = "";
    /**
     * @generated from field: map<string, string> headers = 6;
     */
    this.headers = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandApi().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandApi().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandApi().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandApi, a, b);
  }
}
CommandApi.runtime = proto3;
CommandApi.typeName = "GoThrive.CommandApi";
CommandApi.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "noRetry",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 3,
  name: "config",
  kind: "message",
  T: RequestConfig
}, {
  no: 4,
  name: "response",
  kind: "message",
  T: Any
}, {
  no: 5,
  name: "status",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "headers",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "scalar",
    T: 9 /* ScalarType.STRING */
  }
}]);
/**
 * @generated from message GoThrive.RequestConfig
 */
export class RequestConfig extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, string> headers = 1;
     */
    this.headers = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RequestConfig().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RequestConfig().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RequestConfig().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RequestConfig, a, b);
  }
}
RequestConfig.runtime = proto3;
RequestConfig.typeName = "GoThrive.RequestConfig";
RequestConfig.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "headers",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "scalar",
    T: 9 /* ScalarType.STRING */
  }
}, {
  no: 2,
  name: "params",
  kind: "message",
  T: Any
}, {
  no: 3,
  name: "urlParams",
  kind: "message",
  T: Any
}, {
  no: 4,
  name: "bodyParams",
  kind: "message",
  T: Any
}, {
  no: 5,
  name: "method",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}]);