// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file emailCodes.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.EmailChallengeParams
 */
export class EmailChallengeParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string configurationId = 1;
     */
    this.configurationId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new EmailChallengeParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new EmailChallengeParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new EmailChallengeParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(EmailChallengeParams, a, b);
  }
}
EmailChallengeParams.runtime = proto3;
EmailChallengeParams.typeName = "GoThrive.EmailChallengeParams";
EmailChallengeParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "configurationId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.EmailChallengeResponse
 */
export class EmailChallengeResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Token = 1;
     */
    this.Token = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new EmailChallengeResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new EmailChallengeResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new EmailChallengeResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(EmailChallengeResponse, a, b);
  }
}
EmailChallengeResponse.runtime = proto3;
EmailChallengeResponse.typeName = "GoThrive.EmailChallengeResponse";
EmailChallengeResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Token",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.EmailChallengeResponseBody
 */
export class EmailChallengeResponseBody extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string code = 1;
     */
    this.code = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new EmailChallengeResponseBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new EmailChallengeResponseBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new EmailChallengeResponseBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(EmailChallengeResponseBody, a, b);
  }
}
EmailChallengeResponseBody.runtime = proto3;
EmailChallengeResponseBody.typeName = "GoThrive.EmailChallengeResponseBody";
EmailChallengeResponseBody.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "code",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);