// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file activitiesTotal.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.ActivitiesTotal
 */
export class ActivitiesTotal extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 totalActivities = 1;
     */
    this.totalActivities = 0;
    /**
     * @generated from field: int32 completedGoals = 2;
     */
    this.completedGoals = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ActivitiesTotal().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ActivitiesTotal().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ActivitiesTotal().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ActivitiesTotal, a, b);
  }
}
ActivitiesTotal.runtime = proto3;
ActivitiesTotal.typeName = "GoThrive.ActivitiesTotal";
ActivitiesTotal.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "totalActivities",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "completedGoals",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "Activity_MessageInABottle",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 4,
  name: "Activity_MoodMeter",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 5,
  name: "Activity_Self_Hypnosis",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}]);