// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file resultScreen.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, BottomBar2, NavigationBar } from "./common_pb.js";
/**
 * @generated from message GoThrive.ResultScreen
 */
export class ResultScreen extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 5;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.ResultScreenOption data = 6;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ResultScreen().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ResultScreen().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ResultScreen().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ResultScreen, a, b);
  }
}
ResultScreen.runtime = proto3;
ResultScreen.typeName = "GoThrive.ResultScreen";
ResultScreen.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 3,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 4,
  name: "bottomBar",
  kind: "message",
  T: BottomBar2
}, {
  no: 5,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 6,
  name: "data",
  kind: "message",
  T: ResultScreenOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.ResultScreenOption
 */
export class ResultScreenOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ResultScreenOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ResultScreenOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ResultScreenOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ResultScreenOption, a, b);
  }
}
ResultScreenOption.runtime = proto3;
ResultScreenOption.typeName = "GoThrive.ResultScreenOption";
ResultScreenOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.RatingView
 */
export class RatingView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string caption = 2;
     */
    this.caption = "";
    /**
     * @generated from field: string body = 3;
     */
    this.body = "";
    /**
     * @generated from field: int32 stars = 4;
     */
    this.stars = 0;
    /**
     * @generated from field: string style = 5;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RatingView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RatingView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RatingView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RatingView, a, b);
  }
}
RatingView.runtime = proto3;
RatingView.typeName = "GoThrive.RatingView";
RatingView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "caption",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "stars",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 5,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ResultScreenCatalog
 */
export class ResultScreenCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.ResultScreen> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ResultScreenCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ResultScreenCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ResultScreenCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ResultScreenCatalog, a, b);
  }
}
ResultScreenCatalog.runtime = proto3;
ResultScreenCatalog.typeName = "GoThrive.ResultScreenCatalog";
ResultScreenCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: ResultScreen
  }
}]);