// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file mood.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { CustomInput } from "./common_pb.js";
/**
 * @generated from message GoThrive.MoodData
 */
export class MoodData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: bool healthy = 2;
     */
    this.healthy = false;
    /**
     * @generated from field: int32 percentage = 3;
     */
    this.percentage = 0;
    /**
     * @generated from field: repeated GoThrive.CustomInput customInputs = 4;
     */
    this.customInputs = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoodData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoodData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoodData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoodData, a, b);
  }
}
MoodData.runtime = proto3;
MoodData.typeName = "GoThrive.MoodData";
MoodData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "healthy",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 3,
  name: "percentage",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "customInputs",
  kind: "message",
  T: CustomInput,
  repeated: true
}]);
/**
 * @generated from message GoThrive.MoodCatalog
 */
export class MoodCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.MoodData> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoodCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoodCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoodCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoodCatalog, a, b);
  }
}
MoodCatalog.runtime = proto3;
MoodCatalog.typeName = "GoThrive.MoodCatalog";
MoodCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: MoodData
  }
}]);
/**
 * @generated from message GoThrive.RelaxData
 */
export class RelaxData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: bool healthy = 2;
     */
    this.healthy = false;
    /**
     * @generated from field: int32 percentage = 3;
     */
    this.percentage = 0;
    /**
     * @generated from field: repeated GoThrive.CustomInput customInputs = 4;
     */
    this.customInputs = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RelaxData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RelaxData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RelaxData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RelaxData, a, b);
  }
}
RelaxData.runtime = proto3;
RelaxData.typeName = "GoThrive.RelaxData";
RelaxData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "healthy",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 3,
  name: "percentage",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "customInputs",
  kind: "message",
  T: CustomInput,
  repeated: true
}]);
/**
 * @generated from message GoThrive.SituationData
 */
export class SituationData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string prefix = 2;
     */
    this.prefix = "";
    /**
     * @generated from field: int32 valence = 3;
     */
    this.valence = 0;
    /**
     * @generated from field: int32 bitcode = 4;
     */
    this.bitcode = 0;
    /**
     * @generated from field: repeated GoThrive.CustomInput customInputs = 6;
     */
    this.customInputs = [];
    /**
     * @generated from field: string goal = 7;
     */
    this.goal = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new SituationData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new SituationData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new SituationData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(SituationData, a, b);
  }
}
SituationData.runtime = proto3;
SituationData.typeName = "GoThrive.SituationData";
SituationData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "prefix",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "valence",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "bitcode",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 5,
  name: "thoughts",
  kind: "message",
  T: ThoughtsCatalog
}, {
  no: 6,
  name: "customInputs",
  kind: "message",
  T: CustomInput,
  repeated: true
}, {
  no: 7,
  name: "goal",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.SituationCatalog
 */
export class SituationCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.SituationData> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new SituationCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new SituationCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new SituationCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(SituationCatalog, a, b);
  }
}
SituationCatalog.runtime = proto3;
SituationCatalog.typeName = "GoThrive.SituationCatalog";
SituationCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: SituationData
  }
}]);
/**
 * @generated from message GoThrive.Thoughts
 */
export class Thoughts extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string sensitive = 2;
     */
    this.sensitive = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Thoughts().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Thoughts().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Thoughts().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Thoughts, a, b);
  }
}
Thoughts.runtime = proto3;
Thoughts.typeName = "GoThrive.Thoughts";
Thoughts.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "sensitive",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ThoughtsDataCatalog
 */
export class ThoughtsDataCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.Thoughts> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ThoughtsDataCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ThoughtsDataCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ThoughtsDataCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ThoughtsDataCatalog, a, b);
  }
}
ThoughtsDataCatalog.runtime = proto3;
ThoughtsDataCatalog.typeName = "GoThrive.ThoughtsDataCatalog";
ThoughtsDataCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Thoughts
  }
}]);
/**
 * @generated from message GoThrive.ThoughtValence
 */
export class ThoughtValence extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ThoughtValence().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ThoughtValence().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ThoughtValence().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ThoughtValence, a, b);
  }
}
ThoughtValence.runtime = proto3;
ThoughtValence.typeName = "GoThrive.ThoughtValence";
ThoughtValence.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "situation",
  kind: "message",
  T: ThoughtsDataCatalog
}, {
  no: 2,
  name: "thoughts",
  kind: "message",
  T: ThoughtsDataCatalog
}, {
  no: 3,
  name: "alt",
  kind: "message",
  T: ThoughtsDataCatalog
}]);
/**
 * @generated from message GoThrive.ThoughtsData
 */
export class ThoughtsData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.CustomInput customInputs = 3;
     */
    this.customInputs = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ThoughtsData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ThoughtsData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ThoughtsData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ThoughtsData, a, b);
  }
}
ThoughtsData.runtime = proto3;
ThoughtsData.typeName = "GoThrive.ThoughtsData";
ThoughtsData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "healthy",
  kind: "message",
  T: ThoughtValence
}, {
  no: 2,
  name: "unhealthy",
  kind: "message",
  T: ThoughtValence
}, {
  no: 3,
  name: "customInputs",
  kind: "message",
  T: CustomInput,
  repeated: true
}]);
/**
 * @generated from message GoThrive.ThoughtsCatalog
 */
export class ThoughtsCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.ThoughtsData> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ThoughtsCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ThoughtsCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ThoughtsCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ThoughtsCatalog, a, b);
  }
}
ThoughtsCatalog.runtime = proto3;
ThoughtsCatalog.typeName = "GoThrive.ThoughtsCatalog";
ThoughtsCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: ThoughtsData
  }
}]);
/**
 * @generated from message GoThrive.ClientData
 */
export class ClientData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: int32 bitcode = 2;
     */
    this.bitcode = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ClientData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ClientData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ClientData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ClientData, a, b);
  }
}
ClientData.runtime = proto3;
ClientData.typeName = "GoThrive.ClientData";
ClientData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "bitcode",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.ClientCatalog
 */
export class ClientCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.ClientData> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ClientCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ClientCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ClientCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ClientCatalog, a, b);
  }
}
ClientCatalog.runtime = proto3;
ClientCatalog.typeName = "GoThrive.ClientCatalog";
ClientCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: ClientData
  }
}]);
/**
 * @generated from message GoThrive.MoodSelect
 */
export class MoodSelect extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 maxselection = 3;
     */
    this.maxselection = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoodSelect().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoodSelect().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoodSelect().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoodSelect, a, b);
  }
}
MoodSelect.runtime = proto3;
MoodSelect.typeName = "GoThrive.MoodSelect";
MoodSelect.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "catalog",
  kind: "message",
  T: MoodCatalog
}, {
  no: 2,
  name: "selection",
  kind: "message",
  T: MoodCatalog
}, {
  no: 3,
  name: "maxselection",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.SituationSelect
 */
export class SituationSelect extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 maxselection = 3;
     */
    this.maxselection = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new SituationSelect().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new SituationSelect().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new SituationSelect().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(SituationSelect, a, b);
  }
}
SituationSelect.runtime = proto3;
SituationSelect.typeName = "GoThrive.SituationSelect";
SituationSelect.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "catalog",
  kind: "message",
  T: SituationCatalog
}, {
  no: 2,
  name: "selection",
  kind: "message",
  T: SituationCatalog
}, {
  no: 3,
  name: "maxselection",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.ThoughtSelect
 */
export class ThoughtSelect extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 maxselection = 3;
     */
    this.maxselection = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ThoughtSelect().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ThoughtSelect().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ThoughtSelect().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ThoughtSelect, a, b);
  }
}
ThoughtSelect.runtime = proto3;
ThoughtSelect.typeName = "GoThrive.ThoughtSelect";
ThoughtSelect.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "catalog",
  kind: "message",
  T: ThoughtsDataCatalog
}, {
  no: 2,
  name: "selection",
  kind: "message",
  T: ThoughtsDataCatalog
}, {
  no: 3,
  name: "maxselection",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.HotThoughtRating
 */
export class HotThoughtRating extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string thought = 1;
     */
    this.thought = "";
    /**
     * @generated from field: int32 percentage = 2;
     */
    this.percentage = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new HotThoughtRating().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new HotThoughtRating().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new HotThoughtRating().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(HotThoughtRating, a, b);
  }
}
HotThoughtRating.runtime = proto3;
HotThoughtRating.typeName = "GoThrive.HotThoughtRating";
HotThoughtRating.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "thought",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "percentage",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.HotThoughtsSelect
 */
export class HotThoughtsSelect extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 maxselection = 3;
     */
    this.maxselection = 0;
    /**
     * @generated from field: repeated GoThrive.HotThoughtRating Selected = 4;
     */
    this.Selected = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new HotThoughtsSelect().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new HotThoughtsSelect().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new HotThoughtsSelect().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(HotThoughtsSelect, a, b);
  }
}
HotThoughtsSelect.runtime = proto3;
HotThoughtsSelect.typeName = "GoThrive.HotThoughtsSelect";
HotThoughtsSelect.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "moods",
  kind: "message",
  T: MoodSelect
}, {
  no: 2,
  name: "thoughts",
  kind: "message",
  T: ThoughtSelect
}, {
  no: 3,
  name: "maxselection",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "Selected",
  kind: "message",
  T: HotThoughtRating,
  repeated: true
}]);
/**
 * @generated from message GoThrive.Evidence
 */
export class Evidence extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated string EvidenceFor = 2;
     */
    this.EvidenceFor = [];
    /**
     * @generated from field: repeated string EvidenceAgainst = 3;
     */
    this.EvidenceAgainst = [];
    /**
     * @generated from field: bool EvidenceForActive = 4;
     */
    this.EvidenceForActive = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Evidence().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Evidence().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Evidence().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Evidence, a, b);
  }
}
Evidence.runtime = proto3;
Evidence.typeName = "GoThrive.Evidence";
Evidence.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "HotThoughts",
  kind: "message",
  T: HotThoughtsSelect
}, {
  no: 2,
  name: "EvidenceFor",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 3,
  name: "EvidenceAgainst",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 4,
  name: "EvidenceForActive",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.List
 */
export class List extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated string entry = 1;
     */
    this.entry = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new List().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new List().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new List().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(List, a, b);
  }
}
List.runtime = proto3;
List.typeName = "GoThrive.List";
List.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entry",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);