// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file recommendationsScreen.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, Button, ImageIcon, NavigationBar } from "./common_pb.js";
/**
 * @generated from message GoThrive.RecommendationsScreen
 */
export class RecommendationsScreen extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 4;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.RecommendationsScreenOption data = 5;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RecommendationsScreen().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RecommendationsScreen().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RecommendationsScreen().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RecommendationsScreen, a, b);
  }
}
RecommendationsScreen.runtime = proto3;
RecommendationsScreen.typeName = "GoThrive.RecommendationsScreen";
RecommendationsScreen.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 3,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 4,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 5,
  name: "data",
  kind: "message",
  T: RecommendationsScreenOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.RecommendationsScreenOption
 */
export class RecommendationsScreenOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RecommendationsScreenOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RecommendationsScreenOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RecommendationsScreenOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RecommendationsScreenOption, a, b);
  }
}
RecommendationsScreenOption.runtime = proto3;
RecommendationsScreenOption.typeName = "GoThrive.RecommendationsScreenOption";
RecommendationsScreenOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.RecommendationsCardView
 */
export class RecommendationsCardView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string notRelevantToggleText = 3;
     */
    this.notRelevantToggleText = "";
    /**
     * @generated from field: string animation = 6;
     */
    this.animation = "";
    /**
     * @generated from field: string style = 8;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RecommendationsCardView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RecommendationsCardView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RecommendationsCardView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RecommendationsCardView, a, b);
  }
}
RecommendationsCardView.runtime = proto3;
RecommendationsCardView.typeName = "GoThrive.RecommendationsCardView";
RecommendationsCardView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "primaryImage",
  kind: "message",
  T: ImageIcon
}, {
  no: 3,
  name: "notRelevantToggleText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "trackGoalButton",
  kind: "message",
  T: Button
}, {
  no: 5,
  name: "inProgressButton",
  kind: "message",
  T: Button
}, {
  no: 6,
  name: "animation",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.RecommendationsScreenCatalog
 */
export class RecommendationsScreenCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.RecommendationsScreen> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RecommendationsScreenCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RecommendationsScreenCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RecommendationsScreenCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RecommendationsScreenCatalog, a, b);
  }
}
RecommendationsScreenCatalog.runtime = proto3;
RecommendationsScreenCatalog.typeName = "GoThrive.RecommendationsScreenCatalog";
RecommendationsScreenCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: RecommendationsScreen
  }
}]);