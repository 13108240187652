// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file fullscreenInfoPanel.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, BottomBar2, NavigationBar } from "./common_pb.js";
/**
 * @generated from message GoThrive.FullscreenInfoPanel
 */
export class FullscreenInfoPanel extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 5;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.FullscreenInfoPanelOption data = 6;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new FullscreenInfoPanel().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new FullscreenInfoPanel().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new FullscreenInfoPanel().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(FullscreenInfoPanel, a, b);
  }
}
FullscreenInfoPanel.runtime = proto3;
FullscreenInfoPanel.typeName = "GoThrive.FullscreenInfoPanel";
FullscreenInfoPanel.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 3,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 4,
  name: "bottomBar",
  kind: "message",
  T: BottomBar2
}, {
  no: 5,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 6,
  name: "data",
  kind: "message",
  T: FullscreenInfoPanelOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.FullscreenInfoPanelOption
 */
export class FullscreenInfoPanelOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new FullscreenInfoPanelOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new FullscreenInfoPanelOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new FullscreenInfoPanelOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(FullscreenInfoPanelOption, a, b);
  }
}
FullscreenInfoPanelOption.runtime = proto3;
FullscreenInfoPanelOption.typeName = "GoThrive.FullscreenInfoPanelOption";
FullscreenInfoPanelOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.FullscreenInfoPanelCatalog
 */
export class FullscreenInfoPanelCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.FullscreenInfoPanel> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new FullscreenInfoPanelCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new FullscreenInfoPanelCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new FullscreenInfoPanelCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(FullscreenInfoPanelCatalog, a, b);
  }
}
FullscreenInfoPanelCatalog.runtime = proto3;
FullscreenInfoPanelCatalog.typeName = "GoThrive.FullscreenInfoPanelCatalog";
FullscreenInfoPanelCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: FullscreenInfoPanel
  }
}]);