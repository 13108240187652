// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file audio.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.Audio
 */
export class Audio extends Message {
  constructor(data) {
    super();
    /**
     * Request
     *
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string url = 2;
     */
    this.url = "";
    /**
     * @generated from field: string encoding = 3;
     */
    this.encoding = "";
    /**
     * @generated from field: int64 duration = 4;
     */
    this.duration = protoInt64.zero;
    /**
     * @generated from field: string subtitleKey = 5;
     */
    this.subtitleKey = "";
    /**
     * @generated from field: bytes data = 6;
     */
    this.data = new Uint8Array(0);
    /**
     * @generated from field: bool omitdata = 7;
     */
    this.omitdata = false;
    /**
     * @generated from field: string baseURL = 8;
     */
    this.baseURL = "";
    /**
     * @generated from field: bool localised = 9;
     */
    this.localised = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Audio().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Audio().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Audio().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Audio, a, b);
  }
}
Audio.runtime = proto3;
Audio.typeName = "GoThrive.Audio";
Audio.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "url",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "encoding",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "duration",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 5,
  name: "subtitleKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "data",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}, {
  no: 7,
  name: "omitdata",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 8,
  name: "baseURL",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "localised",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.AudioCatalog
 */
export class AudioCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.Audio> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AudioCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AudioCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AudioCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AudioCatalog, a, b);
  }
}
AudioCatalog.runtime = proto3;
AudioCatalog.typeName = "GoThrive.AudioCatalog";
AudioCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Audio
  }
}]);
/**
 * @generated from message GoThrive.CommandPlayAudio
 */
export class CommandPlayAudio extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string url = 1;
     */
    this.url = "";
    /**
     * @generated from field: string progress = 2;
     */
    this.progress = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandPlayAudio().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandPlayAudio().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandPlayAudio().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandPlayAudio, a, b);
  }
}
CommandPlayAudio.runtime = proto3;
CommandPlayAudio.typeName = "GoThrive.CommandPlayAudio";
CommandPlayAudio.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "url",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "progress",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.LanguageInfo
 */
export class LanguageInfo extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: string language = 2;
     */
    this.language = "";
    /**
     * @generated from field: string language_text = 3;
     */
    this.languageText = "";
    /**
     * @generated from field: string country = 4;
     */
    this.country = "";
    /**
     * @generated from field: string country_text = 5;
     */
    this.countryText = "";
    /**
     * @generated from field: string voice = 6;
     */
    this.voice = "";
    /**
     * @generated from field: string voice_text = 7;
     */
    this.voiceText = "";
    /**
     * @generated from field: string folder = 8;
     */
    this.folder = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new LanguageInfo().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new LanguageInfo().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new LanguageInfo().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(LanguageInfo, a, b);
  }
}
LanguageInfo.runtime = proto3;
LanguageInfo.typeName = "GoThrive.LanguageInfo";
LanguageInfo.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "language",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "language_text",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "country",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "country_text",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "voice",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "voice_text",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "folder",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.LanguageSupport
 */
export class LanguageSupport extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.LanguageInfo> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new LanguageSupport().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new LanguageSupport().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new LanguageSupport().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(LanguageSupport, a, b);
  }
}
LanguageSupport.runtime = proto3;
LanguageSupport.typeName = "GoThrive.LanguageSupport";
LanguageSupport.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: LanguageInfo
  }
}]);