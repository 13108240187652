// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file therapists.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.Availability
 */
export class Availability extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 day = 1;
     */
    this.day = 0;
    /**
     * @generated from field: int32 from = 2;
     */
    this.from = 0;
    /**
     * @generated from field: int32 to = 3;
     */
    this.to = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Availability().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Availability().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Availability().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Availability, a, b);
  }
}
Availability.runtime = proto3;
Availability.typeName = "GoThrive.Availability";
Availability.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "day",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "from",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "to",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.AvailabilityBody
 */
export class AvailabilityBody extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated int32 days = 1;
     */
    this.days = [];
    /**
     * @generated from field: int32 from = 2;
     */
    this.from = 0;
    /**
     * @generated from field: int32 to = 3;
     */
    this.to = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AvailabilityBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AvailabilityBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AvailabilityBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AvailabilityBody, a, b);
  }
}
AvailabilityBody.runtime = proto3;
AvailabilityBody.typeName = "GoThrive.AvailabilityBody";
AvailabilityBody.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "days",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  repeated: true
}, {
  no: 2,
  name: "from",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "to",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.AvailabilityBodyPatch
 */
export class AvailabilityBodyPatch extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.AvailabilityBodyPatch.Data entries = 1;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AvailabilityBodyPatch().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AvailabilityBodyPatch().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AvailabilityBodyPatch().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AvailabilityBodyPatch, a, b);
  }
}
AvailabilityBodyPatch.runtime = proto3;
AvailabilityBodyPatch.typeName = "GoThrive.AvailabilityBodyPatch";
AvailabilityBodyPatch.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "message",
  T: AvailabilityBodyPatch_Data,
  repeated: true
}]);
/**
 * @generated from message GoThrive.AvailabilityBodyPatch.Data
 */
export class AvailabilityBodyPatch_Data extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 day = 1;
     */
    this.day = 0;
    /**
     * @generated from field: repeated GoThrive.AvailabilityBodyPatch.Data.Times times = 2;
     */
    this.times = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AvailabilityBodyPatch_Data().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AvailabilityBodyPatch_Data().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AvailabilityBodyPatch_Data().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AvailabilityBodyPatch_Data, a, b);
  }
}
AvailabilityBodyPatch_Data.runtime = proto3;
AvailabilityBodyPatch_Data.typeName = "GoThrive.AvailabilityBodyPatch.Data";
AvailabilityBodyPatch_Data.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "day",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "times",
  kind: "message",
  T: AvailabilityBodyPatch_Data_Times,
  repeated: true
}]);
/**
 * @generated from message GoThrive.AvailabilityBodyPatch.Data.Times
 */
export class AvailabilityBodyPatch_Data_Times extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 from = 1;
     */
    this.from = 0;
    /**
     * @generated from field: int32 to = 2;
     */
    this.to = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AvailabilityBodyPatch_Data_Times().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AvailabilityBodyPatch_Data_Times().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AvailabilityBodyPatch_Data_Times().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AvailabilityBodyPatch_Data_Times, a, b);
  }
}
AvailabilityBodyPatch_Data_Times.runtime = proto3;
AvailabilityBodyPatch_Data_Times.typeName = "GoThrive.AvailabilityBodyPatch.Data.Times";
AvailabilityBodyPatch_Data_Times.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "from",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "to",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.Therapist
 */
export class Therapist extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string userId = 1;
     */
    this.userId = "";
    /**
     * @generated from field: string id = 2;
     */
    this.id = "";
    /**
     * @generated from field: string firstName = 3;
     */
    this.firstName = "";
    /**
     * @generated from field: string lastName = 4;
     */
    this.lastName = "";
    /**
     * @generated from field: repeated GoThrive.Availability availability = 5;
     */
    this.availability = [];
    /**
     * @generated from field: string timezone = 6;
     */
    this.timezone = "";
    /**
     * @generated from field: string biography = 7;
     */
    this.biography = "";
    /**
     * @generated from field: bool active = 8;
     */
    this.active = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Therapist().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Therapist().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Therapist().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Therapist, a, b);
  }
}
Therapist.runtime = proto3;
Therapist.typeName = "GoThrive.Therapist";
Therapist.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "userId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "firstName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "lastName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "availability",
  kind: "message",
  T: Availability,
  repeated: true
}, {
  no: 6,
  name: "timezone",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "biography",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "active",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.Therapists
 */
export class Therapists extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.Therapist entries = 1;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Therapists().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Therapists().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Therapists().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Therapists, a, b);
  }
}
Therapists.runtime = proto3;
Therapists.typeName = "GoThrive.Therapists";
Therapists.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "message",
  T: Therapist,
  repeated: true
}]);
/**
 * @generated from message GoThrive.TherapistAvailability
 */
export class TherapistAvailability extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.Availability entries = 1;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TherapistAvailability().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TherapistAvailability().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TherapistAvailability().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TherapistAvailability, a, b);
  }
}
TherapistAvailability.runtime = proto3;
TherapistAvailability.typeName = "GoThrive.TherapistAvailability";
TherapistAvailability.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "message",
  T: Availability,
  repeated: true
}]);
/**
 * @generated from message GoThrive.NotifyParams
 */
export class NotifyParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 gadScore = 1;
     */
    this.gadScore = 0;
    /**
     * @generated from field: int32 phqScore = 2;
     */
    this.phqScore = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new NotifyParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new NotifyParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new NotifyParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(NotifyParams, a, b);
  }
}
NotifyParams.runtime = proto3;
NotifyParams.typeName = "GoThrive.NotifyParams";
NotifyParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "gadScore",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "phqScore",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.DataGraphParams
 */
export class DataGraphParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int64 startFrom = 1;
     */
    this.startFrom = protoInt64.zero;
    /**
     * @generated from field: int64 endAt = 2;
     */
    this.endAt = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new DataGraphParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new DataGraphParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new DataGraphParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(DataGraphParams, a, b);
  }
}
DataGraphParams.runtime = proto3;
DataGraphParams.typeName = "GoThrive.DataGraphParams";
DataGraphParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "startFrom",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 2,
  name: "endAt",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);
/**
 * @generated from message GoThrive.DataGraphResponse
 */
export class DataGraphResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.DataGraphResponse.Entry gad = 1;
     */
    this.gad = [];
    /**
     * @generated from field: repeated GoThrive.DataGraphResponse.Entry phq = 2;
     */
    this.phq = [];
    /**
     * @generated from field: repeated GoThrive.DataGraphResponse.Entry mood = 3;
     */
    this.mood = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new DataGraphResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new DataGraphResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new DataGraphResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(DataGraphResponse, a, b);
  }
}
DataGraphResponse.runtime = proto3;
DataGraphResponse.typeName = "GoThrive.DataGraphResponse";
DataGraphResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "gad",
  kind: "message",
  T: DataGraphResponse_Entry,
  repeated: true
}, {
  no: 2,
  name: "phq",
  kind: "message",
  T: DataGraphResponse_Entry,
  repeated: true
}, {
  no: 3,
  name: "mood",
  kind: "message",
  T: DataGraphResponse_Entry,
  repeated: true
}]);
/**
 * @generated from message GoThrive.DataGraphResponse.Entry
 */
export class DataGraphResponse_Entry extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int64 timestamp = 1;
     */
    this.timestamp = protoInt64.zero;
    /**
     * @generated from field: float score = 2;
     */
    this.score = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new DataGraphResponse_Entry().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new DataGraphResponse_Entry().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new DataGraphResponse_Entry().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(DataGraphResponse_Entry, a, b);
  }
}
DataGraphResponse_Entry.runtime = proto3;
DataGraphResponse_Entry.typeName = "GoThrive.DataGraphResponse.Entry";
DataGraphResponse_Entry.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "timestamp",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 2,
  name: "score",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}]);
/**
 * @generated from message GoThrive.EligibleTherapistsParams
 */
export class EligibleTherapistsParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated string purpose = 1;
     */
    this.purpose = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new EligibleTherapistsParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new EligibleTherapistsParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new EligibleTherapistsParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(EligibleTherapistsParams, a, b);
  }
}
EligibleTherapistsParams.runtime = proto3;
EligibleTherapistsParams.typeName = "GoThrive.EligibleTherapistsParams";
EligibleTherapistsParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "purpose",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PatientShareDataUrlParams
 */
export class PatientShareDataUrlParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string patientId = 1;
     */
    this.patientId = "";
    /**
     * @generated from field: string therapistId = 2;
     */
    this.therapistId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PatientShareDataUrlParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PatientShareDataUrlParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PatientShareDataUrlParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PatientShareDataUrlParams, a, b);
  }
}
PatientShareDataUrlParams.runtime = proto3;
PatientShareDataUrlParams.typeName = "GoThrive.PatientShareDataUrlParams";
PatientShareDataUrlParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "patientId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "therapistId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);