// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file screenConfig.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, Button, ImageIcon, NavigationBar } from "./common_pb.js";
/**
 * @generated from message GoThrive.ScreenConfig
 */
export class ScreenConfig extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ScreenConfig().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ScreenConfig().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ScreenConfig().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ScreenConfig, a, b);
  }
}
ScreenConfig.runtime = proto3;
ScreenConfig.typeName = "GoThrive.ScreenConfig";
ScreenConfig.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 3,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 4,
  name: "screenElements",
  kind: "message",
  T: ScreenElements
}]);
/**
 * @generated from message GoThrive.ScreenElements
 */
export class ScreenElements extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: map<string, GoThrive.Button> buttons = 2;
     */
    this.buttons = {};
    /**
     * @generated from field: map<string, GoThrive.ImageIcon> imageIcons = 3;
     */
    this.imageIcons = {};
    /**
     * @generated from field: map<string, string> styles = 4;
     */
    this.styles = {};
    /**
     * @generated from field: map<string, string> texts = 5;
     */
    this.texts = {};
    /**
     * @generated from field: map<string, string> properties = 6;
     */
    this.properties = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ScreenElements().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ScreenElements().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ScreenElements().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ScreenElements, a, b);
  }
}
ScreenElements.runtime = proto3;
ScreenElements.typeName = "GoThrive.ScreenElements";
ScreenElements.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "buttons",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Button
  }
}, {
  no: 3,
  name: "imageIcons",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: ImageIcon
  }
}, {
  no: 4,
  name: "styles",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "scalar",
    T: 9 /* ScalarType.STRING */
  }
}, {
  no: 5,
  name: "texts",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "scalar",
    T: 9 /* ScalarType.STRING */
  }
}, {
  no: 6,
  name: "properties",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "scalar",
    T: 9 /* ScalarType.STRING */
  }
}]);
/**
 * @generated from message GoThrive.ScreenConfigCatalog
 */
export class ScreenConfigCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.ScreenConfig> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ScreenConfigCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ScreenConfigCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ScreenConfigCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ScreenConfigCatalog, a, b);
  }
}
ScreenConfigCatalog.runtime = proto3;
ScreenConfigCatalog.typeName = "GoThrive.ScreenConfigCatalog";
ScreenConfigCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: ScreenConfig
  }
}]);