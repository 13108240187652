// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file choicePanel.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, NavigationBar } from "./common_pb.js";
/**
 * @generated from message GoThrive.ChoicePanel
 */
export class ChoicePanel extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 5;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.ChoicePanelOption data = 6;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChoicePanel().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChoicePanel().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChoicePanel().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChoicePanel, a, b);
  }
}
ChoicePanel.runtime = proto3;
ChoicePanel.typeName = "GoThrive.ChoicePanel";
ChoicePanel.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 3,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 5,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 6,
  name: "data",
  kind: "message",
  T: ChoicePanelOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.ChoicePanelOption
 */
export class ChoicePanelOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChoicePanelOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChoicePanelOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChoicePanelOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChoicePanelOption, a, b);
  }
}
ChoicePanelOption.runtime = proto3;
ChoicePanelOption.typeName = "GoThrive.ChoicePanelOption";
ChoicePanelOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.ChoicePanelCatalog
 */
export class ChoicePanelCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.ChoicePanel> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChoicePanelCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChoicePanelCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChoicePanelCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChoicePanelCatalog, a, b);
  }
}
ChoicePanelCatalog.runtime = proto3;
ChoicePanelCatalog.typeName = "GoThrive.ChoicePanelCatalog";
ChoicePanelCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: ChoicePanel
  }
}]);