// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file moment.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Cbt_Data_cbtCore } from "./cbt_pb.js";
import { RiskFactor_Data_Results } from "./riskFactor_pb.js";
import { MoodEntries } from "./dailyCheckup_pb.js";
import { ImageSet } from "./image_pb.js";
/**
 * @generated from message GoThrive.RelaxationLevel
 */
export class RelaxationLevel extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 afterSession = 1;
     */
    this.afterSession = 0;
    /**
     * @generated from field: int32 beforeSession = 2;
     */
    this.beforeSession = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RelaxationLevel().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RelaxationLevel().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RelaxationLevel().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RelaxationLevel, a, b);
  }
}
RelaxationLevel.runtime = proto3;
RelaxationLevel.typeName = "GoThrive.RelaxationLevel";
RelaxationLevel.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "afterSession",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "beforeSession",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.RelaxationTime
 */
export class RelaxationTime extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string answer = 1;
     */
    this.answer = "";
    /**
     * @generated from field: int32 timeTakenToRelax = 2;
     */
    this.timeTakenToRelax = 0;
    /**
     * @generated from field: int32 estimatedTimeTakenToRelax = 3;
     */
    this.estimatedTimeTakenToRelax = 0;
    /**
     * @generated from field: string question = 4;
     */
    this.question = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RelaxationTime().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RelaxationTime().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RelaxationTime().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RelaxationTime, a, b);
  }
}
RelaxationTime.runtime = proto3;
RelaxationTime.typeName = "GoThrive.RelaxationTime";
RelaxationTime.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "answer",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "timeTakenToRelax",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "estimatedTimeTakenToRelax",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "question",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.SessionProgress
 */
export class SessionProgress extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 done = 1;
     */
    this.done = 0;
    /**
     * @generated from field: int32 total = 2;
     */
    this.total = 0;
    /**
     * @generated from field: string previousMomentId = 3;
     */
    this.previousMomentId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new SessionProgress().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new SessionProgress().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new SessionProgress().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(SessionProgress, a, b);
  }
}
SessionProgress.runtime = proto3;
SessionProgress.typeName = "GoThrive.SessionProgress";
SessionProgress.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "done",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "total",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "previousMomentId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.Data
 */
export class Data extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.MoodEntries MoodEntries = 11;
     */
    this.MoodEntries = [];
    /**
     * @generated from field: repeated string SubGoals = 26;
     */
    this.SubGoals = [];
    /**
     * @generated from field: string GoTitle = 27;
     */
    this.GoTitle = "";
    /**
     * @generated from field: bool Hide = 30;
     */
    this.Hide = false;
    /**
     * @generated from field: string GoType = 31;
     */
    this.GoType = "";
    /**
     * @generated from field: bool IsGoCore = 32;
     */
    this.IsGoCore = false;
    /**
     * @generated from field: repeated string GratitudeQuestions = 33;
     */
    this.GratitudeQuestions = [];
    /**
     * @generated from field: repeated string GratitudeAnswers = 34;
     */
    this.GratitudeAnswers = [];
    /**
     * @generated from field: map<string, string> MindfulAction = 35;
     */
    this.MindfulAction = {};
    /**
     * @generated from field: string LinkName = 37;
     */
    this.LinkName = "";
    /**
     * @generated from field: string LinkUrl = 38;
     */
    this.LinkUrl = "";
    /**
     * @generated from field: bool LinkIsHelpLink = 40;
     */
    this.LinkIsHelpLink = false;
    /**
     * @generated from field: string SmartCardId = 42;
     */
    this.SmartCardId = "";
    /**
     * @generated from field: string Answer = 49;
     */
    this.Answer = "";
    /**
     * @generated from field: string Question = 50;
     */
    this.Question = "";
    /**
     * @generated from field: string ActivityKind = 53;
     */
    this.ActivityKind = "";
    /**
     * @generated from field: string SessionId = 54;
     */
    this.SessionId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Data().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Data().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Data().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Data, a, b);
  }
}
Data.runtime = proto3;
Data.typeName = "GoThrive.Data";
Data.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Duration",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 2,
  name: "CbtCore",
  kind: "message",
  T: Cbt_Data_cbtCore,
  opt: true
}, {
  no: 3,
  name: "selfEsteem",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 4,
  name: "anger",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 5,
  name: "ocd",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 6,
  name: "substance",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 7,
  name: "bodyImage",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 8,
  name: "bodyImageMain",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 9,
  name: "Results",
  kind: "message",
  T: RiskFactor_Data_Results
}, {
  no: 10,
  name: "MoodRating",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */,
  opt: true
}, {
  no: 11,
  name: "MoodEntries",
  kind: "message",
  T: MoodEntries,
  repeated: true
}, {
  no: 12,
  name: "note",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 13,
  name: "chatRoomId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 14,
  name: "contentType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 15,
  name: "url",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 16,
  name: "objectName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 17,
  name: "fileName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 18,
  name: "size",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 19,
  name: "Amount",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 20,
  name: "PatchTimestamp",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */,
  opt: true
}, {
  no: 21,
  name: "Timestamp",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */,
  opt: true
}, {
  no: 22,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 23,
  name: "FuzzNetType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 24,
  name: "GoalName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 25,
  name: "GoalStepIndex",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 26,
  name: "SubGoals",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 27,
  name: "GoTitle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 28,
  name: "Icon",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 29,
  name: "IconBackground",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 30,
  name: "Hide",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 31,
  name: "GoType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 32,
  name: "IsGoCore",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 33,
  name: "GratitudeQuestions",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 34,
  name: "GratitudeAnswers",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 35,
  name: "MindfulAction",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "scalar",
    T: 9 /* ScalarType.STRING */
  }
}, {
  no: 36,
  name: "SubMoment",
  kind: "message",
  T: Moment,
  opt: true
}, {
  no: 37,
  name: "LinkName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 38,
  name: "LinkUrl",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 40,
  name: "LinkIsHelpLink",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 41,
  name: "ImageSet",
  kind: "message",
  T: ImageSet
}, {
  no: 42,
  name: "SmartCardId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 43,
  name: "AppliedRelaxationAssessment",
  kind: "message",
  T: AppliedRelaxationAssessment
}, {
  no: 44,
  name: "AppliedRelaxationResult",
  kind: "message",
  T: AppliedRelaxationResult
}, {
  no: 45,
  name: "TimeTakenToRelax",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 46,
  name: "AfterSession",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 47,
  name: "BeforeSession",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 48,
  name: "EstimatedTimeTakenToRelax",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 49,
  name: "Answer",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 50,
  name: "Question",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 51,
  name: "relaxationLevel",
  kind: "message",
  T: RelaxationLevel
}, {
  no: 52,
  name: "RelaxationTime",
  kind: "message",
  T: RelaxationTime
}, {
  no: 53,
  name: "ActivityKind",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 54,
  name: "SessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 55,
  name: "Progress",
  kind: "message",
  T: SessionProgress
}]);
/**
 * @generated from message GoThrive.AppliedRelaxationAssessmentAnswers
 */
export class AppliedRelaxationAssessmentAnswers extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Question = 1;
     */
    this.Question = "";
    /**
     * @generated from field: repeated string Answers = 2;
     */
    this.Answers = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AppliedRelaxationAssessmentAnswers().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AppliedRelaxationAssessmentAnswers().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AppliedRelaxationAssessmentAnswers().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AppliedRelaxationAssessmentAnswers, a, b);
  }
}
AppliedRelaxationAssessmentAnswers.runtime = proto3;
AppliedRelaxationAssessmentAnswers.typeName = "GoThrive.AppliedRelaxationAssessmentAnswers";
AppliedRelaxationAssessmentAnswers.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Question",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Answers",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.AppliedRelaxationResult
 */
export class AppliedRelaxationResult extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Question = 1;
     */
    this.Question = "";
    /**
     * @generated from field: string Answer = 2;
     */
    this.Answer = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AppliedRelaxationResult().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AppliedRelaxationResult().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AppliedRelaxationResult().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AppliedRelaxationResult, a, b);
  }
}
AppliedRelaxationResult.runtime = proto3;
AppliedRelaxationResult.typeName = "GoThrive.AppliedRelaxationResult";
AppliedRelaxationResult.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Question",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Answer",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.AppliedRelaxationAssessment
 */
export class AppliedRelaxationAssessment extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.AppliedRelaxationAssessmentAnswers Results = 1;
     */
    this.Results = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AppliedRelaxationAssessment().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AppliedRelaxationAssessment().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AppliedRelaxationAssessment().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AppliedRelaxationAssessment, a, b);
  }
}
AppliedRelaxationAssessment.runtime = proto3;
AppliedRelaxationAssessment.typeName = "GoThrive.AppliedRelaxationAssessment";
AppliedRelaxationAssessment.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Results",
  kind: "message",
  T: AppliedRelaxationAssessmentAnswers,
  repeated: true
}]);
/**
 * @generated from message GoThrive.Moment
 */
export class Moment extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string type = 1;
     */
    this.type = "";
    /**
     * @generated from field: int64 ts = 2;
     */
    this.ts = protoInt64.zero;
    /**
     * @generated from field: string id = 3;
     */
    this.id = "";
    /**
     * @generated from field: bool isGoCore = 5;
     */
    this.isGoCore = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Moment().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Moment().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Moment().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Moment, a, b);
  }
}
Moment.runtime = proto3;
Moment.typeName = "GoThrive.Moment";
Moment.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "ts",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 3,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "data",
  kind: "message",
  T: Data
}, {
  no: 5,
  name: "isGoCore",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.Moments
 */
export class Moments extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.Moment entries = 1;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Moments().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Moments().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Moments().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Moments, a, b);
  }
}
Moments.runtime = proto3;
Moments.typeName = "GoThrive.Moments";
Moments.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "message",
  T: Moment,
  repeated: true
}]);
/**
 * @generated from message GoThrive.ProgressMoments
 */
export class ProgressMoments extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.Moment moments = 2;
     */
    this.moments = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ProgressMoments().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ProgressMoments().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ProgressMoments().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ProgressMoments, a, b);
  }
}
ProgressMoments.runtime = proto3;
ProgressMoments.typeName = "GoThrive.ProgressMoments";
ProgressMoments.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "lastDoc",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 2,
  name: "moments",
  kind: "message",
  T: Moment,
  repeated: true
}]);
/**
 * @generated from message GoThrive.SyncMomentsBody
 */
export class SyncMomentsBody extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.Data entries = 2;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new SyncMomentsBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new SyncMomentsBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new SyncMomentsBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(SyncMomentsBody, a, b);
  }
}
SyncMomentsBody.runtime = proto3;
SyncMomentsBody.typeName = "GoThrive.SyncMomentsBody";
SyncMomentsBody.fields = proto3.util.newFieldList(() => [{
  no: 2,
  name: "entries",
  kind: "message",
  T: Data,
  repeated: true
}]);
/**
 * @generated from message GoThrive.SyncMomentsResponse
 */
export class SyncMomentsResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.Moment moments = 1;
     */
    this.moments = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new SyncMomentsResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new SyncMomentsResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new SyncMomentsResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(SyncMomentsResponse, a, b);
  }
}
SyncMomentsResponse.runtime = proto3;
SyncMomentsResponse.typeName = "GoThrive.SyncMomentsResponse";
SyncMomentsResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "moments",
  kind: "message",
  T: Moment,
  repeated: true
}]);
/**
 * @generated from message GoThrive.SyncMomentsParams
 */
export class SyncMomentsParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string eventType = 1;
     */
    this.eventType = "";
    /**
     * @generated from field: int64 startFrom = 2;
     */
    this.startFrom = protoInt64.zero;
    /**
     * @generated from field: int64 endAt = 3;
     */
    this.endAt = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new SyncMomentsParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new SyncMomentsParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new SyncMomentsParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(SyncMomentsParams, a, b);
  }
}
SyncMomentsParams.runtime = proto3;
SyncMomentsParams.typeName = "GoThrive.SyncMomentsParams";
SyncMomentsParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "eventType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "startFrom",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 3,
  name: "endAt",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);