// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file reward.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.Reward
 */
export class Reward extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 baseReward = 1;
     */
    this.baseReward = 0;
    /**
     * @generated from field: int32 consistencyBonus = 2;
     */
    this.consistencyBonus = 0;
    /**
     * @generated from field: int32 commitmentBonus = 3;
     */
    this.commitmentBonus = 0;
    /**
     * @generated from field: int32 recommendedBonus = 4;
     */
    this.recommendedBonus = 0;
    /**
     * @generated from field: int32 consistencyLevel = 5;
     */
    this.consistencyLevel = 0;
    /**
     * @generated from field: int32 commitmentLevel = 6;
     */
    this.commitmentLevel = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Reward().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Reward().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Reward().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Reward, a, b);
  }
}
Reward.runtime = proto3;
Reward.typeName = "GoThrive.Reward";
Reward.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "baseReward",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "consistencyBonus",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "commitmentBonus",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "recommendedBonus",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 5,
  name: "consistencyLevel",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 6,
  name: "commitmentLevel",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.RewardParams
 */
export class RewardParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool isRecommended = 1;
     */
    this.isRecommended = false;
    /**
     * @generated from field: float duration = 2;
     */
    this.duration = 0;
    /**
     * @generated from field: string sessionId = 3;
     */
    this.sessionId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RewardParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RewardParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RewardParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RewardParams, a, b);
  }
}
RewardParams.runtime = proto3;
RewardParams.typeName = "GoThrive.RewardParams";
RewardParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "isRecommended",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 2,
  name: "duration",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}, {
  no: 3,
  name: "sessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);