// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file pubNub.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64, Struct } from "@bufbuild/protobuf";
import { AcceptedChat_Patient } from "./chatRequest_pb.js";
import { ChatParticipant } from "./chats_pb.js";
/**
 * @generated from message GoThrive.PubNub
 */
export class PubNub extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNub().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNub().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNub().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNub, a, b);
  }
}
PubNub.runtime = proto3;
PubNub.typeName = "GoThrive.PubNub";
PubNub.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "token",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 2,
  name: "chatRoomId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}]);
/**
 * @generated from message GoThrive.PubNubArchivedChats
 */
export class PubNubArchivedChats extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated string Entries = 1;
     */
    this.Entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubArchivedChats().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubArchivedChats().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubArchivedChats().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubArchivedChats, a, b);
  }
}
PubNubArchivedChats.runtime = proto3;
PubNubArchivedChats.typeName = "GoThrive.PubNubArchivedChats";
PubNubArchivedChats.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Entries",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PubNubUserData
 */
export class PubNubUserData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: bool therapist = 2;
     */
    this.therapist = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubUserData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubUserData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubUserData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubUserData, a, b);
  }
}
PubNubUserData.runtime = proto3;
PubNubUserData.typeName = "GoThrive.PubNubUserData";
PubNubUserData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "therapist",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 3,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 4,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}]);
/**
 * @generated from message GoThrive.PubNubChat
 */
export class PubNubChat extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: string key = 2;
     */
    this.key = "";
    /**
     * @generated from field: string sessionCookie = 3;
     */
    this.sessionCookie = "";
    /**
     * @generated from field: bool isTherapist = 4;
     */
    this.isTherapist = false;
    /**
     * @generated from field: string userUUID = 5;
     */
    this.userUUID = "";
    /**
     * @generated from field: map<string, string> SubCommands = 6;
     */
    this.SubCommands = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubChat().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubChat().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubChat().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubChat, a, b);
  }
}
PubNubChat.runtime = proto3;
PubNubChat.typeName = "GoThrive.PubNubChat";
PubNubChat.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "sessionCookie",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "isTherapist",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 5,
  name: "userUUID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "SubCommands",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "scalar",
    T: 9 /* ScalarType.STRING */
  }
}]);
/**
 * @generated from message GoThrive.PubNubChatEventsLoop
 */
export class PubNubChatEventsLoop extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool quit = 1;
     */
    this.quit = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubChatEventsLoop().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubChatEventsLoop().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubChatEventsLoop().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubChatEventsLoop, a, b);
  }
}
PubNubChatEventsLoop.runtime = proto3;
PubNubChatEventsLoop.typeName = "GoThrive.PubNubChatEventsLoop";
PubNubChatEventsLoop.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "quit",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.PubNubChannelIds
 */
export class PubNubChannelIds extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated string ids = 1;
     */
    this.ids = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubChannelIds().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubChannelIds().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubChannelIds().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubChannelIds, a, b);
  }
}
PubNubChannelIds.runtime = proto3;
PubNubChannelIds.typeName = "GoThrive.PubNubChannelIds";
PubNubChannelIds.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "ids",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PubNubChannel
 */
export class PubNubChannel extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Name = 1;
     */
    this.Name = "";
    /**
     * @generated from field: int64 time = 2;
     */
    this.time = protoInt64.zero;
    /**
     * @generated from field: int32 MessageCount = 3;
     */
    this.MessageCount = 0;
    /**
     * @generated from field: map<string, GoThrive.PubNubChannelUser> Participants = 4;
     */
    this.Participants = {};
    /**
     * @generated from field: repeated GoThrive.PubNubChannelUser ActiveParticipants = 5;
     */
    this.ActiveParticipants = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubChannel().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubChannel().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubChannel().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubChannel, a, b);
  }
}
PubNubChannel.runtime = proto3;
PubNubChannel.typeName = "GoThrive.PubNubChannel";
PubNubChannel.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "time",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 3,
  name: "MessageCount",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "Participants",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: PubNubChannelUser
  }
}, {
  no: 5,
  name: "ActiveParticipants",
  kind: "message",
  T: PubNubChannelUser,
  repeated: true
}, {
  no: 6,
  name: "Patient",
  kind: "message",
  T: AcceptedChat_Patient
}]);
/**
 * @generated from message GoThrive.PubNubChannels
 */
export class PubNubChannels extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.PubNubChannel> channels = 1;
     */
    this.channels = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubChannels().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubChannels().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubChannels().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubChannels, a, b);
  }
}
PubNubChannels.runtime = proto3;
PubNubChannels.typeName = "GoThrive.PubNubChannels";
PubNubChannels.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "channels",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: PubNubChannel
  }
}]);
/**
 * @generated from message GoThrive.PubNubMsgData
 */
export class PubNubMsgData extends Message {
  constructor(data) {
    super();
    /**
     * Time Token
     *
     * @generated from field: int64 TimeID = 1;
     */
    this.TimeID = protoInt64.zero;
    /**
     * @generated from field: repeated string Text = 2;
     */
    this.Text = [];
    /**
     * uuid of sender
     *
     * @generated from field: string Uuid = 3;
     */
    this.Uuid = "";
    /**
     * message has edit history
     *
     * @generated from field: bool Edited = 4;
     */
    this.Edited = false;
    /**
     * message has been deleted
     *
     * @generated from field: bool Deleted = 5;
     */
    this.Deleted = false;
    /**
     * message is liked
     *
     * @generated from field: map<string, string> Liked = 6;
     */
    this.Liked = {};
    /**
     * Text of the message
     *
     * @generated from field: string Title = 8;
     */
    this.Title = "";
    /**
     * @generated from field: repeated string Actions = 9;
     */
    this.Actions = [];
    /**
     * Text of the message
     *
     * @generated from field: int64 OriginalId = 10;
     */
    this.OriginalId = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubMsgData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubMsgData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubMsgData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubMsgData, a, b);
  }
}
PubNubMsgData.runtime = proto3;
PubNubMsgData.typeName = "GoThrive.PubNubMsgData";
PubNubMsgData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "TimeID",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 2,
  name: "Text",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 3,
  name: "Uuid",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "Edited",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 5,
  name: "Deleted",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 6,
  name: "Liked",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "scalar",
    T: 9 /* ScalarType.STRING */
  }
}, {
  no: 7,
  name: "Meta",
  kind: "message",
  T: Struct,
  opt: true
}, {
  no: 8,
  name: "Title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "Actions",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 10,
  name: "OriginalId",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);
/**
 * @generated from message GoThrive.PubNubTherapySession
 */
export class PubNubTherapySession extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Start = 1;
     */
    this.Start = "";
    /**
     * @generated from field: string End = 2;
     */
    this.End = "";
    /**
     * @generated from field: string Therapist = 3;
     */
    this.Therapist = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubTherapySession().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubTherapySession().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubTherapySession().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubTherapySession, a, b);
  }
}
PubNubTherapySession.runtime = proto3;
PubNubTherapySession.typeName = "GoThrive.PubNubTherapySession";
PubNubTherapySession.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Start",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "End",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "Therapist",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.PubNubActiveTherapists
 */
export class PubNubActiveTherapists extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated string Therapists = 1;
     */
    this.Therapists = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubActiveTherapists().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubActiveTherapists().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubActiveTherapists().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubActiveTherapists, a, b);
  }
}
PubNubActiveTherapists.runtime = proto3;
PubNubActiveTherapists.typeName = "GoThrive.PubNubActiveTherapists";
PubNubActiveTherapists.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Therapists",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PubNubTherapySessions
 */
export class PubNubTherapySessions extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.PubNubTherapySession sessions = 1;
     */
    this.sessions = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubTherapySessions().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubTherapySessions().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubTherapySessions().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubTherapySessions, a, b);
  }
}
PubNubTherapySessions.runtime = proto3;
PubNubTherapySessions.typeName = "GoThrive.PubNubTherapySessions";
PubNubTherapySessions.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessions",
  kind: "message",
  T: PubNubTherapySession,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PubNubMessages
 */
export class PubNubMessages extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.PubNubMsgData messages = 1;
     */
    this.messages = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubMessages().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubMessages().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubMessages().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubMessages, a, b);
  }
}
PubNubMessages.runtime = proto3;
PubNubMessages.typeName = "GoThrive.PubNubMessages";
PubNubMessages.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "messages",
  kind: "message",
  T: PubNubMsgData,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PubNubConnected
 */
export class PubNubConnected extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool connected = 1;
     */
    this.connected = false;
    /**
     * @generated from field: bool online = 2;
     */
    this.online = false;
    /**
     * @generated from field: bool refreshMessages = 3;
     */
    this.refreshMessages = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubConnected().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubConnected().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubConnected().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubConnected, a, b);
  }
}
PubNubConnected.runtime = proto3;
PubNubConnected.typeName = "GoThrive.PubNubConnected";
PubNubConnected.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "connected",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 2,
  name: "online",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 3,
  name: "refreshMessages",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.PubNubChatStatus
 */
export class PubNubChatStatus extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int64 state = 1;
     */
    this.state = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubChatStatus().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubChatStatus().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubChatStatus().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubChatStatus, a, b);
  }
}
PubNubChatStatus.runtime = proto3;
PubNubChatStatus.typeName = "GoThrive.PubNubChatStatus";
PubNubChatStatus.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "state",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);
/**
 * @generated from message GoThrive.PubNubTyping
 */
export class PubNubTyping extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated string typingNow = 1;
     */
    this.typingNow = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubTyping().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubTyping().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubTyping().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubTyping, a, b);
  }
}
PubNubTyping.runtime = proto3;
PubNubTyping.typeName = "GoThrive.PubNubTyping";
PubNubTyping.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "typingNow",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PubNubEmergencyChat
 */
export class PubNubEmergencyChat extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool showPopup = 1;
     */
    this.showPopup = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubEmergencyChat().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubEmergencyChat().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubEmergencyChat().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubEmergencyChat, a, b);
  }
}
PubNubEmergencyChat.runtime = proto3;
PubNubEmergencyChat.typeName = "GoThrive.PubNubEmergencyChat";
PubNubEmergencyChat.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "showPopup",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.PubNubActiveUser
 */
export class PubNubActiveUser extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string UUID = 1;
     */
    this.UUID = "";
    /**
     * @generated from field: string Role = 2;
     */
    this.Role = "";
    /**
     * @generated from field: string Name = 3;
     */
    this.Name = "";
    /**
     * @generated from field: bool Online = 4;
     */
    this.Online = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubActiveUser().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubActiveUser().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubActiveUser().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubActiveUser, a, b);
  }
}
PubNubActiveUser.runtime = proto3;
PubNubActiveUser.typeName = "GoThrive.PubNubActiveUser";
PubNubActiveUser.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "UUID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Role",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "Name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "Online",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.PubNubActiveChannel
 */
export class PubNubActiveChannel extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string ChannelName = 1;
     */
    this.ChannelName = "";
    /**
     * @generated from field: int32 Occupancy = 2;
     */
    this.Occupancy = 0;
    /**
     * @generated from field: repeated GoThrive.PubNubActiveUser Users = 3;
     */
    this.Users = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubActiveChannel().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubActiveChannel().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubActiveChannel().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubActiveChannel, a, b);
  }
}
PubNubActiveChannel.runtime = proto3;
PubNubActiveChannel.typeName = "GoThrive.PubNubActiveChannel";
PubNubActiveChannel.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "ChannelName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Occupancy",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "Users",
  kind: "message",
  T: PubNubActiveUser,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PubNubActiveUsers
 */
export class PubNubActiveUsers extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.PubNubActiveChannel Channels = 2;
     */
    this.Channels = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubActiveUsers().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubActiveUsers().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubActiveUsers().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubActiveUsers, a, b);
  }
}
PubNubActiveUsers.runtime = proto3;
PubNubActiveUsers.typeName = "GoThrive.PubNubActiveUsers";
PubNubActiveUsers.fields = proto3.util.newFieldList(() => [{
  no: 2,
  name: "Channels",
  kind: "message",
  T: PubNubActiveChannel,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PubNubChatResponse
 */
export class PubNubChatResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.ChatParticipant Participants = 9;
     */
    this.Participants = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubChatResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubChatResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubChatResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubChatResponse, a, b);
  }
}
PubNubChatResponse.runtime = proto3;
PubNubChatResponse.typeName = "GoThrive.PubNubChatResponse";
PubNubChatResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "activeUsers",
  kind: "message",
  T: PubNubActiveUsers,
  opt: true
}, {
  no: 2,
  name: "channels",
  kind: "message",
  T: PubNubChannels,
  opt: true
}, {
  no: 3,
  name: "messages",
  kind: "message",
  T: PubNubMessages,
  opt: true
}, {
  no: 4,
  name: "editString",
  kind: "message",
  T: PubNubMsgData,
  opt: true
}, {
  no: 5,
  name: "activeChannel",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 6,
  name: "connected",
  kind: "message",
  T: PubNubConnected,
  opt: true
}, {
  no: 7,
  name: "activeTherapists",
  kind: "message",
  T: PubNubActiveTherapists,
  opt: true
}, {
  no: 8,
  name: "chatUser",
  kind: "message",
  T: PubNubUserData
}, {
  no: 9,
  name: "Participants",
  kind: "message",
  T: ChatParticipant,
  repeated: true
}]);
/**
 * @generated from message GoThrive.ChatErrorResponse
 */
export class ChatErrorResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string error = 1;
     */
    this.error = "";
    /**
     * @generated from field: string message = 2;
     */
    this.message = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatErrorResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatErrorResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatErrorResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatErrorResponse, a, b);
  }
}
ChatErrorResponse.runtime = proto3;
ChatErrorResponse.typeName = "GoThrive.ChatErrorResponse";
ChatErrorResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "error",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.PubNubRequestCommand
 */
export class PubNubRequestCommand extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Id = 1;
     */
    this.Id = "";
    /**
     * @generated from field: string UserUUID = 2;
     */
    this.UserUUID = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubRequestCommand().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubRequestCommand().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubRequestCommand().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubRequestCommand, a, b);
  }
}
PubNubRequestCommand.runtime = proto3;
PubNubRequestCommand.typeName = "GoThrive.PubNubRequestCommand";
PubNubRequestCommand.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "UserUUID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "Params",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}]);
/**
 * @generated from message GoThrive.PubNubAssignCommand
 */
export class PubNubAssignCommand extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Id = 1;
     */
    this.Id = "";
    /**
     * @generated from field: string UserUUID = 2;
     */
    this.UserUUID = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubAssignCommand().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubAssignCommand().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubAssignCommand().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubAssignCommand, a, b);
  }
}
PubNubAssignCommand.runtime = proto3;
PubNubAssignCommand.typeName = "GoThrive.PubNubAssignCommand";
PubNubAssignCommand.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "UserUUID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "Params",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}]);
/**
 * @generated from message GoThrive.PubNubMetaList
 */
export class PubNubMetaList extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.PubNubMetaData Entries = 1;
     */
    this.Entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubMetaList().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubMetaList().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubMetaList().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubMetaList, a, b);
  }
}
PubNubMetaList.runtime = proto3;
PubNubMetaList.typeName = "GoThrive.PubNubMetaList";
PubNubMetaList.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Entries",
  kind: "message",
  T: PubNubMetaData,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PubNubMetaData
 */
export class PubNubMetaData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Type = 1;
     */
    this.Type = "";
    /**
     * @generated from field: bool Accepted = 2;
     */
    this.Accepted = false;
    /**
     * @generated from field: string Created = 3;
     */
    this.Created = "";
    /**
     * @generated from field: bool Left = 6;
     */
    this.Left = false;
    /**
     * @generated from field: string Status = 7;
     */
    this.Status = "";
    /**
     * @generated from field: string Therapist = 8;
     */
    this.Therapist = "";
    /**
     * @generated from field: string AssetId = 9;
     */
    this.AssetId = "";
    /**
     * @generated from field: string DiaryId = 10;
     */
    this.DiaryId = "";
    /**
     * @generated from field: string Title = 11;
     */
    this.Title = "";
    /**
     * @generated from field: string Description = 12;
     */
    this.Description = "";
    /**
     * carry id in diary
     * maybe move to messages form channel metadata
     * Call Martin/Kev about moving appointments and stuff to separate view
     *
     * @generated from field: string SubType = 13;
     */
    this.SubType = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubMetaData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubMetaData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubMetaData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubMetaData, a, b);
  }
}
PubNubMetaData.runtime = proto3;
PubNubMetaData.typeName = "GoThrive.PubNubMetaData";
PubNubMetaData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Accepted",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 3,
  name: "Created",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "Resolved",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 6,
  name: "Left",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 7,
  name: "Status",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "Therapist",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "AssetId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 10,
  name: "DiaryId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 11,
  name: "Title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 12,
  name: "Description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 13,
  name: "SubType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.PubNubMeta
 */
export class PubNubMeta extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.PubNubLastRead> lastRead = 1;
     */
    this.lastRead = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubMeta().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubMeta().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubMeta().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubMeta, a, b);
  }
}
PubNubMeta.runtime = proto3;
PubNubMeta.typeName = "GoThrive.PubNubMeta";
PubNubMeta.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "lastRead",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: PubNubLastRead
  }
}, {
  no: 2,
  name: "channelUsers",
  kind: "message",
  T: PubNubChannelUsers
}]);
/**
 * @generated from message GoThrive.PubNubLastRead
 */
export class PubNubLastRead extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string channel = 1;
     */
    this.channel = "";
    /**
     * @generated from field: int64 Time = 2;
     */
    this.Time = protoInt64.zero;
    /**
     * @generated from field: string userID = 3;
     */
    this.userID = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubLastRead().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubLastRead().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubLastRead().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubLastRead, a, b);
  }
}
PubNubLastRead.runtime = proto3;
PubNubLastRead.typeName = "GoThrive.PubNubLastRead";
PubNubLastRead.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "channel",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Time",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 3,
  name: "userID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.PubNubChannelUser
 */
export class PubNubChannelUser extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string name = 1;
     */
    this.name = "";
    /**
     * @generated from field: bool therapist = 2;
     */
    this.therapist = false;
    /**
     * @generated from field: string id = 3;
     */
    this.id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubChannelUser().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubChannelUser().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubChannelUser().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubChannelUser, a, b);
  }
}
PubNubChannelUser.runtime = proto3;
PubNubChannelUser.typeName = "GoThrive.PubNubChannelUser";
PubNubChannelUser.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "therapist",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 3,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.PubNubChannelUsers
 */
export class PubNubChannelUsers extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string channel = 1;
     */
    this.channel = "";
    /**
     * @generated from field: map<string, GoThrive.PubNubChannelUser> Users = 6;
     */
    this.Users = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubChannelUsers().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubChannelUsers().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubChannelUsers().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubChannelUsers, a, b);
  }
}
PubNubChannelUsers.runtime = proto3;
PubNubChannelUsers.typeName = "GoThrive.PubNubChannelUsers";
PubNubChannelUsers.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "channel",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "Users",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: PubNubChannelUser
  }
}]);
/**
 * @generated from message GoThrive.PubNubChannelMetaData
 */
export class PubNubChannelMetaData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.PubNubMetaData requests = 1;
     */
    this.requests = [];
    /**
     * @generated from field: repeated GoThrive.PubNubTherapySession sessions = 3;
     */
    this.sessions = [];
    /**
     * @generated from field: repeated GoThrive.PubNubUserData Users = 4;
     */
    this.Users = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubChannelMetaData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubChannelMetaData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubChannelMetaData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubChannelMetaData, a, b);
  }
}
PubNubChannelMetaData.runtime = proto3;
PubNubChannelMetaData.typeName = "GoThrive.PubNubChannelMetaData";
PubNubChannelMetaData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "requests",
  kind: "message",
  T: PubNubMetaData,
  repeated: true
}, {
  no: 2,
  name: "LastRead",
  kind: "message",
  T: PubNubLastRead
}, {
  no: 3,
  name: "sessions",
  kind: "message",
  T: PubNubTherapySession,
  repeated: true
}, {
  no: 4,
  name: "Users",
  kind: "message",
  T: PubNubUserData,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PubNubSimpleMeta
 */
export class PubNubSimpleMeta extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Name = 1;
     */
    this.Name = "";
    /**
     * @generated from field: string Value = 2;
     */
    this.Value = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubSimpleMeta().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubSimpleMeta().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubSimpleMeta().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubSimpleMeta, a, b);
  }
}
PubNubSimpleMeta.runtime = proto3;
PubNubSimpleMeta.typeName = "GoThrive.PubNubSimpleMeta";
PubNubSimpleMeta.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Value",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.PubNubSimpleMetaList
 */
export class PubNubSimpleMetaList extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.PubNubSimpleMeta Entries = 1;
     */
    this.Entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubSimpleMetaList().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubSimpleMetaList().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubSimpleMetaList().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubSimpleMetaList, a, b);
  }
}
PubNubSimpleMetaList.runtime = proto3;
PubNubSimpleMetaList.typeName = "GoThrive.PubNubSimpleMetaList";
PubNubSimpleMetaList.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Entries",
  kind: "message",
  T: PubNubSimpleMeta,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PubNubLastReadParams
 */
export class PubNubLastReadParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string channelIds = 1;
     */
    this.channelIds = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubLastReadParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubLastReadParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubLastReadParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubLastReadParams, a, b);
  }
}
PubNubLastReadParams.runtime = proto3;
PubNubLastReadParams.typeName = "GoThrive.PubNubLastReadParams";
PubNubLastReadParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "channelIds",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.PubNubLastReadEntry
 */
export class PubNubLastReadEntry extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int64 timeToken = 1;
     */
    this.timeToken = protoInt64.zero;
    /**
     * @generated from field: int64 count = 2;
     */
    this.count = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubLastReadEntry().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubLastReadEntry().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubLastReadEntry().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubLastReadEntry, a, b);
  }
}
PubNubLastReadEntry.runtime = proto3;
PubNubLastReadEntry.typeName = "GoThrive.PubNubLastReadEntry";
PubNubLastReadEntry.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "timeToken",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 2,
  name: "count",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);
/**
 * @generated from message GoThrive.PubNubLastReadBody
 */
export class PubNubLastReadBody extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.PubNubLastReadEntry> channels = 1;
     */
    this.channels = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PubNubLastReadBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PubNubLastReadBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PubNubLastReadBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PubNubLastReadBody, a, b);
  }
}
PubNubLastReadBody.runtime = proto3;
PubNubLastReadBody.typeName = "GoThrive.PubNubLastReadBody";
PubNubLastReadBody.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "channels",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: PubNubLastReadEntry
  }
}]);