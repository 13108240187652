// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file subscribe.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Any, Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Provider } from "./providers_pb.js";
import { CustomTokenPatient, CustomTokenUser } from "./network_pb.js";
import { MFAOption } from "./mfa_pb.js";
import { Moment } from "./moment_pb.js";
/**
 * @generated from message GoThrive.PropertyAuthorisation
 */
export class PropertyAuthorisation extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string message = 1;
     */
    this.message = "";
    /**
     * @generated from field: string email = 2;
     */
    this.email = "";
    /**
     * @generated from field: string password = 3;
     */
    this.password = "";
    /**
     * @generated from field: string error = 4;
     */
    this.error = "";
    /**
     * @generated from field: string openIDCustomToken = 5;
     */
    this.openIDCustomToken = "";
    /**
     * @generated from field: repeated GoThrive.Provider entries = 6;
     */
    this.entries = [];
    /**
     * @generated from field: string clientID = 7;
     */
    this.clientID = "";
    /**
     * @generated from field: string openIDurl = 8;
     */
    this.openIDurl = "";
    /**
     * @generated from field: string refreshToken = 9;
     */
    this.refreshToken = "";
    /**
     * @generated from field: string sessionCookie = 10;
     */
    this.sessionCookie = "";
    /**
     * @generated from field: string accessCode = 15;
     */
    this.accessCode = "";
    /**
     * @generated from field: int32 awaitFlags = 16;
     */
    this.awaitFlags = 0;
    /**
     * @generated from field: string TOTPIdToken = 17;
     */
    this.TOTPIdToken = "";
    /**
     * @generated from field: string TOTPCode = 18;
     */
    this.TOTPCode = "";
    /**
     * @generated from field: string TOTPType = 19;
     */
    this.TOTPType = "";
    /**
     * @generated from field: repeated GoThrive.MFAOption MFAOptions = 20;
     */
    this.MFAOptions = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PropertyAuthorisation().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PropertyAuthorisation().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PropertyAuthorisation().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PropertyAuthorisation, a, b);
  }
}
PropertyAuthorisation.runtime = proto3;
PropertyAuthorisation.typeName = "GoThrive.PropertyAuthorisation";
PropertyAuthorisation.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "password",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "error",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "openIDCustomToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "entries",
  kind: "message",
  T: Provider,
  repeated: true
}, {
  no: 7,
  name: "clientID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "openIDurl",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "refreshToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 10,
  name: "sessionCookie",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 12,
  name: "patientRecord",
  kind: "message",
  T: CustomTokenPatient,
  opt: true
}, {
  no: 13,
  name: "userRecord",
  kind: "message",
  T: CustomTokenUser,
  opt: true
}, {
  no: 14,
  name: "TubUrl",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 15,
  name: "accessCode",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 16,
  name: "awaitFlags",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 17,
  name: "TOTPIdToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 18,
  name: "TOTPCode",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 19,
  name: "TOTPType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 20,
  name: "MFAOptions",
  kind: "message",
  T: MFAOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PropertyApiKeys
 */
export class PropertyApiKeys extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Firebase = 1;
     */
    this.Firebase = "";
    /**
     * @generated from field: string MixpanelProjectID = 2;
     */
    this.MixpanelProjectID = "";
    /**
     * @generated from field: string PubNubPubKey = 3;
     */
    this.PubNubPubKey = "";
    /**
     * @generated from field: string PubNubSubKey = 4;
     */
    this.PubNubSubKey = "";
    /**
     * @generated from field: string TubUrl = 5;
     */
    this.TubUrl = "";
    /**
     * @generated from field: string LegalDocs = 6;
     */
    this.LegalDocs = "";
    /**
     * @generated from field: string PrivacyPolicy = 7;
     */
    this.PrivacyPolicy = "";
    /**
     * @generated from field: string CollectionStatement = 8;
     */
    this.CollectionStatement = "";
    /**
     * @generated from field: string Message = 9;
     */
    this.Message = "";
    /**
     * @generated from field: string Plist = 10;
     */
    this.Plist = "";
    /**
     * @generated from field: string AuthDomain = 11;
     */
    this.AuthDomain = "";
    /**
     * @generated from field: string ProjectId = 12;
     */
    this.ProjectId = "";
    /**
     * @generated from field: string StorageBucket = 13;
     */
    this.StorageBucket = "";
    /**
     * @generated from field: string DatabaseURL = 14;
     */
    this.DatabaseURL = "";
    /**
     * @generated from field: string AppId = 15;
     */
    this.AppId = "";
    /**
     * @generated from field: string MessagingSenderId = 16;
     */
    this.MessagingSenderId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PropertyApiKeys().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PropertyApiKeys().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PropertyApiKeys().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PropertyApiKeys, a, b);
  }
}
PropertyApiKeys.runtime = proto3;
PropertyApiKeys.typeName = "GoThrive.PropertyApiKeys";
PropertyApiKeys.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Firebase",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "MixpanelProjectID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "PubNubPubKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "PubNubSubKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "TubUrl",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "LegalDocs",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "PrivacyPolicy",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "CollectionStatement",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "Message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 10,
  name: "Plist",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 11,
  name: "AuthDomain",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 12,
  name: "ProjectId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 13,
  name: "StorageBucket",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 14,
  name: "DatabaseURL",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 15,
  name: "AppId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 16,
  name: "MessagingSenderId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.TubFirebase
 */
export class TubFirebase extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string ApiKey = 1;
     */
    this.ApiKey = "";
    /**
     * @generated from field: string AppId = 2;
     */
    this.AppId = "";
    /**
     * @generated from field: string AuthDomain = 3;
     */
    this.AuthDomain = "";
    /**
     * @generated from field: string DatabaseURL = 4;
     */
    this.DatabaseURL = "";
    /**
     * @generated from field: string MessagingSenderId = 5;
     */
    this.MessagingSenderId = "";
    /**
     * @generated from field: string ProjectId = 6;
     */
    this.ProjectId = "";
    /**
     * @generated from field: string StorageBucket = 7;
     */
    this.StorageBucket = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TubFirebase().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TubFirebase().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TubFirebase().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TubFirebase, a, b);
  }
}
TubFirebase.runtime = proto3;
TubFirebase.typeName = "GoThrive.TubFirebase";
TubFirebase.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "ApiKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "AppId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "AuthDomain",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "DatabaseURL",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "MessagingSenderId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "ProjectId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "StorageBucket",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.TubPubnub
 */
export class TubPubnub extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string PubKey = 1;
     */
    this.PubKey = "";
    /**
     * @generated from field: string SubKey = 2;
     */
    this.SubKey = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TubPubnub().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TubPubnub().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TubPubnub().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TubPubnub, a, b);
  }
}
TubPubnub.runtime = proto3;
TubPubnub.typeName = "GoThrive.TubPubnub";
TubPubnub.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "PubKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "SubKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.TubClientData
 */
export class TubClientData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string legalDocuments = 1;
     */
    this.legalDocuments = "";
    /**
     * @generated from field: string privacyPolicyUrl = 2;
     */
    this.privacyPolicyUrl = "";
    /**
     * @generated from field: string collectionStatementUrl = 3;
     */
    this.collectionStatementUrl = "";
    /**
     * @generated from field: string AndroidGoogleServicesFileUrl = 4;
     */
    this.AndroidGoogleServicesFileUrl = "";
    /**
     * @generated from field: string IosGoogleServicesFileUrl = 5;
     */
    this.IosGoogleServicesFileUrl = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TubClientData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TubClientData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TubClientData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TubClientData, a, b);
  }
}
TubClientData.runtime = proto3;
TubClientData.typeName = "GoThrive.TubClientData";
TubClientData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "legalDocuments",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "privacyPolicyUrl",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "collectionStatementUrl",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "AndroidGoogleServicesFileUrl",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "IosGoogleServicesFileUrl",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ClientConfig
 */
export class ClientConfig extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string MixpanelProjectToken = 2;
     */
    this.MixpanelProjectToken = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ClientConfig().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ClientConfig().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ClientConfig().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ClientConfig, a, b);
  }
}
ClientConfig.runtime = proto3;
ClientConfig.typeName = "GoThrive.ClientConfig";
ClientConfig.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Firebase",
  kind: "message",
  T: TubFirebase
}, {
  no: 2,
  name: "MixpanelProjectToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "Pubnub",
  kind: "message",
  T: TubPubnub
}, {
  no: 4,
  name: "ClientData",
  kind: "message",
  T: TubClientData
}]);
/**
 * @generated from message GoThrive.TubRegionsEntry
 */
export class TubRegionsEntry extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string TubApiUrl = 1;
     */
    this.TubApiUrl = "";
    /**
     * @generated from field: string AuthApiKey = 2;
     */
    this.AuthApiKey = "";
    /**
     * @generated from field: string RegionCode = 3;
     */
    this.RegionCode = "";
    /**
     * @generated from field: bool Recommended = 4;
     */
    this.Recommended = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TubRegionsEntry().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TubRegionsEntry().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TubRegionsEntry().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TubRegionsEntry, a, b);
  }
}
TubRegionsEntry.runtime = proto3;
TubRegionsEntry.typeName = "GoThrive.TubRegionsEntry";
TubRegionsEntry.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "TubApiUrl",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "AuthApiKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "RegionCode",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "Recommended",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.TubRegions
 */
export class TubRegions extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.TubRegionsEntry regions = 1;
     */
    this.regions = [];
    /**
     * @generated from field: string CfRegion = 2;
     */
    this.CfRegion = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TubRegions().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TubRegions().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TubRegions().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TubRegions, a, b);
  }
}
TubRegions.runtime = proto3;
TubRegions.typeName = "GoThrive.TubRegions";
TubRegions.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "regions",
  kind: "message",
  T: TubRegionsEntry,
  repeated: true
}, {
  no: 2,
  name: "CfRegion",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.TaskInfo
 */
export class TaskInfo extends Message {
  constructor(data) {
    super();
    /**
     * eg CBT_01
     *
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * Text ID of Task
     *
     * @generated from field: string textid = 2;
     */
    this.textid = "";
    /**
     * Secondary text
     *
     * @generated from field: string secondarytextid = 3;
     */
    this.secondarytextid = "";
    /**
     * @generated from field: string icon = 4;
     */
    this.icon = "";
    /**
     * @generated from field: string iconbackground = 5;
     */
    this.iconbackground = "";
    /**
     * @generated from field: int32 priority = 6;
     */
    this.priority = 0;
    /**
     * @generated from field: string type = 7;
     */
    this.type = "";
    /**
     * @generated from field: int32 steps = 8;
     */
    this.steps = 0;
    /**
     * @generated from field: repeated google.protobuf.Timestamp intervals = 9;
     */
    this.intervals = [];
    /**
     * @generated from field: string menuId = 10;
     */
    this.menuId = "";
    /**
     * @generated from field: string state = 13;
     */
    this.state = "";
    /**
     * @generated from field: string title = 14;
     */
    this.title = "";
    /**
     * @generated from field: bool optout = 15;
     */
    this.optout = false;
    /**
     * @generated from field: int32 hint = 17;
     */
    this.hint = 0;
    /**
     * @generated from field: map<string, string> hintData = 18;
     */
    this.hintData = {};
    /**
     * @generated from field: int64 notificationTime = 19;
     */
    this.notificationTime = protoInt64.zero;
    /**
     * @generated from field: int64 lastNotificationTime = 20;
     */
    this.lastNotificationTime = protoInt64.zero;
    /**
     * @generated from field: repeated int32 ticks = 21;
     */
    this.ticks = [];
    /**
     * @generated from field: repeated string nestedTasks = 22;
     */
    this.nestedTasks = [];
    /**
     * @generated from field: string SaveCommand = 23;
     */
    this.SaveCommand = "";
    /**
     * @generated from field: bool Hide = 24;
     */
    this.Hide = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TaskInfo().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TaskInfo().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TaskInfo().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TaskInfo, a, b);
  }
}
TaskInfo.runtime = proto3;
TaskInfo.typeName = "GoThrive.TaskInfo";
TaskInfo.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "textid",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "secondarytextid",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "icon",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "iconbackground",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "priority",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 7,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "steps",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 9,
  name: "intervals",
  kind: "message",
  T: Timestamp,
  repeated: true
}, {
  no: 10,
  name: "menuId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 11,
  name: "started",
  kind: "message",
  T: Timestamp
}, {
  no: 12,
  name: "completed",
  kind: "message",
  T: Timestamp
}, {
  no: 13,
  name: "state",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 14,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 15,
  name: "optout",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 16,
  name: "message",
  kind: "message",
  T: Any,
  opt: true
}, {
  no: 17,
  name: "hint",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 18,
  name: "hintData",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "scalar",
    T: 9 /* ScalarType.STRING */
  }
}, {
  no: 19,
  name: "notificationTime",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 20,
  name: "lastNotificationTime",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 21,
  name: "ticks",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  repeated: true
}, {
  no: 22,
  name: "nestedTasks",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 23,
  name: "SaveCommand",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 24,
  name: "Hide",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.RecommendationInfo
 */
export class RecommendationInfo extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: string menuID = 2;
     */
    this.menuID = "";
    /**
     * @generated from field: string textid = 3;
     */
    this.textid = "";
    /**
     * @generated from field: string icon = 4;
     */
    this.icon = "";
    /**
     * @generated from field: string iconbackground = 5;
     */
    this.iconbackground = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RecommendationInfo().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RecommendationInfo().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RecommendationInfo().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RecommendationInfo, a, b);
  }
}
RecommendationInfo.runtime = proto3;
RecommendationInfo.typeName = "GoThrive.RecommendationInfo";
RecommendationInfo.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "menuID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "textid",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "icon",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "iconbackground",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.InfoPanelRecommendationList
 */
export class InfoPanelRecommendationList extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string placeholder = 3;
     */
    this.placeholder = "";
    /**
     * @generated from field: string style = 4;
     */
    this.style = "";
    /**
     * @generated from field: repeated GoThrive.RecommendationInfo recommendations = 5;
     */
    this.recommendations = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new InfoPanelRecommendationList().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new InfoPanelRecommendationList().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new InfoPanelRecommendationList().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(InfoPanelRecommendationList, a, b);
  }
}
InfoPanelRecommendationList.runtime = proto3;
InfoPanelRecommendationList.typeName = "GoThrive.InfoPanelRecommendationList";
InfoPanelRecommendationList.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "placeholder",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "recommendations",
  kind: "message",
  T: RecommendationInfo,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PropertyTasks
 */
export class PropertyTasks extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.TaskInfo tasks = 1;
     */
    this.tasks = [];
    /**
     * @generated from field: repeated GoThrive.RecommendationInfo recommendations = 2;
     */
    this.recommendations = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PropertyTasks().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PropertyTasks().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PropertyTasks().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PropertyTasks, a, b);
  }
}
PropertyTasks.runtime = proto3;
PropertyTasks.typeName = "GoThrive.PropertyTasks";
PropertyTasks.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "tasks",
  kind: "message",
  T: TaskInfo,
  repeated: true
}, {
  no: 2,
  name: "recommendations",
  kind: "message",
  T: RecommendationInfo,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PropertyNotification
 */
export class PropertyNotification extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: int64 notifyTime = 2;
     */
    this.notifyTime = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PropertyNotification().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PropertyNotification().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PropertyNotification().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PropertyNotification, a, b);
  }
}
PropertyNotification.runtime = proto3;
PropertyNotification.typeName = "GoThrive.PropertyNotification";
PropertyNotification.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "notifyTime",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);
/**
 * @generated from message GoThrive.DataUpdate
 */
export class DataUpdate extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool updateRequired = 1;
     */
    this.updateRequired = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new DataUpdate().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new DataUpdate().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new DataUpdate().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(DataUpdate, a, b);
  }
}
DataUpdate.runtime = proto3;
DataUpdate.typeName = "GoThrive.DataUpdate";
DataUpdate.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "updateRequired",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.MomentUpdate
 */
export class MomentUpdate extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MomentUpdate().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MomentUpdate().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MomentUpdate().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MomentUpdate, a, b);
  }
}
MomentUpdate.runtime = proto3;
MomentUpdate.typeName = "GoThrive.MomentUpdate";
MomentUpdate.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "moment",
  kind: "message",
  T: Moment
}]);
/**
 * @generated from message GoThrive.CatalogUpdate
 */
export class CatalogUpdate extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string catalog = 1;
     */
    this.catalog = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CatalogUpdate().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CatalogUpdate().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CatalogUpdate().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CatalogUpdate, a, b);
  }
}
CatalogUpdate.runtime = proto3;
CatalogUpdate.typeName = "GoThrive.CatalogUpdate";
CatalogUpdate.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "catalog",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.TherapistInformed
 */
export class TherapistInformed extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool informed = 1;
     */
    this.informed = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TherapistInformed().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TherapistInformed().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TherapistInformed().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TherapistInformed, a, b);
  }
}
TherapistInformed.runtime = proto3;
TherapistInformed.typeName = "GoThrive.TherapistInformed";
TherapistInformed.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "informed",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.Deprecated
 */
export class Deprecated extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Deprecated().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Deprecated().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Deprecated().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Deprecated, a, b);
  }
}
Deprecated.runtime = proto3;
Deprecated.typeName = "GoThrive.Deprecated";
Deprecated.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message GoThrive.PropertyLocalConfig
 */
export class PropertyLocalConfig extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string localConfig = 1;
     */
    this.localConfig = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PropertyLocalConfig().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PropertyLocalConfig().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PropertyLocalConfig().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PropertyLocalConfig, a, b);
  }
}
PropertyLocalConfig.runtime = proto3;
PropertyLocalConfig.typeName = "GoThrive.PropertyLocalConfig";
PropertyLocalConfig.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "localConfig",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);