// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file manifest.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.AssetInfo
 */
export class AssetInfo extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string assetkey = 2;
     */
    this.assetkey = "";
    /**
     * @generated from field: string type = 3;
     */
    this.type = "";
    /**
     * @generated from field: string hash = 4;
     */
    this.hash = "";
    /**
     * @generated from field: string option = 5;
     */
    this.option = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssetInfo().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssetInfo().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssetInfo().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssetInfo, a, b);
  }
}
AssetInfo.runtime = proto3;
AssetInfo.typeName = "GoThrive.AssetInfo";
AssetInfo.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "assetkey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "hash",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "option",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.GroupInfo
 */
export class GroupInfo extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: map<string, GoThrive.AssetInfo> assets = 2;
     */
    this.assets = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new GroupInfo().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new GroupInfo().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new GroupInfo().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(GroupInfo, a, b);
  }
}
GroupInfo.runtime = proto3;
GroupInfo.typeName = "GoThrive.GroupInfo";
GroupInfo.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "assets",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: AssetInfo
  }
}]);
/**
 * @generated from message GoThrive.ManifestCatalog
 */
export class ManifestCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.GroupInfo> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ManifestCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ManifestCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ManifestCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ManifestCatalog, a, b);
  }
}
ManifestCatalog.runtime = proto3;
ManifestCatalog.typeName = "GoThrive.ManifestCatalog";
ManifestCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: GroupInfo
  }
}]);