// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file patient.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.Patient
 */
export class Patient extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Patient().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Patient().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Patient().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Patient, a, b);
  }
}
Patient.runtime = proto3;
Patient.typeName = "GoThrive.Patient";
Patient.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 2,
  name: "firstName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 3,
  name: "lastName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 4,
  name: "organisation",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 5,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 6,
  name: "language",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 7,
  name: "screenName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 8,
  name: "highRisk",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */,
  opt: true
}]);
/**
 * @generated from message GoThrive.PatientUpdateParams
 */
export class PatientUpdateParams extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PatientUpdateParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PatientUpdateParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PatientUpdateParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PatientUpdateParams, a, b);
  }
}
PatientUpdateParams.runtime = proto3;
PatientUpdateParams.typeName = "GoThrive.PatientUpdateParams";
PatientUpdateParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "emailAddress",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 2,
  name: "language",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}]);
/**
 * @generated from message GoThrive.PatientUpdateEmail
 */
export class PatientUpdateEmail extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string email = 1;
     */
    this.email = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PatientUpdateEmail().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PatientUpdateEmail().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PatientUpdateEmail().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PatientUpdateEmail, a, b);
  }
}
PatientUpdateEmail.runtime = proto3;
PatientUpdateEmail.typeName = "GoThrive.PatientUpdateEmail";
PatientUpdateEmail.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.Patients
 */
export class Patients extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.Patient entries = 1;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Patients().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Patients().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Patients().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Patients, a, b);
  }
}
Patients.runtime = proto3;
Patients.typeName = "GoThrive.Patients";
Patients.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "message",
  T: Patient,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PatientHighRisk
 */
export class PatientHighRisk extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool highRisk = 1;
     */
    this.highRisk = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PatientHighRisk().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PatientHighRisk().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PatientHighRisk().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PatientHighRisk, a, b);
  }
}
PatientHighRisk.runtime = proto3;
PatientHighRisk.typeName = "GoThrive.PatientHighRisk";
PatientHighRisk.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "highRisk",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);