// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file subtitle.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.Subtitle
 */
export class Subtitle extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string words = 2;
     */
    this.words = [];
    /**
     * @generated from field: repeated float time = 3;
     */
    this.time = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Subtitle().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Subtitle().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Subtitle().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Subtitle, a, b);
  }
}
Subtitle.runtime = proto3;
Subtitle.typeName = "GoThrive.Subtitle";
Subtitle.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "words",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 3,
  name: "time",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.SubtitleCatalog
 */
export class SubtitleCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.Subtitle> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new SubtitleCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new SubtitleCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new SubtitleCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(SubtitleCatalog, a, b);
  }
}
SubtitleCatalog.runtime = proto3;
SubtitleCatalog.typeName = "GoThrive.SubtitleCatalog";
SubtitleCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Subtitle
  }
}]);