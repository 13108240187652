// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file strings.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { LanguageInfo } from "./audio_pb.js";
/**
 * @generated from message GoThrive.StringData
 */
export class StringData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string localizedString = 2;
     */
    this.localizedString = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new StringData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new StringData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new StringData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(StringData, a, b);
  }
}
StringData.runtime = proto3;
StringData.typeName = "GoThrive.StringData";
StringData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "localizedString",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.StringCatalog
 */
export class StringCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.StringData> contentMap = 1;
     */
    this.contentMap = {};
    /**
     * @generated from field: map<string, GoThrive.StringData> tokens = 2;
     */
    this.tokens = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new StringCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new StringCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new StringCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(StringCatalog, a, b);
  }
}
StringCatalog.runtime = proto3;
StringCatalog.typeName = "GoThrive.StringCatalog";
StringCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: StringData
  }
}, {
  no: 2,
  name: "tokens",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: StringData
  }
}]);
/**
 * @generated from message GoThrive.Language
 */
export class Language extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string country = 2;
     */
    this.country = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Language().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Language().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Language().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Language, a, b);
  }
}
Language.runtime = proto3;
Language.typeName = "GoThrive.Language";
Language.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "country",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.LanguageCatalog
 */
export class LanguageCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.Language> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new LanguageCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new LanguageCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new LanguageCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(LanguageCatalog, a, b);
  }
}
LanguageCatalog.runtime = proto3;
LanguageCatalog.typeName = "GoThrive.LanguageCatalog";
LanguageCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Language
  }
}]);
/**
 * @generated from message GoThrive.CommandSetLanguage
 */
export class CommandSetLanguage extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string languageCode = 1;
     */
    this.languageCode = "";
    /**
     * @generated from field: bool noReloadOrganisation = 3;
     */
    this.noReloadOrganisation = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSetLanguage().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSetLanguage().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSetLanguage().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSetLanguage, a, b);
  }
}
CommandSetLanguage.runtime = proto3;
CommandSetLanguage.typeName = "GoThrive.CommandSetLanguage";
CommandSetLanguage.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "languageCode",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "LanguageInfo",
  kind: "message",
  T: LanguageInfo
}, {
  no: 3,
  name: "noReloadOrganisation",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);