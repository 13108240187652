// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file chatRequest.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.ChatRequest
 */
export class ChatRequest extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: int64 timestamp = 2;
     */
    this.timestamp = protoInt64.zero;
    /**
     * @generated from field: string author = 3;
     */
    this.author = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatRequest, a, b);
  }
}
ChatRequest.runtime = proto3;
ChatRequest.typeName = "GoThrive.ChatRequest";
ChatRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "timestamp",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 3,
  name: "author",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "data",
  kind: "message",
  T: ChatRequest_Data
}]);
/**
 * @generated from message GoThrive.ChatRequest.Data
 */
export class ChatRequest_Data extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string message = 1;
     */
    this.message = "";
    /**
     * @generated from field: int32 severity = 2;
     */
    this.severity = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatRequest_Data().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatRequest_Data().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatRequest_Data().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatRequest_Data, a, b);
  }
}
ChatRequest_Data.runtime = proto3;
ChatRequest_Data.typeName = "GoThrive.ChatRequest.Data";
ChatRequest_Data.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "severity",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "patient",
  kind: "message",
  T: ChatRequest_Data_Patient
}]);
/**
 * @generated from message GoThrive.ChatRequest.Data.Patient
 */
export class ChatRequest_Data_Patient extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: string firstName = 2;
     */
    this.firstName = "";
    /**
     * @generated from field: string lastName = 3;
     */
    this.lastName = "";
    /**
     * @generated from field: string email = 4;
     */
    this.email = "";
    /**
     * @generated from field: bool hasExistingChat = 5;
     */
    this.hasExistingChat = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatRequest_Data_Patient().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatRequest_Data_Patient().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatRequest_Data_Patient().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatRequest_Data_Patient, a, b);
  }
}
ChatRequest_Data_Patient.runtime = proto3;
ChatRequest_Data_Patient.typeName = "GoThrive.ChatRequest.Data.Patient";
ChatRequest_Data_Patient.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "firstName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "lastName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "hasExistingChat",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.ChatRequestsParams
 */
export class ChatRequestsParams extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatRequestsParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatRequestsParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatRequestsParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatRequestsParams, a, b);
  }
}
ChatRequestsParams.runtime = proto3;
ChatRequestsParams.typeName = "GoThrive.ChatRequestsParams";
ChatRequestsParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "pubNub",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */,
  opt: true
}]);
/**
 * @generated from message GoThrive.ChatRequests
 */
export class ChatRequests extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.ChatRequest entries = 1;
     */
    this.entries = [];
    /**
     * @generated from field: string patientId = 2;
     */
    this.patientId = "";
    /**
     * @generated from field: string momentId = 3;
     */
    this.momentId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatRequests().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatRequests().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatRequests().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatRequests, a, b);
  }
}
ChatRequests.runtime = proto3;
ChatRequests.typeName = "GoThrive.ChatRequests";
ChatRequests.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "message",
  T: ChatRequest,
  repeated: true
}, {
  no: 2,
  name: "patientId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "momentId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChatRoom
 */
export class ChatRoom extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string ChatRoomId = 1;
     */
    this.ChatRoomId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatRoom().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatRoom().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatRoom().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatRoom, a, b);
  }
}
ChatRoom.runtime = proto3;
ChatRoom.typeName = "GoThrive.ChatRoom";
ChatRoom.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "ChatRoomId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.AcceptChatRequest
 */
export class AcceptChatRequest extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string requestId = 1;
     */
    this.requestId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AcceptChatRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AcceptChatRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AcceptChatRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AcceptChatRequest, a, b);
  }
}
AcceptChatRequest.runtime = proto3;
AcceptChatRequest.typeName = "GoThrive.AcceptChatRequest";
AcceptChatRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "requestId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.AcceptedChat
 */
export class AcceptedChat extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AcceptedChat().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AcceptedChat().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AcceptedChat().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AcceptedChat, a, b);
  }
}
AcceptedChat.runtime = proto3;
AcceptedChat.typeName = "GoThrive.AcceptedChat";
AcceptedChat.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "patient",
  kind: "message",
  T: AcceptedChat_Patient
}, {
  no: 3,
  name: "organisation",
  kind: "message",
  T: AcceptedChat_Organisation
}]);
/**
 * @generated from message GoThrive.AcceptedChat.Patient
 */
export class AcceptedChat_Patient extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: string userId = 2;
     */
    this.userId = "";
    /**
     * @generated from field: string email = 3;
     */
    this.email = "";
    /**
     * @generated from field: string name = 4;
     */
    this.name = "";
    /**
     * @generated from field: string code = 5;
     */
    this.code = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AcceptedChat_Patient().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AcceptedChat_Patient().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AcceptedChat_Patient().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AcceptedChat_Patient, a, b);
  }
}
AcceptedChat_Patient.runtime = proto3;
AcceptedChat_Patient.typeName = "GoThrive.AcceptedChat.Patient";
AcceptedChat_Patient.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "userId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "code",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.AcceptedChat.Organisation
 */
export class AcceptedChat_Organisation extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string name = 1;
     */
    this.name = "";
    /**
     * @generated from field: bool triage = 2;
     */
    this.triage = false;
    /**
     * @generated from field: bool chatEnabled = 3;
     */
    this.chatEnabled = false;
    /**
     * @generated from field: string sharedCareProtocolLink = 4;
     */
    this.sharedCareProtocolLink = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AcceptedChat_Organisation().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AcceptedChat_Organisation().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AcceptedChat_Organisation().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AcceptedChat_Organisation, a, b);
  }
}
AcceptedChat_Organisation.runtime = proto3;
AcceptedChat_Organisation.typeName = "GoThrive.AcceptedChat.Organisation";
AcceptedChat_Organisation.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "triage",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 3,
  name: "chatEnabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 4,
  name: "sharedCareProtocolLink",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.AcceptedChats
 */
export class AcceptedChats extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.AcceptedChat entries = 1;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AcceptedChats().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AcceptedChats().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AcceptedChats().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AcceptedChats, a, b);
  }
}
AcceptedChats.runtime = proto3;
AcceptedChats.typeName = "GoThrive.AcceptedChats";
AcceptedChats.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "message",
  T: AcceptedChat,
  repeated: true
}]);
/**
 * @generated from message GoThrive.AcceptedChatParams
 */
export class AcceptedChatParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool pubNub = 3;
     */
    this.pubNub = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AcceptedChatParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AcceptedChatParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AcceptedChatParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AcceptedChatParams, a, b);
  }
}
AcceptedChatParams.runtime = proto3;
AcceptedChatParams.typeName = "GoThrive.AcceptedChatParams";
AcceptedChatParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "skip",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 2,
  name: "limit",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 3,
  name: "pubNub",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.TherapistInviteUrlParams
 */
export class TherapistInviteUrlParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string patientId = 1;
     */
    this.patientId = "";
    /**
     * @generated from field: string therapistId = 2;
     */
    this.therapistId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TherapistInviteUrlParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TherapistInviteUrlParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TherapistInviteUrlParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TherapistInviteUrlParams, a, b);
  }
}
TherapistInviteUrlParams.runtime = proto3;
TherapistInviteUrlParams.typeName = "GoThrive.TherapistInviteUrlParams";
TherapistInviteUrlParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "patientId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "therapistId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.TherapistInviteBody
 */
export class TherapistInviteBody extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string content = 1;
     */
    this.content = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TherapistInviteBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TherapistInviteBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TherapistInviteBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TherapistInviteBody, a, b);
  }
}
TherapistInviteBody.runtime = proto3;
TherapistInviteBody.typeName = "GoThrive.TherapistInviteBody";
TherapistInviteBody.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "content",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);