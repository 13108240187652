// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file menuScreen.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, BottomBar2, ImageIcon, NavigationBar } from "./common_pb.js";
/**
 * @generated from message GoThrive.MenuScreen
 */
export class MenuScreen extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 5;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.MenuScreenOption data = 6;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MenuScreen().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MenuScreen().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MenuScreen().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MenuScreen, a, b);
  }
}
MenuScreen.runtime = proto3;
MenuScreen.typeName = "GoThrive.MenuScreen";
MenuScreen.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 3,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 4,
  name: "bottomBar",
  kind: "message",
  T: BottomBar2
}, {
  no: 5,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 6,
  name: "data",
  kind: "message",
  T: MenuScreenOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.MenuScreenOption
 */
export class MenuScreenOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MenuScreenOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MenuScreenOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MenuScreenOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MenuScreenOption, a, b);
  }
}
MenuScreenOption.runtime = proto3;
MenuScreenOption.typeName = "GoThrive.MenuScreenOption";
MenuScreenOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.MenuScreenCatalog
 */
export class MenuScreenCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.MenuScreen> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MenuScreenCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MenuScreenCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MenuScreenCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MenuScreenCatalog, a, b);
  }
}
MenuScreenCatalog.runtime = proto3;
MenuScreenCatalog.typeName = "GoThrive.MenuScreenCatalog";
MenuScreenCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: MenuScreen
  }
}]);
/**
 * @generated from message GoThrive.ChapterDetail
 */
export class ChapterDetail extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string title = 4;
     */
    this.title = "";
    /**
     * @generated from field: string body = 5;
     */
    this.body = "";
    /**
     * @generated from field: string style = 6;
     */
    this.style = "";
    /**
     * @generated from field: repeated string image = 7;
     */
    this.image = [];
    /**
     * @generated from field: string subheading = 8;
     */
    this.subheading = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChapterDetail().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChapterDetail().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChapterDetail().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChapterDetail, a, b);
  }
}
ChapterDetail.runtime = proto3;
ChapterDetail.typeName = "GoThrive.ChapterDetail";
ChapterDetail.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "primaryImage",
  kind: "message",
  T: ImageIcon
}, {
  no: 3,
  name: "secondaryImage",
  kind: "message",
  T: ImageIcon
}, {
  no: 4,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "body",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 8,
  name: "subheading",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "overlayView",
  kind: "message",
  T: OverlayView
}]);
/**
 * @generated from message GoThrive.OverlayView
 */
export class OverlayView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string text = 2;
     */
    this.text = "";
    /**
     * @generated from field: string style = 4;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OverlayView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OverlayView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OverlayView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OverlayView, a, b);
  }
}
OverlayView.runtime = proto3;
OverlayView.typeName = "GoThrive.OverlayView";
OverlayView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "text",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "image",
  kind: "message",
  T: ImageIcon
}, {
  no: 4,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);