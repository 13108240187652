// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file chat.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { PubNubChannels, PubNubChatResponse } from "./pubNub_pb.js";
import { ChatRequest, ChatRoom } from "./chatRequest_pb.js";
import { Therapists } from "./therapists_pb.js";
/**
 * @generated from enum GoThrive.ChatStatusType
 */
export var ChatStatusType;
(function (ChatStatusType) {
  /**
   * @generated from enum value: UNAVAILABLE = 0;
   */
  ChatStatusType[ChatStatusType["UNAVAILABLE"] = 0] = "UNAVAILABLE";
  /**
   * @generated from enum value: AVAILABLE = 1;
   */
  ChatStatusType[ChatStatusType["AVAILABLE"] = 1] = "AVAILABLE";
})(ChatStatusType || (ChatStatusType = {}));
// Retrieve enum metadata with: proto3.getEnumType(ChatStatusType)
proto3.util.setEnumType(ChatStatusType, "GoThrive.ChatStatusType", [{
  no: 0,
  name: "UNAVAILABLE"
}, {
  no: 1,
  name: "AVAILABLE"
}]);
/**
 * @generated from enum GoThrive.ChatOperationType
 */
export var ChatOperationType;
(function (ChatOperationType) {
  /**
   * @generated from enum value: OPEN = 0;
   */
  ChatOperationType[ChatOperationType["OPEN"] = 0] = "OPEN";
  /**
   * @generated from enum value: MESSAGE_DATA = 1;
   */
  ChatOperationType[ChatOperationType["MESSAGE_DATA"] = 1] = "MESSAGE_DATA";
  /**
   * @generated from enum value: TYPING_DATA = 2;
   */
  ChatOperationType[ChatOperationType["TYPING_DATA"] = 2] = "TYPING_DATA";
  /**
   * @generated from enum value: MESSAGE_RANGE = 3;
   */
  ChatOperationType[ChatOperationType["MESSAGE_RANGE"] = 3] = "MESSAGE_RANGE";
})(ChatOperationType || (ChatOperationType = {}));
// Retrieve enum metadata with: proto3.getEnumType(ChatOperationType)
proto3.util.setEnumType(ChatOperationType, "GoThrive.ChatOperationType", [{
  no: 0,
  name: "OPEN"
}, {
  no: 1,
  name: "MESSAGE_DATA"
}, {
  no: 2,
  name: "TYPING_DATA"
}, {
  no: 3,
  name: "MESSAGE_RANGE"
}]);
/**
 * @generated from message GoThrive.ChatOperationMessageData
 */
export class ChatOperationMessageData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sender = 1;
     */
    this.sender = "";
    /**
     * @generated from field: string message = 2;
     */
    this.message = "";
    /**
     * @generated from field: int32 timestamp = 3;
     */
    this.timestamp = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatOperationMessageData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatOperationMessageData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatOperationMessageData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatOperationMessageData, a, b);
  }
}
ChatOperationMessageData.runtime = proto3;
ChatOperationMessageData.typeName = "GoThrive.ChatOperationMessageData";
ChatOperationMessageData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sender",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "timestamp",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.ChatOperationMessageRangeData
 */
export class ChatOperationMessageRangeData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 messageCount = 1;
     */
    this.messageCount = 0;
    /**
     * @generated from field: repeated GoThrive.ChatOperationMessageData messages = 2;
     */
    this.messages = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatOperationMessageRangeData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatOperationMessageRangeData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatOperationMessageRangeData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatOperationMessageRangeData, a, b);
  }
}
ChatOperationMessageRangeData.runtime = proto3;
ChatOperationMessageRangeData.typeName = "GoThrive.ChatOperationMessageRangeData";
ChatOperationMessageRangeData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "messageCount",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "messages",
  kind: "message",
  T: ChatOperationMessageData,
  repeated: true
}]);
/**
 * @generated from message GoThrive.ChatOperationStatusData
 */
export class ChatOperationStatusData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string target = 1;
     */
    this.target = "";
    /**
     * @generated from field: GoThrive.ChatStatusType status = 2;
     */
    this.status = ChatStatusType.UNAVAILABLE;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatOperationStatusData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatOperationStatusData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatOperationStatusData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatOperationStatusData, a, b);
  }
}
ChatOperationStatusData.runtime = proto3;
ChatOperationStatusData.typeName = "GoThrive.ChatOperationStatusData";
ChatOperationStatusData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "target",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "status",
  kind: "enum",
  T: proto3.getEnumType(ChatStatusType)
}]);
/**
 * @generated from message GoThrive.ChatOperationTypingData
 */
export class ChatOperationTypingData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string target = 1;
     */
    this.target = "";
    /**
     * @generated from field: int32 timeout = 2;
     */
    this.timeout = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatOperationTypingData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatOperationTypingData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatOperationTypingData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatOperationTypingData, a, b);
  }
}
ChatOperationTypingData.runtime = proto3;
ChatOperationTypingData.typeName = "GoThrive.ChatOperationTypingData";
ChatOperationTypingData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "target",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "timeout",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.CommandChatOperation
 */
export class CommandChatOperation extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: GoThrive.ChatOperationType chatOperation = 2;
     */
    this.chatOperation = ChatOperationType.OPEN;
    /**
     * @generated from field: bytes operationData = 3;
     */
    this.operationData = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatOperation().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatOperation().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatOperation().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatOperation, a, b);
  }
}
CommandChatOperation.runtime = proto3;
CommandChatOperation.typeName = "GoThrive.CommandChatOperation";
CommandChatOperation.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "chatOperation",
  kind: "enum",
  T: proto3.getEnumType(ChatOperationType)
}, {
  no: 3,
  name: "operationData",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.CommandEnterChat
 */
export class CommandEnterChat extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandEnterChat().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandEnterChat().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandEnterChat().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandEnterChat, a, b);
  }
}
CommandEnterChat.runtime = proto3;
CommandEnterChat.typeName = "GoThrive.CommandEnterChat";
CommandEnterChat.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandExitChat
 */
export class CommandExitChat extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandExitChat().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandExitChat().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandExitChat().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandExitChat, a, b);
  }
}
CommandExitChat.runtime = proto3;
CommandExitChat.typeName = "GoThrive.CommandExitChat";
CommandExitChat.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChatHistoryEntry
 */
export class ChatHistoryEntry extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string From = 1;
     */
    this.From = "";
    /**
     * @generated from field: string Msg = 2;
     */
    this.Msg = "";
    /**
     * @generated from field: int64 time = 3;
     */
    this.time = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatHistoryEntry().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatHistoryEntry().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatHistoryEntry().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatHistoryEntry, a, b);
  }
}
ChatHistoryEntry.runtime = proto3;
ChatHistoryEntry.typeName = "GoThrive.ChatHistoryEntry";
ChatHistoryEntry.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "From",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Msg",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "time",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 4,
  name: "username",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}]);
/**
 * @generated from message GoThrive.ChatHistory
 */
export class ChatHistory extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.ChatHistoryEntry> history = 1;
     */
    this.history = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatHistory().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatHistory().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatHistory().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatHistory, a, b);
  }
}
ChatHistory.runtime = proto3;
ChatHistory.typeName = "GoThrive.ChatHistory";
ChatHistory.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "history",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: ChatHistoryEntry
  }
}]);
/**
 * @generated from message GoThrive.TherapistChatHistoryUrlParams
 */
export class TherapistChatHistoryUrlParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string PatientId = 1;
     */
    this.PatientId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TherapistChatHistoryUrlParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TherapistChatHistoryUrlParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TherapistChatHistoryUrlParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TherapistChatHistoryUrlParams, a, b);
  }
}
TherapistChatHistoryUrlParams.runtime = proto3;
TherapistChatHistoryUrlParams.typeName = "GoThrive.TherapistChatHistoryUrlParams";
TherapistChatHistoryUrlParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "PatientId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.TherapistChatHistoryQueryParams
 */
export class TherapistChatHistoryQueryParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string userId = 1;
     */
    this.userId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TherapistChatHistoryQueryParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TherapistChatHistoryQueryParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TherapistChatHistoryQueryParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TherapistChatHistoryQueryParams, a, b);
  }
}
TherapistChatHistoryQueryParams.runtime = proto3;
TherapistChatHistoryQueryParams.typeName = "GoThrive.TherapistChatHistoryQueryParams";
TherapistChatHistoryQueryParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "userId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChatPatient
 */
export class ChatPatient extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Id = 1;
     */
    this.Id = "";
    /**
     * @generated from field: string UserId = 2;
     */
    this.UserId = "";
    /**
     * @generated from field: string Email = 3;
     */
    this.Email = "";
    /**
     * @generated from field: string Name = 4;
     */
    this.Name = "";
    /**
     * @generated from field: string Code = 5;
     */
    this.Code = "";
    /**
     * @generated from field: string CodeEnabled = 6;
     */
    this.CodeEnabled = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatPatient().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatPatient().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatPatient().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatPatient, a, b);
  }
}
ChatPatient.runtime = proto3;
ChatPatient.typeName = "GoThrive.ChatPatient";
ChatPatient.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "UserId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "Email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "Name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "Code",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "CodeEnabled",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChatInfoSummary
 */
export class ChatInfoSummary extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Id = 1;
     */
    this.Id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatInfoSummary().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatInfoSummary().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatInfoSummary().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatInfoSummary, a, b);
  }
}
ChatInfoSummary.runtime = proto3;
ChatInfoSummary.typeName = "GoThrive.ChatInfoSummary";
ChatInfoSummary.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Patient",
  kind: "message",
  T: ChatPatient
}]);
/**
 * @generated from message GoThrive.ChatOrganisation
 */
export class ChatOrganisation extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Name = 1;
     */
    this.Name = "";
    /**
     * @generated from field: string Triage = 2;
     */
    this.Triage = "";
    /**
     * @generated from field: string ChatEnabled = 3;
     */
    this.ChatEnabled = "";
    /**
     * @generated from field: string SharedCareProtocolLink = 4;
     */
    this.SharedCareProtocolLink = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatOrganisation().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatOrganisation().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatOrganisation().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatOrganisation, a, b);
  }
}
ChatOrganisation.runtime = proto3;
ChatOrganisation.typeName = "GoThrive.ChatOrganisation";
ChatOrganisation.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Triage",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "ChatEnabled",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "SharedCareProtocolLink",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChatInfo
 */
export class ChatInfo extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Id = 1;
     */
    this.Id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatInfo().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatInfo().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatInfo().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatInfo, a, b);
  }
}
ChatInfo.runtime = proto3;
ChatInfo.typeName = "GoThrive.ChatInfo";
ChatInfo.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Patient",
  kind: "message",
  T: ChatPatient
}, {
  no: 3,
  name: "Organisation",
  kind: "message",
  T: ChatOrganisation
}]);
/**
 * @generated from message GoThrive.ChatInfoUrlParams
 */
export class ChatInfoUrlParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string ChatSessionId = 1;
     */
    this.ChatSessionId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatInfoUrlParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatInfoUrlParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatInfoUrlParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatInfoUrlParams, a, b);
  }
}
ChatInfoUrlParams.runtime = proto3;
ChatInfoUrlParams.typeName = "GoThrive.ChatInfoUrlParams";
ChatInfoUrlParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "ChatSessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChatMessageUrlParams
 */
export class ChatMessageUrlParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string chatSessionId = 1;
     */
    this.chatSessionId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatMessageUrlParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatMessageUrlParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatMessageUrlParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatMessageUrlParams, a, b);
  }
}
ChatMessageUrlParams.runtime = proto3;
ChatMessageUrlParams.typeName = "GoThrive.ChatMessageUrlParams";
ChatMessageUrlParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "chatSessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "messageId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}]);
/**
 * @generated from message GoThrive.ChatMarkAsReadUrlParams
 */
export class ChatMarkAsReadUrlParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string chatSessionId = 1;
     */
    this.chatSessionId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatMarkAsReadUrlParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatMarkAsReadUrlParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatMarkAsReadUrlParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatMarkAsReadUrlParams, a, b);
  }
}
ChatMarkAsReadUrlParams.runtime = proto3;
ChatMarkAsReadUrlParams.typeName = "GoThrive.ChatMarkAsReadUrlParams";
ChatMarkAsReadUrlParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "chatSessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChatMarkAsTypingUrlParams
 */
export class ChatMarkAsTypingUrlParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string chatSessionId = 1;
     */
    this.chatSessionId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatMarkAsTypingUrlParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatMarkAsTypingUrlParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatMarkAsTypingUrlParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatMarkAsTypingUrlParams, a, b);
  }
}
ChatMarkAsTypingUrlParams.runtime = proto3;
ChatMarkAsTypingUrlParams.typeName = "GoThrive.ChatMarkAsTypingUrlParams";
ChatMarkAsTypingUrlParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "chatSessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChatMarkAsTypingParams
 */
export class ChatMarkAsTypingParams extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatMarkAsTypingParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatMarkAsTypingParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatMarkAsTypingParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatMarkAsTypingParams, a, b);
  }
}
ChatMarkAsTypingParams.runtime = proto3;
ChatMarkAsTypingParams.typeName = "GoThrive.ChatMarkAsTypingParams";
ChatMarkAsTypingParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "isTyping",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */,
  opt: true
}]);
/**
 * @generated from message GoThrive.ChatMessageReply
 */
export class ChatMessageReply extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string messageId = 1;
     */
    this.messageId = "";
    /**
     * @generated from field: string messageText = 2;
     */
    this.messageText = "";
    /**
     * @generated from field: string messageAuthorId = 3;
     */
    this.messageAuthorId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatMessageReply().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatMessageReply().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatMessageReply().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatMessageReply, a, b);
  }
}
ChatMessageReply.runtime = proto3;
ChatMessageReply.typeName = "GoThrive.ChatMessageReply";
ChatMessageReply.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "messageId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "messageText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "messageAuthorId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChatUserMeta
 */
export class ChatUserMeta extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string userId = 1;
     */
    this.userId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatUserMeta().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatUserMeta().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatUserMeta().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatUserMeta, a, b);
  }
}
ChatUserMeta.runtime = proto3;
ChatUserMeta.typeName = "GoThrive.ChatUserMeta";
ChatUserMeta.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "userId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChatMetaControl
 */
export class ChatMetaControl extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool requestChat = 1;
     */
    this.requestChat = false;
    /**
     * @generated from field: string correctedTime = 2;
     */
    this.correctedTime = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatMetaControl().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatMetaControl().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatMetaControl().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatMetaControl, a, b);
  }
}
ChatMetaControl.runtime = proto3;
ChatMetaControl.typeName = "GoThrive.ChatMetaControl";
ChatMetaControl.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "requestChat",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 2,
  name: "correctedTime",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatSendMessage
 */
export class CommandChatSendMessage extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string message = 1;
     */
    this.message = "";
    /**
     * @generated from field: string clientTimeToken = 5;
     */
    this.clientTimeToken = "";
    /**
     * @generated from field: bool resend = 6;
     */
    this.resend = false;
    /**
     * @generated from field: int64 messageId = 7;
     */
    this.messageId = protoInt64.zero;
    /**
     * @generated from field: string platform = 8;
     */
    this.platform = "";
    /**
     * @generated from field: string channel = 9;
     */
    this.channel = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatSendMessage().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatSendMessage().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatSendMessage().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatSendMessage, a, b);
  }
}
CommandChatSendMessage.runtime = proto3;
CommandChatSendMessage.typeName = "GoThrive.CommandChatSendMessage";
CommandChatSendMessage.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "reply",
  kind: "message",
  T: ChatMessageReply,
  opt: true
}, {
  no: 3,
  name: "chatUserMeta",
  kind: "message",
  T: ChatUserMeta,
  opt: true
}, {
  no: 4,
  name: "chatMetaControl",
  kind: "message",
  T: ChatMetaControl,
  opt: true
}, {
  no: 5,
  name: "clientTimeToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "resend",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 7,
  name: "messageId",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 8,
  name: "platform",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "channel",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatSendMessageTUB
 */
export class CommandChatSendMessageTUB extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string message = 1;
     */
    this.message = "";
    /**
     * @generated from field: string clientTs = 3;
     */
    this.clientTs = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatSendMessageTUB().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatSendMessageTUB().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatSendMessageTUB().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatSendMessageTUB, a, b);
  }
}
CommandChatSendMessageTUB.runtime = proto3;
CommandChatSendMessageTUB.typeName = "GoThrive.CommandChatSendMessageTUB";
CommandChatSendMessageTUB.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "inReplyTo",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 3,
  name: "clientTs",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatDeleteMessageTUB
 */
export class CommandChatDeleteMessageTUB extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int64 clientTs = 1;
     */
    this.clientTs = protoInt64.zero;
    /**
     * @generated from field: string channel = 2;
     */
    this.channel = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatDeleteMessageTUB().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatDeleteMessageTUB().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatDeleteMessageTUB().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatDeleteMessageTUB, a, b);
  }
}
CommandChatDeleteMessageTUB.runtime = proto3;
CommandChatDeleteMessageTUB.typeName = "GoThrive.CommandChatDeleteMessageTUB";
CommandChatDeleteMessageTUB.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "clientTs",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 2,
  name: "channel",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatSendMessageResultTUB
 */
export class CommandChatSendMessageResultTUB extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string timeToken = 1;
     */
    this.timeToken = "";
    /**
     * @generated from field: string messageId = 2;
     */
    this.messageId = "";
    /**
     * @generated from field: string channel = 3;
     */
    this.channel = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatSendMessageResultTUB().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatSendMessageResultTUB().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatSendMessageResultTUB().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatSendMessageResultTUB, a, b);
  }
}
CommandChatSendMessageResultTUB.runtime = proto3;
CommandChatSendMessageResultTUB.typeName = "GoThrive.CommandChatSendMessageResultTUB";
CommandChatSendMessageResultTUB.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "timeToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "messageId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "channel",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatEditMessage
 */
export class CommandChatEditMessage extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string messageId = 1;
     */
    this.messageId = "";
    /**
     * @generated from field: string messageText = 2;
     */
    this.messageText = "";
    /**
     * @generated from field: string channel = 3;
     */
    this.channel = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatEditMessage().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatEditMessage().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatEditMessage().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatEditMessage, a, b);
  }
}
CommandChatEditMessage.runtime = proto3;
CommandChatEditMessage.typeName = "GoThrive.CommandChatEditMessage";
CommandChatEditMessage.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "messageId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "messageText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "channel",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatDeleteMessage
 */
export class CommandChatDeleteMessage extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string messageId = 1;
     */
    this.messageId = "";
    /**
     * @generated from field: string channel = 2;
     */
    this.channel = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatDeleteMessage().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatDeleteMessage().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatDeleteMessage().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatDeleteMessage, a, b);
  }
}
CommandChatDeleteMessage.runtime = proto3;
CommandChatDeleteMessage.typeName = "GoThrive.CommandChatDeleteMessage";
CommandChatDeleteMessage.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "messageId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "channel",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatBegin
 */
export class CommandChatBegin extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Message = 1;
     */
    this.Message = "";
    /**
     * @generated from field: string Origin = 2;
     */
    this.Origin = "";
    /**
     * @generated from field: bool reInit = 4;
     */
    this.reInit = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatBegin().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatBegin().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatBegin().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatBegin, a, b);
  }
}
CommandChatBegin.runtime = proto3;
CommandChatBegin.typeName = "GoThrive.CommandChatBegin";
CommandChatBegin.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Origin",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "chatResponse",
  kind: "message",
  T: PubNubChatResponse
}, {
  no: 4,
  name: "reInit",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.CommandChatEnd
 */
export class CommandChatEnd extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatEnd().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatEnd().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatEnd().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatEnd, a, b);
  }
}
CommandChatEnd.runtime = proto3;
CommandChatEnd.typeName = "GoThrive.CommandChatEnd";
CommandChatEnd.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message GoThrive.CommandChatExists
 */
export class CommandChatExists extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string chatId = 1;
     */
    this.chatId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatExists().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatExists().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatExists().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatExists, a, b);
  }
}
CommandChatExists.runtime = proto3;
CommandChatExists.typeName = "GoThrive.CommandChatExists";
CommandChatExists.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "chatId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatRequest
 */
export class CommandChatRequest extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Message = 1;
     */
    this.Message = "";
    /**
     * @generated from field: string Origin = 2;
     */
    this.Origin = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatRequest, a, b);
  }
}
CommandChatRequest.runtime = proto3;
CommandChatRequest.typeName = "GoThrive.CommandChatRequest";
CommandChatRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Origin",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "chatResponse",
  kind: "message",
  T: PubNubChatResponse
}]);
/**
 * @generated from message GoThrive.CommandChatAccept
 */
export class CommandChatAccept extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string ChatId = 1;
     */
    this.ChatId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatAccept().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatAccept().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatAccept().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatAccept, a, b);
  }
}
CommandChatAccept.runtime = proto3;
CommandChatAccept.typeName = "GoThrive.CommandChatAccept";
CommandChatAccept.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "ChatId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "chatResponse",
  kind: "message",
  T: PubNubChatResponse
}]);
/**
 * @generated from message GoThrive.CommandChatAcceptRequest
 */
export class CommandChatAcceptRequest extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string RequestID = 1;
     */
    this.RequestID = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatAcceptRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatAcceptRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatAcceptRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatAcceptRequest, a, b);
  }
}
CommandChatAcceptRequest.runtime = proto3;
CommandChatAcceptRequest.typeName = "GoThrive.CommandChatAcceptRequest";
CommandChatAcceptRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "RequestID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "chatRoom",
  kind: "message",
  T: ChatRoom
}]);
/**
 * @generated from message GoThrive.CommandChatRequests
 */
export class CommandChatRequests extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.ChatRequest chatRequests = 1;
     */
    this.chatRequests = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatRequests().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatRequests().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatRequests().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatRequests, a, b);
  }
}
CommandChatRequests.runtime = proto3;
CommandChatRequests.typeName = "GoThrive.CommandChatRequests";
CommandChatRequests.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "chatRequests",
  kind: "message",
  T: ChatRequest,
  repeated: true
}]);
/**
 * @generated from message GoThrive.CommandChatSetActiveChannel
 */
export class CommandChatSetActiveChannel extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string ChatId = 1;
     */
    this.ChatId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatSetActiveChannel().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatSetActiveChannel().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatSetActiveChannel().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatSetActiveChannel, a, b);
  }
}
CommandChatSetActiveChannel.runtime = proto3;
CommandChatSetActiveChannel.typeName = "GoThrive.CommandChatSetActiveChannel";
CommandChatSetActiveChannel.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "ChatId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "chatResponse",
  kind: "message",
  T: PubNubChatResponse
}]);
/**
 * @generated from message GoThrive.CommandChatRequestEmergency
 */
export class CommandChatRequestEmergency extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatRequestEmergency().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatRequestEmergency().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatRequestEmergency().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatRequestEmergency, a, b);
  }
}
CommandChatRequestEmergency.runtime = proto3;
CommandChatRequestEmergency.typeName = "GoThrive.CommandChatRequestEmergency";
CommandChatRequestEmergency.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message GoThrive.CommandChatSetPush
 */
export class CommandChatSetPush extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Token = 1;
     */
    this.Token = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatSetPush().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatSetPush().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatSetPush().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatSetPush, a, b);
  }
}
CommandChatSetPush.runtime = proto3;
CommandChatSetPush.typeName = "GoThrive.CommandChatSetPush";
CommandChatSetPush.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Token",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatSetLastRead
 */
export class CommandChatSetLastRead extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string channel = 1;
     */
    this.channel = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatSetLastRead().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatSetLastRead().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatSetLastRead().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatSetLastRead, a, b);
  }
}
CommandChatSetLastRead.runtime = proto3;
CommandChatSetLastRead.typeName = "GoThrive.CommandChatSetLastRead";
CommandChatSetLastRead.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "channel",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatTherapistInvite
 */
export class CommandChatTherapistInvite extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string PatientId = 1;
     */
    this.PatientId = "";
    /**
     * @generated from field: string TherapistId = 2;
     */
    this.TherapistId = "";
    /**
     * @generated from field: string Message = 3;
     */
    this.Message = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatTherapistInvite().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatTherapistInvite().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatTherapistInvite().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatTherapistInvite, a, b);
  }
}
CommandChatTherapistInvite.runtime = proto3;
CommandChatTherapistInvite.typeName = "GoThrive.CommandChatTherapistInvite";
CommandChatTherapistInvite.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "PatientId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "TherapistId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "Message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatLostFocus
 */
export class CommandChatLostFocus extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatLostFocus().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatLostFocus().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatLostFocus().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatLostFocus, a, b);
  }
}
CommandChatLostFocus.runtime = proto3;
CommandChatLostFocus.typeName = "GoThrive.CommandChatLostFocus";
CommandChatLostFocus.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message GoThrive.CommandChatRestoreFocus
 */
export class CommandChatRestoreFocus extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatRestoreFocus().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatRestoreFocus().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatRestoreFocus().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatRestoreFocus, a, b);
  }
}
CommandChatRestoreFocus.runtime = proto3;
CommandChatRestoreFocus.typeName = "GoThrive.CommandChatRestoreFocus";
CommandChatRestoreFocus.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message GoThrive.CommandChatEligibleTherapists
 */
export class CommandChatEligibleTherapists extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string PatientId = 1;
     */
    this.PatientId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatEligibleTherapists().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatEligibleTherapists().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatEligibleTherapists().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatEligibleTherapists, a, b);
  }
}
CommandChatEligibleTherapists.runtime = proto3;
CommandChatEligibleTherapists.typeName = "GoThrive.CommandChatEligibleTherapists";
CommandChatEligibleTherapists.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "PatientId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Therapists",
  kind: "message",
  T: Therapists
}]);
/**
 * @generated from message GoThrive.CommandChatChannels
 */
export class CommandChatChannels extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatChannels().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatChannels().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatChannels().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatChannels, a, b);
  }
}
CommandChatChannels.runtime = proto3;
CommandChatChannels.typeName = "GoThrive.CommandChatChannels";
CommandChatChannels.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "channels",
  kind: "message",
  T: PubNubChannels
}]);
/**
 * @generated from message GoThrive.CommandChatDeletePush
 */
export class CommandChatDeletePush extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Token = 1;
     */
    this.Token = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatDeletePush().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatDeletePush().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatDeletePush().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatDeletePush, a, b);
  }
}
CommandChatDeletePush.runtime = proto3;
CommandChatDeletePush.typeName = "GoThrive.CommandChatDeletePush";
CommandChatDeletePush.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Token",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatTypingOn
 */
export class CommandChatTypingOn extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string channel = 1;
     */
    this.channel = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatTypingOn().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatTypingOn().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatTypingOn().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatTypingOn, a, b);
  }
}
CommandChatTypingOn.runtime = proto3;
CommandChatTypingOn.typeName = "GoThrive.CommandChatTypingOn";
CommandChatTypingOn.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "channel",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatMessageRemoveAction
 */
export class CommandChatMessageRemoveAction extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string MessageId = 1;
     */
    this.MessageId = "";
    /**
     * @generated from field: string ActionId = 2;
     */
    this.ActionId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatMessageRemoveAction().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatMessageRemoveAction().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatMessageRemoveAction().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatMessageRemoveAction, a, b);
  }
}
CommandChatMessageRemoveAction.runtime = proto3;
CommandChatMessageRemoveAction.typeName = "GoThrive.CommandChatMessageRemoveAction";
CommandChatMessageRemoveAction.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "MessageId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "ActionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatMessageAddAction
 */
export class CommandChatMessageAddAction extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string MessageId = 1;
     */
    this.MessageId = "";
    /**
     * @generated from field: string ActionType = 2;
     */
    this.ActionType = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatMessageAddAction().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatMessageAddAction().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatMessageAddAction().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatMessageAddAction, a, b);
  }
}
CommandChatMessageAddAction.runtime = proto3;
CommandChatMessageAddAction.typeName = "GoThrive.CommandChatMessageAddAction";
CommandChatMessageAddAction.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "MessageId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "ActionType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatTypingOff
 */
export class CommandChatTypingOff extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string channel = 1;
     */
    this.channel = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatTypingOff().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatTypingOff().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatTypingOff().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatTypingOff, a, b);
  }
}
CommandChatTypingOff.runtime = proto3;
CommandChatTypingOff.typeName = "GoThrive.CommandChatTypingOff";
CommandChatTypingOff.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "channel",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatMessages
 */
export class CommandChatMessages extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string channel = 1;
     */
    this.channel = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatMessages().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatMessages().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatMessages().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatMessages, a, b);
  }
}
CommandChatMessages.runtime = proto3;
CommandChatMessages.typeName = "GoThrive.CommandChatMessages";
CommandChatMessages.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "channel",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatLeave
 */
export class CommandChatLeave extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string ChatId = 1;
     */
    this.ChatId = "";
    /**
     * @generated from field: string PatientId = 2;
     */
    this.PatientId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatLeave().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatLeave().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatLeave().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatLeave, a, b);
  }
}
CommandChatLeave.runtime = proto3;
CommandChatLeave.typeName = "GoThrive.CommandChatLeave";
CommandChatLeave.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "ChatId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "PatientId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatDischarge
 */
export class CommandChatDischarge extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string ChatId = 1;
     */
    this.ChatId = "";
    /**
     * @generated from field: string PatientId = 2;
     */
    this.PatientId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatDischarge().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatDischarge().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatDischarge().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatDischarge, a, b);
  }
}
CommandChatDischarge.runtime = proto3;
CommandChatDischarge.typeName = "GoThrive.CommandChatDischarge";
CommandChatDischarge.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "ChatId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "PatientId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChatNotify
 */
export class CommandChatNotify extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Message = 1;
     */
    this.Message = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChatNotify().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChatNotify().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChatNotify().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChatNotify, a, b);
  }
}
CommandChatNotify.runtime = proto3;
CommandChatNotify.typeName = "GoThrive.CommandChatNotify";
CommandChatNotify.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChatCommands
 */
export class ChatCommands extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.ChatCommand> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatCommands().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatCommands().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatCommands().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatCommands, a, b);
  }
}
ChatCommands.runtime = proto3;
ChatCommands.typeName = "GoThrive.ChatCommands";
ChatCommands.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: ChatCommand
  }
}]);
/**
 * @generated from message GoThrive.ChatCommand
 */
export class ChatCommand extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Name = 1;
     */
    this.Name = "";
    /**
     * @generated from field: string Command = 2;
     */
    this.Command = "";
    /**
     * @generated from field: string Scope = 3;
     */
    this.Scope = "";
    /**
     * @generated from field: repeated string Params = 4;
     */
    this.Params = [];
    /**
     * @generated from field: string Action = 5;
     */
    this.Action = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatCommand().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatCommand().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatCommand().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatCommand, a, b);
  }
}
ChatCommand.runtime = proto3;
ChatCommand.typeName = "GoThrive.ChatCommand";
ChatCommand.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Command",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "Scope",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "Params",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 5,
  name: "Action",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);