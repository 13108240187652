// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file mentorScreen.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, BottomBar2, ImageIcon, NavigationBar, TabView } from "./common_pb.js";
/**
 * @generated from message GoThrive.MentorScreen
 */
export class MentorScreen extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 5;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.MentorScreenOption data = 6;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MentorScreen().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MentorScreen().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MentorScreen().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MentorScreen, a, b);
  }
}
MentorScreen.runtime = proto3;
MentorScreen.typeName = "GoThrive.MentorScreen";
MentorScreen.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 3,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 4,
  name: "bottomBar",
  kind: "message",
  T: BottomBar2
}, {
  no: 5,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 6,
  name: "data",
  kind: "message",
  T: MentorScreenOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.MentorScreenOption
 */
export class MentorScreenOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MentorScreenOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MentorScreenOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MentorScreenOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MentorScreenOption, a, b);
  }
}
MentorScreenOption.runtime = proto3;
MentorScreenOption.typeName = "GoThrive.MentorScreenOption";
MentorScreenOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.MentorScreenCatalog
 */
export class MentorScreenCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.MentorScreen> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MentorScreenCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MentorScreenCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MentorScreenCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MentorScreenCatalog, a, b);
  }
}
MentorScreenCatalog.runtime = proto3;
MentorScreenCatalog.typeName = "GoThrive.MentorScreenCatalog";
MentorScreenCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: MentorScreen
  }
}]);
/**
 * @generated from message GoThrive.GoalInfo
 */
export class GoalInfo extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string completedSubtitle = 3;
     */
    this.completedSubtitle = "";
    /**
     * @generated from field: string uncompletedSubtitle = 5;
     */
    this.uncompletedSubtitle = "";
    /**
     * @generated from field: string previousResult = 7;
     */
    this.previousResult = "";
    /**
     * @generated from field: string style = 9;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new GoalInfo().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new GoalInfo().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new GoalInfo().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(GoalInfo, a, b);
  }
}
GoalInfo.runtime = proto3;
GoalInfo.typeName = "GoThrive.GoalInfo";
GoalInfo.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "completedSubtitle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "completedIcon",
  kind: "message",
  T: ImageIcon
}, {
  no: 5,
  name: "uncompletedSubtitle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "uncompletedIcon",
  kind: "message",
  T: ImageIcon
}, {
  no: 7,
  name: "previousResult",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "tabView",
  kind: "message",
  T: TabView
}, {
  no: 9,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.GoalList
 */
export class GoalList extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: repeated GoThrive.GoalInfo goalInfos = 2;
     */
    this.goalInfos = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new GoalList().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new GoalList().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new GoalList().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(GoalList, a, b);
  }
}
GoalList.runtime = proto3;
GoalList.typeName = "GoThrive.GoalList";
GoalList.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "goalInfos",
  kind: "message",
  T: GoalInfo,
  repeated: true
}]);