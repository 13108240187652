// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file messageAlerts.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.MessageAlerts
 */
export class MessageAlerts extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.MessageAlerts.Msg Messages = 1;
     */
    this.Messages = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MessageAlerts().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MessageAlerts().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MessageAlerts().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MessageAlerts, a, b);
  }
}
MessageAlerts.runtime = proto3;
MessageAlerts.typeName = "GoThrive.MessageAlerts";
MessageAlerts.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Messages",
  kind: "message",
  T: MessageAlerts_Msg,
  repeated: true
}, {
  no: 2,
  name: "ForceUpdate",
  kind: "message",
  T: MessageAlerts_forceUpdate
}]);
/**
 * @generated from message GoThrive.MessageAlerts.Msg
 */
export class MessageAlerts_Msg extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Title = 1;
     */
    this.Title = "";
    /**
     * @generated from field: string MessageText = 2;
     */
    this.MessageText = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MessageAlerts_Msg().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MessageAlerts_Msg().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MessageAlerts_Msg().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MessageAlerts_Msg, a, b);
  }
}
MessageAlerts_Msg.runtime = proto3;
MessageAlerts_Msg.typeName = "GoThrive.MessageAlerts.Msg";
MessageAlerts_Msg.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "MessageText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.MessageAlerts.forceUpdate
 */
export class MessageAlerts_forceUpdate extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 Latest = 1;
     */
    this.Latest = 0;
    /**
     * @generated from field: int32 Required = 2;
     */
    this.Required = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MessageAlerts_forceUpdate().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MessageAlerts_forceUpdate().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MessageAlerts_forceUpdate().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MessageAlerts_forceUpdate, a, b);
  }
}
MessageAlerts_forceUpdate.runtime = proto3;
MessageAlerts_forceUpdate.typeName = "GoThrive.MessageAlerts.forceUpdate";
MessageAlerts_forceUpdate.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Latest",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "Required",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.MessageAlertsParams
 */
export class MessageAlertsParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string version = 1;
     */
    this.version = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MessageAlertsParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MessageAlertsParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MessageAlertsParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MessageAlertsParams, a, b);
  }
}
MessageAlertsParams.runtime = proto3;
MessageAlertsParams.typeName = "GoThrive.MessageAlertsParams";
MessageAlertsParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "version",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.MessageAlertBody
 */
export class MessageAlertBody extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string message = 3;
     */
    this.message = "";
    /**
     * @generated from field: int64 startDate = 4;
     */
    this.startDate = protoInt64.zero;
    /**
     * @generated from field: int64 endDate = 5;
     */
    this.endDate = protoInt64.zero;
    /**
     * @generated from field: repeated string clients = 6;
     */
    this.clients = [];
    /**
     * @generated from field: bool includeClones = 7;
     */
    this.includeClones = false;
    /**
     * @generated from field: int32 minVersion = 8;
     */
    this.minVersion = 0;
    /**
     * @generated from field: int32 maxVersion = 9;
     */
    this.maxVersion = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MessageAlertBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MessageAlertBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MessageAlertBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MessageAlertBody, a, b);
  }
}
MessageAlertBody.runtime = proto3;
MessageAlertBody.typeName = "GoThrive.MessageAlertBody";
MessageAlertBody.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "startDate",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 5,
  name: "endDate",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 6,
  name: "clients",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 7,
  name: "includeClones",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 8,
  name: "minVersion",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 9,
  name: "maxVersion",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.MessageAlertsAdmin
 */
export class MessageAlertsAdmin extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.MessageAlertBody entries = 1;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MessageAlertsAdmin().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MessageAlertsAdmin().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MessageAlertsAdmin().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MessageAlertsAdmin, a, b);
  }
}
MessageAlertsAdmin.runtime = proto3;
MessageAlertsAdmin.typeName = "GoThrive.MessageAlertsAdmin";
MessageAlertsAdmin.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "message",
  T: MessageAlertBody,
  repeated: true
}]);
/**
 * @generated from message GoThrive.MessageAlertsUrlParams
 */
export class MessageAlertsUrlParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string documentRef = 1;
     */
    this.documentRef = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MessageAlertsUrlParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MessageAlertsUrlParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MessageAlertsUrlParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MessageAlertsUrlParams, a, b);
  }
}
MessageAlertsUrlParams.runtime = proto3;
MessageAlertsUrlParams.typeName = "GoThrive.MessageAlertsUrlParams";
MessageAlertsUrlParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "documentRef",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);