// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file cbt.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { MoodEntries } from "./dailyCheckup_pb.js";
/**
 * @generated from message GoThrive.Cbt
 */
export class Cbt extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string type = 1;
     */
    this.type = "";
    /**
     * @generated from field: int32 timestamp = 2;
     */
    this.timestamp = 0;
    /**
     * @generated from field: string id = 3;
     */
    this.id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Cbt().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Cbt().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Cbt().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Cbt, a, b);
  }
}
Cbt.runtime = proto3;
Cbt.typeName = "GoThrive.Cbt";
Cbt.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "timestamp",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "data",
  kind: "message",
  T: Cbt_Data
}]);
/**
 * @generated from message GoThrive.Cbt.Data
 */
export class Cbt_Data extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string type = 5;
     */
    this.type = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Cbt_Data().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Cbt_Data().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Cbt_Data().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Cbt_Data, a, b);
  }
}
Cbt_Data.runtime = proto3;
Cbt_Data.typeName = "GoThrive.Cbt.Data";
Cbt_Data.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "duration",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 2,
  name: "CbtCore",
  kind: "message",
  T: Cbt_Data_cbtCore
}, {
  no: 3,
  name: "PatchTimestamp",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */,
  opt: true
}, {
  no: 4,
  name: "timestamp",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */,
  opt: true
}, {
  no: 5,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.Cbt.Data.cbtCore
 */
export class Cbt_Data_cbtCore extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated string AlternativeThoughts = 1;
     */
    this.AlternativeThoughts = [];
    /**
     * @generated from field: repeated GoThrive.Cbt.Data.cbtCore.detailedSituationEntry DetailedSituationEntries = 2;
     */
    this.DetailedSituationEntries = [];
    /**
     * @generated from field: repeated string EvidenceAgainst = 3;
     */
    this.EvidenceAgainst = [];
    /**
     * @generated from field: repeated string EvidenceFor = 4;
     */
    this.EvidenceFor = [];
    /**
     * @generated from field: repeated GoThrive.MoodEntries MoodEntries = 6;
     */
    this.MoodEntries = [];
    /**
     * @generated from field: string Situation = 7;
     */
    this.Situation = "";
    /**
     * @generated from field: string SituationLocalisationKey = 8;
     */
    this.SituationLocalisationKey = "";
    /**
     * @generated from field: repeated string Thoughts = 9;
     */
    this.Thoughts = [];
    /**
     * @generated from field: string FuzzNetType = 10;
     */
    this.FuzzNetType = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Cbt_Data_cbtCore().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Cbt_Data_cbtCore().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Cbt_Data_cbtCore().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Cbt_Data_cbtCore, a, b);
  }
}
Cbt_Data_cbtCore.runtime = proto3;
Cbt_Data_cbtCore.typeName = "GoThrive.Cbt.Data.cbtCore";
Cbt_Data_cbtCore.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "AlternativeThoughts",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 2,
  name: "DetailedSituationEntries",
  kind: "message",
  T: Cbt_Data_cbtCore_detailedSituationEntry,
  repeated: true
}, {
  no: 3,
  name: "EvidenceAgainst",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 4,
  name: "EvidenceFor",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 5,
  name: "HotThought",
  kind: "message",
  T: Cbt_Data_cbtCore_hotThought,
  opt: true
}, {
  no: 6,
  name: "MoodEntries",
  kind: "message",
  T: MoodEntries,
  repeated: true
}, {
  no: 7,
  name: "Situation",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "SituationLocalisationKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "Thoughts",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 10,
  name: "FuzzNetType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.Cbt.Data.cbtCore.hotThought
 */
export class Cbt_Data_cbtCore_hotThought extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Thought = 3;
     */
    this.Thought = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Cbt_Data_cbtCore_hotThought().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Cbt_Data_cbtCore_hotThought().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Cbt_Data_cbtCore_hotThought().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Cbt_Data_cbtCore_hotThought, a, b);
  }
}
Cbt_Data_cbtCore_hotThought.runtime = proto3;
Cbt_Data_cbtCore_hotThought.typeName = "GoThrive.Cbt.Data.cbtCore.hotThought";
Cbt_Data_cbtCore_hotThought.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Belief",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */,
  opt: true
}, {
  no: 2,
  name: "RevisedBelief",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */,
  opt: true
}, {
  no: 3,
  name: "Thought",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.Cbt.Data.cbtCore.detailedSituationEntry
 */
export class Cbt_Data_cbtCore_detailedSituationEntry extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string DetailedSituation = 1;
     */
    this.DetailedSituation = "";
    /**
     * @generated from field: string FuzzNetType = 3;
     */
    this.FuzzNetType = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Cbt_Data_cbtCore_detailedSituationEntry().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Cbt_Data_cbtCore_detailedSituationEntry().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Cbt_Data_cbtCore_detailedSituationEntry().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Cbt_Data_cbtCore_detailedSituationEntry, a, b);
  }
}
Cbt_Data_cbtCore_detailedSituationEntry.runtime = proto3;
Cbt_Data_cbtCore_detailedSituationEntry.typeName = "GoThrive.Cbt.Data.cbtCore.detailedSituationEntry";
Cbt_Data_cbtCore_detailedSituationEntry.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "DetailedSituation",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "SensitiveContent",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 3,
  name: "FuzzNetType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "IsSensitive",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */,
  opt: true
}]);
/**
 * @generated from message GoThrive.Cbts
 */
export class Cbts extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.Cbt entries = 1;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Cbts().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Cbts().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Cbts().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Cbts, a, b);
  }
}
Cbts.runtime = proto3;
Cbts.typeName = "GoThrive.Cbts";
Cbts.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "message",
  T: Cbt,
  repeated: true
}]);
/**
 * @generated from message GoThrive.HotThoughtMoments
 */
export class HotThoughtMoments extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string lastDoc = 1;
     */
    this.lastDoc = "";
    /**
     * @generated from field: repeated GoThrive.Cbt moments = 2;
     */
    this.moments = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new HotThoughtMoments().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new HotThoughtMoments().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new HotThoughtMoments().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(HotThoughtMoments, a, b);
  }
}
HotThoughtMoments.runtime = proto3;
HotThoughtMoments.typeName = "GoThrive.HotThoughtMoments";
HotThoughtMoments.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "lastDoc",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "moments",
  kind: "message",
  T: Cbt,
  repeated: true
}]);