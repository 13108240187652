// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file momentsTimestamps.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.MomentsTimestamps
 */
export class MomentsTimestamps extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated int64 entries = 1;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MomentsTimestamps().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MomentsTimestamps().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MomentsTimestamps().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MomentsTimestamps, a, b);
  }
}
MomentsTimestamps.runtime = proto3;
MomentsTimestamps.typeName = "GoThrive.MomentsTimestamps";
MomentsTimestamps.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */,
  repeated: true
}]);