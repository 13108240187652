// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file helpSeekingSupportScreen.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, NavigationBar } from "./common_pb.js";
/**
 * @generated from message GoThrive.HelpSeekingSupportScreen
 */
export class HelpSeekingSupportScreen extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 5;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.HelpSeekingSupportScreenOption data = 6;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new HelpSeekingSupportScreen().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new HelpSeekingSupportScreen().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new HelpSeekingSupportScreen().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(HelpSeekingSupportScreen, a, b);
  }
}
HelpSeekingSupportScreen.runtime = proto3;
HelpSeekingSupportScreen.typeName = "GoThrive.HelpSeekingSupportScreen";
HelpSeekingSupportScreen.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 3,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 5,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 6,
  name: "data",
  kind: "message",
  T: HelpSeekingSupportScreenOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.HelpSeekingSupportScreenOption
 */
export class HelpSeekingSupportScreenOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new HelpSeekingSupportScreenOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new HelpSeekingSupportScreenOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new HelpSeekingSupportScreenOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(HelpSeekingSupportScreenOption, a, b);
  }
}
HelpSeekingSupportScreenOption.runtime = proto3;
HelpSeekingSupportScreenOption.typeName = "GoThrive.HelpSeekingSupportScreenOption";
HelpSeekingSupportScreenOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.HelpSeekingSupportScreenCatalog
 */
export class HelpSeekingSupportScreenCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.HelpSeekingSupportScreen> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new HelpSeekingSupportScreenCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new HelpSeekingSupportScreenCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new HelpSeekingSupportScreenCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(HelpSeekingSupportScreenCatalog, a, b);
  }
}
HelpSeekingSupportScreenCatalog.runtime = proto3;
HelpSeekingSupportScreenCatalog.typeName = "GoThrive.HelpSeekingSupportScreenCatalog";
HelpSeekingSupportScreenCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: HelpSeekingSupportScreen
  }
}]);