// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file cloudDocuments.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64, Struct } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.CloudDocument
 */
export class CloudDocument extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string name = 1;
     */
    this.name = "";
    /**
     * @generated from field: int64 timestamp = 2;
     */
    this.timestamp = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CloudDocument().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CloudDocument().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CloudDocument().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CloudDocument, a, b);
  }
}
CloudDocument.runtime = proto3;
CloudDocument.typeName = "GoThrive.CloudDocument";
CloudDocument.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "timestamp",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 3,
  name: "content",
  kind: "message",
  T: Struct
}, {
  no: 4,
  name: "dataStructureVersion",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}]);
/**
 * @generated from message GoThrive.CloudDocumentBody
 */
export class CloudDocumentBody extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CloudDocumentBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CloudDocumentBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CloudDocumentBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CloudDocumentBody, a, b);
  }
}
CloudDocumentBody.runtime = proto3;
CloudDocumentBody.typeName = "GoThrive.CloudDocumentBody";
CloudDocumentBody.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "message",
  T: Struct
}]);
/**
 * @generated from message GoThrive.CloudDocumentUrlParams
 */
export class CloudDocumentUrlParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string name = 1;
     */
    this.name = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CloudDocumentUrlParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CloudDocumentUrlParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CloudDocumentUrlParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CloudDocumentUrlParams, a, b);
  }
}
CloudDocumentUrlParams.runtime = proto3;
CloudDocumentUrlParams.typeName = "GoThrive.CloudDocumentUrlParams";
CloudDocumentUrlParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CloudDocumentParams
 */
export class CloudDocumentParams extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CloudDocumentParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CloudDocumentParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CloudDocumentParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CloudDocumentParams, a, b);
  }
}
CloudDocumentParams.runtime = proto3;
CloudDocumentParams.typeName = "GoThrive.CloudDocumentParams";
CloudDocumentParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "dataStructureVersion",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  opt: true
}, {
  no: 2,
  name: "timestamp",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */,
  opt: true
}]);