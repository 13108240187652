// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file assessmentResultScreen.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, BottomBar2, Button, ImageIcon, NavigationBar } from "./common_pb.js";
/**
 * @generated from message GoThrive.AssessmentResultScreen
 */
export class AssessmentResultScreen extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 5;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.AssessmentResultScreenOption data = 6;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentResultScreen().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentResultScreen().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentResultScreen().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentResultScreen, a, b);
  }
}
AssessmentResultScreen.runtime = proto3;
AssessmentResultScreen.typeName = "GoThrive.AssessmentResultScreen";
AssessmentResultScreen.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 3,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 4,
  name: "bottomBar",
  kind: "message",
  T: BottomBar2
}, {
  no: 5,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 6,
  name: "data",
  kind: "message",
  T: AssessmentResultScreenOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.AssessmentResultScreenOption
 */
export class AssessmentResultScreenOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentResultScreenOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentResultScreenOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentResultScreenOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentResultScreenOption, a, b);
  }
}
AssessmentResultScreenOption.runtime = proto3;
AssessmentResultScreenOption.typeName = "GoThrive.AssessmentResultScreenOption";
AssessmentResultScreenOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.AssessmentResultScreenScoreView
 */
export class AssessmentResultScreenScoreView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string title = 3;
     */
    this.title = "";
    /**
     * @generated from field: string detail = 4;
     */
    this.detail = "";
    /**
     * @generated from field: string scoreType = 5;
     */
    this.scoreType = "";
    /**
     * @generated from field: string style = 7;
     */
    this.style = "";
    /**
     * @generated from field: repeated GoThrive.AssessmentResultScreenScoreScale scale = 8;
     */
    this.scale = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentResultScreenScoreView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentResultScreenScoreView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentResultScreenScoreView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentResultScreenScoreView, a, b);
  }
}
AssessmentResultScreenScoreView.runtime = proto3;
AssessmentResultScreenScoreView.typeName = "GoThrive.AssessmentResultScreenScoreView";
AssessmentResultScreenScoreView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "icon",
  kind: "message",
  T: ImageIcon
}, {
  no: 3,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "detail",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "scoreType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "button",
  kind: "message",
  T: Button
}, {
  no: 7,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "scale",
  kind: "message",
  T: AssessmentResultScreenScoreScale,
  repeated: true
}]);
/**
 * @generated from message GoThrive.AssessmentResultScreenScoreScale
 */
export class AssessmentResultScreenScoreScale extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string score_type = 2;
     */
    this.scoreType = "";
    /**
     * @generated from field: string score = 3;
     */
    this.score = "";
    /**
     * @generated from field: string asset = 4;
     */
    this.asset = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentResultScreenScoreScale().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentResultScreenScoreScale().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentResultScreenScoreScale().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentResultScreenScoreScale, a, b);
  }
}
AssessmentResultScreenScoreScale.runtime = proto3;
AssessmentResultScreenScoreScale.typeName = "GoThrive.AssessmentResultScreenScoreScale";
AssessmentResultScreenScoreScale.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "score_type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "score",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "asset",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.HelpLinkScale
 */
export class HelpLinkScale extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string score = 2;
     */
    this.score = "";
    /**
     * @generated from field: string prefix = 3;
     */
    this.prefix = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new HelpLinkScale().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new HelpLinkScale().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new HelpLinkScale().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(HelpLinkScale, a, b);
  }
}
HelpLinkScale.runtime = proto3;
HelpLinkScale.typeName = "GoThrive.HelpLinkScale";
HelpLinkScale.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "score",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "prefix",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.AssessmentResultScreenAdditionalSupportView
 */
export class AssessmentResultScreenAdditionalSupportView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string header = 2;
     */
    this.header = "";
    /**
     * @generated from field: string addressStyle = 3;
     */
    this.addressStyle = "";
    /**
     * @generated from field: string buttonStyle = 4;
     */
    this.buttonStyle = "";
    /**
     * @generated from field: string style = 5;
     */
    this.style = "";
    /**
     * @generated from field: repeated GoThrive.HelpLinkScale helpLinks = 6;
     */
    this.helpLinks = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentResultScreenAdditionalSupportView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentResultScreenAdditionalSupportView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentResultScreenAdditionalSupportView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentResultScreenAdditionalSupportView, a, b);
  }
}
AssessmentResultScreenAdditionalSupportView.runtime = proto3;
AssessmentResultScreenAdditionalSupportView.typeName = "GoThrive.AssessmentResultScreenAdditionalSupportView";
AssessmentResultScreenAdditionalSupportView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "header",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "addressStyle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "buttonStyle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "helpLinks",
  kind: "message",
  T: HelpLinkScale,
  repeated: true
}]);
/**
 * @generated from message GoThrive.AssessmentResultScreenRecommendationsView
 */
export class AssessmentResultScreenRecommendationsView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string header = 2;
     */
    this.header = "";
    /**
     * @generated from field: string style = 3;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentResultScreenRecommendationsView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentResultScreenRecommendationsView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentResultScreenRecommendationsView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentResultScreenRecommendationsView, a, b);
  }
}
AssessmentResultScreenRecommendationsView.runtime = proto3;
AssessmentResultScreenRecommendationsView.typeName = "GoThrive.AssessmentResultScreenRecommendationsView";
AssessmentResultScreenRecommendationsView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "header",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.AssessmentResultScreenGoalsView
 */
export class AssessmentResultScreenGoalsView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string header = 2;
     */
    this.header = "";
    /**
     * @generated from field: string style = 3;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentResultScreenGoalsView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentResultScreenGoalsView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentResultScreenGoalsView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentResultScreenGoalsView, a, b);
  }
}
AssessmentResultScreenGoalsView.runtime = proto3;
AssessmentResultScreenGoalsView.typeName = "GoThrive.AssessmentResultScreenGoalsView";
AssessmentResultScreenGoalsView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "header",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.AssessmentResultScreenAnswerView
 */
export class AssessmentResultScreenAnswerView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string header = 2;
     */
    this.header = "";
    /**
     * @generated from field: string style = 3;
     */
    this.style = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentResultScreenAnswerView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentResultScreenAnswerView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentResultScreenAnswerView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentResultScreenAnswerView, a, b);
  }
}
AssessmentResultScreenAnswerView.runtime = proto3;
AssessmentResultScreenAnswerView.typeName = "GoThrive.AssessmentResultScreenAnswerView";
AssessmentResultScreenAnswerView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "header",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CustomResultScreenType
 */
export class CustomResultScreenType extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string typeId = 2;
     */
    this.typeId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CustomResultScreenType().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CustomResultScreenType().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CustomResultScreenType().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CustomResultScreenType, a, b);
  }
}
CustomResultScreenType.runtime = proto3;
CustomResultScreenType.typeName = "GoThrive.CustomResultScreenType";
CustomResultScreenType.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "typeId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.AssessmentResultScreenCatalog
 */
export class AssessmentResultScreenCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.AssessmentResultScreen> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentResultScreenCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentResultScreenCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentResultScreenCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentResultScreenCatalog, a, b);
  }
}
AssessmentResultScreenCatalog.runtime = proto3;
AssessmentResultScreenCatalog.typeName = "GoThrive.AssessmentResultScreenCatalog";
AssessmentResultScreenCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: AssessmentResultScreen
  }
}]);