// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file coreBeliefsScreen.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, BottomBar2, CustomInput, ExerciseOptionRow, NavigationBar, NavigationBar2, TableStyle } from "./common_pb.js";
/**
 * @generated from message GoThrive.CoreBeliefsScreen
 */
export class CoreBeliefsScreen extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 5;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.CoreBeliefsScreenOption data = 6;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CoreBeliefsScreen().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CoreBeliefsScreen().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CoreBeliefsScreen().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CoreBeliefsScreen, a, b);
  }
}
CoreBeliefsScreen.runtime = proto3;
CoreBeliefsScreen.typeName = "GoThrive.CoreBeliefsScreen";
CoreBeliefsScreen.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 3,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 4,
  name: "bottomBar",
  kind: "message",
  T: BottomBar2
}, {
  no: 5,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 6,
  name: "data",
  kind: "message",
  T: CoreBeliefsScreenOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.CoreBeliefsScreenOption
 */
export class CoreBeliefsScreenOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CoreBeliefsScreenOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CoreBeliefsScreenOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CoreBeliefsScreenOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CoreBeliefsScreenOption, a, b);
  }
}
CoreBeliefsScreenOption.runtime = proto3;
CoreBeliefsScreenOption.typeName = "GoThrive.CoreBeliefsScreenOption";
CoreBeliefsScreenOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.CoreBeliefCardView
 */
export class CoreBeliefCardView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string header = 2;
     */
    this.header = "";
    /**
     * @generated from field: string lockText = 3;
     */
    this.lockText = "";
    /**
     * @generated from field: string unlockText = 4;
     */
    this.unlockText = "";
    /**
     * @generated from field: string lockAsset = 5;
     */
    this.lockAsset = "";
    /**
     * @generated from field: string style = 6;
     */
    this.style = "";
    /**
     * @generated from field: repeated string optionKeys = 7;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.CoreBeliefCardViewOption data = 8;
     */
    this.data = [];
    /**
     * @generated from field: repeated GoThrive.CustomInput customInputs = 9;
     */
    this.customInputs = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CoreBeliefCardView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CoreBeliefCardView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CoreBeliefCardView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CoreBeliefCardView, a, b);
  }
}
CoreBeliefCardView.runtime = proto3;
CoreBeliefCardView.typeName = "GoThrive.CoreBeliefCardView";
CoreBeliefCardView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "header",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "lockText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "unlockText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "lockAsset",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 8,
  name: "data",
  kind: "message",
  T: CoreBeliefCardViewOption,
  repeated: true
}, {
  no: 9,
  name: "customInputs",
  kind: "message",
  T: CustomInput,
  repeated: true
}]);
/**
 * @generated from message GoThrive.CoreBeliefCardViewOption
 */
export class CoreBeliefCardViewOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CoreBeliefCardViewOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CoreBeliefCardViewOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CoreBeliefCardViewOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CoreBeliefCardViewOption, a, b);
  }
}
CoreBeliefCardViewOption.runtime = proto3;
CoreBeliefCardViewOption.typeName = "GoThrive.CoreBeliefCardViewOption";
CoreBeliefCardViewOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.ChooseQuestion
 */
export class ChooseQuestion extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string placeholder = 2;
     */
    this.placeholder = "";
    /**
     * @generated from field: string style = 4;
     */
    this.style = "";
    /**
     * @generated from field: int32 selectedQuestion = 5;
     */
    this.selectedQuestion = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChooseQuestion().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChooseQuestion().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChooseQuestion().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChooseQuestion, a, b);
  }
}
ChooseQuestion.runtime = proto3;
ChooseQuestion.typeName = "GoThrive.ChooseQuestion";
ChooseQuestion.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "placeholder",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "detailView",
  kind: "message",
  T: ChooseQuestionDetailView
}, {
  no: 4,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "selectedQuestion",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.ChooseQuestionDetailView
 */
export class ChooseQuestionDetailView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string style = 4;
     */
    this.style = "";
    /**
     * @generated from field: repeated GoThrive.ExerciseOptionRow questions = 6;
     */
    this.questions = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChooseQuestionDetailView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChooseQuestionDetailView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChooseQuestionDetailView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChooseQuestionDetailView, a, b);
  }
}
ChooseQuestionDetailView.runtime = proto3;
ChooseQuestionDetailView.typeName = "GoThrive.ChooseQuestionDetailView";
ChooseQuestionDetailView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar2
}, {
  no: 3,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 4,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "tableStyle",
  kind: "message",
  T: TableStyle
}, {
  no: 6,
  name: "questions",
  kind: "message",
  T: ExerciseOptionRow,
  repeated: true
}]);
/**
 * @generated from message GoThrive.CoreBeliefsScreenCatalog
 */
export class CoreBeliefsScreenCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.CoreBeliefsScreen> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CoreBeliefsScreenCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CoreBeliefsScreenCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CoreBeliefsScreenCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CoreBeliefsScreenCatalog, a, b);
  }
}
CoreBeliefsScreenCatalog.runtime = proto3;
CoreBeliefsScreenCatalog.typeName = "GoThrive.CoreBeliefsScreenCatalog";
CoreBeliefsScreenCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: CoreBeliefsScreen
  }
}]);