// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file fileUploadPolicy.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.FileUploadPolicy
 */
export class FileUploadPolicy extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string url = 1;
     */
    this.url = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new FileUploadPolicy().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new FileUploadPolicy().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new FileUploadPolicy().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(FileUploadPolicy, a, b);
  }
}
FileUploadPolicy.runtime = proto3;
FileUploadPolicy.typeName = "GoThrive.FileUploadPolicy";
FileUploadPolicy.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "url",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "formData",
  kind: "message",
  T: FileUploadPolicy_FormData
}]);
/**
 * @generated from message GoThrive.FileUploadPolicy.FormData
 */
export class FileUploadPolicy_FormData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string GoogleAccessId = 2;
     */
    this.GoogleAccessId = "";
    /**
     * @generated from field: string policy = 3;
     */
    this.policy = "";
    /**
     * @generated from field: string signature = 4;
     */
    this.signature = "";
    /**
     * @generated from field: string x_goog_meta_moment = 5 [json_name = "x-goog-meta-moment"];
     */
    this.xGoogMetaMoment = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new FileUploadPolicy_FormData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new FileUploadPolicy_FormData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new FileUploadPolicy_FormData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(FileUploadPolicy_FormData, a, b);
  }
}
FileUploadPolicy_FormData.runtime = proto3;
FileUploadPolicy_FormData.typeName = "GoThrive.FileUploadPolicy.FormData";
FileUploadPolicy_FormData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "GoogleAccessId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "policy",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "signature",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "x_goog_meta_moment",
  jsonName: "x-goog-meta-moment",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);