// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file smartCard.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.SmartCard
 */
export class SmartCard extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    /**
     * @generated from field: string titleToken = 3;
     */
    this.titleToken = "";
    /**
     * @generated from field: string subtitle = 4;
     */
    this.subtitle = "";
    /**
     * @generated from field: bool isNewMoment = 5;
     */
    this.isNewMoment = false;
    /**
     * @generated from field: repeated string largeBackground = 6;
     */
    this.largeBackground = [];
    /**
     * @generated from field: string mediumBackground = 7;
     */
    this.mediumBackground = "";
    /**
     * @generated from field: string smallBackground = 8;
     */
    this.smallBackground = "";
    /**
     * @generated from field: string momentStyle = 9;
     */
    this.momentStyle = "";
    /**
     * @generated from field: string milestoneIcon = 10;
     */
    this.milestoneIcon = "";
    /**
     * @generated from field: string milestoneAnimation = 11;
     */
    this.milestoneAnimation = "";
    /**
     * @generated from field: string milestoneDescription = 12;
     */
    this.milestoneDescription = "";
    /**
     * @generated from field: string milestoneClickAnimation = 13;
     */
    this.milestoneClickAnimation = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new SmartCard().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new SmartCard().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new SmartCard().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(SmartCard, a, b);
  }
}
SmartCard.runtime = proto3;
SmartCard.typeName = "GoThrive.SmartCard";
SmartCard.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "titleToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "subtitle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "isNewMoment",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 6,
  name: "largeBackground",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 7,
  name: "mediumBackground",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "smallBackground",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "momentStyle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 10,
  name: "milestoneIcon",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 11,
  name: "milestoneAnimation",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 12,
  name: "milestoneDescription",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 13,
  name: "milestoneClickAnimation",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.SmartCardCatalog
 */
export class SmartCardCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.SmartCard> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new SmartCardCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new SmartCardCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new SmartCardCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(SmartCardCatalog, a, b);
  }
}
SmartCardCatalog.runtime = proto3;
SmartCardCatalog.typeName = "GoThrive.SmartCardCatalog";
SmartCardCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: SmartCard
  }
}]);