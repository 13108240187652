// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file goCoreUnity.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.GoCoreUnityEntry
 */
export class GoCoreUnityEntry extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string GoCoreType = 1;
     */
    this.GoCoreType = "";
    /**
     * @generated from field: string UnityType = 2;
     */
    this.UnityType = "";
    /**
     * @generated from field: string FuzzType = 3;
     */
    this.FuzzType = "";
    /**
     * @generated from field: string SubFuzzType = 4;
     */
    this.SubFuzzType = "";
    /**
     * @generated from field: string TextAdaptor = 5;
     */
    this.TextAdaptor = "";
    /**
     * @generated from field: string TextAdaptorVal = 6;
     */
    this.TextAdaptorVal = "";
    /**
     * @generated from field: string TextAdaptorExtraVal = 7;
     */
    this.TextAdaptorExtraVal = "";
    /**
     * @generated from field: string NumAdaptor = 8;
     */
    this.NumAdaptor = "";
    /**
     * @generated from field: string NumAdaptorVal = 9;
     */
    this.NumAdaptorVal = "";
    /**
     * @generated from field: string MomentTitle = 10;
     */
    this.MomentTitle = "";
    /**
     * @generated from field: string LookupField = 11;
     */
    this.LookupField = "";
    /**
     * @generated from field: string Icon = 12;
     */
    this.Icon = "";
    /**
     * @generated from field: string IconBackground = 13;
     */
    this.IconBackground = "";
    /**
     * @generated from field: bool Hide = 14;
     */
    this.Hide = false;
    /**
     * @generated from field: string SmartCardId = 15;
     */
    this.SmartCardId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new GoCoreUnityEntry().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new GoCoreUnityEntry().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new GoCoreUnityEntry().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(GoCoreUnityEntry, a, b);
  }
}
GoCoreUnityEntry.runtime = proto3;
GoCoreUnityEntry.typeName = "GoThrive.GoCoreUnityEntry";
GoCoreUnityEntry.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "GoCoreType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "UnityType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "FuzzType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "SubFuzzType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "TextAdaptor",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "TextAdaptorVal",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "TextAdaptorExtraVal",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "NumAdaptor",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "NumAdaptorVal",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 10,
  name: "MomentTitle",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 11,
  name: "LookupField",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 12,
  name: "Icon",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 13,
  name: "IconBackground",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 14,
  name: "Hide",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 15,
  name: "SmartCardId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.GoCoreUnity
 */
export class GoCoreUnity extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.GoCoreUnityEntry> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new GoCoreUnity().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new GoCoreUnity().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new GoCoreUnity().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(GoCoreUnity, a, b);
  }
}
GoCoreUnity.runtime = proto3;
GoCoreUnity.typeName = "GoThrive.GoCoreUnity";
GoCoreUnity.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: GoCoreUnityEntry
  }
}]);
/**
 * @generated from message GoThrive.EntryTable
 */
export class EntryTable extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: int32 value = 2;
     */
    this.value = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new EntryTable().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new EntryTable().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new EntryTable().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(EntryTable, a, b);
  }
}
EntryTable.runtime = proto3;
EntryTable.typeName = "GoThrive.EntryTable";
EntryTable.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "value",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.DurationTable
 */
export class DurationTable extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.EntryTable> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new DurationTable().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new DurationTable().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new DurationTable().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(DurationTable, a, b);
  }
}
DurationTable.runtime = proto3;
DurationTable.typeName = "GoThrive.DurationTable";
DurationTable.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: EntryTable
  }
}]);