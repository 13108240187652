// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file momentsParams.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.MomentsParams
 */
export class MomentsParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: uint64 to = 2;
     */
    this.to = protoInt64.zero;
    /**
     * @generated from field: string lastDoc = 3;
     */
    this.lastDoc = "";
    /**
     * @generated from field: int32 limit = 4;
     */
    this.limit = 0;
    /**
     * @generated from field: int32 count = 5;
     */
    this.count = 0;
    /**
     * @generated from field: string momentType = 6;
     */
    this.momentType = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MomentsParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MomentsParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MomentsParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MomentsParams, a, b);
  }
}
MomentsParams.runtime = proto3;
MomentsParams.typeName = "GoThrive.MomentsParams";
MomentsParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "from",
  kind: "scalar",
  T: 4 /* ScalarType.UINT64 */,
  opt: true
}, {
  no: 2,
  name: "to",
  kind: "scalar",
  T: 4 /* ScalarType.UINT64 */
}, {
  no: 3,
  name: "lastDoc",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "limit",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 5,
  name: "count",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 6,
  name: "momentType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);