// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file batchTimestampsParams.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.BatchTimestampsParams
 */
export class BatchTimestampsParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated string eventType = 1;
     */
    this.eventType = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new BatchTimestampsParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new BatchTimestampsParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new BatchTimestampsParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(BatchTimestampsParams, a, b);
  }
}
BatchTimestampsParams.runtime = proto3;
BatchTimestampsParams.typeName = "GoThrive.BatchTimestampsParams";
BatchTimestampsParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "eventType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);