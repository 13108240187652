// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file totp.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.TOTPChallengeResponse
 */
export class TOTPChallengeResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string token = 1;
     */
    this.token = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TOTPChallengeResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TOTPChallengeResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TOTPChallengeResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TOTPChallengeResponse, a, b);
  }
}
TOTPChallengeResponse.runtime = proto3;
TOTPChallengeResponse.typeName = "GoThrive.TOTPChallengeResponse";
TOTPChallengeResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "token",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.TOTPChallengeResponseBody
 */
export class TOTPChallengeResponseBody extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string code = 1;
     */
    this.code = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TOTPChallengeResponseBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TOTPChallengeResponseBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TOTPChallengeResponseBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TOTPChallengeResponseBody, a, b);
  }
}
TOTPChallengeResponseBody.runtime = proto3;
TOTPChallengeResponseBody.typeName = "GoThrive.TOTPChallengeResponseBody";
TOTPChallengeResponseBody.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "code",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.TOTPSecret
 */
export class TOTPSecret extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Id = 1;
     */
    this.Id = "";
    /**
     * @generated from field: string Label = 2;
     */
    this.Label = "";
    /**
     * @generated from field: int64 Timestamp = 3;
     */
    this.Timestamp = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TOTPSecret().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TOTPSecret().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TOTPSecret().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TOTPSecret, a, b);
  }
}
TOTPSecret.runtime = proto3;
TOTPSecret.typeName = "GoThrive.TOTPSecret";
TOTPSecret.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "Label",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "Timestamp",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);
/**
 * @generated from message GoThrive.TOTPSecrets
 */
export class TOTPSecrets extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.TOTPSecret entries = 1;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TOTPSecrets().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TOTPSecrets().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TOTPSecrets().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TOTPSecrets, a, b);
  }
}
TOTPSecrets.runtime = proto3;
TOTPSecrets.typeName = "GoThrive.TOTPSecrets";
TOTPSecrets.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "message",
  T: TOTPSecret,
  repeated: true
}]);
/**
 * @generated from message GoThrive.TOTPSecretsBody
 */
export class TOTPSecretsBody extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string secret = 1;
     */
    this.secret = "";
    /**
     * @generated from field: string code = 2;
     */
    this.code = "";
    /**
     * @generated from field: string label = 3;
     */
    this.label = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TOTPSecretsBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TOTPSecretsBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TOTPSecretsBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TOTPSecretsBody, a, b);
  }
}
TOTPSecretsBody.runtime = proto3;
TOTPSecretsBody.typeName = "GoThrive.TOTPSecretsBody";
TOTPSecretsBody.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "secret",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "code",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "label",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.TOTPSecretsUrlParams
 */
export class TOTPSecretsUrlParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Id = 1;
     */
    this.Id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TOTPSecretsUrlParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TOTPSecretsUrlParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TOTPSecretsUrlParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TOTPSecretsUrlParams, a, b);
  }
}
TOTPSecretsUrlParams.runtime = proto3;
TOTPSecretsUrlParams.typeName = "GoThrive.TOTPSecretsUrlParams";
TOTPSecretsUrlParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.TOTPSecretsPutBody
 */
export class TOTPSecretsPutBody extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Label = 1;
     */
    this.Label = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TOTPSecretsPutBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TOTPSecretsPutBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TOTPSecretsPutBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TOTPSecretsPutBody, a, b);
  }
}
TOTPSecretsPutBody.runtime = proto3;
TOTPSecretsPutBody.typeName = "GoThrive.TOTPSecretsPutBody";
TOTPSecretsPutBody.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Label",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);