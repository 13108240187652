// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file changeEmailAddress.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.ChangeEmailAddress
 */
export class ChangeEmailAddress extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChangeEmailAddress().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChangeEmailAddress().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChangeEmailAddress().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChangeEmailAddress, a, b);
  }
}
ChangeEmailAddress.runtime = proto3;
ChangeEmailAddress.typeName = "GoThrive.ChangeEmailAddress";
ChangeEmailAddress.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);