// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file batchTimestamps.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.BatchTimestamp
 */
export class BatchTimestamp extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string eventType = 1;
     */
    this.eventType = "";
    /**
     * @generated from field: repeated int64 timestamps = 2;
     */
    this.timestamps = [];
    /**
     * @generated from field: int32 errorCode = 3;
     */
    this.errorCode = 0;
    /**
     * @generated from field: string errorMessage = 4;
     */
    this.errorMessage = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new BatchTimestamp().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new BatchTimestamp().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new BatchTimestamp().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(BatchTimestamp, a, b);
  }
}
BatchTimestamp.runtime = proto3;
BatchTimestamp.typeName = "GoThrive.BatchTimestamp";
BatchTimestamp.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "eventType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "timestamps",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */,
  repeated: true
}, {
  no: 3,
  name: "errorCode",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "errorMessage",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.BatchTimestamps
 */
export class BatchTimestamps extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.BatchTimestamp entries = 1;
     */
    this.entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new BatchTimestamps().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new BatchTimestamps().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new BatchTimestamps().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(BatchTimestamps, a, b);
  }
}
BatchTimestamps.runtime = proto3;
BatchTimestamps.typeName = "GoThrive.BatchTimestamps";
BatchTimestamps.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "entries",
  kind: "message",
  T: BatchTimestamp,
  repeated: true
}]);