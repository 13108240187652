// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file requestChat.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.RequestChatParams
 */
export class RequestChatParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string message = 1;
     */
    this.message = "";
    /**
     * @generated from field: bool pubNub = 2;
     */
    this.pubNub = false;
    /**
     * @generated from field: bool initial = 3;
     */
    this.initial = false;
    /**
     * @generated from field: bool upgradeV1 = 4;
     */
    this.upgradeV1 = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RequestChatParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RequestChatParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RequestChatParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RequestChatParams, a, b);
  }
}
RequestChatParams.runtime = proto3;
RequestChatParams.typeName = "GoThrive.RequestChatParams";
RequestChatParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "pubNub",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 3,
  name: "initial",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 4,
  name: "upgradeV1",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.ChatExistsBody
 */
export class ChatExistsBody extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool hasActiveChat = 1;
     */
    this.hasActiveChat = false;
    /**
     * @generated from field: string chatRoomId = 2;
     */
    this.chatRoomId = "";
    /**
     * @generated from field: string leadTherapist = 3;
     */
    this.leadTherapist = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatExistsBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatExistsBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatExistsBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatExistsBody, a, b);
  }
}
ChatExistsBody.runtime = proto3;
ChatExistsBody.typeName = "GoThrive.ChatExistsBody";
ChatExistsBody.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "hasActiveChat",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 2,
  name: "chatRoomId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "leadTherapist",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChatExistsParams
 */
export class ChatExistsParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool pubnub = 1;
     */
    this.pubnub = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChatExistsParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChatExistsParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChatExistsParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChatExistsParams, a, b);
  }
}
ChatExistsParams.runtime = proto3;
ChatExistsParams.typeName = "GoThrive.ChatExistsParams";
ChatExistsParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "pubnub",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.MyTherapistsBody
 */
export class MyTherapistsBody extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated string Entries = 1;
     */
    this.Entries = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MyTherapistsBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MyTherapistsBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MyTherapistsBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MyTherapistsBody, a, b);
  }
}
MyTherapistsBody.runtime = proto3;
MyTherapistsBody.typeName = "GoThrive.MyTherapistsBody";
MyTherapistsBody.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Entries",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);