// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file personalGoalsScreen.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, BottomBar2, CustomInput, NavigationBar } from "./common_pb.js";
/**
 * @generated from message GoThrive.PersonalGoalsScreen
 */
export class PersonalGoalsScreen extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 5;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.PersonalGoalsScreenOption data = 6;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PersonalGoalsScreen().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PersonalGoalsScreen().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PersonalGoalsScreen().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PersonalGoalsScreen, a, b);
  }
}
PersonalGoalsScreen.runtime = proto3;
PersonalGoalsScreen.typeName = "GoThrive.PersonalGoalsScreen";
PersonalGoalsScreen.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 3,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 4,
  name: "bottomBar",
  kind: "message",
  T: BottomBar2
}, {
  no: 5,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 6,
  name: "data",
  kind: "message",
  T: PersonalGoalsScreenOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PersonalGoalsScreenOption
 */
export class PersonalGoalsScreenOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PersonalGoalsScreenOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PersonalGoalsScreenOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PersonalGoalsScreenOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PersonalGoalsScreenOption, a, b);
  }
}
PersonalGoalsScreenOption.runtime = proto3;
PersonalGoalsScreenOption.typeName = "GoThrive.PersonalGoalsScreenOption";
PersonalGoalsScreenOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.PersonalGoalCardView
 */
export class PersonalGoalCardView extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string header = 2;
     */
    this.header = "";
    /**
     * @generated from field: string lockText = 3;
     */
    this.lockText = "";
    /**
     * @generated from field: string unlockText = 4;
     */
    this.unlockText = "";
    /**
     * @generated from field: string lockAsset = 5;
     */
    this.lockAsset = "";
    /**
     * @generated from field: string style = 6;
     */
    this.style = "";
    /**
     * @generated from field: repeated string optionKeys = 7;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.PersonalGoalCardViewOption data = 8;
     */
    this.data = [];
    /**
     * @generated from field: repeated GoThrive.CustomInput customInputs = 9;
     */
    this.customInputs = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PersonalGoalCardView().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PersonalGoalCardView().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PersonalGoalCardView().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PersonalGoalCardView, a, b);
  }
}
PersonalGoalCardView.runtime = proto3;
PersonalGoalCardView.typeName = "GoThrive.PersonalGoalCardView";
PersonalGoalCardView.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "header",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "lockText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "unlockText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "lockAsset",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "style",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 8,
  name: "data",
  kind: "message",
  T: PersonalGoalCardViewOption,
  repeated: true
}, {
  no: 9,
  name: "customInputs",
  kind: "message",
  T: CustomInput,
  repeated: true
}]);
/**
 * @generated from message GoThrive.PersonalGoalCardViewOption
 */
export class PersonalGoalCardViewOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PersonalGoalCardViewOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PersonalGoalCardViewOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PersonalGoalCardViewOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PersonalGoalCardViewOption, a, b);
  }
}
PersonalGoalCardViewOption.runtime = proto3;
PersonalGoalCardViewOption.typeName = "GoThrive.PersonalGoalCardViewOption";
PersonalGoalCardViewOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.PersonalGoalsScreenCatalog
 */
export class PersonalGoalsScreenCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.PersonalGoalsScreen> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PersonalGoalsScreenCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PersonalGoalsScreenCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PersonalGoalsScreenCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PersonalGoalsScreenCatalog, a, b);
  }
}
PersonalGoalsScreenCatalog.runtime = proto3;
PersonalGoalsScreenCatalog.typeName = "GoThrive.PersonalGoalsScreenCatalog";
PersonalGoalsScreenCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: PersonalGoalsScreen
  }
}]);