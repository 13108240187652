// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file momentsTimestampsParams.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.MomentsTimestampsParams
 */
export class MomentsTimestampsParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string eventType = 1;
     */
    this.eventType = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MomentsTimestampsParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MomentsTimestampsParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MomentsTimestampsParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MomentsTimestampsParams, a, b);
  }
}
MomentsTimestampsParams.runtime = proto3;
MomentsTimestampsParams.typeName = "GoThrive.MomentsTimestampsParams";
MomentsTimestampsParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "eventType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);