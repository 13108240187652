// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file error.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from enum GoThrive.ResponseCodes
 */
export var ResponseCodes;
(function (ResponseCodes) {
  /**
   * @generated from enum value: SUCCESS = 0;
   */
  ResponseCodes[ResponseCodes["SUCCESS"] = 0] = "SUCCESS";
  /**
   * @generated from enum value: ERROR = 1;
   */
  ResponseCodes[ResponseCodes["ERROR"] = 1] = "ERROR";
  /**
   * @generated from enum value: PROGRESS = 2;
   */
  ResponseCodes[ResponseCodes["PROGRESS"] = 2] = "PROGRESS";
  /**
   * @generated from enum value: BEGIN = 3;
   */
  ResponseCodes[ResponseCodes["BEGIN"] = 3] = "BEGIN";
  /**
   * @generated from enum value: UPDATE = 4;
   */
  ResponseCodes[ResponseCodes["UPDATE"] = 4] = "UPDATE";
  /**
   * @generated from enum value: END = 5;
   */
  ResponseCodes[ResponseCodes["END"] = 5] = "END";
})(ResponseCodes || (ResponseCodes = {}));
// Retrieve enum metadata with: proto3.getEnumType(ResponseCodes)
proto3.util.setEnumType(ResponseCodes, "GoThrive.ResponseCodes", [{
  no: 0,
  name: "SUCCESS"
}, {
  no: 1,
  name: "ERROR"
}, {
  no: 2,
  name: "PROGRESS"
}, {
  no: 3,
  name: "BEGIN"
}, {
  no: 4,
  name: "UPDATE"
}, {
  no: 5,
  name: "END"
}]);
/**
 * @generated from enum GoThrive.ErrorCategory
 */
export var ErrorCategory;
(function (ErrorCategory) {
  /**
   * @generated from enum value: FATAL = 0;
   */
  ErrorCategory[ErrorCategory["FATAL"] = 0] = "FATAL";
  /**
   * @generated from enum value: WARNING = 1;
   */
  ErrorCategory[ErrorCategory["WARNING"] = 1] = "WARNING";
  /**
   * @generated from enum value: RECOVERABLE = 2;
   */
  ErrorCategory[ErrorCategory["RECOVERABLE"] = 2] = "RECOVERABLE";
  /**
   * @generated from enum value: PARSE = 3;
   */
  ErrorCategory[ErrorCategory["PARSE"] = 3] = "PARSE";
  /**
   * @generated from enum value: UNAUTHORISED = 4;
   */
  ErrorCategory[ErrorCategory["UNAUTHORISED"] = 4] = "UNAUTHORISED";
})(ErrorCategory || (ErrorCategory = {}));
// Retrieve enum metadata with: proto3.getEnumType(ErrorCategory)
proto3.util.setEnumType(ErrorCategory, "GoThrive.ErrorCategory", [{
  no: 0,
  name: "FATAL"
}, {
  no: 1,
  name: "WARNING"
}, {
  no: 2,
  name: "RECOVERABLE"
}, {
  no: 3,
  name: "PARSE"
}, {
  no: 4,
  name: "UNAUTHORISED"
}]);
/**
 * @generated from message GoThrive.Response
 */
export class Response extends Message {
  constructor(data) {
    super();
    /**
     * Success, Error, Progress
     *
     * @generated from field: GoThrive.ResponseCodes code = 1;
     */
    this.code = ResponseCodes.SUCCESS;
    /**
     * @generated from field: string message = 2;
     */
    this.message = "";
    /**
     * @generated from field: string commandName = 3;
     */
    this.commandName = "";
    /**
     * @generated from field: int32 commandID = 4;
     */
    this.commandID = 0;
    /**
     * @generated from field: bytes body = 5;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Response().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Response().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Response().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Response, a, b);
  }
}
Response.runtime = proto3;
Response.typeName = "GoThrive.Response";
Response.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "code",
  kind: "enum",
  T: proto3.getEnumType(ResponseCodes)
}, {
  no: 2,
  name: "message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "commandName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "commandID",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 5,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}, {
  no: 6,
  name: "error",
  kind: "message",
  T: ResponseError
}]);
/**
 * @generated from message GoThrive.ResponseError
 */
export class ResponseError extends Message {
  constructor(data) {
    super();
    /**
     * Error message description
     *
     * @generated from field: string Message = 1;
     */
    this.Message = "";
    /**
     * In case that the error is localized
     *
     * @generated from field: string LocalizationKey = 2;
     */
    this.LocalizationKey = "";
    /**
     * Fatal, warning, recoverable error, parse error
     *
     * @generated from field: GoThrive.ErrorCategory category = 3;
     */
    this.category = ErrorCategory.FATAL;
    /**
     * Thrive error code
     *
     * @generated from field: int32 ErrorCode = 4;
     */
    this.ErrorCode = 0;
    /**
     * Additional information
     *
     * @generated from field: string Description = 5;
     */
    this.Description = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ResponseError().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ResponseError().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ResponseError().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ResponseError, a, b);
  }
}
ResponseError.runtime = proto3;
ResponseError.typeName = "GoThrive.ResponseError";
ResponseError.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "LocalizationKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "category",
  kind: "enum",
  T: proto3.getEnumType(ErrorCategory)
}, {
  no: 4,
  name: "ErrorCode",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 5,
  name: "Description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ErrorCatalog
 */
export class ErrorCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.ResponseError> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ErrorCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ErrorCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ErrorCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ErrorCatalog, a, b);
  }
}
ErrorCatalog.runtime = proto3;
ErrorCatalog.typeName = "GoThrive.ErrorCatalog";
ErrorCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: ResponseError
  }
}]);