// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file network.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { AdditionalData } from "./iamsUserManagement_pb.js";
/**
 * @generated from message GoThrive.CommandLogoutSession
 */
export class CommandLogoutSession extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandLogoutSession().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandLogoutSession().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandLogoutSession().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandLogoutSession, a, b);
  }
}
CommandLogoutSession.runtime = proto3;
CommandLogoutSession.typeName = "GoThrive.CommandLogoutSession";
CommandLogoutSession.fields = proto3.util.newFieldList(() => []);
/**
 * Network commands
 *
 * @generated from message GoThrive.CommandGetPatientRecord
 */
export class CommandGetPatientRecord extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sessionCookie = 1;
     */
    this.sessionCookie = "";
    /**
     * @generated from field: string email = 4;
     */
    this.email = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetPatientRecord().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetPatientRecord().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetPatientRecord().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetPatientRecord, a, b);
  }
}
CommandGetPatientRecord.runtime = proto3;
CommandGetPatientRecord.typeName = "GoThrive.CommandGetPatientRecord";
CommandGetPatientRecord.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessionCookie",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandGetCustomToken
 */
export class CommandGetCustomToken extends Message {
  constructor(data) {
    super();
    /**
     * FB id token
     *
     * @generated from field: string idToken = 1;
     */
    this.idToken = "";
    /**
     * @generated from field: string refreshToken = 2;
     */
    this.refreshToken = "";
    /**
     * response
     *
     * @generated from field: string customToken = 3;
     */
    this.customToken = "";
    /**
     * @generated from field: string mfaChallengeResponse = 6;
     */
    this.mfaChallengeResponse = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetCustomToken().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetCustomToken().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetCustomToken().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetCustomToken, a, b);
  }
}
CommandGetCustomToken.runtime = proto3;
CommandGetCustomToken.typeName = "GoThrive.CommandGetCustomToken";
CommandGetCustomToken.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "idToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "refreshToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "customToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "user",
  kind: "message",
  T: CustomTokenUser,
  opt: true
}, {
  no: 5,
  name: "patientRecord",
  kind: "message",
  T: CustomTokenPatient,
  opt: true
}, {
  no: 6,
  name: "mfaChallengeResponse",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CustomTokenPatient
 */
export class CustomTokenPatient extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CustomTokenPatient().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CustomTokenPatient().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CustomTokenPatient().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CustomTokenPatient, a, b);
  }
}
CustomTokenPatient.runtime = proto3;
CustomTokenPatient.typeName = "GoThrive.CustomTokenPatient";
CustomTokenPatient.fields = proto3.util.newFieldList(() => [{
  no: 3,
  name: "Language",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 4,
  name: "AccessCode",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}]);
/**
 * @generated from message GoThrive.CustomTokenUser
 */
export class CustomTokenUser extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CustomTokenUser().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CustomTokenUser().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CustomTokenUser().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CustomTokenUser, a, b);
  }
}
CustomTokenUser.runtime = proto3;
CustomTokenUser.typeName = "GoThrive.CustomTokenUser";
CustomTokenUser.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "PatientId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 2,
  name: "TherapistId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}]);
/**
 * @generated from message GoThrive.TubUserRecord
 */
export class TubUserRecord extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Uid = 1;
     */
    this.Uid = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TubUserRecord().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TubUserRecord().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TubUserRecord().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TubUserRecord, a, b);
  }
}
TubUserRecord.runtime = proto3;
TubUserRecord.typeName = "GoThrive.TubUserRecord";
TubUserRecord.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Uid",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "PatientId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 3,
  name: "TherapistId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 4,
  name: "OpenId",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */,
  opt: true
}, {
  no: 5,
  name: "DisplayName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}]);
/**
 * @generated from message GoThrive.TubPatientRecord
 */
export class TubPatientRecord extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string email = 1;
     */
    this.email = "";
    /**
     * @generated from field: string organisation = 2;
     */
    this.organisation = "";
    /**
     * @generated from field: string language = 3;
     */
    this.language = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TubPatientRecord().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TubPatientRecord().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TubPatientRecord().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TubPatientRecord, a, b);
  }
}
TubPatientRecord.runtime = proto3;
TubPatientRecord.typeName = "GoThrive.TubPatientRecord";
TubPatientRecord.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "organisation",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "language",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.TubUserProfile
 */
export class TubUserProfile extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TubUserProfile().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TubUserProfile().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TubUserProfile().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TubUserProfile, a, b);
  }
}
TubUserProfile.runtime = proto3;
TubUserProfile.typeName = "GoThrive.TubUserProfile";
TubUserProfile.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "user",
  kind: "message",
  T: TubUserRecord
}, {
  no: 2,
  name: "patientRecord",
  kind: "message",
  T: TubPatientRecord
}]);
/**
 * @generated from message GoThrive.CommandGetSessionCookie
 */
export class CommandGetSessionCookie extends Message {
  constructor(data) {
    super();
    /**
     * FB id token after logged in with custom token
     *
     * @generated from field: string accessToken = 1;
     */
    this.accessToken = "";
    /**
     * response
     *
     * @generated from field: string sessionCookie = 2;
     */
    this.sessionCookie = "";
    /**
     * response
     *
     * @generated from field: string refreshToken = 3;
     */
    this.refreshToken = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetSessionCookie().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetSessionCookie().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetSessionCookie().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetSessionCookie, a, b);
  }
}
CommandGetSessionCookie.runtime = proto3;
CommandGetSessionCookie.typeName = "GoThrive.CommandGetSessionCookie";
CommandGetSessionCookie.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "accessToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "sessionCookie",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "refreshToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandGetSignInWithEmail
 */
export class CommandGetSignInWithEmail extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string email = 1;
     */
    this.email = "";
    /**
     * @generated from field: string password = 2;
     */
    this.password = "";
    /**
     * response
     *
     * @generated from field: string idToken = 3;
     */
    this.idToken = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetSignInWithEmail().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetSignInWithEmail().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetSignInWithEmail().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetSignInWithEmail, a, b);
  }
}
CommandGetSignInWithEmail.runtime = proto3;
CommandGetSignInWithEmail.typeName = "GoThrive.CommandGetSignInWithEmail";
CommandGetSignInWithEmail.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "password",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "idToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandGetSignInWithToken
 */
export class CommandGetSignInWithToken extends Message {
  constructor(data) {
    super();
    /**
     * A Firebase Auth custom token from which to create an ID and refresh token pair.
     *
     * @generated from field: string token = 1;
     */
    this.token = "";
    /**
     * response A Firebase Auth ID token generated from the provided custom token.
     *
     * @generated from field: string idToken = 2;
     */
    this.idToken = "";
    /**
     * response A Firebase Auth refresh token generated from the provided custom token.
     *
     * @generated from field: string refreshToken = 3;
     */
    this.refreshToken = "";
    /**
     * response 	The number of seconds in which the ID token expires.
     *
     * @generated from field: string expiresIn = 4;
     */
    this.expiresIn = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetSignInWithToken().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetSignInWithToken().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetSignInWithToken().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetSignInWithToken, a, b);
  }
}
CommandGetSignInWithToken.runtime = proto3;
CommandGetSignInWithToken.typeName = "GoThrive.CommandGetSignInWithToken";
CommandGetSignInWithToken.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "token",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "idToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "refreshToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "expiresIn",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandGetFreshCustomToken
 */
export class CommandGetFreshCustomToken extends Message {
  constructor(data) {
    super();
    /**
     * Current session cookie (it should not be an expiring one)
     *
     * @generated from field: string sessionCookie = 1;
     */
    this.sessionCookie = "";
    /**
     * response
     *
     * @generated from field: string customToken = 2;
     */
    this.customToken = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetFreshCustomToken().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetFreshCustomToken().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetFreshCustomToken().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetFreshCustomToken, a, b);
  }
}
CommandGetFreshCustomToken.runtime = proto3;
CommandGetFreshCustomToken.typeName = "GoThrive.CommandGetFreshCustomToken";
CommandGetFreshCustomToken.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessionCookie",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "customToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandPushNotificationsRegister
 */
export class CommandPushNotificationsRegister extends Message {
  constructor(data) {
    super();
    /**
     * Current session cookie (it should not be an expiring one)
     *
     * @generated from field: string sessionCookie = 1;
     */
    this.sessionCookie = "";
    /**
     * FCM Token
     *
     * @generated from field: string fcmToken = 2;
     */
    this.fcmToken = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandPushNotificationsRegister().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandPushNotificationsRegister().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandPushNotificationsRegister().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandPushNotificationsRegister, a, b);
  }
}
CommandPushNotificationsRegister.runtime = proto3;
CommandPushNotificationsRegister.typeName = "GoThrive.CommandPushNotificationsRegister";
CommandPushNotificationsRegister.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessionCookie",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "fcmToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandPushNotificationsUnregister
 */
export class CommandPushNotificationsUnregister extends Message {
  constructor(data) {
    super();
    /**
     * Current session cookie (it should not be an expiring one)
     *
     * @generated from field: string sessionCookie = 1;
     */
    this.sessionCookie = "";
    /**
     * FCM Token
     *
     * @generated from field: string fcmToken = 2;
     */
    this.fcmToken = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandPushNotificationsUnregister().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandPushNotificationsUnregister().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandPushNotificationsUnregister().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandPushNotificationsUnregister, a, b);
  }
}
CommandPushNotificationsUnregister.runtime = proto3;
CommandPushNotificationsUnregister.typeName = "GoThrive.CommandPushNotificationsUnregister";
CommandPushNotificationsUnregister.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessionCookie",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "fcmToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandGetForceUpdates
 */
export class CommandGetForceUpdates extends Message {
  constructor(data) {
    super();
    /**
     * App version
     *
     * @generated from field: int64 currentVersion = 1;
     */
    this.currentVersion = protoInt64.zero;
    /**
     * Response
     *
     * @generated from field: bool updateAvailable = 2;
     */
    this.updateAvailable = false;
    /**
     * Response
     *
     * @generated from field: bool requiredUpdate = 3;
     */
    this.requiredUpdate = false;
    /**
     * Response
     *
     * @generated from field: int64 latestVersion = 4;
     */
    this.latestVersion = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetForceUpdates().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetForceUpdates().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetForceUpdates().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetForceUpdates, a, b);
  }
}
CommandGetForceUpdates.runtime = proto3;
CommandGetForceUpdates.typeName = "GoThrive.CommandGetForceUpdates";
CommandGetForceUpdates.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "currentVersion",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 2,
  name: "updateAvailable",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 3,
  name: "requiredUpdate",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 4,
  name: "latestVersion",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);
/**
 * @generated from message GoThrive.CommandReportError
 */
export class CommandReportError extends Message {
  constructor(data) {
    super();
    /**
     * FCM registration token invalid
     *
     * @generated from field: string errorDescription = 1;
     */
    this.errorDescription = "";
    /**
     * FirebasePushNotificationHandler.swift
     *
     * @generated from field: string filename = 2;
     */
    this.filename = "";
    /**
     * bindApnsToken())
     *
     * @generated from field: string function = 3;
     */
    this.function = "";
    /**
     * 30
     *
     * @generated from field: int32 line = 5;
     */
    this.line = 0;
    /**
     * 5
     *
     * @generated from field: int32 column = 6;
     */
    this.column = 0;
    /**
     * e.g. 2020-12-16 08:07:40180
     *
     * @generated from field: string date = 4;
     */
    this.date = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandReportError().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandReportError().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandReportError().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandReportError, a, b);
  }
}
CommandReportError.runtime = proto3;
CommandReportError.typeName = "GoThrive.CommandReportError";
CommandReportError.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "errorDescription",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "filename",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "function",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "line",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 6,
  name: "column",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "date",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandRequestChangePassword
 */
export class CommandRequestChangePassword extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string email = 1;
     */
    this.email = "";
    /**
     * @generated from field: string language = 2;
     */
    this.language = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandRequestChangePassword().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandRequestChangePassword().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandRequestChangePassword().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandRequestChangePassword, a, b);
  }
}
CommandRequestChangePassword.runtime = proto3;
CommandRequestChangePassword.typeName = "GoThrive.CommandRequestChangePassword";
CommandRequestChangePassword.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "language",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandRequestChallenge
 */
export class CommandRequestChallenge extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string totpId = 1;
     */
    this.totpId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandRequestChallenge().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandRequestChallenge().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandRequestChallenge().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandRequestChallenge, a, b);
  }
}
CommandRequestChallenge.runtime = proto3;
CommandRequestChallenge.typeName = "GoThrive.CommandRequestChallenge";
CommandRequestChallenge.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "totpId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandRegistration
 */
export class CommandRegistration extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string email = 1;
     */
    this.email = "";
    /**
     * @generated from field: string password = 2;
     */
    this.password = "";
    /**
     * @generated from field: string accessCode = 3;
     */
    this.accessCode = "";
    /**
     * @generated from field: string language = 4;
     */
    this.language = "";
    /**
     * @generated from field: repeated GoThrive.AdditionalData additionalSignupData = 5;
     */
    this.additionalSignupData = [];
    /**
     * response
     *
     * @generated from field: string userId = 7;
     */
    this.userId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandRegistration().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandRegistration().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandRegistration().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandRegistration, a, b);
  }
}
CommandRegistration.runtime = proto3;
CommandRegistration.typeName = "GoThrive.CommandRegistration";
CommandRegistration.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "password",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "accessCode",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "language",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "additionalSignupData",
  kind: "message",
  T: AdditionalData,
  repeated: true
}, {
  no: 7,
  name: "userId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);