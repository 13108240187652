// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file tasks.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Any, Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Moment } from "./moment_pb.js";
import { TaskInfo } from "./subscribe_pb.js";
/**
 * @generated from message GoThrive.TaskAnalytics
 */
export class TaskAnalytics extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string beginConfig = 1;
     */
    this.beginConfig = "";
    /**
     * @generated from field: string endConfig = 2;
     */
    this.endConfig = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TaskAnalytics().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TaskAnalytics().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TaskAnalytics().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TaskAnalytics, a, b);
  }
}
TaskAnalytics.runtime = proto3;
TaskAnalytics.typeName = "GoThrive.TaskAnalytics";
TaskAnalytics.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "beginConfig",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "endConfig",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.Task
 */
export class Task extends Message {
  constructor(data) {
    super();
    /**
     * Pending / Unlocked / InProgress / Completed / Done / Cancelled
     *
     * @generated from field: int32 state = 1;
     */
    this.state = 0;
    /**
     * eg CBT_01
     *
     * @generated from field: string id = 2;
     */
    this.id = "";
    /**
     * Text ID of Task
     *
     * @generated from field: string text = 3;
     */
    this.text = "";
    /**
     * @generated from field: map<string, GoThrive.Item> UnlockRequirements = 4;
     */
    this.UnlockRequirements = {};
    /**
     * @generated from field: map<string, GoThrive.Item> CompletionRequirements = 5;
     */
    this.CompletionRequirements = {};
    /**
     * @generated from field: map<string, GoThrive.Item> Rewards = 6;
     */
    this.Rewards = {};
    /**
     * Secondary text
     *
     * @generated from field: string secondaryText = 8;
     */
    this.secondaryText = "";
    /**
     * @generated from field: string icon = 9;
     */
    this.icon = "";
    /**
     * @generated from field: string background = 10;
     */
    this.background = "";
    /**
     * @generated from field: int32 priority = 11;
     */
    this.priority = 0;
    /**
     * @generated from field: string frequency = 12;
     */
    this.frequency = "";
    /**
     * @generated from field: int32 count = 13;
     */
    this.count = 0;
    /**
     * @generated from field: bool optout = 15;
     */
    this.optout = false;
    /**
     * @generated from field: string menuId = 16;
     */
    this.menuId = "";
    /**
     * @generated from field: string title = 18;
     */
    this.title = "";
    /**
     * @generated from field: bool user = 19;
     */
    this.user = false;
    /**
     * @generated from field: int32 hint = 21;
     */
    this.hint = 0;
    /**
     * @generated from field: map<string, string> hintData = 22;
     */
    this.hintData = {};
    /**
     * @generated from field: int64 notificationTime = 23;
     */
    this.notificationTime = protoInt64.zero;
    /**
     * @generated from field: int64 lastNotificationTime = 24;
     */
    this.lastNotificationTime = protoInt64.zero;
    /**
     * @generated from field: int32 progress = 25;
     */
    this.progress = 0;
    /**
     * @generated from field: repeated string nestedTasks = 26;
     */
    this.nestedTasks = [];
    /**
     * @generated from field: string saveCommand = 28;
     */
    this.saveCommand = "";
    /**
     * @generated from field: bool hide = 30;
     */
    this.hide = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Task().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Task().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Task().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Task, a, b);
  }
}
Task.runtime = proto3;
Task.typeName = "GoThrive.Task";
Task.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "state",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "text",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "UnlockRequirements",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Item
  }
}, {
  no: 5,
  name: "CompletionRequirements",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Item
  }
}, {
  no: 6,
  name: "Rewards",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Item
  }
}, {
  no: 8,
  name: "secondaryText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "icon",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 10,
  name: "background",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 11,
  name: "priority",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 12,
  name: "frequency",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 13,
  name: "count",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 14,
  name: "started",
  kind: "message",
  T: Timestamp
}, {
  no: 15,
  name: "optout",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 16,
  name: "menuId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 17,
  name: "completed",
  kind: "message",
  T: Timestamp
}, {
  no: 18,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 19,
  name: "user",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 20,
  name: "message",
  kind: "message",
  T: Any,
  opt: true
}, {
  no: 21,
  name: "hint",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 22,
  name: "hintData",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "scalar",
    T: 9 /* ScalarType.STRING */
  }
}, {
  no: 23,
  name: "notificationTime",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 24,
  name: "lastNotificationTime",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 25,
  name: "progress",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 26,
  name: "nestedTasks",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 27,
  name: "taskAnalytics",
  kind: "message",
  T: TaskAnalytics,
  opt: true
}, {
  no: 28,
  name: "saveCommand",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 30,
  name: "hide",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.Item
 */
export class Item extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: int32 trackCount = 2;
     */
    this.trackCount = 0;
    /**
     * @generated from field: int32 stackCount = 4;
     */
    this.stackCount = 0;
    /**
     * @generated from field: string type = 5;
     */
    this.type = "";
    /**
     * @generated from field: int32 progress = 6;
     */
    this.progress = 0;
    /**
     * @generated from field: int32 total = 7;
     */
    this.total = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Item().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Item().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Item().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Item, a, b);
  }
}
Item.runtime = proto3;
Item.typeName = "GoThrive.Item";
Item.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "trackCount",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "timestamp",
  kind: "message",
  T: Timestamp
}, {
  no: 4,
  name: "stackCount",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 5,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "progress",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 7,
  name: "total",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.Inventory
 */
export class Inventory extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.Item> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Inventory().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Inventory().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Inventory().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Inventory, a, b);
  }
}
Inventory.runtime = proto3;
Inventory.typeName = "GoThrive.Inventory";
Inventory.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Item
  }
}]);
/**
 * @generated from message GoThrive.TrackedItems
 */
export class TrackedItems extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.Item> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TrackedItems().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TrackedItems().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TrackedItems().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TrackedItems, a, b);
  }
}
TrackedItems.runtime = proto3;
TrackedItems.typeName = "GoThrive.TrackedItems";
TrackedItems.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Item
  }
}]);
/**
 * @generated from message GoThrive.ItemList
 */
export class ItemList extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.Item> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ItemList().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ItemList().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ItemList().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ItemList, a, b);
  }
}
ItemList.runtime = proto3;
ItemList.typeName = "GoThrive.ItemList";
ItemList.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Item
  }
}]);
/**
 * @generated from message GoThrive.TaskList
 */
export class TaskList extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.Task> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TaskList().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TaskList().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TaskList().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TaskList, a, b);
  }
}
TaskList.runtime = proto3;
TaskList.typeName = "GoThrive.TaskList";
TaskList.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Task
  }
}]);
/**
 * @generated from message GoThrive.UserTaskList
 */
export class UserTaskList extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.Task> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new UserTaskList().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new UserTaskList().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new UserTaskList().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(UserTaskList, a, b);
  }
}
UserTaskList.runtime = proto3;
UserTaskList.typeName = "GoThrive.UserTaskList";
UserTaskList.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Task
  }
}]);
/**
 * @generated from message GoThrive.CommandAddToInventory
 */
export class CommandAddToInventory extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandAddToInventory().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandAddToInventory().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandAddToInventory().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandAddToInventory, a, b);
  }
}
CommandAddToInventory.runtime = proto3;
CommandAddToInventory.typeName = "GoThrive.CommandAddToInventory";
CommandAddToInventory.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandTaskOptOut
 */
export class CommandTaskOptOut extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: bool optout = 2;
     */
    this.optout = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandTaskOptOut().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandTaskOptOut().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandTaskOptOut().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandTaskOptOut, a, b);
  }
}
CommandTaskOptOut.runtime = proto3;
CommandTaskOptOut.typeName = "GoThrive.CommandTaskOptOut";
CommandTaskOptOut.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "optout",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.CommandSaveInventory
 */
export class CommandSaveInventory extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSaveInventory().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSaveInventory().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSaveInventory().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSaveInventory, a, b);
  }
}
CommandSaveInventory.runtime = proto3;
CommandSaveInventory.typeName = "GoThrive.CommandSaveInventory";
CommandSaveInventory.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message GoThrive.CommandClearInventory
 */
export class CommandClearInventory extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandClearInventory().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandClearInventory().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandClearInventory().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandClearInventory, a, b);
  }
}
CommandClearInventory.runtime = proto3;
CommandClearInventory.typeName = "GoThrive.CommandClearInventory";
CommandClearInventory.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message GoThrive.CommandRemoveFromInventory
 */
export class CommandRemoveFromInventory extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandRemoveFromInventory().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandRemoveFromInventory().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandRemoveFromInventory().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandRemoveFromInventory, a, b);
  }
}
CommandRemoveFromInventory.runtime = proto3;
CommandRemoveFromInventory.typeName = "GoThrive.CommandRemoveFromInventory";
CommandRemoveFromInventory.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandStartProgramme
 */
export class CommandStartProgramme extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string masterkey = 1;
     */
    this.masterkey = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandStartProgramme().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandStartProgramme().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandStartProgramme().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandStartProgramme, a, b);
  }
}
CommandStartProgramme.runtime = proto3;
CommandStartProgramme.typeName = "GoThrive.CommandStartProgramme";
CommandStartProgramme.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "masterkey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "message",
  kind: "message",
  T: Any,
  opt: true
}]);
/**
 * @generated from message GoThrive.CommandAbandonGoalStep
 */
export class CommandAbandonGoalStep extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandAbandonGoalStep().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandAbandonGoalStep().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandAbandonGoalStep().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandAbandonGoalStep, a, b);
  }
}
CommandAbandonGoalStep.runtime = proto3;
CommandAbandonGoalStep.typeName = "GoThrive.CommandAbandonGoalStep";
CommandAbandonGoalStep.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandCompleteGoalStep
 */
export class CommandCompleteGoalStep extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandCompleteGoalStep().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandCompleteGoalStep().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandCompleteGoalStep().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandCompleteGoalStep, a, b);
  }
}
CommandCompleteGoalStep.runtime = proto3;
CommandCompleteGoalStep.typeName = "GoThrive.CommandCompleteGoalStep";
CommandCompleteGoalStep.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "moment",
  kind: "message",
  T: Moment,
  opt: true
}]);
/**
 * @generated from message GoThrive.CommandGetInventory
 */
export class CommandGetInventory extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.Item items = 1;
     */
    this.items = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetInventory().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetInventory().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetInventory().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetInventory, a, b);
  }
}
CommandGetInventory.runtime = proto3;
CommandGetInventory.typeName = "GoThrive.CommandGetInventory";
CommandGetInventory.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "items",
  kind: "message",
  T: Item,
  repeated: true
}]);
/**
 * @generated from message GoThrive.CommandGetAvailableItems
 */
export class CommandGetAvailableItems extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated string items = 1;
     */
    this.items = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetAvailableItems().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetAvailableItems().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetAvailableItems().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetAvailableItems, a, b);
  }
}
CommandGetAvailableItems.runtime = proto3;
CommandGetAvailableItems.typeName = "GoThrive.CommandGetAvailableItems";
CommandGetAvailableItems.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "items",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.CommandGetAvailableTasks
 */
export class CommandGetAvailableTasks extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated GoThrive.TaskInfo tasks = 1;
     */
    this.tasks = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetAvailableTasks().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetAvailableTasks().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetAvailableTasks().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetAvailableTasks, a, b);
  }
}
CommandGetAvailableTasks.runtime = proto3;
CommandGetAvailableTasks.typeName = "GoThrive.CommandGetAvailableTasks";
CommandGetAvailableTasks.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "tasks",
  kind: "message",
  T: TaskInfo,
  repeated: true
}]);
/**
 * @generated from message GoThrive.ProgrammeInfo
 */
export class ProgrammeInfo extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: string masterkey = 2;
     */
    this.masterkey = "";
    /**
     * @generated from field: repeated string sessions = 3;
     */
    this.sessions = [];
    /**
     * @generated from field: string type = 4;
     */
    this.type = "";
    /**
     * @generated from field: string title = 5;
     */
    this.title = "";
    /**
     * @generated from field: string info = 6;
     */
    this.info = "";
    /**
     * @generated from field: string menuID = 7;
     */
    this.menuID = "";
    /**
     * @generated from field: string requiredKey = 8;
     */
    this.requiredKey = "";
    /**
     * @generated from field: string icon = 9;
     */
    this.icon = "";
    /**
     * @generated from field: string iconbackground = 10;
     */
    this.iconbackground = "";
    /**
     * @generated from field: bool user = 11;
     */
    this.user = false;
    /**
     * @generated from field: string groupID = 12;
     */
    this.groupID = "";
    /**
     * @generated from field: string progress = 13;
     */
    this.progress = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ProgrammeInfo().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ProgrammeInfo().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ProgrammeInfo().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ProgrammeInfo, a, b);
  }
}
ProgrammeInfo.runtime = proto3;
ProgrammeInfo.typeName = "GoThrive.ProgrammeInfo";
ProgrammeInfo.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "masterkey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "sessions",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 4,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "info",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "menuID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "requiredKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "icon",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 10,
  name: "iconbackground",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 11,
  name: "user",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 12,
  name: "groupID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 13,
  name: "progress",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.Programmes
 */
export class Programmes extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.ProgrammeInfo> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Programmes().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Programmes().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Programmes().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Programmes, a, b);
  }
}
Programmes.runtime = proto3;
Programmes.typeName = "GoThrive.Programmes";
Programmes.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: ProgrammeInfo
  }
}]);
/**
 * @generated from message GoThrive.UserProgrammes
 */
export class UserProgrammes extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.ProgrammeInfo> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new UserProgrammes().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new UserProgrammes().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new UserProgrammes().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(UserProgrammes, a, b);
  }
}
UserProgrammes.runtime = proto3;
UserProgrammes.typeName = "GoThrive.UserProgrammes";
UserProgrammes.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: ProgrammeInfo
  }
}]);
/**
 * @generated from message GoThrive.CommandCreateProgrammeGroup
 */
export class CommandCreateProgrammeGroup extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string groupID = 1;
     */
    this.groupID = "";
    /**
     * @generated from field: string title = 2;
     */
    this.title = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandCreateProgrammeGroup().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandCreateProgrammeGroup().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandCreateProgrammeGroup().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandCreateProgrammeGroup, a, b);
  }
}
CommandCreateProgrammeGroup.runtime = proto3;
CommandCreateProgrammeGroup.typeName = "GoThrive.CommandCreateProgrammeGroup";
CommandCreateProgrammeGroup.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "groupID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "title",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandAddProgramme
 */
export class CommandAddProgramme extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandAddProgramme().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandAddProgramme().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandAddProgramme().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandAddProgramme, a, b);
  }
}
CommandAddProgramme.runtime = proto3;
CommandAddProgramme.typeName = "GoThrive.CommandAddProgramme";
CommandAddProgramme.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "programme",
  kind: "message",
  T: ProgrammeInfo
}]);
/**
 * @generated from message GoThrive.CommandRemoveProgramme
 */
export class CommandRemoveProgramme extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandRemoveProgramme().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandRemoveProgramme().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandRemoveProgramme().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandRemoveProgramme, a, b);
  }
}
CommandRemoveProgramme.runtime = proto3;
CommandRemoveProgramme.typeName = "GoThrive.CommandRemoveProgramme";
CommandRemoveProgramme.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "programme",
  kind: "message",
  T: ProgrammeInfo
}]);
/**
 * @generated from message GoThrive.CommandRemoveProgrammeGroup
 */
export class CommandRemoveProgrammeGroup extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string groupID = 1;
     */
    this.groupID = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandRemoveProgrammeGroup().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandRemoveProgrammeGroup().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandRemoveProgrammeGroup().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandRemoveProgrammeGroup, a, b);
  }
}
CommandRemoveProgrammeGroup.runtime = proto3;
CommandRemoveProgrammeGroup.typeName = "GoThrive.CommandRemoveProgrammeGroup";
CommandRemoveProgrammeGroup.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "groupID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandAddUserTask
 */
export class CommandAddUserTask extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandAddUserTask().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandAddUserTask().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandAddUserTask().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandAddUserTask, a, b);
  }
}
CommandAddUserTask.runtime = proto3;
CommandAddUserTask.typeName = "GoThrive.CommandAddUserTask";
CommandAddUserTask.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "programme",
  kind: "message",
  T: ProgrammeInfo
}, {
  no: 2,
  name: "task",
  kind: "message",
  T: Task
}]);
/**
 * @generated from message GoThrive.CommandRemoveUserTask
 */
export class CommandRemoveUserTask extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandRemoveUserTask().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandRemoveUserTask().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandRemoveUserTask().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandRemoveUserTask, a, b);
  }
}
CommandRemoveUserTask.runtime = proto3;
CommandRemoveUserTask.typeName = "GoThrive.CommandRemoveUserTask";
CommandRemoveUserTask.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "programme",
  kind: "message",
  T: ProgrammeInfo
}, {
  no: 2,
  name: "task",
  kind: "message",
  T: Task
}]);
/**
 * @generated from message GoThrive.CommandGetTask
 */
export class CommandGetTask extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetTask().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetTask().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetTask().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetTask, a, b);
  }
}
CommandGetTask.runtime = proto3;
CommandGetTask.typeName = "GoThrive.CommandGetTask";
CommandGetTask.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "task",
  kind: "message",
  T: Task
}]);
/**
 * @generated from message GoThrive.CommandGetTaskData
 */
export class CommandGetTaskData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetTaskData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetTaskData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetTaskData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetTaskData, a, b);
  }
}
CommandGetTaskData.runtime = proto3;
CommandGetTaskData.typeName = "GoThrive.CommandGetTaskData";
CommandGetTaskData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "moment",
  kind: "message",
  T: Moment
}]);
/**
 * @generated from message GoThrive.CommandSetTaskData
 */
export class CommandSetTaskData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSetTaskData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSetTaskData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSetTaskData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSetTaskData, a, b);
  }
}
CommandSetTaskData.runtime = proto3;
CommandSetTaskData.typeName = "GoThrive.CommandSetTaskData";
CommandSetTaskData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "message",
  kind: "message",
  T: Any,
  opt: true
}]);
/**
 * @generated from message GoThrive.CommandAddTaskTime
 */
export class CommandAddTaskTime extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string id = 1;
     */
    this.id = "";
    /**
     * @generated from field: int64 time = 2;
     */
    this.time = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandAddTaskTime().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandAddTaskTime().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandAddTaskTime().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandAddTaskTime, a, b);
  }
}
CommandAddTaskTime.runtime = proto3;
CommandAddTaskTime.typeName = "GoThrive.CommandAddTaskTime";
CommandAddTaskTime.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "time",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);