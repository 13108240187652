import { ReplaySubject } from 'rxjs';
/* GoCore Imports */
import { Response, ResponseCodes, ResponseError } from '../protobuf/error_pb';
import { ActiveRequest } from './active-request';
import { Command } from '../protobuf/command_pb';
/* Model Imports */
import { CORE_STATE } from './model/core';
/**
 * GoCore Mediator
 * This class manages communication between a web client and the GoCore runtime
 */
export class GoCoreMediator {
  constructor() {
    this.goVersion$ = new ReplaySubject();
    this._currentId = 0;
    this._callbackMap = new Map();
  }
  /**
   * GoCore requires initialisation before it is ready to receive messages from the client
   * @param sourceRootPath path to the location of the gocore data bucket
   * @param networkPath path to the backend environment
   * @param buildType which environment gocore should be set to (DEV/STAGE/LIVE)
   * @param onInitComplete callback that is executed when GoCore is ready to receive instructions
   * @param onGoCoreException callback that is executed when GoCore is in an recoverable state
   */
  initialise(sourceRootPath, networkPath, buildType, onInitComplete, onGoCoreException) {
    this._initCompleteCallback = onInitComplete;
    this._sourceRootPath = sourceRootPath;
    this._networkPath = networkPath;
    this._buildType = buildType;
    // We need to listen to the GoCore attached functions on window
    window.onCoreStatusChanged = this.onCoreStatusChanged.bind(this);
    window.receiveFromCore = this.receiveFromCore.bind(this);
    // Instantiate the Go runtime
    const go = new Go();
    // Fetch and run the GoCore webassembly module 
    return WebAssembly.instantiateStreaming(fetch('./assets/gocore/libthrivecore.wasm'), go.importObject).then(res => {
      go.run(res.instance).then(e => onGoCoreException(e)); // capture panics, unrecoverable crashes
    }).catch(e => {
      onGoCoreException(e); // capture go runtime initialise issues
    });
  }
  /**
   * Attempt to retrieve the commit has of GoCore library
   */
  async getLibraryVersion() {
    let version;
    console.log('GoCore Mediator: Attempting to retrieve GoCore Version...');
    try {
      version = window.GetCommitHash();
      console.log(`GoCore Version Retrieved: ${version}`);
      this.goVersion$.next(version);
    } catch (e) {
      console.warn('Failed to fetch GoCore Version: ', e);
    }
    return version !== null && version !== void 0 ? version : 'Unknown';
  }
  /**
   * Send Commands to GoCore
   * @param command The command type that describes the protobuf
   * @param data A protobuf message wrapped in a command to send to gocore
   * @param callback The function that should be called when a response to the command is received from gocore
   */
  sendCommand(command, callback) {
    // Every command causes this value to increase, it can be seen as a unique id for the command
    // And it acts as an index into the callback map to retrieve the appropriate callback
    this._currentId++;
    // All incoming commands are wrapped in an outer Command structure to be received by GoCore
    const outerCommand = new Command();
    outerCommand.name = command.getType().typeName;
    outerCommand.body = command.toBinary();
    // Some commands do not specify a callback as they require no response handling from GoCore
    if (callback != null) {
      if (this._callbackMap.has(this._currentId)) {
        console.log(`Callback already exists for ${this._currentId}`);
      }
      this._callbackMap.set(this._currentId, new ActiveRequest(this._currentId, callback));
    }
    // 'SendCommand' is a mediator function created by GoCore runtime and attached to window
    window.SendCommand(outerCommand.toBinary(), this._currentId);
  }
  onCoreStatusChanged(status) {
    if (status === CORE_STATE.CORE_INIT_OK) {
      // InitGoBridge is a mediator function created by the GoCore runtime & is attached to window
      // Third parameter is for app storage config, this should be empty for web applications as they
      // use an in-memory file system in GoCore and cannot persist data
      // There is now a 4th(!) required parameter to initialise GoCore - the build environment
      window.InitGoBridge(this._sourceRootPath, this._networkPath, '', this._buildType);
      // test whether go version is available at this point
      this.getLibraryVersion().then(version => {
        this.goVersion$.next(version);
      });
    } else if (status === CORE_STATE.BRIDGE_INIT_OK) {
      // When GoCore is ready to receive requests we can let the client know by completing
      this._initCompleteCallback();
      // test whether go version is available at this point
      this.getLibraryVersion().then(version => {
        this.goVersion$.next(version);
      });
    }
  }
  /**
   * All messages sent from GoCore are processed through this function
   * @param data Uin8Array Encoded response from GoCore
   */
  receiveFromCore(data) {
    var _a;
    const response = Response.fromBinary(data);
    if (response.code === ResponseCodes.ERROR) {
      const error = (_a = response.error) !== null && _a !== void 0 ? _a : new ResponseError({
        Message: '',
        LocalizationKey: '',
        category: null,
        ErrorCode: -1,
        Description: ''
      });
      // If Gocore does not send us an error response then we reconstruct one to prevent
      // potential issues in clients reading the data back
      response['error'] = error;
      console.error(`Error received from GoCore: [${response.commandName}], Message: ${response.message}.`, error.Description);
    } else {
      console.log(`Command Received: [${response.commandName}], Message: ${response.message}, Result: ${ResponseCodes[response.code]}`);
    }
    /// Sometimes a command will have a prefix on it, depending on
    /// the language that generated it. We don't care about any
    /// prefixes here so we just find the last part
    let commandName = response.commandName;
    const splitPos = commandName.indexOf('.');
    if (splitPos !== -1) {
      commandName = commandName.substring(splitPos);
    }
    if (!this._callbackMap.has(response.commandID)) {
      console.warn(`No handler found for command id: ${response.commandID}, name: ${response.commandName}, message: ${response.message}`);
      return;
    }
    // As every response from GoCore specifies the id of the command that sent it 
    // we can use that id to select and fire the appropriate callback
    const request = this._callbackMap.get(response.commandID);
    if (request != null) {
      request.callback(response);
      // We check for explicit response codes because 'Progress' response codes should not
      // trigger a deletion of associated callback. They are subscriptions and need to be held onto
      if (response.code === ResponseCodes.SUCCESS || response.code === ResponseCodes.ERROR) {
        // PubNubChat can't be allowed to be removed, it should be a property observer but hasn't been made
        // that way in Gocore. If we detect that command then bypass the removal of the callback
        if (response.commandName === 'PubNubChat') {
          console.warn('Attempted removal of PubNubChat callback was prevented.');
        } else {
          console.warn(`Removing callback from callback map: ${response.commandID}`);
          this._callbackMap.delete(response.commandID);
        }
      }
    } else {
      console.warn(`Callback retrieval was null for ${response.commandID, response.commandName, response.message}`);
    }
  }
}