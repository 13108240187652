// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file image.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.Image
 */
export class Image extends Message {
  constructor(data) {
    super();
    /**
     * Request
     *
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string url = 2;
     */
    this.url = "";
    /**
     * @generated from field: bytes data = 3;
     */
    this.data = new Uint8Array(0);
    /**
     * @generated from field: bool omitdata = 4;
     */
    this.omitdata = false;
    /**
     * @generated from field: string actualURL = 5;
     */
    this.actualURL = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Image().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Image().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Image().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Image, a, b);
  }
}
Image.runtime = proto3;
Image.typeName = "GoThrive.Image";
Image.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "url",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "data",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}, {
  no: 4,
  name: "omitdata",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 5,
  name: "actualURL",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ImageCatalog
 */
export class ImageCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.Image> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ImageCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ImageCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ImageCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ImageCatalog, a, b);
  }
}
ImageCatalog.runtime = proto3;
ImageCatalog.typeName = "GoThrive.ImageCatalog";
ImageCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Image
  }
}]);
/**
 * @generated from message GoThrive.ImageSet
 */
export class ImageSet extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string images = 2;
     */
    this.images = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ImageSet().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ImageSet().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ImageSet().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ImageSet, a, b);
  }
}
ImageSet.runtime = proto3;
ImageSet.typeName = "GoThrive.ImageSet";
ImageSet.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "images",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.ImageSetCatalog
 */
export class ImageSetCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.ImageSet> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ImageSetCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ImageSetCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ImageSetCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ImageSetCatalog, a, b);
  }
}
ImageSetCatalog.runtime = proto3;
ImageSetCatalog.typeName = "GoThrive.ImageSetCatalog";
ImageSetCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: ImageSet
  }
}]);
/**
 * @generated from message GoThrive.MoodSet
 */
export class MoodSet extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string month = 1;
     */
    this.month = "";
    /**
     * @generated from field: string ImageSetID = 4;
     */
    this.ImageSetID = "";
    /**
     * @generated from field: float weight = 5;
     */
    this.weight = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoodSet().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoodSet().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoodSet().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoodSet, a, b);
  }
}
MoodSet.runtime = proto3;
MoodSet.typeName = "GoThrive.MoodSet";
MoodSet.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "month",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "begin",
  kind: "message",
  T: Timestamp
}, {
  no: 3,
  name: "end",
  kind: "message",
  T: Timestamp
}, {
  no: 4,
  name: "ImageSetID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "weight",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}]);
/**
 * @generated from message GoThrive.MoodGroup
 */
export class MoodGroup extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string month = 1;
     */
    this.month = "";
    /**
     * @generated from field: repeated GoThrive.MoodSet moodSet = 2;
     */
    this.moodSet = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoodGroup().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoodGroup().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoodGroup().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoodGroup, a, b);
  }
}
MoodGroup.runtime = proto3;
MoodGroup.typeName = "GoThrive.MoodGroup";
MoodGroup.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "month",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "moodSet",
  kind: "message",
  T: MoodSet,
  repeated: true
}]);
/**
 * @generated from message GoThrive.MoodSetCatalog
 */
export class MoodSetCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.MoodGroup> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoodSetCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoodSetCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoodSetCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoodSetCatalog, a, b);
  }
}
MoodSetCatalog.runtime = proto3;
MoodSetCatalog.typeName = "GoThrive.MoodSetCatalog";
MoodSetCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: MoodGroup
  }
}]);