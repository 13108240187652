// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file user.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Moment } from "./moment_pb.js";
import { Organization } from "./organization_pb.js";
import { CloudDocument } from "./cloudDocuments_pb.js";
/**
 * @generated from message GoThrive.Points
 */
export class Points extends Message {
  constructor(data) {
    super();
    /**
     * e.g. "CARD_EXERCISE_POINTS_ID" or "CARD_EXERCISE_XP_ID".
     *
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * e.g. "POINTS" or "XP".
     *
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: int32 value = 3;
     */
    this.value = 0;
    /**
     * optional, e.g. the level.
     *
     * @generated from field: int32 bracket = 4;
     */
    this.bracket = 0;
    /**
     * lower bound of the bracket.
     *
     * @generated from field: int32 lowerBound = 5;
     */
    this.lowerBound = 0;
    /**
     * upper bound of the bracket.
     *
     * @generated from field: int32 upperBound = 6;
     */
    this.upperBound = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Points().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Points().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Points().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Points, a, b);
  }
}
Points.runtime = proto3;
Points.typeName = "GoThrive.Points";
Points.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "value",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "bracket",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 5,
  name: "lowerBound",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 6,
  name: "upperBound",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.PointsAwarded
 */
export class PointsAwarded extends Message {
  constructor(data) {
    super();
    /**
     * e.g. "CARD_EXERCISE_POINTS_ID" or "CARD_EXERCISE_XP_ID".
     *
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * e.g. "POINTS" or "XP".
     *
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: int32 value = 3;
     */
    this.value = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PointsAwarded().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PointsAwarded().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PointsAwarded().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PointsAwarded, a, b);
  }
}
PointsAwarded.runtime = proto3;
PointsAwarded.typeName = "GoThrive.PointsAwarded";
PointsAwarded.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "value",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.DataType
 */
export class DataType extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string type = 1;
     */
    this.type = "";
    /**
     * @generated from field: string name = 2;
     */
    this.name = "";
    /**
     * @generated from field: string value = 3;
     */
    this.value = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new DataType().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new DataType().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new DataType().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(DataType, a, b);
  }
}
DataType.runtime = proto3;
DataType.typeName = "GoThrive.DataType";
DataType.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "value",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.DataResult
 */
export class DataResult extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.DataType> contentMap = 1;
     */
    this.contentMap = {};
    /**
     * @generated from field: int64 time = 2;
     */
    this.time = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new DataResult().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new DataResult().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new DataResult().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(DataResult, a, b);
  }
}
DataResult.runtime = proto3;
DataResult.typeName = "GoThrive.DataResult";
DataResult.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: DataType
  }
}, {
  no: 2,
  name: "time",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);
/**
 * @generated from message GoThrive.UserData
 */
export class UserData extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.DataResult> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new UserData().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new UserData().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new UserData().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(UserData, a, b);
  }
}
UserData.runtime = proto3;
UserData.typeName = "GoThrive.UserData";
UserData.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: DataResult
  }
}]);
/**
 * @generated from message GoThrive.CommandGetMoments
 */
export class CommandGetMoments extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string lastdoc = 3;
     */
    this.lastdoc = "";
    /**
     * @generated from field: int32 limit = 4;
     */
    this.limit = 0;
    /**
     * @generated from field: repeated GoThrive.Moment moments = 5;
     */
    this.moments = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetMoments().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetMoments().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetMoments().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetMoments, a, b);
  }
}
CommandGetMoments.runtime = proto3;
CommandGetMoments.typeName = "GoThrive.CommandGetMoments";
CommandGetMoments.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "from",
  kind: "message",
  T: Timestamp
}, {
  no: 2,
  name: "to",
  kind: "message",
  T: Timestamp
}, {
  no: 3,
  name: "lastdoc",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "limit",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 5,
  name: "moments",
  kind: "message",
  T: Moment,
  repeated: true
}]);
/**
 * @generated from message GoThrive.CommandPopulateMomentStaticInfo
 */
export class CommandPopulateMomentStaticInfo extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandPopulateMomentStaticInfo().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandPopulateMomentStaticInfo().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandPopulateMomentStaticInfo().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandPopulateMomentStaticInfo, a, b);
  }
}
CommandPopulateMomentStaticInfo.runtime = proto3;
CommandPopulateMomentStaticInfo.typeName = "GoThrive.CommandPopulateMomentStaticInfo";
CommandPopulateMomentStaticInfo.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "moment",
  kind: "message",
  T: Moment
}]);
/**
 * @generated from message GoThrive.CommandGetMomentAnswers
 */
export class CommandGetMomentAnswers extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetMomentAnswers().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetMomentAnswers().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetMomentAnswers().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetMomentAnswers, a, b);
  }
}
CommandGetMomentAnswers.runtime = proto3;
CommandGetMomentAnswers.typeName = "GoThrive.CommandGetMomentAnswers";
CommandGetMomentAnswers.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "moment",
  kind: "message",
  T: Moment
}]);
/**
 * @generated from message GoThrive.CommandLostFocus
 */
export class CommandLostFocus extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandLostFocus().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandLostFocus().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandLostFocus().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandLostFocus, a, b);
  }
}
CommandLostFocus.runtime = proto3;
CommandLostFocus.typeName = "GoThrive.CommandLostFocus";
CommandLostFocus.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message GoThrive.CommandRestoreFocus
 */
export class CommandRestoreFocus extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandRestoreFocus().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandRestoreFocus().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandRestoreFocus().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandRestoreFocus, a, b);
  }
}
CommandRestoreFocus.runtime = proto3;
CommandRestoreFocus.typeName = "GoThrive.CommandRestoreFocus";
CommandRestoreFocus.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message GoThrive.CommandTerminating
 */
export class CommandTerminating extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandTerminating().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandTerminating().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandTerminating().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandTerminating, a, b);
  }
}
CommandTerminating.runtime = proto3;
CommandTerminating.typeName = "GoThrive.CommandTerminating";
CommandTerminating.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message GoThrive.CommandRequestAccountDeletion
 */
export class CommandRequestAccountDeletion extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandRequestAccountDeletion().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandRequestAccountDeletion().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandRequestAccountDeletion().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandRequestAccountDeletion, a, b);
  }
}
CommandRequestAccountDeletion.runtime = proto3;
CommandRequestAccountDeletion.typeName = "GoThrive.CommandRequestAccountDeletion";
CommandRequestAccountDeletion.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message GoThrive.CommandInitProfile
 */
export class CommandInitProfile extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string email = 1;
     */
    this.email = "";
    /**
     * @generated from field: string accessCode = 2;
     */
    this.accessCode = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandInitProfile().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandInitProfile().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandInitProfile().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandInitProfile, a, b);
  }
}
CommandInitProfile.runtime = proto3;
CommandInitProfile.typeName = "GoThrive.CommandInitProfile";
CommandInitProfile.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "accessCode",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandGetEULA
 */
export class CommandGetEULA extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string EULA = 1;
     */
    this.EULA = "";
    /**
     * @generated from field: string EULATXT = 2;
     */
    this.EULATXT = "";
    /**
     * @generated from field: string EULABETA = 3;
     */
    this.EULABETA = "";
    /**
     * @generated from field: string EULABETATXT = 4;
     */
    this.EULABETATXT = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetEULA().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetEULA().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetEULA().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetEULA, a, b);
  }
}
CommandGetEULA.runtime = proto3;
CommandGetEULA.typeName = "GoThrive.CommandGetEULA";
CommandGetEULA.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "EULA",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "EULATXT",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "EULABETA",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "EULABETATXT",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandGetCBTCategory
 */
export class CommandGetCBTCategory extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string Category = 1;
     */
    this.Category = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetCBTCategory().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetCBTCategory().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetCBTCategory().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetCBTCategory, a, b);
  }
}
CommandGetCBTCategory.runtime = proto3;
CommandGetCBTCategory.typeName = "GoThrive.CommandGetCBTCategory";
CommandGetCBTCategory.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Category",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandGetOrganisationDetails
 */
export class CommandGetOrganisationDetails extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetOrganisationDetails().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetOrganisationDetails().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetOrganisationDetails().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetOrganisationDetails, a, b);
  }
}
CommandGetOrganisationDetails.runtime = proto3;
CommandGetOrganisationDetails.typeName = "GoThrive.CommandGetOrganisationDetails";
CommandGetOrganisationDetails.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "organisation",
  kind: "message",
  T: Organization
}]);
/**
 * @generated from message GoThrive.UserSetting
 */
export class UserSetting extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string value = 2;
     */
    this.value = "";
    /**
     * @generated from field: string type = 3;
     */
    this.type = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new UserSetting().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new UserSetting().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new UserSetting().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(UserSetting, a, b);
  }
}
UserSetting.runtime = proto3;
UserSetting.typeName = "GoThrive.UserSetting";
UserSetting.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "value",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.OrganisationSettingCatalog
 */
export class OrganisationSettingCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.UserSetting> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new OrganisationSettingCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new OrganisationSettingCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new OrganisationSettingCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(OrganisationSettingCatalog, a, b);
  }
}
OrganisationSettingCatalog.runtime = proto3;
OrganisationSettingCatalog.typeName = "GoThrive.OrganisationSettingCatalog";
OrganisationSettingCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: UserSetting
  }
}]);
/**
 * @generated from message GoThrive.UserProfile
 */
export class UserProfile extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string email = 1;
     */
    this.email = "";
    /**
     * @generated from field: string username = 2;
     */
    this.username = "";
    /**
     * @generated from field: float EulaVersion = 5;
     */
    this.EulaVersion = 0;
    /**
     * This is GoCore side only for Personal Goals stuff.
     *
     * @generated from field: string UUID = 6;
     */
    this.UUID = "";
    /**
     * @generated from field: string PatientID = 8;
     */
    this.PatientID = "";
    /**
     * @generated from field: string TherapistID = 9;
     */
    this.TherapistID = "";
    /**
     * @generated from field: repeated string scope = 10;
     */
    this.scope = [];
    /**
     * @generated from field: string accessCode = 11;
     */
    this.accessCode = "";
    /**
     * @generated from field: bool openId = 12;
     */
    this.openId = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new UserProfile().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new UserProfile().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new UserProfile().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(UserProfile, a, b);
  }
}
UserProfile.runtime = proto3;
UserProfile.typeName = "GoThrive.UserProfile";
UserProfile.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "username",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "EulaAccepted",
  kind: "message",
  T: Timestamp
}, {
  no: 4,
  name: "settings",
  kind: "message",
  T: OrganisationSettingCatalog
}, {
  no: 5,
  name: "EulaVersion",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}, {
  no: 6,
  name: "UUID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "unityProfile",
  kind: "message",
  T: CloudDocument
}, {
  no: 8,
  name: "PatientID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "TherapistID",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 10,
  name: "scope",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 11,
  name: "accessCode",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 12,
  name: "openId",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.UserProfileCatalog
 */
export class UserProfileCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.UserProfile> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new UserProfileCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new UserProfileCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new UserProfileCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(UserProfileCatalog, a, b);
  }
}
UserProfileCatalog.runtime = proto3;
UserProfileCatalog.typeName = "GoThrive.UserProfileCatalog";
UserProfileCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: UserProfile
  }
}]);
/**
 * @generated from message GoThrive.CommandGetUserProfile
 */
export class CommandGetUserProfile extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetUserProfile().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetUserProfile().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetUserProfile().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetUserProfile, a, b);
  }
}
CommandGetUserProfile.runtime = proto3;
CommandGetUserProfile.typeName = "GoThrive.CommandGetUserProfile";
CommandGetUserProfile.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "profile",
  kind: "message",
  T: UserProfile
}]);
/**
 * @generated from message GoThrive.CommandClearUserProfile
 */
export class CommandClearUserProfile extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandClearUserProfile().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandClearUserProfile().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandClearUserProfile().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandClearUserProfile, a, b);
  }
}
CommandClearUserProfile.runtime = proto3;
CommandClearUserProfile.typeName = "GoThrive.CommandClearUserProfile";
CommandClearUserProfile.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message GoThrive.CommandSetDarkMode
 */
export class CommandSetDarkMode extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool darkMode = 1;
     */
    this.darkMode = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSetDarkMode().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSetDarkMode().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSetDarkMode().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSetDarkMode, a, b);
  }
}
CommandSetDarkMode.runtime = proto3;
CommandSetDarkMode.typeName = "GoThrive.CommandSetDarkMode";
CommandSetDarkMode.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "darkMode",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.CommandLoadProfile
 */
export class CommandLoadProfile extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandLoadProfile().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandLoadProfile().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandLoadProfile().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandLoadProfile, a, b);
  }
}
CommandLoadProfile.runtime = proto3;
CommandLoadProfile.typeName = "GoThrive.CommandLoadProfile";
CommandLoadProfile.fields = proto3.util.newFieldList(() => []);
/**
 * @generated from message GoThrive.UserProfileStatus
 */
export class UserProfileStatus extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool initialised = 1;
     */
    this.initialised = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new UserProfileStatus().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new UserProfileStatus().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new UserProfileStatus().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(UserProfileStatus, a, b);
  }
}
UserProfileStatus.runtime = proto3;
UserProfileStatus.typeName = "GoThrive.UserProfileStatus";
UserProfileStatus.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "initialised",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 2,
  name: "profile",
  kind: "message",
  T: UserProfile
}]);
/**
 * @generated from message GoThrive.CommandSignEULA
 */
export class CommandSignEULA extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: bool clear = 1;
     */
    this.clear = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSignEULA().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSignEULA().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSignEULA().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSignEULA, a, b);
  }
}
CommandSignEULA.runtime = proto3;
CommandSignEULA.typeName = "GoThrive.CommandSignEULA";
CommandSignEULA.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "clear",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 2,
  name: "signup",
  kind: "message",
  T: Timestamp
}]);
/**
 * @generated from message GoThrive.CommandChangeAccessCode
 */
export class CommandChangeAccessCode extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string accessCode = 1;
     */
    this.accessCode = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChangeAccessCode().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChangeAccessCode().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChangeAccessCode().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChangeAccessCode, a, b);
  }
}
CommandChangeAccessCode.runtime = proto3;
CommandChangeAccessCode.typeName = "GoThrive.CommandChangeAccessCode";
CommandChangeAccessCode.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "accessCode",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandVerifyUserEmail
 */
export class CommandVerifyUserEmail extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string email = 1;
     */
    this.email = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandVerifyUserEmail().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandVerifyUserEmail().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandVerifyUserEmail().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandVerifyUserEmail, a, b);
  }
}
CommandVerifyUserEmail.runtime = proto3;
CommandVerifyUserEmail.typeName = "GoThrive.CommandVerifyUserEmail";
CommandVerifyUserEmail.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandVerifyUserPassword
 */
export class CommandVerifyUserPassword extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string password = 1;
     */
    this.password = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandVerifyUserPassword().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandVerifyUserPassword().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandVerifyUserPassword().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandVerifyUserPassword, a, b);
  }
}
CommandVerifyUserPassword.runtime = proto3;
CommandVerifyUserPassword.typeName = "GoThrive.CommandVerifyUserPassword";
CommandVerifyUserPassword.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "password",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChangeUserPassword
 */
export class CommandChangeUserPassword extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string password = 1;
     */
    this.password = "";
    /**
     * @generated from field: string newPassword = 2;
     */
    this.newPassword = "";
    /**
     * @generated from field: string newPasswordConfirm = 3;
     */
    this.newPasswordConfirm = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChangeUserPassword().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChangeUserPassword().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChangeUserPassword().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChangeUserPassword, a, b);
  }
}
CommandChangeUserPassword.runtime = proto3;
CommandChangeUserPassword.typeName = "GoThrive.CommandChangeUserPassword";
CommandChangeUserPassword.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "password",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "newPassword",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "newPasswordConfirm",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandChangeUserEmail
 */
export class CommandChangeUserEmail extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string email = 1;
     */
    this.email = "";
    /**
     * @generated from field: string password = 2;
     */
    this.password = "";
    /**
     * @generated from field: string TOTPType = 3;
     */
    this.TOTPType = "";
    /**
     * @generated from field: string TOTPCode = 4;
     */
    this.TOTPCode = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandChangeUserEmail().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandChangeUserEmail().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandChangeUserEmail().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandChangeUserEmail, a, b);
  }
}
CommandChangeUserEmail.runtime = proto3;
CommandChangeUserEmail.typeName = "GoThrive.CommandChangeUserEmail";
CommandChangeUserEmail.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "password",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "TOTPType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "TOTPCode",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandGetAuthStatus
 */
export class CommandGetAuthStatus extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string authorisedStatus = 1;
     */
    this.authorisedStatus = "";
    /**
     * @generated from field: string authToken = 2;
     */
    this.authToken = "";
    /**
     * @generated from field: int32 awaitFlags = 3;
     */
    this.awaitFlags = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandGetAuthStatus().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandGetAuthStatus().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandGetAuthStatus().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandGetAuthStatus, a, b);
  }
}
CommandGetAuthStatus.runtime = proto3;
CommandGetAuthStatus.typeName = "GoThrive.CommandGetAuthStatus";
CommandGetAuthStatus.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "authorisedStatus",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "authToken",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "awaitFlags",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);