// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file logs.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from enum GoThrive.LogLevel
 */
export var LogLevel;
(function (LogLevel) {
  /**
   * @generated from enum value: LOG_LEVEL_NONE = 0;
   */
  LogLevel[LogLevel["NONE"] = 0] = "NONE";
  /**
   * @generated from enum value: LOG_LEVEL_TRACE = 1;
   */
  LogLevel[LogLevel["TRACE"] = 1] = "TRACE";
  /**
   * @generated from enum value: LOG_LEVEL_DEBUG = 2;
   */
  LogLevel[LogLevel["DEBUG"] = 2] = "DEBUG";
  /**
   * @generated from enum value: LOG_LEVEL_INFO = 3;
   */
  LogLevel[LogLevel["INFO"] = 3] = "INFO";
  /**
   * @generated from enum value: LOG_LEVEL_WARN = 4;
   */
  LogLevel[LogLevel["WARN"] = 4] = "WARN";
  /**
   * @generated from enum value: LOG_LEVEL_ERROR = 5;
   */
  LogLevel[LogLevel["ERROR"] = 5] = "ERROR";
  /**
   * @generated from enum value: LOG_LEVEL_FATAL = 6;
   */
  LogLevel[LogLevel["FATAL"] = 6] = "FATAL";
  /**
   * @generated from enum value: LOG_LEVEL_PANIC = 7;
   */
  LogLevel[LogLevel["PANIC"] = 7] = "PANIC";
})(LogLevel || (LogLevel = {}));
// Retrieve enum metadata with: proto3.getEnumType(LogLevel)
proto3.util.setEnumType(LogLevel, "GoThrive.LogLevel", [{
  no: 0,
  name: "LOG_LEVEL_NONE"
}, {
  no: 1,
  name: "LOG_LEVEL_TRACE"
}, {
  no: 2,
  name: "LOG_LEVEL_DEBUG"
}, {
  no: 3,
  name: "LOG_LEVEL_INFO"
}, {
  no: 4,
  name: "LOG_LEVEL_WARN"
}, {
  no: 5,
  name: "LOG_LEVEL_ERROR"
}, {
  no: 6,
  name: "LOG_LEVEL_FATAL"
}, {
  no: 7,
  name: "LOG_LEVEL_PANIC"
}]);
/**
 * @generated from message GoThrive.CommandSetLogLevel
 */
export class CommandSetLogLevel extends Message {
  constructor(data) {
    super();
    /**
     * One of "STDOUT", "FILE", "MONITOR", "SERVER"
     *
     * @generated from field: string loggerId = 1;
     */
    this.loggerId = "";
    /**
     * NONE to turn off, or the minimum log level to be reported
     *
     * @generated from field: GoThrive.LogLevel logLevel = 2;
     */
    this.logLevel = LogLevel.NONE;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSetLogLevel().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSetLogLevel().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSetLogLevel().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSetLogLevel, a, b);
  }
}
CommandSetLogLevel.runtime = proto3;
CommandSetLogLevel.typeName = "GoThrive.CommandSetLogLevel";
CommandSetLogLevel.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "loggerId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "logLevel",
  kind: "enum",
  T: proto3.getEnumType(LogLevel)
}]);
/**
 * @generated from message GoThrive.CommandSetLogFilename
 */
export class CommandSetLogFilename extends Message {
  constructor(data) {
    super();
    /**
     * File to log to, if logging to a file is enabled
     *
     * @generated from field: string logFilename = 1;
     */
    this.logFilename = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandSetLogFilename().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandSetLogFilename().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandSetLogFilename().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandSetLogFilename, a, b);
  }
}
CommandSetLogFilename.runtime = proto3;
CommandSetLogFilename.typeName = "GoThrive.CommandSetLogFilename";
CommandSetLogFilename.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "logFilename",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CommandLogMessage
 */
export class CommandLogMessage extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string logMessage = 1;
     */
    this.logMessage = "";
    /**
     * @generated from field: GoThrive.LogLevel logLevel = 2;
     */
    this.logLevel = LogLevel.NONE;
    /**
     * @generated from field: string fileName = 3;
     */
    this.fileName = "";
    /**
     * @generated from field: string function = 4;
     */
    this.function = "";
    /**
     * @generated from field: int32 line = 5;
     */
    this.line = 0;
    /**
     * @generated from field: int32 column = 6;
     */
    this.column = 0;
    /**
     * Any additional key/value pairs to log
     *
     * @generated from field: repeated GoThrive.LogField fields = 7;
     */
    this.fields = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandLogMessage().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandLogMessage().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandLogMessage().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandLogMessage, a, b);
  }
}
CommandLogMessage.runtime = proto3;
CommandLogMessage.typeName = "GoThrive.CommandLogMessage";
CommandLogMessage.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "logMessage",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "logLevel",
  kind: "enum",
  T: proto3.getEnumType(LogLevel)
}, {
  no: 3,
  name: "fileName",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "function",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "line",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 6,
  name: "column",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 7,
  name: "fields",
  kind: "message",
  T: LogField,
  repeated: true
}]);
/**
 * @generated from message GoThrive.LogField
 */
export class LogField extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string value = 2;
     */
    this.value = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new LogField().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new LogField().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new LogField().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(LogField, a, b);
  }
}
LogField.runtime = proto3;
LogField.typeName = "GoThrive.LogField";
LogField.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "value",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * Flushes any log buffers, before the app is paused or closes.
 *
 * @generated from message GoThrive.CommandFlushLogs
 */
export class CommandFlushLogs extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CommandFlushLogs().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CommandFlushLogs().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CommandFlushLogs().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CommandFlushLogs, a, b);
  }
}
CommandFlushLogs.runtime = proto3;
CommandFlushLogs.typeName = "GoThrive.CommandFlushLogs";
CommandFlushLogs.fields = proto3.util.newFieldList(() => []);