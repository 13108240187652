// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file monitor.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.MemoryUsageRequest
 */
export class MemoryUsageRequest extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sessionId = 1;
     */
    this.sessionId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MemoryUsageRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MemoryUsageRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MemoryUsageRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MemoryUsageRequest, a, b);
  }
}
MemoryUsageRequest.runtime = proto3;
MemoryUsageRequest.typeName = "GoThrive.MemoryUsageRequest";
MemoryUsageRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.MemoryUsageResponse
 */
export class MemoryUsageResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: uint64 Alloc = 1;
     */
    this.Alloc = protoInt64.zero;
    /**
     * @generated from field: uint64 TotalAlloc = 2;
     */
    this.TotalAlloc = protoInt64.zero;
    /**
     * @generated from field: uint64 Sys = 3;
     */
    this.Sys = protoInt64.zero;
    /**
     * @generated from field: uint32 NumGC = 4;
     */
    this.NumGC = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MemoryUsageResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MemoryUsageResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MemoryUsageResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MemoryUsageResponse, a, b);
  }
}
MemoryUsageResponse.runtime = proto3;
MemoryUsageResponse.typeName = "GoThrive.MemoryUsageResponse";
MemoryUsageResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Alloc",
  kind: "scalar",
  T: 4 /* ScalarType.UINT64 */
}, {
  no: 2,
  name: "TotalAlloc",
  kind: "scalar",
  T: 4 /* ScalarType.UINT64 */
}, {
  no: 3,
  name: "Sys",
  kind: "scalar",
  T: 4 /* ScalarType.UINT64 */
}, {
  no: 4,
  name: "NumGC",
  kind: "scalar",
  T: 13 /* ScalarType.UINT32 */
}]);
/**
 * @generated from message GoThrive.ViewLogsRequest
 */
export class ViewLogsRequest extends Message {
  constructor(data) {
    super();
    /**
     * Number of last message received, or -1.
     *
     * @generated from field: int32 lastMessageReceived = 1;
     */
    this.lastMessageReceived = 0;
    /**
     * Identifies the app the last message was received from.
     *
     * @generated from field: int64 appStartTime = 2;
     */
    this.appStartTime = protoInt64.zero;
    /**
     * @generated from field: string sessionId = 3;
     */
    this.sessionId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ViewLogsRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ViewLogsRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ViewLogsRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ViewLogsRequest, a, b);
  }
}
ViewLogsRequest.runtime = proto3;
ViewLogsRequest.typeName = "GoThrive.ViewLogsRequest";
ViewLogsRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "lastMessageReceived",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "appStartTime",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 3,
  name: "sessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.LogMessage
 */
export class LogMessage extends Message {
  constructor(data) {
    super();
    /**
     * Log message JSON.
     *
     * @generated from field: string message = 1;
     */
    this.message = "";
    /**
     * Message number, from 0.
     *
     * @generated from field: int32 messageNum = 2;
     */
    this.messageNum = 0;
    /**
     * Identifies the app the last message was received from.
     *
     * @generated from field: int64 appStartTime = 3;
     */
    this.appStartTime = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new LogMessage().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new LogMessage().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new LogMessage().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(LogMessage, a, b);
  }
}
LogMessage.runtime = proto3;
LogMessage.typeName = "GoThrive.LogMessage";
LogMessage.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "message",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "messageNum",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "appStartTime",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);
/**
 * @generated from message GoThrive.ListDirectoryRequest
 */
export class ListDirectoryRequest extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sessionId = 1;
     */
    this.sessionId = "";
    /**
     * @generated from field: string path = 2;
     */
    this.path = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ListDirectoryRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ListDirectoryRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ListDirectoryRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ListDirectoryRequest, a, b);
  }
}
ListDirectoryRequest.runtime = proto3;
ListDirectoryRequest.typeName = "GoThrive.ListDirectoryRequest";
ListDirectoryRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "path",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.FileInfo
 */
export class FileInfo extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string filename = 1;
     */
    this.filename = "";
    /**
     * @generated from field: uint32 mode = 2;
     */
    this.mode = 0;
    /**
     * @generated from field: int64 size = 3;
     */
    this.size = protoInt64.zero;
    /**
     * @generated from field: int64 modificationTime = 4;
     */
    this.modificationTime = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new FileInfo().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new FileInfo().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new FileInfo().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(FileInfo, a, b);
  }
}
FileInfo.runtime = proto3;
FileInfo.typeName = "GoThrive.FileInfo";
FileInfo.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "filename",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "mode",
  kind: "scalar",
  T: 13 /* ScalarType.UINT32 */
}, {
  no: 3,
  name: "size",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 4,
  name: "modificationTime",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);
/**
 * @generated from message GoThrive.ListDirectoryResponse
 */
export class ListDirectoryResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string path = 1;
     */
    this.path = "";
    /**
     * @generated from field: repeated GoThrive.FileInfo files = 2;
     */
    this.files = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ListDirectoryResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ListDirectoryResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ListDirectoryResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ListDirectoryResponse, a, b);
  }
}
ListDirectoryResponse.runtime = proto3;
ListDirectoryResponse.typeName = "GoThrive.ListDirectoryResponse";
ListDirectoryResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "path",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "files",
  kind: "message",
  T: FileInfo,
  repeated: true
}]);
/**
 * @generated from message GoThrive.ChangeDirectoryRequest
 */
export class ChangeDirectoryRequest extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sessionId = 1;
     */
    this.sessionId = "";
    /**
     * @generated from field: string path = 2;
     */
    this.path = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChangeDirectoryRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChangeDirectoryRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChangeDirectoryRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChangeDirectoryRequest, a, b);
  }
}
ChangeDirectoryRequest.runtime = proto3;
ChangeDirectoryRequest.typeName = "GoThrive.ChangeDirectoryRequest";
ChangeDirectoryRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "path",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.ChangeDirectoryResponse
 */
export class ChangeDirectoryResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string path = 1;
     */
    this.path = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new ChangeDirectoryResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new ChangeDirectoryResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new ChangeDirectoryResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(ChangeDirectoryResponse, a, b);
  }
}
ChangeDirectoryResponse.runtime = proto3;
ChangeDirectoryResponse.typeName = "GoThrive.ChangeDirectoryResponse";
ChangeDirectoryResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "path",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.MakeDirectoryRequest
 */
export class MakeDirectoryRequest extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sessionId = 1;
     */
    this.sessionId = "";
    /**
     * @generated from field: string path = 2;
     */
    this.path = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MakeDirectoryRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MakeDirectoryRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MakeDirectoryRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MakeDirectoryRequest, a, b);
  }
}
MakeDirectoryRequest.runtime = proto3;
MakeDirectoryRequest.typeName = "GoThrive.MakeDirectoryRequest";
MakeDirectoryRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "path",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.MakeDirectoryResponse
 */
export class MakeDirectoryResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string path = 1;
     */
    this.path = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MakeDirectoryResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MakeDirectoryResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MakeDirectoryResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MakeDirectoryResponse, a, b);
  }
}
MakeDirectoryResponse.runtime = proto3;
MakeDirectoryResponse.typeName = "GoThrive.MakeDirectoryResponse";
MakeDirectoryResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "path",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.RemoveDirectoryRequest
 */
export class RemoveDirectoryRequest extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sessionId = 1;
     */
    this.sessionId = "";
    /**
     * @generated from field: string path = 2;
     */
    this.path = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RemoveDirectoryRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RemoveDirectoryRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RemoveDirectoryRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RemoveDirectoryRequest, a, b);
  }
}
RemoveDirectoryRequest.runtime = proto3;
RemoveDirectoryRequest.typeName = "GoThrive.RemoveDirectoryRequest";
RemoveDirectoryRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "path",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.RemoveDirectoryResponse
 */
export class RemoveDirectoryResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string path = 1;
     */
    this.path = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RemoveDirectoryResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RemoveDirectoryResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RemoveDirectoryResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RemoveDirectoryResponse, a, b);
  }
}
RemoveDirectoryResponse.runtime = proto3;
RemoveDirectoryResponse.typeName = "GoThrive.RemoveDirectoryResponse";
RemoveDirectoryResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "path",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CopyFilesRequest
 */
export class CopyFilesRequest extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sessionId = 1;
     */
    this.sessionId = "";
    /**
     * files to copy, which can include globs e.g. "*.zlib"
     *
     * @generated from field: repeated string filenames = 2;
     */
    this.filenames = [];
    /**
     * destination file or directory
     *
     * @generated from field: string destination = 3;
     */
    this.destination = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CopyFilesRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CopyFilesRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CopyFilesRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CopyFilesRequest, a, b);
  }
}
CopyFilesRequest.runtime = proto3;
CopyFilesRequest.typeName = "GoThrive.CopyFilesRequest";
CopyFilesRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "filenames",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 3,
  name: "destination",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.CopyFilesResponse
 */
export class CopyFilesResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 filesCopied = 1;
     */
    this.filesCopied = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new CopyFilesResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new CopyFilesResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new CopyFilesResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(CopyFilesResponse, a, b);
  }
}
CopyFilesResponse.runtime = proto3;
CopyFilesResponse.typeName = "GoThrive.CopyFilesResponse";
CopyFilesResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "filesCopied",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.RemoveFilesRequest
 */
export class RemoveFilesRequest extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sessionId = 1;
     */
    this.sessionId = "";
    /**
     * files to remove, which can include globs e.g. "*.zlib"
     *
     * @generated from field: repeated string filenames = 2;
     */
    this.filenames = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RemoveFilesRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RemoveFilesRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RemoveFilesRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RemoveFilesRequest, a, b);
  }
}
RemoveFilesRequest.runtime = proto3;
RemoveFilesRequest.typeName = "GoThrive.RemoveFilesRequest";
RemoveFilesRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "filenames",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
/**
 * @generated from message GoThrive.RemoveFilesResponse
 */
export class RemoveFilesResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 filesRemoved = 1;
     */
    this.filesRemoved = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RemoveFilesResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RemoveFilesResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RemoveFilesResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RemoveFilesResponse, a, b);
  }
}
RemoveFilesResponse.runtime = proto3;
RemoveFilesResponse.typeName = "GoThrive.RemoveFilesResponse";
RemoveFilesResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "filesRemoved",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.MoveFilesRequest
 */
export class MoveFilesRequest extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sessionId = 1;
     */
    this.sessionId = "";
    /**
     * files to move, which can include globs e.g. "*.zlib"
     *
     * @generated from field: repeated string filenames = 2;
     */
    this.filenames = [];
    /**
     * destination file or directory
     *
     * @generated from field: string destination = 3;
     */
    this.destination = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoveFilesRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoveFilesRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoveFilesRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoveFilesRequest, a, b);
  }
}
MoveFilesRequest.runtime = proto3;
MoveFilesRequest.typeName = "GoThrive.MoveFilesRequest";
MoveFilesRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "filenames",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 3,
  name: "destination",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.MoveFilesResponse
 */
export class MoveFilesResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 filesMoved = 1;
     */
    this.filesMoved = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new MoveFilesResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new MoveFilesResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new MoveFilesResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(MoveFilesResponse, a, b);
  }
}
MoveFilesResponse.runtime = proto3;
MoveFilesResponse.typeName = "GoThrive.MoveFilesResponse";
MoveFilesResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "filesMoved",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
/**
 * @generated from message GoThrive.DateRequest
 */
export class DateRequest extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sessionId = 1;
     */
    this.sessionId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new DateRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new DateRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new DateRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(DateRequest, a, b);
  }
}
DateRequest.runtime = proto3;
DateRequest.typeName = "GoThrive.DateRequest";
DateRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.DateResponse
 */
export class DateResponse extends Message {
  constructor(data) {
    super();
    /**
     * e.g. "Mon, 02 Jan 2006 15:04:05 -0700"
     *
     * @generated from field: string date = 1;
     */
    this.date = "";
    /**
     * Unix time - seconds elapsed since January 1, 1970 UTC
     *
     * @generated from field: int64 time = 2;
     */
    this.time = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new DateResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new DateResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new DateResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(DateResponse, a, b);
  }
}
DateResponse.runtime = proto3;
DateResponse.typeName = "GoThrive.DateResponse";
DateResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "date",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "time",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);
/**
 * @generated from message GoThrive.DiskSpaceRequest
 */
export class DiskSpaceRequest extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sessionId = 1;
     */
    this.sessionId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new DiskSpaceRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new DiskSpaceRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new DiskSpaceRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(DiskSpaceRequest, a, b);
  }
}
DiskSpaceRequest.runtime = proto3;
DiskSpaceRequest.typeName = "GoThrive.DiskSpaceRequest";
DiskSpaceRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.DiskSpaceResponse
 */
export class DiskSpaceResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: uint64 diskSpace = 1;
     */
    this.diskSpace = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new DiskSpaceResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new DiskSpaceResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new DiskSpaceResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(DiskSpaceResponse, a, b);
  }
}
DiskSpaceResponse.runtime = proto3;
DiskSpaceResponse.typeName = "GoThrive.DiskSpaceResponse";
DiskSpaceResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "diskSpace",
  kind: "scalar",
  T: 4 /* ScalarType.UINT64 */
}]);
/**
 * @generated from message GoThrive.DirectoryTreeRequest
 */
export class DirectoryTreeRequest extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sessionId = 1;
     */
    this.sessionId = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new DirectoryTreeRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new DirectoryTreeRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new DirectoryTreeRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(DirectoryTreeRequest, a, b);
  }
}
DirectoryTreeRequest.runtime = proto3;
DirectoryTreeRequest.typeName = "GoThrive.DirectoryTreeRequest";
DirectoryTreeRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.Directory
 */
export class Directory extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string path = 1;
     */
    this.path = "";
    /**
     * @generated from field: repeated GoThrive.Directory children = 2;
     */
    this.children = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Directory().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Directory().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Directory().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Directory, a, b);
  }
}
Directory.runtime = proto3;
Directory.typeName = "GoThrive.Directory";
Directory.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "path",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "children",
  kind: "message",
  T: Directory,
  repeated: true
}]);
/**
 * @generated from message GoThrive.DirectoryTreeResponse
 */
export class DirectoryTreeResponse extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new DirectoryTreeResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new DirectoryTreeResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new DirectoryTreeResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(DirectoryTreeResponse, a, b);
  }
}
DirectoryTreeResponse.runtime = proto3;
DirectoryTreeResponse.typeName = "GoThrive.DirectoryTreeResponse";
DirectoryTreeResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "directory",
  kind: "message",
  T: Directory
}]);
/**
 * @generated from message GoThrive.UploadFileChunkRequest
 */
export class UploadFileChunkRequest extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string sessionId = 1;
     */
    this.sessionId = "";
    /**
     * @generated from field: string path = 2;
     */
    this.path = "";
    /**
     * @generated from field: bytes data = 3;
     */
    this.data = new Uint8Array(0);
    /**
     * If this is the first chunk of the file being uploaded.
     *
     * @generated from field: bool firstChunk = 4;
     */
    this.firstChunk = false;
    /**
     * If this is the last chunk of the file being uploaded.
     *
     * @generated from field: bool lastChunk = 5;
     */
    this.lastChunk = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new UploadFileChunkRequest().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new UploadFileChunkRequest().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new UploadFileChunkRequest().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(UploadFileChunkRequest, a, b);
  }
}
UploadFileChunkRequest.runtime = proto3;
UploadFileChunkRequest.typeName = "GoThrive.UploadFileChunkRequest";
UploadFileChunkRequest.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "sessionId",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "path",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "data",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}, {
  no: 4,
  name: "firstChunk",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 5,
  name: "lastChunk",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.UploadFileChunkResponse
 */
export class UploadFileChunkResponse extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string path = 1;
     */
    this.path = "";
    /**
     * @generated from field: int64 bytesWritten = 2;
     */
    this.bytesWritten = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new UploadFileChunkResponse().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new UploadFileChunkResponse().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new UploadFileChunkResponse().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(UploadFileChunkResponse, a, b);
  }
}
UploadFileChunkResponse.runtime = proto3;
UploadFileChunkResponse.typeName = "GoThrive.UploadFileChunkResponse";
UploadFileChunkResponse.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "path",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "bytesWritten",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);