// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file pushNotifications.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.PushNotificationsUrlParams
 */
export class PushNotificationsUrlParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string token = 1;
     */
    this.token = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PushNotificationsUrlParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PushNotificationsUrlParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PushNotificationsUrlParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PushNotificationsUrlParams, a, b);
  }
}
PushNotificationsUrlParams.runtime = proto3;
PushNotificationsUrlParams.typeName = "GoThrive.PushNotificationsUrlParams";
PushNotificationsUrlParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "token",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.PushNotificationsEnableParams
 */
export class PushNotificationsEnableParams extends Message {
  constructor(data) {
    super();
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PushNotificationsEnableParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PushNotificationsEnableParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PushNotificationsEnableParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PushNotificationsEnableParams, a, b);
  }
}
PushNotificationsEnableParams.runtime = proto3;
PushNotificationsEnableParams.typeName = "GoThrive.PushNotificationsEnableParams";
PushNotificationsEnableParams.fields = proto3.util.newFieldList(() => [{
  no: 2,
  name: "enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */,
  opt: true
}]);