// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file assessmentScreen.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, BottomBar2, NavigationBar } from "./common_pb.js";
/**
 * @generated from message GoThrive.AssessmentScreen
 */
export class AssessmentScreen extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: repeated string optionKeys = 5;
     */
    this.optionKeys = [];
    /**
     * @generated from field: repeated GoThrive.AssessmentScreenOption data = 6;
     */
    this.data = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentScreen().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentScreen().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentScreen().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentScreen, a, b);
  }
}
AssessmentScreen.runtime = proto3;
AssessmentScreen.typeName = "GoThrive.AssessmentScreen";
AssessmentScreen.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 3,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 4,
  name: "bottomBar",
  kind: "message",
  T: BottomBar2
}, {
  no: 5,
  name: "optionKeys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 6,
  name: "data",
  kind: "message",
  T: AssessmentScreenOption,
  repeated: true
}]);
/**
 * @generated from message GoThrive.AssessmentScreenOption
 */
export class AssessmentScreenOption extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: bytes body = 3;
     */
    this.body = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentScreenOption().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentScreenOption().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentScreenOption().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentScreenOption, a, b);
  }
}
AssessmentScreenOption.runtime = proto3;
AssessmentScreenOption.typeName = "GoThrive.AssessmentScreenOption";
AssessmentScreenOption.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "body",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
/**
 * @generated from message GoThrive.AssessmentScreenCatalog
 */
export class AssessmentScreenCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.AssessmentScreen> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new AssessmentScreenCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new AssessmentScreenCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new AssessmentScreenCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(AssessmentScreenCatalog, a, b);
  }
}
AssessmentScreenCatalog.runtime = proto3;
AssessmentScreenCatalog.typeName = "GoThrive.AssessmentScreenCatalog";
AssessmentScreenCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: AssessmentScreen
  }
}]);