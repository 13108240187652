// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file timeline.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
import { Background, MediaPopup, NavigationBar } from "./common_pb.js";
/**
 * @generated from message GoThrive.TimelineTrack
 */
export class TimelineTrack extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: string type = 2;
     */
    this.type = "";
    /**
     * @generated from field: string asset = 3;
     */
    this.asset = "";
    /**
     * @generated from field: bool mainTrack = 4;
     */
    this.mainTrack = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TimelineTrack().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TimelineTrack().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TimelineTrack().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TimelineTrack, a, b);
  }
}
TimelineTrack.runtime = proto3;
TimelineTrack.typeName = "GoThrive.TimelineTrack";
TimelineTrack.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "asset",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "mainTrack",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
/**
 * @generated from message GoThrive.Timeline
 */
export class Timeline extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: int32 duration = 2;
     */
    this.duration = 0;
    /**
     * @generated from field: repeated string trackIds = 3;
     */
    this.trackIds = [];
    /**
     * @generated from field: repeated GoThrive.TimelineTrack tracks = 4;
     */
    this.tracks = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new Timeline().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Timeline().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Timeline().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Timeline, a, b);
  }
}
Timeline.runtime = proto3;
Timeline.typeName = "GoThrive.Timeline";
Timeline.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "duration",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "trackIds",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 4,
  name: "tracks",
  kind: "message",
  T: TimelineTrack,
  repeated: true
}, {
  no: 5,
  name: "configuration",
  kind: "message",
  T: TimelineConfiguration
}]);
/**
 * @generated from message GoThrive.TimelineCatalog
 */
export class TimelineCatalog extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: map<string, GoThrive.Timeline> contentMap = 1;
     */
    this.contentMap = {};
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TimelineCatalog().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TimelineCatalog().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TimelineCatalog().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TimelineCatalog, a, b);
  }
}
TimelineCatalog.runtime = proto3;
TimelineCatalog.typeName = "GoThrive.TimelineCatalog";
TimelineCatalog.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "contentMap",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: Timeline
  }
}]);
/**
 * @generated from message GoThrive.TimelineConfiguration
 */
export class TimelineConfiguration extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string key = 1;
     */
    this.key = "";
    /**
     * @generated from field: int32 skipSeconds = 4;
     */
    this.skipSeconds = 0;
    /**
     * @generated from field: bool showNativeMediaWidget = 5;
     */
    this.showNativeMediaWidget = false;
    /**
     * @generated from field: string nativeMediaWidgetText = 6;
     */
    this.nativeMediaWidgetText = "";
    /**
     * @generated from field: string nativeMediaWidgetImage = 7;
     */
    this.nativeMediaWidgetImage = "";
    /**
     * @generated from field: bool showProgressBar = 8;
     */
    this.showProgressBar = false;
    /**
     * @generated from field: string progressBarStyleKey = 9;
     */
    this.progressBarStyleKey = "";
    /**
     * @generated from field: bool showTimeSlider = 10;
     */
    this.showTimeSlider = false;
    /**
     * @generated from field: string timeSliderStyleKey = 11;
     */
    this.timeSliderStyleKey = "";
    /**
     * @generated from field: bool showMediaControl = 12;
     */
    this.showMediaControl = false;
    /**
     * @generated from field: string mediaControlStyleKey = 13;
     */
    this.mediaControlStyleKey = "";
    /**
     * @generated from field: bool showSubtitles = 14;
     */
    this.showSubtitles = false;
    /**
     * @generated from field: string subtitleStyleKey = 15;
     */
    this.subtitleStyleKey = "";
    /**
     * @generated from field: bool repeated = 16;
     */
    this.repeated = false;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new TimelineConfiguration().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new TimelineConfiguration().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new TimelineConfiguration().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(TimelineConfiguration, a, b);
  }
}
TimelineConfiguration.runtime = proto3;
TimelineConfiguration.typeName = "GoThrive.TimelineConfiguration";
TimelineConfiguration.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "background",
  kind: "message",
  T: Background
}, {
  no: 3,
  name: "navigationBar",
  kind: "message",
  T: NavigationBar
}, {
  no: 4,
  name: "skipSeconds",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 5,
  name: "showNativeMediaWidget",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 6,
  name: "nativeMediaWidgetText",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "nativeMediaWidgetImage",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "showProgressBar",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 9,
  name: "progressBarStyleKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 10,
  name: "showTimeSlider",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 11,
  name: "timeSliderStyleKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 12,
  name: "showMediaControl",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 13,
  name: "mediaControlStyleKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 14,
  name: "showSubtitles",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 15,
  name: "subtitleStyleKey",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 16,
  name: "repeated",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 17,
  name: "mediaPopupView",
  kind: "message",
  T: MediaPopup
}]);