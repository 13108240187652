// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file patientMomentsParams.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.PatientMomentsParams
 */
export class PatientMomentsParams extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: repeated string types = 1;
     */
    this.types = [];
    /**
     * @generated from field: int64 startFrom = 2;
     */
    this.startFrom = protoInt64.zero;
    /**
     * @generated from field: int32 limit = 3;
     */
    this.limit = 0;
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PatientMomentsParams().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PatientMomentsParams().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PatientMomentsParams().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PatientMomentsParams, a, b);
  }
}
PatientMomentsParams.runtime = proto3;
PatientMomentsParams.typeName = "GoThrive.PatientMomentsParams";
PatientMomentsParams.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "types",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 2,
  name: "startFrom",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 3,
  name: "limit",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "paginationCursor",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}]);