// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file patientNoteRequestBody.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.PatientNoteRequestBody
 */
export class PatientNoteRequestBody extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string text = 1;
     */
    this.text = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new PatientNoteRequestBody().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new PatientNoteRequestBody().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new PatientNoteRequestBody().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(PatientNoteRequestBody, a, b);
  }
}
PatientNoteRequestBody.runtime = proto3;
PatientNoteRequestBody.typeName = "GoThrive.PatientNoteRequestBody";
PatientNoteRequestBody.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "text",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);