// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file riskFactor.proto (package GoThrive, syntax proto3)
/* eslint-disable */
// @ts-nocheck
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
/**
 * @generated from message GoThrive.RiskFactor
 */
export class RiskFactor extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: string type = 1;
     */
    this.type = "";
    /**
     * @generated from field: int64 ts = 2;
     */
    this.ts = protoInt64.zero;
    /**
     * @generated from field: string id = 3;
     */
    this.id = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RiskFactor().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RiskFactor().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RiskFactor().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RiskFactor, a, b);
  }
}
RiskFactor.runtime = proto3;
RiskFactor.typeName = "GoThrive.RiskFactor";
RiskFactor.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "ts",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 3,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "data",
  kind: "message",
  T: RiskFactor_Data
}]);
/**
 * @generated from message GoThrive.RiskFactor.Data
 */
export class RiskFactor_Data extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: int32 duration = 1;
     */
    this.duration = 0;
    /**
     * @generated from field: int64 ts = 3;
     */
    this.ts = protoInt64.zero;
    /**
     * @generated from field: string type = 4;
     */
    this.type = "";
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RiskFactor_Data().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RiskFactor_Data().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RiskFactor_Data().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RiskFactor_Data, a, b);
  }
}
RiskFactor_Data.runtime = proto3;
RiskFactor_Data.typeName = "GoThrive.RiskFactor.Data";
RiskFactor_Data.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "duration",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "results",
  kind: "message",
  T: RiskFactor_Data_Results
}, {
  no: 3,
  name: "ts",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}, {
  no: 4,
  name: "type",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
/**
 * @generated from message GoThrive.RiskFactor.Data.Results
 */
export class RiskFactor_Data_Results extends Message {
  constructor(data) {
    super();
    /**
     * @generated from field: float Anxiety = 1;
     */
    this.Anxiety = 0;
    /**
     * @generated from field: float Depression = 2;
     */
    this.Depression = 0;
    /**
     * @generated from field: repeated int32 QuestionResults = 3;
     */
    this.QuestionResults = [];
    /**
     * @generated from field: repeated string QuestionsAsked = 4;
     */
    this.QuestionsAsked = [];
    /**
     * @generated from field: map<string, int32> Scores = 5;
     */
    this.Scores = {};
    /**
     * @generated from field: repeated string QuestionsOptions = 7;
     */
    this.QuestionsOptions = [];
    proto3.util.initPartial(data, this);
  }
  static fromBinary(bytes, options) {
    return new RiskFactor_Data_Results().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new RiskFactor_Data_Results().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new RiskFactor_Data_Results().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(RiskFactor_Data_Results, a, b);
  }
}
RiskFactor_Data_Results.runtime = proto3;
RiskFactor_Data_Results.typeName = "GoThrive.RiskFactor.Data.Results";
RiskFactor_Data_Results.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "Anxiety",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}, {
  no: 2,
  name: "Depression",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}, {
  no: 3,
  name: "QuestionResults",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */,
  repeated: true
}, {
  no: 4,
  name: "QuestionsAsked",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 5,
  name: "Scores",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "scalar",
    T: 5 /* ScalarType.INT32 */
  }
}, {
  no: 6,
  name: "FuzzNetType",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  opt: true
}, {
  no: 7,
  name: "QuestionsOptions",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);